import { Organization } from '@/lib/graphql/types'
import { useQuery, useReactiveVar } from '@apollo/client'
import {
  Box,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectProps,
  Stack,
  Typography,
} from '@mui/material'
import { ORGANIZATIONS_LIST } from 'lib/graphql/listOrganizations.ts'
import React, { useEffect, useState } from 'react'
import { organizationId } from 'config/cache.ts'
import { selectedOrganization } from 'config/cache.ts'

export const OrganizationSelect = ({ name, label, value, onChange }: SelectProps<string>) => {
  const [defaultVal, setDefaultVal] = useState<string | undefined>()

  const { data, loading, error } = useQuery(ORGANIZATIONS_LIST, {
    variables: {
      take: 100,
      skip: 0,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (data && data.listOrganizations.length == 1) {
      organizationId(data.listOrganizations[0].id)
      selectedOrganization(data.listOrganizations[0])
    }
  }, [data])

  if (loading || !data) {
    return <LinearProgress />
  }

  if (error) {
    return <>Error loading organizations</>
  }

  if (data.listOrganizations.length < 1) {
    return <Box>You do not have access to any organizations. Please contact support.</Box>
  }

  if (data.listOrganizations.length == 1) {
    return (
      <Box>
        <Stack direction={'column'}>
          <Box>
            <Typography variant={'body2'}>Organization</Typography>
          </Box>
          <Box sx={{ paddingLeft: '20px', paddingTop: '10px' }}>
            <Typography variant={'body1'} sx={{ fontWeight: '410' }}>
              {data.listOrganizations[0].name}
            </Typography>
          </Box>
        </Stack>
      </Box>
    )
  }

  const localOnChange = (v: any, w: any) => {
    selectedOrganization((data.listOrganizations || []).find((x: Organization) => x.id === v.target.value))
    onChange && onChange(v, w)
  }
  return (
    <FormControl sx={{ m: 0, width: '100%' }}>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        name={name}
        fullWidth
        defaultValue={defaultVal}
        variant='standard'
        value={value || ''}
        onChange={localOnChange}
        sx={{ marginTop: '15px' }}
      >
        {(data.listOrganizations || []).map((org: Organization) => {
          return (
            <MenuItem key={org.id} value={org.id}>
              <Typography variant={'body1'} sx={{ fontWeight: '410' }}>
                {org.name}
              </Typography>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
