import React from 'react'
import { Button, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { FormikProps, useFormik } from 'formik'
import { CREATE_BRAND } from 'lib/graphql/createBrand'
import { UPDATE_BRAND } from 'lib/graphql/updateBrand'
import { useMutation } from '@apollo/client'
import * as Yup from 'yup'

type BrandFieldProps = {
  id?: string
  name: string
}

type BrandFormProps = FormikProps<BrandFieldProps> & {
  submitText: string
  closeDialog: () => void
}

const DisplayingErrorMessagesSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
})

const BrandForm = ({
  values,
  handleChange,
  errors,
  touched,
  handleSubmit,
  handleBlur,
  submitText,
  dirty,
  isValid,
  isSubmitting,
  closeDialog,
}: BrandFormProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <input type='hidden' name='id' value={values.id} />
      <DialogContent>
        <TextField
          id='name'
          title='name'
          label='Brand Name'
          type='text'
          fullWidth
          variant='standard'
          value={values.name}
          error={touched.name && Boolean(errors.name)}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.name && errors.name}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isSubmitting}
          onClick={() => {
            closeDialog()
          }}
        >
          Cancel
        </Button>
        <Button type='submit' disabled={isSubmitting || !(dirty && isValid)}>
          {submitText}
        </Button>
      </DialogActions>
    </form>
  )
}

interface CreateOrUpdateBrandProps {
  mode: 'create' | 'update'
  organizationId: string
  data: any
  onSuccess: () => void
  closeDialog: () => void
}

export const BrandCreateOrUpdateDialog: React.FC<CreateOrUpdateBrandProps> = ({
  mode,
  data,
  organizationId,
  onSuccess,
  closeDialog,
}) => {
  const [createBrand] = useMutation(CREATE_BRAND, {
    onCompleted() {
      onSuccess()
    },
  })

  const [updateBrand] = useMutation(UPDATE_BRAND, {
    onCompleted() {
      onSuccess()
    },
  })

  const initialValues = {
    name: '',
  }

  const transformDataToFormValues = (data: any) => {
    return {
      id: data.id,
      name: data.brand,
    }
  }

  const create = async (values: BrandFieldProps) => {
    await createBrand({
      variables: {
        data: {
          name: values.name,
          organization: {
            connect: {
              id: organizationId,
            },
          },
        },
      },
    })
  }

  const update = async (values: BrandFieldProps) => {
    await updateBrand({
      variables: {
        where: {
          id: values.id,
        },
        data: {
          name: values.name,
        },
      },
    })
  }

  const createFormik = useFormik<BrandFieldProps>({
    initialValues: mode === 'update' ? transformDataToFormValues(data) : initialValues,
    validationSchema: DisplayingErrorMessagesSchema,
    onSubmit: async (values: BrandFieldProps) => {
      await (mode === 'create' ? create(values) : update(values))
      closeDialog()
    },
  })

  if (!data && mode === 'update') {
    return <></>
  }

  return (
    <>
      <DialogTitle>{mode === 'update' ? 'Update' : 'Create New'} Brand</DialogTitle>
      <BrandForm
        {...createFormik}
        submitText={mode === 'update' ? 'Update' : 'Create'}
        closeDialog={closeDialog}
      />
    </>
  )
}
