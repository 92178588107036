import { z } from 'zod'
import { MailingAddress, Money } from 'pages/VendorApp/Shop/types.ts'

export type User = {
  id: string
  sub: string
  email: string
}

export type ConnectMessage = {
  requestType: string
}

export type Order = {
  id: string
  commonId: string
}
export type OrderLabel = {
  id: string
  orderId: string
  order: {
    orderNumber: string
  }
  sku: {
    sku: string
    skuWhiteLabel: string
    skuFact: {
      dimensions: string
    }
  }
  lot: {
    lotNumber: string
    expirationDate: string
  }
  bucketKey: string
  printed: boolean
  url?: string
}

export type LabelApprovalWorkflowConfiguration = {
  steps: {
    name: string
    stepNumber: number
    description: string
    assignedToId: string
  }[]
}
export type LabelApprovalWorkflowStep = {
  id: string
  stepNumber: number
  name: string
  workflowId: string
  assignedToId: string
  notes: string
  status: string
  createdAt: string
  updatedAt: string
  assignedTo: User
  rejectionReason?: string
}

export type SkuFact = {
  cleanName: string
}

export const SkuDetails = z.object({
  product_supplement_facts: z.string().optional(),
  product_suggested_use: z.string().optional(),
  product_allergy_statement: z.string().optional(),
  product_caution: z.string().optional(),
  product_ingredients: z.string().optional(),
  product_name: z.string(),
  product_fda_disclaimer: z.string().optional(),
  product_prop_65_warning: z.string().optional(),
  lot: z.string().optional(),
  expiration: z.string().optional(),
  revision_number: z.string().optional(),
  label_facts_version: z.string().optional(),
  practitioner_name: z.string().optional(),
  practitioner_practice_name: z.string().optional(),
  practitioner_address: z.string().optional(),
  practitioner_city: z.string().optional(),
  practitioner_state: z.string().optional(),
  practitioner_zip: z.string().optional(),
  practitioner_metawebsite_url: z.string().optional(),
  practitioner_contact_email: z.string().optional(),
  practitioner_contact_phone: z.string().optional(),
  product_distributedby: z.string().optional(),
})
export type SkuDetails = z.infer<typeof SkuDetails>

export type ShopifyMetafield = {
  id?: string
  key?: string
  namespace?: string
  value: string
}

export type ShopifyPageInfo = {
  hasNextPage: boolean
  hasPreviousPage: boolean
  startCursor: string
  endCursor: string
}

export type ShopifyCustomer = {
  id: string
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  displayName?: string
  brandAcl?: ShopifyMetafield
  defaultAddress?: MailingAddress
  website?: ShopifyMetafield
  addresses?: ShopifyAddress[]
  privateLabels?: ShopifyMetafield
}

export type OrderLineItem = {
  itemIdentifier: {
    sku: string
  }
  qty: string | number
  isLabel?: boolean
  serialNumber?: string
}

export type ShopifyAddress = {
  id?: string
  address1?: string
  address2?: string
  phone?: string
  company?: string
  country?: string
  countryCodeV2?: string
  city?: string
  province?: string
  provinceCode?: string
  zip?: string
  name?: string
  firstName?: string
  lastName?: string
}

export const ACCOUNT_STATUS = {
  CREATED: 'CREATED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
}

export const SHOPIFY_NAMESPACE = {
  CUSTOM: 'custom',
}

export const SHOPIFY_CUSTOMER_METAFIELD = {
  ACCOUNT_STATUS: 'account_status',
}

export type ShopifyFulfillment = {
  status: string
  deliveredAt?: string
}

export type ShopifyCustomAttribute = {
  key: string
  value: string
}

export type ShopifyLineItem = {
  id: string
  sku: string
  quantity: number
  currentQuantity: number
  variantTitle: string
  serviceFeePercent?: number
  // variant: Partial<ShopifyProductVariant>
  customAttributes: Array<ShopifyCustomAttribute>
  discountedTotalSet?: ShopifyMoneyBag
}

export type PricingPercentageValue = {
  __typename: 'PricingPercentageValue'
  percentage: number
}

export type ShopifyMoneyV2 = {
  __typename: 'MoneyV2'
  amount: string
  currency: string
}

export type ShopifyMoneyBag = {
  shopMoney: ShopifyMoneyV2
  presentmentMoney: ShopifyMoneyV2
}

export type ShopifyLineItemConnection = {
  edges: {
    node: ShopifyLineItem
  }[]
}

export type ShopifyDiscountApplication = {
  edges: {
    node: {
      value: ShopifyMoneyV2 | PricingPercentageValue
    }
  }[]
}

export type ShopifyOrderTransaction = {
  kind: string
  amountSet: ShopifyMoneyBag
  paymentIcon: {
    url: string
  }
  processedAt: Date
  status: string
  paymentDetails: {
    company: string
    number: string
    expirationMonth: string
    expirationYear: string
  }
}

export type ShopifyOrder = {
  id: string
  name: string
  createdAt: Date
  totalPriceSet: ShopifyMoneyBag
  subtotalLineItemsQuantity: number
  shippingLine: {
    title: string
    discountedPriceSet: ShopifyMoneyBag
  }
  totalDiscountsSet: ShopifyMoneyBag
  netPaymentSet: ShopifyMoneyBag
  lineItems: ShopifyLineItem[] | ShopifyLineItemConnection
  billingAddress: ShopifyAddress
  shippingAddress: ShopifyAddress
  billingAddressMatchesShippingAddress: boolean
  customer: ShopifyCustomer
  displayFulfillmentStatus: string
  displayFinancialStatus: string
  transactions: ShopifyOrderTransaction[]
  discountApplications: ShopifyDiscountApplication
}

export type OrderScreenFilters = 'all' | 'shipmentNotificationFailed' | 'orderProcessingFailed'
