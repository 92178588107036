import React from 'react'
import LazyCarousel from './LazyImageCarousel.js'
type props = import('./LazyImageCarousel').ImageCarouselProps

const ImageCarousel: React.FC<props> = ({
  images,
  onThumbnailClick,
  showZoom,
  currentSlide,
  ...imageProps
}) => {
  return (
    <LazyCarousel
      images={images}
      showZoom={showZoom}
      currentSlide={currentSlide}
      onThumbnailClick={onThumbnailClick}
      {...imageProps}
    />
  )
}
export default ImageCarousel
