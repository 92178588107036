import { useState, useEffect } from 'react'
import { Typography, Button, Grid, Paper, Tooltip, ListItem, List, Box, ListItemIcon } from '@mui/material'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import HelpIcon from '@mui/icons-material/Help'
import AddIcon from '@mui/icons-material/Add'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import _ from 'lodash'

import { FormSelect } from 'components/Forms/FormSelect'
import { Input } from 'components/Forms/Input.tsx'
import { organizationId, selectedStoreId } from 'config/cache.ts'
import { LIST_USERS } from 'lib/graphql/listUsers.ts'
import { User } from 'lib/types.ts'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { GET_SHOPIFY_STORE_CONFIGURATION } from 'lib/graphql/getShopifyStoreConfiguration.ts'
import { UPDATE_SHOPIFY_STORE_CONFIGURATION } from 'lib/graphql/updateShopifyStoreConfiguration.ts'

interface CustomerApprover {
  email: string
}

interface CustomerApproverField {
  field: string
  email: string
}

type CustomerApprovalConfigurationData = {
  customerApprovers?: CustomerApprover[]
}

type CustomerConfigurationFields = CustomerApprovalConfigurationData

const validationSchema = Yup.object().shape({
  customerApprovers: Yup.array().of(
    Yup.object({
      email: Yup.string().required(),
    })
  ),
})

const PAGE_SIZE = 50

export const CustomersStoreSettings = () => {
  const [storeData, setStoreData] = useState<CustomerApprovalConfigurationData>()
  const [userData, setUserData] = useState<Record<string, User>>({})

  const organizationIdVar = useReactiveVar(organizationId)
  const selectedStoreIdVar = useReactiveVar(selectedStoreId)

  const { refetch: refetch, loading: loading } = useQuery(GET_SHOPIFY_STORE_CONFIGURATION, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        id: selectedStoreIdVar,
      },
    },
    skip: !selectedStoreIdVar,
    onCompleted(data) {
      const newStoreData = {
        ...storeData,
        ...data.getShopifyStoreConfiguration,
      }
      setStoreData(newStoreData)
      reset(transformDataToFormValues(newStoreData))
    },
  })

  const [updateStoreConfiguration] = useMutation(UPDATE_SHOPIFY_STORE_CONFIGURATION)

  const { data: users } = useQuery(LIST_USERS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        organizations: {
          some: {
            organizationId: { equals: organizationIdVar },
          },
        },
      },
      orderBy: [{ email: 'ASC' }],
      limit: PAGE_SIZE,
    },
    onCompleted(dataUsers) {
      setUserData({
        ...userData,
        ..._.merge(
          {},
          ...dataUsers.listUsers.map((data: User) => ({
            [data.id]: data,
          }))
        ),
      })
    },
  })

  const toGraphQL = async (data: any) => {
    return {
      customerApprovers: data.customerApprovers.map((x: CustomerApprover) => x.email),
    }
  }

  const transformDataToFormValues = (data?: any) => {
    if (!data) return {}
    return {
      id: data.id,
      customerApprovers: data.customerApprovers?.map((v: string) => ({
        email: v,
      })),
    }
  }

  useEffect(() => {
    if (!_.isEmpty(userData)) {
      reset(transformDataToFormValues(storeData))
    }
  }, [userData])

  const onSubmit: SubmitHandler<CustomerApprovalConfigurationData> = async (data) => {
    const updateData = await toGraphQL(data)
    await updateStoreConfiguration({
      variables: {
        where: {
          id: selectedStoreIdVar,
        },
        data: updateData,
      },
      onError: (error) => setError('root.serverError', { type: 'custom', message: error.message }),
      onCompleted: () => refetch(),
    })
  }

  const onReset = async () => {
    reset(transformDataToFormValues(storeData))
  }

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid, errors },
    reset,
    watch,
    getValues,
    setError,
  } = useForm<CustomerConfigurationFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver<CustomerConfigurationFields>(validationSchema),
  })

  const {
    fields: fieldsCustomerApprovers,
    append: appendCustomerApprover,
    remove: removeCustomerApprover,
  } = useFieldArray({
    control,
    name: 'customerApprovers',
  })

  if (_.isEmpty(userData)) return <></>
  return (
    <Paper
      elevation={5}
      sx={{
        height: 'inherit',
        display: 'grid',
        gridTemplateRows: 'auto 1f auto',
        borderRadius: '16px',
        p: 1,
      }}
    >
      {' '}
      <Grid sx={{ marginTop: '5px', padding: '0px 20px 20px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input type='hidden' control={control} name='id' />
          <Grid
            container
            direction={'row'}
            spacing={4}
            sx={{
              marginTop: '5px',
              marginBottom: '5px',
              justifyContent: 'space-between',
            }}
          >
            <Grid item>
              <Typography variant='h5'>Customers</Typography>
            </Grid>
            <Grid item sx={{ alignSelf: 'right' }}>
              <Button
                variant={'outlined'}
                disabled={isSubmitting}
                onClick={() => {
                  onReset()
                }}
              >
                Reset
              </Button>

              <Button
                sx={{ marginLeft: '10px' }}
                variant={'contained'}
                type='submit'
                disabled={isSubmitting || !(isDirty && isValid)}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          {errors.root?.serverError && (
            <Grid item>
              <Typography variant={'body2'} color={'red'}>
                {errors.root?.serverError.message}
              </Typography>
            </Grid>
          )}
          <Grid container spacing={2} sx={{ marginTop: '5px', marginLeft: '1px' }}>
            <Grid item xs={12} sm={3}>
              New Customer Approval&nbsp;
              <Tooltip
                title={'Approvers will receive notifications at the same time. Only one approval is needed.'}
              >
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={9} sx={{ paddingTop: '10px' }}>
              <List>
                {fieldsCustomerApprovers.map((field, index) => {
                  return (
                    <ListItem
                      key={field.id}
                      sx={{ paddingTop: '0', paddingBottom: '0px' }}
                      secondaryAction={
                        <IconButton
                          edge='end'
                          aria-label='delete'
                          onClick={() => removeCustomerApprover(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <FormSelect
                        control={control}
                        data={
                          users?.listUsers
                            ?.filter(
                              (user: User) =>
                                !fieldsCustomerApprovers.map((field) => field.email).includes(user.email) ||
                                user.email === field.email
                            )
                            .map((user: { email: string; id: string }) => ({
                              label: user.email,
                              value: user.email,
                            })) || []
                        }
                        name={`customerApprovers[${index}].email` || ''}
                        withName
                      />
                      {/*<FormTextField*/}
                      {/*  name={`customerApprovers.${index}.email`}*/}
                      {/*  label=''*/}
                      {/*  type='text'*/}
                      {/*  fullWidth*/}
                      {/*  variant='standard'*/}
                      {/*  disabled={loading}*/}
                      {/*  control={control}*/}
                      {/*/>*/}
                    </ListItem>
                  )
                })}
                <ListItem sx={{ paddingTop: '0', paddingBottom: '0' }}>
                  <ListItemIcon sx={{ minWidth: '40px' }}>
                    <IconButton edge='end' onClick={() => appendCustomerApprover([{ email: '' }])}>
                      <AddIcon />
                    </IconButton>
                  </ListItemIcon>
                  <Typography sx={{ color: 'darkgray' }}>Add another approver</Typography>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Paper>
  )
}
