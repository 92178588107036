import { useRecoilValueLoadable } from 'recoil'
import { currentUser } from 'store/user.ts'

export enum ROLES {
  BRAND,
  ADMIN,
}

export const getUserRole = () => {
  const { state, contents } = useRecoilValueLoadable(currentUser)
  if (state === 'hasValue') return contents?.role
  return undefined
}
