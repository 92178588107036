import React from 'react'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import PrintIcon from '@mui/icons-material/Print'
import SettingsIcon from '@mui/icons-material/Settings'
import { DrawerLayout, MenuType } from '@/layouts/DrawerLayout.tsx'

export const OperationsAppLayout = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
  const menu: MenuType = [
    {
      title: 'Operations',
      children: [
        {
          title: 'Orders',
          icon: <PrintIcon />,
          url: '/operations/orders',
        },
        {
          title: 'Barcodes',
          icon: <QrCode2Icon />,
          url: '/operations/barcodes',
        },
        {
          title: 'Admin',
          icon: <SettingsIcon />,
          url: '/settings/admin',
        },
      ],
    },
  ]

  return <DrawerLayout menu={menu}>{children}</DrawerLayout>
}
