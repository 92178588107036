import { Box, Grid, Paper, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { selectedStoreId } from 'config/cache.ts'
import _ from 'lodash'
import { LIST_WEBHOOK_REGISTRATIONS } from 'lib/graphql/listWebhookRegistrations.ts'
import { WebhookRegistration } from 'components/Settings/WebhookRegistration.tsx'
import Button from '@mui/material/Button'
import { createStack } from '@mui/system'

export const WebhookSettings = ({ createStoreType }: { createStoreType: string }) => {
  const selectedStoreIdVar = useReactiveVar(selectedStoreId)
  const [showAddNewWebhook, setShowAddNewWebhook] = useState<boolean>(false)

  const {
    refetch: refetchWebhookConfigurations,
    data: dataWebhookConfigurations,
    loading: loadingWebhookConfigurations,
    error: errorWebhookConfigurations,
  } = useQuery(LIST_WEBHOOK_REGISTRATIONS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      where: {
        storeConfigurationWebhook: {
          storeConfigurationId: { equals: selectedStoreIdVar },
        },
      },
    },
    skip: !selectedStoreIdVar,
  })

  if (!selectedStoreIdVar || _.isEmpty(dataWebhookConfigurations)) return <></>

  return (
    <Paper
      elevation={5}
      sx={{
        height: 'inherit',
        display: 'grid',
        gridTemplateRows: 'auto 1f auto',
        borderRadius: '16px',
        p: 1,
      }}
    >
      <Grid sx={{ padding: '0px 20px 20px' }}>
        <Grid
          container
          direction={'row'}
          spacing={4}
          sx={{
            marginTop: '5px',
            marginBottom: '5px',
            justifyContent: 'space-between',
          }}
        >
          <Grid item>
            <Typography variant='h5'>Webhooks</Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ textAlignLast: 'right' }}>
          <Button onClick={() => setShowAddNewWebhook(true)}>Add New Webhook</Button>
        </Grid>
        {showAddNewWebhook && (
          <Box sx={{ border: '1px dashed grey', backgroundColor: '#eeeeee' }}>
            <WebhookRegistration
              isLoading={loadingWebhookConfigurations}
              key={'AddNew'}
              data={undefined}
              createStoreType={createStoreType}
              createCallback={async () => setShowAddNewWebhook(false)}
              refetch={async () => await refetchWebhookConfigurations()}
            />
          </Box>
        )}
        <Stack direction={'column'} spacing={2} sx={{ marginTop: '30px' }}>
          {dataWebhookConfigurations.listWebhookRegistrations.map((registration: any, index: number) => {
            return (
              <WebhookRegistration
                isLoading={loadingWebhookConfigurations}
                key={index}
                data={registration}
                refetch={async () => await refetchWebhookConfigurations()}
              />
            )
          })}
        </Stack>
      </Grid>
    </Paper>
  )
}
