import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useQuery } from '@apollo/client'

import { CATALOG_LIST } from 'lib/graphql/listCatalog.ts'
import { COUNT_SKUS } from 'lib/graphql/countSkus.ts'

type Props = {
  brandId?: string
  onLoading: (v: boolean) => void
  onData: (v: any) => void
}

export type GetAllCatalogSkusRef = {
  refresh: () => void
}

const PAGE_SIZE = 50

export const GetAllCatalogSkus = forwardRef<GetAllCatalogSkusRef, Props>(
  ({ brandId, onLoading, onData }, ref) => {
    const [count, setCount] = useState<number | undefined>(undefined)

    const {
      refetch: refetchCount,
      loading: loadingCount,
      error: errorCount,
    } = useQuery(COUNT_SKUS, {
      fetchPolicy: 'no-cache',
      variables: {
        where: {
          brandId: { equals: brandId },
          archivedAt: null,
        },
      },
      onCompleted: (data) => {
        setCount(data.countSkus)
      },
      skip: !brandId,
    })

    const { data, refetch, loading, error, fetchMore } = useQuery(CATALOG_LIST, {
      variables: {
        where: {
          brandId: { equals: brandId },
          archivedAt: null,
        },
        offset: 0,
        limit: PAGE_SIZE,
      },
      skip: !brandId || count === undefined,
    })

    useEffect(() => {
      if (brandId && count && data?.listSkus.length > 0 && data?.listSkus.length < count) {
        fetchMore({
          variables: {
            offset: data?.listSkus.length || 0,
            limit: PAGE_SIZE,
          },
        })
      }
    }, [count, brandId, data])

    useEffect(() => {
      if (loading || loadingCount || (count !== undefined && data?.listSkus.length < count)) {
        onLoading(true)
      } else {
        onLoading(false)
      }
    }, [loading, loadingCount, data?.listSkus.length])

    useEffect(() => {
      if (count !== undefined && count === data?.listSkus.length) {
        onData(data?.listSkus)
      }
    }, [count, data?.listSkus.length])

    useImperativeHandle(ref, () => ({
      refresh: async () => {
        await refetchCount()
        await refetch()
      },
    }))

    return <></>
  }
)
