import { useState, useEffect } from 'react'
import { Box, Typography, LinearProgress, Button, Dialog, Paper } from '@mui/material'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import _ from 'lodash'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { organizationId } from 'config/cache'
import { LIST_USERS } from 'lib/graphql/listUsers.ts'
import { User } from 'lib/types.ts'
import { COUNT_USERS } from '@/lib/graphql/countUsers'
import { UserCreateOrUpdateDialog } from 'components/Dialogs/UserCreateOrUpdateDialog'

import styles from './styles.module.css'
import { DELETE_USER } from 'lib/graphql/deleteUser.ts'
import {
  GridColDef,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridRowSelectionModel,
  DataGridPremium,
} from '@mui/x-data-grid-premium'

const PAGE_SIZE = 25

const columns: GridColDef[] = [
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
  },
]

type EditToolbarProps = {
  handleAdd: () => void
  handleEdit: () => void
  handleDelete: () => void
  enableEditButton: boolean
  enableDeleteButton: boolean
}

const CreateToolbar = ({ handleAdd, handleDelete, enableDeleteButton }: EditToolbarProps) => {
  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<AddIcon />} onClick={handleAdd}>
        Add User
      </Button>
      <Button
        color='primary'
        startIcon={<DeleteIcon />}
        onClick={handleDelete}
        disabled={!enableDeleteButton}
      >
        Remove user
      </Button>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  )
}

export const Users = () => {
  const [showModal, setShowModal] = useState(false)
  const [mode, setMode] = useState<'create' | 'update'>('create')
  const organizationIdVar = useReactiveVar(organizationId)
  const [countUsers, setCountUsers] = useState<number>(0)
  const [focusedUser, setFocusedUser] = useState<User>()
  const [currentOrganization, setCurrentOrganization] = useState<string>()
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])

  useEffect(() => {
    if (organizationIdVar !== currentOrganization) {
      setCountUsers(0)
      refetchUsers()
      setCurrentOrganization(organizationIdVar)
    }
  }, [organizationIdVar])

  const { data: dataCountUsers } = useQuery(COUNT_USERS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        organizations: {
          some: {
            organizationId: { equals: organizationIdVar },
          },
        },
      },
    },
    skip: !currentOrganization,
  })

  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted: async () => {
      await refetchUsers()
    },
  })

  const {
    data: dataUsers,
    loading: loadingUsers,
    error: errorUsers,
    refetch: refetchUsers,
    fetchMore,
  } = useQuery(LIST_USERS, {
    variables: {
      where: {
        organizations: {
          some: {
            organizationId: { equals: organizationIdVar },
          },
        },
      },
      orderBy: [{ email: 'ASC' }],
      limit: PAGE_SIZE,
    },
    skip: !currentOrganization && !dataCountUsers,
  })

  const handleOnRowsScrollEnd = async () => {
    if (dataUsers?.listUsers?.length < countUsers) {
      await fetchMore({
        variables: { offset: dataUsers?.listUsers.length, limit: PAGE_SIZE },
      })
    }
  }

  useEffect(() => {
    if (dataCountUsers) {
      const count = dataCountUsers.countUsers
      setCountUsers(count)
    }
  }, [dataCountUsers, setCountUsers])

  function closeDialog() {
    setShowModal(false)
  }

  if (!currentOrganization) return <></>

  return (
    <>
      <Box sx={{ paddingBottom: 2 }}>
        <Typography variant={'h5'}>Users</Typography>
      </Box>
      <Box sx={{ height: 'calc(100vh - 240px)' }}>
        <Paper
          elevation={5}
          sx={{
            height: 'inherit',
            display: 'grid',
            gridTemplateRows: 'auto 1f auto',
            borderRadius: '16px',
            p: 1,
          }}
        >
          <DataGridPremium
            sx={{
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderTopWidth: 0,
            }}
            density={'compact'}
            checkboxSelection
            rows={dataUsers?.listUsers || []}
            rowCount={countUsers}
            columns={columns}
            hideFooterPagination
            scrollEndThreshold={10}
            onRowsScrollEnd={handleOnRowsScrollEnd}
            loading={loadingUsers}
            slots={{
              loadingOverlay: LinearProgress,
              toolbar: CreateToolbar,
            }}
            slotProps={{
              toolbar: {
                enableEditButton: !!focusedUser,
                enableDeleteButton: !!focusedUser,
                handleAdd: () => {
                  setMode('create')
                  setShowModal(true)
                },
                handleDelete: () => {
                  focusedUser &&
                    deleteUser({
                      variables: {
                        where: {
                          id: focusedUser.id,
                          organizations: {
                            every: {
                              organizationId: { equals: organizationIdVar },
                            },
                          },
                        },
                      },
                    })
                },
                // handleEdit: () => {
                //   setMode('update')
                //   focusedUser && setShowModal(true)
                // },
              },
            }}
            disableMultipleRowSelection={true}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel)
              if (_.size(newRowSelectionModel) === 1) {
                const data = dataUsers?.listUsers.find((u: User) => u.id === newRowSelectionModel[0])
                setFocusedUser(data)
              } else {
                setFocusedUser(undefined)
              }
            }}
          />
        </Paper>
      </Box>
      <Dialog
        fullWidth={true}
        className={styles.modelAddNewBrand}
        open={showModal}
        onClose={() => closeDialog()}
      >
        <UserCreateOrUpdateDialog
          mode={mode}
          data={focusedUser}
          closeDialog={() => closeDialog()}
          onSuccess={async () => {
            await refetchUsers()
          }}
        />
      </Dialog>
    </>
  )
}
