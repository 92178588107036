import { useQuery, useReactiveVar } from '@apollo/client'
import { Button, Box, Typography, Dialog, Paper } from '@mui/material'
import {
  GridColDef,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro'
import { SKU_LISTINGS_LIST } from 'lib/graphql/listSkuListings'
import React, { useState } from 'react'
import { SkuListing } from '@/lib/graphql/types'
import CheckIcon from '@mui/icons-material/Check'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import { organizationId, selectedStoreId, stores } from 'config/cache.ts'
import Currency from 'components/Currency.tsx'
import _ from 'lodash'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import styles from 'pages/VendorApp/styles.module.css'
import SkuListingsImportDialog from 'pages/VendorApp/Dialogs/SkuListingsImportDialog.tsx'
import { SkuListingCreateOrUpdateDialog } from 'components/Dialogs/SkuListingCreateOrUpdateDialog'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { SKU_LISTINGS_COUNT } from 'lib/graphql/countSkuListings.ts'
import { getUserRole, ROLES } from 'lib/rbac.tsx'

type EditToolbarProps = {
  handleAdd: () => void
  handleEdit: () => void
  handleDelete: () => void
  enableEditButton: boolean
  enableDeleteButton: boolean
}

const CreateToolbar = ({
  handleAdd,
  handleDelete,
  handleEdit,
  enableEditButton,
  enableDeleteButton,
}: EditToolbarProps) => {
  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<AddIcon />} onClick={handleAdd}>
        New
      </Button>
      <Button color='primary' startIcon={<EditIcon />} onClick={handleEdit} disabled={!enableEditButton}>
        Edit
      </Button>
      <Button
        color='primary'
        startIcon={<DeleteIcon />}
        onClick={handleDelete}
        disabled={!enableDeleteButton}
      >
        Delete
      </Button>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  )
}

function renderAvailability(v: any) {
  return v.value ? (
    <CheckIcon sx={{ color: 'green', marginRight: '5px' }} />
  ) : (
    <DoNotDisturbIcon sx={{ color: '#f5c3c3', marginRight: '5px' }} />
  )
}

const columns: GridColDef[] = [
  { field: 'title', headerName: 'Title', flex: 1 },
  { field: 'sku', headerName: 'SKU', flex: 1 },
  {
    field: 'wholesaleAvailable',
    headerName: 'Wholesale',
    width: 140,
    renderCell: (v) => (
      <>
        {renderAvailability(v)} <Currency price={v.row.original.wholesalePricing} />
      </>
    ),
  },
  {
    field: 'wholesaleQuantity',
    headerName: 'Wholesale Quantity',
    width: 180,
    renderCell: (v) => (
      <>
        {v.row.original.wholesaleMinQuantity}&nbsp;&ndash;&nbsp;
        {v.row.original.wholesaleBoxQuantity}
        &nbsp;
        {v.row.original.wholesaleMaxQuantity > 0 ? `\u2013 ${v.row.original.wholesaleMaxQuantity} ` : ''}
      </>
    ),
  },
  {
    field: 'whiteLabelAvailable',
    headerName: 'Private Label',
    width: 140,
    renderCell: (v) => (
      <>
        {renderAvailability(v)} <Currency price={v.row.original.whiteLabelPricing} />
      </>
    ),
  },
  {
    field: 'whiteLabelQuantity',
    headerName: 'Private Label Quantity',
    width: 180,
    renderCell: (v) => (
      <>
        {v.row.original.whiteLabelMinQuantity}&nbsp;&ndash;&nbsp;
        {v.row.original.whiteLabelBoxQuantity}
        &nbsp;
        {v.row.original.whiteLabelMaxQuantity > 0 ? `\u2013 ${v.row.original.whiteLabelMaxQuantity} ` : ''}
      </>
    ),
  },
  {
    field: 'retailAvailable',
    headerName: 'Retail',
    width: 140,
    renderCell: (v) => (
      <>
        {renderAvailability(v)} <Currency price={v.row.original.retailPricing} />
      </>
    ),
  },
]

const PAGE_SIZE = 150

export const SkuListings = () => {
  const userRole = getUserRole()
  const [showImportModal, setShowImportModal] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [mode, setMode] = useState<'create' | 'update'>('create')
  const [focusedSkuListing, setFocusedSkuListing] = useState<SkuListing | undefined>()
  const selectedStoreIdVar = useReactiveVar(selectedStoreId)
  const storesVar = useReactiveVar(stores)
  const organizationIdVar = useReactiveVar(organizationId)
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([])

  const {
    data: dataCountSkuListings,
    loading: loadingCountSkuListings,
    error: errorCountSkuListings,
  } = useQuery(SKU_LISTINGS_COUNT, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        shopifyStoreConfigurationId: {
          equals: selectedStoreIdVar,
        },
        archivedAt: null,
      },
    },
    skip: !selectedStoreIdVar,
  })

  const {
    refetch: refetchSkuListings,
    data: dataListSkuListings,
    loading: loadingListSkuListings,
    error: errorListSkuListings,
    fetchMore: fetchMoreCatalog,
  } = useQuery(SKU_LISTINGS_LIST, {
    variables: {
      where: {
        shopifyStoreConfigurationId: {
          equals: selectedStoreIdVar,
        },
        archivedAt: null,
      },
      orderBy: [{ title: 'ASC' }],
      limit: PAGE_SIZE,
      offset: 0,
    },
    skip: !selectedStoreIdVar || !dataCountSkuListings?.countSkuListings,
  })

  const handleOnRowsScrollEnd = async () => {
    if (dataListSkuListings?.listSkuListings?.length < dataCountSkuListings?.countSkuListings) {
      await fetchMoreCatalog({
        variables: { offset: dataListSkuListings?.listSkuListings.length, limit: PAGE_SIZE },
      })
    }
  }
  function closeDialog() {
    setShowModal(false)
  }

  const rows = (dataListSkuListings?.listSkuListings || []).map((skuListing: SkuListing) => {
    return {
      id: skuListing.id,
      title: skuListing.title,
      wholesaleAvailable: skuListing.wholesaleAvailable,
      retailAvailable: skuListing.retailAvailable,
      whiteLabelAvailable: skuListing.whiteLabelAvailable,
      shopifyStoreConfigurationName: skuListing.shopifyStoreConfiguration?.name,
      sku: skuListing.sku.sku,
      original: skuListing,
    }
  })

  if (!selectedStoreIdVar || !storesVar) return <></>

  if (
    _.isEmpty(
      storesVar.filter(
        (store) => store.organizationId === organizationIdVar && store.id === selectedStoreIdVar
      )
    )
  )
    return <></>

  return (
    <>
      <Box sx={{ paddingBottom: 2 }}>
        <Typography variant={'h5'}>
          {storesVar.find((x) => x.id === selectedStoreIdVar).name} - SKU Listings
        </Typography>
      </Box>
      {userRole === ROLES.ADMIN && (
        <Box sx={{ width: '100', display: 'flex', justifyContent: 'right' }}>
          <Button onClick={() => setShowImportModal(true)}>Import SKU Listings</Button>
        </Box>
      )}
      <Box sx={{ height: 'calc(100vh - 240px)' }}>
        <Paper
          elevation={5}
          sx={{
            height: 'inherit',
            display: 'grid',
            gridTemplateRows: 'auto 1f auto',
            borderRadius: '16px',
            p: 1,
          }}
        >
          <DataGridPremium
            sx={{
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderTopWidth: 0,
            }}
            density={'compact'}
            checkboxSelection
            rows={rows}
            rowCount={dataCountSkuListings?.countSkuListings}
            loading={loadingCountSkuListings || loadingListSkuListings}
            columns={columns}
            scrollEndThreshold={500}
            onRowsScrollEnd={handleOnRowsScrollEnd}
            disableMultipleRowSelection={true}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel)
              if (_.size(newRowSelectionModel) === 1) {
                const data = rows.find((r: any) => r.id === newRowSelectionModel[0])
                if (focusedSkuListing !== data.original) {
                  setFocusedSkuListing(data.original)
                }
              } else {
                setFocusedSkuListing(undefined)
              }
            }}
            slots={{
              toolbar: CreateToolbar,
            }}
            slotProps={{
              toolbar: {
                enableEditButton: !!focusedSkuListing,
                enableDeleteButton: false, //!!focusedSkuListing,
                handleAdd: () => {
                  setMode('create')
                  setShowModal(true)
                },
                handleEdit: () => {
                  setMode('update')
                  focusedSkuListing && setShowModal(true)
                },
                handleDelete: async () => {
                  await refetchSkuListings()
                },
              },
            }}
          />
        </Paper>
      </Box>
      <Dialog className={styles.modelAddNewCustomerGroup} open={showModal} onClose={() => closeDialog()}>
        <SkuListingCreateOrUpdateDialog
          mode={mode}
          data={focusedSkuListing}
          closeDialog={() => closeDialog()}
          storeId={selectedStoreIdVar}
          organizationId={organizationIdVar || ''}
          onSuccess={async () => {
            setRowSelectionModel([])
            setFocusedSkuListing(undefined)
            await refetchSkuListings()
          }}
        />
      </Dialog>
      <Dialog open={showImportModal} onClose={() => setShowImportModal(false)} fullWidth maxWidth='md'>
        <SkuListingsImportDialog
          closeDialog={() => setShowImportModal(false)}
          currentOrganizationId={organizationIdVar || ''}
          storeId={selectedStoreIdVar}
          onSuccess={async () => {
            setRowSelectionModel([])
            setFocusedSkuListing(undefined)
            await refetchSkuListings()
          }}
        />
      </Dialog>
    </>
  )
}
