import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import { Alert, Box, Button, Dialog, LinearProgress, Paper, Typography } from '@mui/material'
import { DataGridPro, GridColDef, GridPaginationModel, GridRowId } from '@mui/x-data-grid-pro'
import React, { useEffect, useState } from 'react'
import { organizationId, selectedStoreId } from 'config/cache'
import dayjs from 'dayjs'
import CheckIcon from '@mui/icons-material/Check'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import { OrderLabel } from 'lib/types.ts'
import { useSearchParams } from 'react-router-dom'
import { LIST_ORDER_LABELS } from 'lib/graphql/listOrderLabels.ts'
import { COUNT_ORDER_LABELS } from 'lib/graphql/countOrderLabels.ts'
import { LabelViewer } from 'components/LabelViewer'
import { SkuSelect } from 'components/Forms/SkuSelect.tsx'
import { useForm } from 'react-hook-form'
import { ExternalStoreSelect } from 'components/Forms/ExternalStoreSelect.tsx'
import { LIST_EXTERNAL_STORE_CONFIGURATIONS } from 'lib/graphql/listExternalStoreConfigurations.ts'
import { GET_EXTERNAL_STORE_CONFIGURATION } from 'lib/graphql/getExternalStoreConfiguration.ts'
import { ExternalStoreSettings } from 'pages/VendorApp/FullfillmentClientSettings/ExternalStoreSettings.tsx'
import { FulfillmentSettings } from 'components/Settings/FulfillmentSettings.tsx'
import { WebhookSettings } from 'components/Settings/WebhookSettings.tsx'
import { getUserRole, ROLES } from 'lib/rbac.tsx'

function renderApproval(v: any) {
  return v.value ? <CheckIcon sx={{ color: 'green' }} /> : <DoNotDisturbIcon sx={{ color: '#f5c3c3' }} />
}

function formatDateTime(v: Date) {
  return v ? dayjs(v).format('M/D/YYYY h:mm A') : ''
}

const columns: GridColDef[] = [
  {
    field: 'orderNumber',
    headerName: 'Order',
    flex: 1,
  },
  {
    field: 'skuName',
    headerName: 'SKU',
    flex: 1,
  },
  {
    field: 'bucketKey',
    headerName: 'Label',
    flex: 0.5,
    renderCell: (v) => {
      return renderApproval(v)
    },
  },
  {
    field: 'lotNumber',
    headerName: 'Lot#',
    flex: 1,
  },
  {
    field: 'expirationDate',
    headerName: 'Expiration Date',
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Ordered On',
    flex: 1,
    renderCell: (v) => formatDateTime(v.value),
  },
]

type EditToolbarProps = {
  handleAdd: () => void
  handleEdit: () => void
  handleDelete: () => void
  enableEditButton: boolean
  enableDeleteButton: boolean
}

const PAGE_SIZE = 25

interface ExternalStoreSelectionProps {
  externalStoreId: string
}

export const FulfillmentClients = () => {
  const userRole = getUserRole()
  const organizationIdVar = useReactiveVar(organizationId)
  const [orderLabels, setOrderLabels] = useState<OrderLabel[]>([])
  const [countOrderLabels, setCountOrderLabels] = useState<number>(0)
  const [skip, setSkip] = useState(0)
  const [externalStoreId, setExternalStoreId] = useState<string>()
  const [externalStoreConfiguration, setExternalStoreConfiguration] = useState()

  const { setValue, control, reset } = useForm<ExternalStoreSelectionProps>({
    defaultValues: { externalStoreId: '' },
    reValidateMode: 'onChange',
  })

  const [getExternalStoreConfiguration] = useLazyQuery(GET_EXTERNAL_STORE_CONFIGURATION)

  const selectStore = (id: string) => {
    setExternalStoreId(id)
    selectedStoreId(id)
  }

  if (!organizationIdVar) return <></>

  return (
    <>
      <Box sx={{ paddingBottom: 2 }}>
        <Typography variant={'h5'}>Fulfillment Clients</Typography>
      </Box>
      <Box sx={{ height: '90px', marginBottom: '20px' }}>
        <Paper
          elevation={5}
          sx={{
            height: 'inherit',
            paddingTop: '10px',
            marginLeft: '2px',
            marginRight: '2px',
            marginBottom: '20px',
            paddingLeft: '10px',
            borderRadius: '16px',
          }}
        >
          <form>
            <ExternalStoreSelect
              label={'Select Client'}
              organizationId={organizationIdVar}
              control={control}
              customOnChange={(v) => selectStore(v.target.value)}
              withName={true}
              name='externalStoreId'
            />
          </form>
        </Paper>
      </Box>
      {externalStoreId && (
        <>
          <ExternalStoreSettings externalStoreId={externalStoreId} />
          {userRole === ROLES.ADMIN && (
            <Box sx={{ paddingBottom: '20px' }}>
              <FulfillmentSettings />
            </Box>
          )}
          <WebhookSettings createStoreType={'Other'} />
        </>
      )}
    </>
  )
}
