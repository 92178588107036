import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const messageState = atom<{ id: string; incoming: any; outgoing: any[]; commonId: string; orderId: string }>({
  key: 'messageId',
  default: {
    id: '',
    commonId: '',
    orderId: '',
    incoming: [],
    outgoing: [],
  },
  effects_UNSTABLE: [persistAtom],
})

export { messageState }
