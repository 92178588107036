import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'

const LabelFactsConfirmationDialog = ({
  open,
  handleClose,
  handleConfirm,
  reason,
  setReason,
  disabled,
}: {
  open: boolean
  handleClose: () => void
  handleConfirm: (v: any) => void
  reason: string
  setReason: (s: string) => void
  disabled?: boolean
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Update Product</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description' sx={{ color: '#001D35' }}>
            To update this product, you need to describe a reason in the below textbox:
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='reason'
            label='Reason'
            type='text'
            fullWidth
            multiline
            minRows={1}
            variant='standard'
            value={reason}
            onChange={(event) => setReason(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus disabled={reason.length < 3 || disabled}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default LabelFactsConfirmationDialog
