import { gql } from '@apollo/client'

export const LIST_ORDER_LABELS = gql`
  query listOrderLabels(
    $where: OrderLabelWhereInput
    $orderBy: [OrderLabelOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    listOrderLabels(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      orderId
      instanceId
      lotId
      skuId
      bucketKey
      updatedAt
      createdAt

      order {
        orderNumber: commonId
      }

      lot {
        lotNumber
        expirationDate
      }

      sku {
        sku
      }
    }
  }
`
