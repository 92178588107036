import { gql } from '@apollo/client'

export const UPDATE_TEMPLATE = gql`
  mutation updateDesignerTemplate(
    $where: DesignerTemplateExtendedWhereUniqueInput!
    $data: DesignerTemplateUpdateInput
  ) {
    updateDesignerTemplate(where: $where, data: $data) {
      id
    }
  }
`
