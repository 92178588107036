import { useState, useRef } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Typography, Button, Grid, Paper, Stack, Link } from '@mui/material'
import { Input } from 'components/Forms/Input.tsx'
import { FormTextField } from 'components/Forms/FormTextField.tsx'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { GET_EXTERNAL_STORE_CONFIGURATION } from 'lib/graphql/getExternalStoreConfiguration.ts'
import { UPDATE_EXTERNAL_STORE_CONFIGURATION } from 'lib/graphql/updateExternalStoreConfiguration.ts'
import { ExternalStoreConfiguration, externalStoreConfigurationType } from 'lib/graphql/types.ts'
import { FormSelect } from 'components/Forms/FormSelect.tsx'

type ExternalStoreConfigurationData = ExternalStoreConfiguration & {}
type ExternalStoreConfigurationFields = Omit<
  ExternalStoreConfigurationData,
  'organizationId' | 'createdAt' | 'updatedAt' | 'domain'
> & {}

const validationSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string(),
  storeType: Yup.mixed<externalStoreConfigurationType>()
    .oneOf(Object.values(externalStoreConfigurationType))
    .required(),
  description: Yup.string(),
})

const ExternalStoreTypes = ['Shopify', 'Pressero', 'Other', 'Webhook']

export const ExternalStoreSettings = ({ externalStoreId }: { externalStoreId: string }) => {
  const [storeData, setStoreData] = useState<ExternalStoreConfigurationData>()

  const [updateExternalStoreConfiguration] = useMutation(UPDATE_EXTERNAL_STORE_CONFIGURATION)

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
    reset,
  } = useForm<ExternalStoreConfigurationFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver<ExternalStoreConfigurationFields>(validationSchema),
    defaultValues: {
      id: '',
      name: '',
      description: '',
      storeType: 'Other',
    },
  })

  const { refetch: refetch, loading: loading } = useQuery(GET_EXTERNAL_STORE_CONFIGURATION, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        id: externalStoreId,
      },
    },
    onCompleted: (data) => {
      setStoreData(data.getExternalStoreConfiguration)
      reset(transformDataToFormValues(data.getExternalStoreConfiguration))
    },
  })

  const toGraphQL = async (data: any) => {
    return {
      name: data.name,
      storeType: data.storeType,
      description: data.description,
    }
  }

  const transformDataToFormValues = (data: any) => {
    return {
      id: data?.id,
      name: data?.name,
      storeType: data?.storeType,
      description: data?.description,
      domain: data?.domain,
    }
  }

  const onSubmit: SubmitHandler<ExternalStoreConfigurationFields> = async (data) => {
    const updateData = await toGraphQL(data)
    await updateExternalStoreConfiguration({
      variables: {
        where: {
          id: externalStoreId,
        },
        data: updateData,
      },
    })
    await refetch()
  }
  //
  const onReset = async () => {
    reset(transformDataToFormValues(storeData))
  }

  if (!storeData) return <></>

  return (
    <>
      <Stack sx={{ paddingBottom: 2 }} direction='column' spacing={2}>
        <Paper
          elevation={5}
          sx={{
            height: 'inherit',
            paddingTop: '10px',
            marginLeft: '2px',
            marginRight: '2px',
            marginBottom: '20px',
            paddingLeft: '10px',
            borderRadius: '16px',
          }}
        >
          {' '}
          <Grid sx={{ marginTop: '5px', padding: '0px 20px 20px' }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input type='hidden' control={control} name='id' />
              <Grid
                container
                direction={'row'}
                spacing={4}
                sx={{
                  marginTop: '5px',
                  marginBottom: '5px',
                  justifyContent: 'space-between',
                }}
              >
                <Grid item>
                  <Typography variant='h5'>{storeData.name} &mdash; Configuration</Typography>
                </Grid>
                <Grid item sx={{ alignSelf: 'right' }}>
                  <Button
                    variant={'outlined'}
                    disabled={isSubmitting}
                    onClick={() => {
                      onReset()
                    }}
                  >
                    Reset
                  </Button>

                  <Button
                    sx={{ marginLeft: '10px' }}
                    variant={'contained'}
                    type='submit'
                    disabled={isSubmitting || !(isDirty && isValid)}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                <Grid item xs={12} md={3} sx={{ fontWeight: 'bold' }}>
                  Store Website
                </Grid>
                <Grid item xs={12} md={9} sx={{ paddingTop: '10px' }}>
                  <Link href={`https://${storeData.domain}`}>https://{storeData.domain}</Link>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                <Grid item xs={12} md={3} sx={{ fontWeight: 'bold' }}>
                  Store Type
                </Grid>
                <Grid item xs={12} md={3} sx={{ paddingTop: '10px' }}>
                  <FormSelect
                    name='storeType'
                    label='Store Type'
                    disabled={loading}
                    data={ExternalStoreTypes.map((v) => ({ value: v, label: v }))}
                    control={control}
                    withName
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                <Grid item xs={12} md={8} sx={{ paddingTop: '10px' }}>
                  <FormTextField
                    name='name'
                    label='Name'
                    type='text'
                    fullWidth
                    variant='standard'
                    disabled={loading}
                    control={control}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ marginTop: '5px' }}>
                <Grid item xs={12} md={12} sx={{ paddingTop: '10px' }}>
                  <FormTextField
                    name='description'
                    label='Description'
                    type='text'
                    fullWidth
                    variant='standard'
                    disabled={loading}
                    control={control}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Paper>
      </Stack>
    </>
  )
}
