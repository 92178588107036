import Stack from '@mui/material/Stack'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import dayjs from 'dayjs'
import Box from '@mui/material/Box'
import { useReactiveVar } from '@apollo/client'
import _ from 'lodash'
import { fromDate, orderSearchFilter, orderSearchString, toDate, orderFilter } from 'config/cache'
import { OrderScreenFilters } from '@/lib/types'

const Filters = () => {
  const fromDateVar = useReactiveVar(fromDate)
  const toDateVar = useReactiveVar(toDate)
  const orderFilterVar = useReactiveVar(orderFilter)

  const fromDateValue = fromDateVar && dayjs(fromDateVar)
  const toDateValue = toDateVar && dayjs(toDateVar)

  const handleChange = (event: SelectChangeEvent) => {
    orderFilter(event.target.value as OrderScreenFilters)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '8px 32px' }}>
        <Box display='flex' maxWidth='300px'>
          <DesktopDatePicker
            value={fromDateValue || null}
            slotProps={{
              textField: { variant: 'standard' },
              openPickerButton: { color: 'primary' },
            }}
            onChange={(newValue) => {
              if (newValue) {
                fromDate(newValue?.startOf('day')?.format())
              } else {
                fromDate(undefined)
              }
            }}
          />
          <Typography
            sx={{
              display: 'inline-block',
              mx: 1,
              fontWeight: 'bold',
              color: '#526070',
            }}
          >
            -
          </Typography>
          <DesktopDatePicker
            slotProps={{
              textField: { variant: 'standard' },
              openPickerButton: { color: 'primary' },
            }}
            value={toDateValue || null}
            onChange={(newValue) => {
              if (newValue) {
                toDate(newValue?.endOf('day')?.format())
              } else {
                toDate(undefined)
              }
            }}
          />
        </Box>
        <Box sx={{ width: '250px' }}>
          <TextField
            fullWidth
            variant='standard'
            placeholder='Search Orders'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={useReactiveVar(orderSearchString)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              orderSearchString(event.target.value)
              if (event.target.value && _.size(event.target.value) >= 3) {
                orderSearchFilter(event.target.value)
              } else {
                orderSearchFilter('')
              }
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
          <Box>Show:</Box>
          <Box sx={{ width: '250px' }}>
            <Select
              fullWidth
              variant='standard'
              placeholder='All'
              value={orderFilterVar}
              onChange={handleChange}
            >
              <MenuItem value='all'>All</MenuItem>
              <MenuItem value='orderProcessingFailed'>Order Processing Issues</MenuItem>
              <MenuItem value='shipmentNotificationFailed'>Shipment Notification Issues</MenuItem>
              <MenuItem value='orderWebhookFailed'>Order Webhooks Issues</MenuItem>
              <MenuItem value='shipmentWebhookFailed'>Shipment Webhooks Issues</MenuItem>
            </Select>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Filters
