import { gql } from '@apollo/client'

export const CREATE_ORGANIZATION = gql`
  mutation createOrganization($data: OrganizationCreateInput!) {
    createOrganization(data: $data) {
      id
      name
    }
  }
`
