import { gql } from '@apollo/client'

export const GET_LABEL = gql`
  query getLabel($where: OrderLabelWhereUniqueInput!) {
    getLabel(where: $where) {
      id
      order {
        commonId
      }
      sku {
        sku
      }
      lot {
        lotNumber
        expirationDate
      }
      url
      createdAt
    }
  }
`
