import { useState } from 'react'
import dayjs from 'dayjs'
import { CircularProgress, ListItemButton, ListItemText, Collapse, Box } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'

import { LIST_SUPPLEMENT_FACTS } from 'lib/graphql/listSupplementFacts'
import { LabelFactsProductTable } from './LabelFactsProductTable.tsx'

type Status = 'APPROVED' | 'DRAFT'
const isApproved = (status: Status) => status === 'APPROVED'

const formatData = (data: any) =>
  data.map((supplementFact: any) => ({
    id: supplementFact.id,
    brandId: supplementFact.sku.brandId,
    majorVersion: supplementFact.majorVersion,
    majorVersionUuid: supplementFact.majorVersionUuid,
    lots: 0,
    date: dayjs(supplementFact.createdAt).format('MM/DD/YYYY'),
    status: supplementFact.status,
    canCreateLot: data
      .filter(
        (supplFact: any) => supplFact.majorVersion.toString() === supplementFact.majorVersion.toString()
      )
      .some((supplFact: any) => isApproved(supplFact.status)),
  }))

export const LabelFactsProductsList = ({
  product,
  productId,
  privateLabelName,
  productName,
  organizationId,
}: {
  product: string
  productId: string
  privateLabelName: string
  productName: string
  organizationId: string | undefined
}) => {
  const [open, setOpen] = useState(false)

  const [loadSupplementFacts, { called, loading, data }] = useLazyQuery(LIST_SUPPLEMENT_FACTS, {
    variables: {
      where: {
        sku: {
          brand: {
            organizationId: { equals: organizationId },
          },
          sku: { equals: product },
        },
      },
      take: 100,
      skip: 0,
    },
    fetchPolicy: 'no-cache',
  })

  const handleClick = () => {
    loadSupplementFacts()
    setOpen(!open)
  }

  if (!organizationId) {
    return <></>
  }

  const reducedRows = _.uniqWith(formatData(data?.listSupplementFacts || []), (pre: any, cur: any) => {
    if (pre.majorVersion === cur.majorVersion) {
      cur.status = pre.status === 'APPROVED' || cur.status === 'APPROVED' ? 'APPROVED' : 'DRAFT'
      cur.date = _.min([pre.date, cur.date])
      return true
    }
    return false
  })

  return (
    <Box
      sx={{
        borderLeft: '1px solid #e0e0e0',
        borderRight: '1px solid #e0e0e0',
      }}
    >
      <ListItemButton
        onClick={handleClick}
        sx={{ backgroundColor: '#F9F9FC', borderBottom: '1px solid #e0e0e0', minWidth: 390 }}
      >
        <ListItemText
          primary={
            <>
              {product} &mdash; {productName}
            </>
          }
          primaryTypographyProps={{ color: '#0062A0', fontWeight: 500, fontSize: '14px' }}
        />
        {open ? <ExpandLess sx={{ color: '#0062A0' }} /> : <ExpandMore sx={{ color: '#0062A0' }} />}
      </ListItemButton>
      <Collapse in={open} timeout='auto'>
        {!called || loading ? (
          <CircularProgress />
        ) : (
          <LabelFactsProductTable
            rows={reducedRows}
            privateLabelName={privateLabelName}
            productName={productName}
            product={product}
            productId={productId}
            organizationId={organizationId}
          />
        )}
      </Collapse>
    </Box>
  )
}
