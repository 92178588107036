import { gql } from '@apollo/client'
import { fragment as fragmentSkuListing } from './fragmentSkuListing'

export const UPDATE_SKU_LISTING = gql`
  ${fragmentSkuListing}
  mutation UpdateSkuListing($where: SkuListingWhereUniqueInput!, $data: SkuListingUpdateInput!) {
    updateSkuListing(where: $where, data: $data) {
      ...skuListingFragment
    }
  }
`
