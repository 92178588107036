import { useState } from 'react'
import dayjs from 'dayjs'
import { Button, Typography, Divider, Collapse } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const TimelineMessage = ({
  error,
  skipped,
  requestType,
  name,
  createdAt,
  responses,
  id,
  onClick,
}: {
  error: boolean
  skipped: boolean
  requestType: string
  name: string
  createdAt: string
  responses: any[]
  id: string
  onClick: (v: 'incoming' | 'outgoing') => void
}) => {
  const [open, setOpen] = useState(false)
  const notificationType = (request: string) =>
    request.toLowerCase().includes('ship') ? 'Shipment' : 'Order'

  return (
    <Box sx={{ border: '1px solid #E0E0E0', borderRadius: 1, py: 1, px: 2 }}>
      <Stack direction="column" spacing={0.5} mb={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {error ? <ErrorOutlineIcon sx={{ color: '#BA1A1A' }} /> : null}
          <Typography color="#0F1D2A" fontSize="14px" fontWeight={500}>
            {!skipped && notificationType(requestType)} Notification received from {name}
          </Typography>
        </Stack>
        <Typography color="#526070" fontSize="14px" fontWeight={500}>
          {dayjs(createdAt).format('MM/DD/YY h:mm a')}
        </Typography>
        <Button variant="outlined" fullWidth sx={{ m: 2 }} onClick={(event) => onClick('incoming')}>
          View Message
        </Button>
      </Stack>
      {responses && (
        <Box display="flex" flexDirection="column">
          <Divider flexItem />
          <Button
            variant="text"
            color="primary"
            sx={{ mt: 0.5, alignSelf: 'flex-end' }}
            onClick={() => setOpen((open) => !open)}
          >
            + {responses.length} More
            <ChevronRightIcon />
          </Button>
          <Collapse in={open} unmountOnExit>
            <Divider />
            <Stack direction="column" spacing={4} sx={{ mt: 1 }}>
              {responses.map((response) => {
                return (
                  <Box display="flex" justifyContent="space-between" key={response.id}>
                    <Box>
                      <Typography color="#0F1D2A" fontSize="14px" fontWeight={500}>
                        {notificationType(response.requestType)} Notification sent to{' '}
                        {response.destination?.name || 'Unknown'}
                      </Typography>
                      <Typography color="#526070" fontSize={14} fontWeight={500}>
                        {dayjs(response.createdAt).format('MM/DD/YY h:mm a')}
                      </Typography>
                    </Box>
                    <Button variant="text" onClick={(event) => onClick('outgoing')}>
                      View
                    </Button>
                  </Box>
                )
              })}
            </Stack>
          </Collapse>
        </Box>
      )}
    </Box>
  )
}

export default TimelineMessage
