import { gql } from '@apollo/client'

export const CREATE_SUPPLEMENT_FACT = gql`
  mutation createSupplementFact($data: SupplementFactCreateInput!) {
    createSupplementFact(data: $data) {
      id
      majorVersion
    }
  }
`
