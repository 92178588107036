import { useQuery, useReactiveVar } from '@apollo/client'
import { Checkbox, CircularProgress, ListItemText, MenuItem, Select, SelectProps } from '@mui/material'
import { CUSTOMER_GROUPS_LIST } from '@/lib/graphql/listCustomerGroups'
import { CustomerGroup } from '@/lib/graphql/types'
import { selectedStoreId } from '@/config/cache'
import { useMemo } from 'react'
import { Controller } from 'react-hook-form'

type CustomerGroupSelectProps = Partial<SelectProps<string[]>> & {
  control: any
}
export const CustomerGroupSelect = ({ name, label, control, disabled }: CustomerGroupSelectProps) => {
  const selectedStoreIdVar = useReactiveVar(selectedStoreId)

  const { data, loading, error } = useQuery(CUSTOMER_GROUPS_LIST, {
    variables: {
      take: 100,
      skip: 0,
      where: {
        shopifyStoreConfigurationId: { equals: selectedStoreIdVar },
      },
    },
    fetchPolicy: 'no-cache',
  })

  const customerGroupLookup = useMemo(() => {
    if (!data || !data.listCustomerGroups) {
      return {}
    }
    return data.listCustomerGroups.reduce((accum: Record<string, CustomerGroup>, value: CustomerGroup) => {
      accum[value.id] = value
      return accum
    }, {})
  }, [data])

  if (loading) {
    return <CircularProgress />
  }
  if (error) {
    return <>Error</>
  }
  return (
    <Controller
      name={name || ''}
      control={control}
      disabled={disabled}
      render={({ field: { onChange, value, disabled }, fieldState: { error } }) => (
        <Select
          label={label}
          name={name}
          fullWidth
          multiple
          variant='standard'
          value={value}
          onChange={onChange}
          renderValue={(values) => {
            if (!values) {
              return 'No Groups Selected'
            }
            return values.map((id: string) => customerGroupLookup[id].name).join(', ')
          }}
        >
          {(data.listCustomerGroups || []).map((customerGroup: CustomerGroup) => {
            return (
              <MenuItem key={customerGroup.id} value={customerGroup.id}>
                <Checkbox checked={value && value.includes(customerGroup.id)} />
                <ListItemText primary={customerGroup.name} />
              </MenuItem>
            )
          })}
        </Select>
      )}
    />
  )
}
