import { gql } from '@apollo/client'

const FIND_LABEL = gql`
  query findLabel(
    $customerName: String!
    $orderNumber: String!
    $sku: String!
    $lotNumber: String
    $expirationDate: String
  ) {
    findLabel(
      where: {
        customerName: $customerName
        orderNumber: $orderNumber
        sku: $sku
        lotNumber: $lotNumber
        expirationDate: $expirationDate
      }
    ) {
      id
      orderId
      instanceId
      lotId
      skuId
      url
      updatedAt
      createdAt

      order {
        orderNumber: commonId
      }

      lot {
        lotNumber
        expirationDate
      }

      sku {
        sku
      }
    }
  }
`

export default FIND_LABEL
