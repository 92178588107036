import React from 'react'
import ReactDOM from 'react-dom/client'
import { RecoilRoot } from 'recoil'
import AppSyncProvider from 'lib/AppSyncProvider'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { LicenseInfo } from '@mui/x-license'

LicenseInfo.setLicenseKey(
  '841ab1e3accad09d26464d8510ee27ddTz04ODY5NCxFPTE3NDUwMjgxNjMwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
)

import App from './App.tsx'
import './index.css'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RecoilRoot>
      <AppSyncProvider>
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>
      </AppSyncProvider>
    </RecoilRoot>
  </React.StrictMode>
)
