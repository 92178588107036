import { useState, useEffect } from 'react'
import { Box, LinearProgress, Paper, Typography } from '@mui/material'
import { useQuery, useLazyQuery } from '@apollo/client'

import { PROXY_SHOPIFY_QUERY } from 'lib/graphql/proxyShopifyQuery'
import { LineItems } from 'pages/ReportsApp/OrderDetails.tsx'
import { ShopifyCustomer, ShopifyOrder } from '@/lib/types'
import { formatFormalDateTime } from 'pages/ReportsApp/utils.tsx'

type CustomerDetailProps = {
  customer: ShopifyCustomer & { metafields: any; original: any; approved: boolean; accountStatus: any }
  selectedStoreIdVar: string
}

export const CustomerOrderCard = ({ customer, selectedStoreIdVar }: CustomerDetailProps) => {
  const [data, setData] = useState<Partial<ShopifyOrder>>()

  const [lazyShopifyQuery, { loading: loadingShopify }] = useLazyQuery(PROXY_SHOPIFY_QUERY)

  useQuery(PROXY_SHOPIFY_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      query: 'orders',
      shopifyStoreId: selectedStoreIdVar,
      variables: JSON.stringify({
        reverse: true,
        first: 1,
        sortKey: 'CREATED_AT',
        query: `email:${customer.email}`,
      }),
    },
    onCompleted: (data) => {
      const order = JSON.parse(data.proxyShopifyQuery || '{}')?.data?.orders?.edges?.[0]?.node
      if (!order) setData(undefined)
      lazyShopifyQuery({
        fetchPolicy: 'no-cache',
        variables: {
          query: 'order',
          shopifyStoreId: selectedStoreIdVar,
          variables: JSON.stringify({
            id: order.id,
          }),
        },
        onCompleted: (data) => {
          setData(JSON.parse(data.proxyShopifyQuery || '{}')?.data?.order)
        },
      })
    },
  })

  if (loadingShopify) {
    return <LinearProgress />
  }

  return (
    <Box>
      <Paper elevation={10} sx={{ p: 2, position: 'relative', borderRadius: '15px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Box>
            <Typography variant={'h5'}>Most recent order</Typography>
          </Box>
          {data && (
            <Box>
              <Typography variant={'body1'}>
                {data.name} ordered on {formatFormalDateTime(data.createdAt)}
              </Typography>
            </Box>
          )}
        </Box>

        <Box sx={{ paddingTop: '10px', width: '100%' }}>
          {!data ? (
            <Typography variant={'body1'}>This customer hasn’t placed any orders yet</Typography>
          ) : (
            <>
              <LineItems order={data} />
            </>
          )}
        </Box>
      </Paper>
    </Box>
  )
}
