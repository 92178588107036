import { Box, Container, Paper } from '@mui/material'

import ResetPasswordForm from './ResetPasswordForm'
import KaerwellLogo from 'assets/kaerwell-logo-2.webp'
import React from 'react'

const ResetPasswordPage: React.FC = () => {
  return (
    <Paper sx={{ background: '#d2e7f6', height: '100vh' }}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Container maxWidth="sm" sx={{ textAlign: 'center', px: '5%', py: '10%' }}>
          <div
            style={{
              background: 'white',
              borderRadius: '20px',
              border: '2px solid #406373',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img alt={'Kaerwell Inc.'} src={KaerwellLogo} style={{ width: '100%' }} />
            <ResetPasswordForm />
            <Box sx={{ mt: '1rem' }}></Box>
          </div>
        </Container>
      </Box>
    </Paper>
  )
}

export default ResetPasswordPage
