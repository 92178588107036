import { FC, useState } from 'react'
import { CarouselProvider, ImageWithZoom, Slide, Slider, Dot } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { Box, Dialog, IconButton, Stack } from '@mui/material'
import styles from './styles.module.css'

const CustomDotGroup: FC<Omit<ImageCarouselProps, 'alt'>> = ({ images, onThumbnailClick, ...imageProps }) => {
  return (
    <Stack direction={'row'} spacing={2}>
      {images.map((image, slide) => (
        <IconButton
          sx={{
            height: { xs: '50px', sm: '56px', md: '63px', xl: '81px' },
            width: { xs: '50px', sm: '56px', md: '63px', xl: '81px' },
            border: '1px solid #AFBCCF',
            borderRadius: 0,
            padding: 0,
            margin: 0,
          }}
          key={slide}
          onClick={() => onThumbnailClick?.(slide)}
        >
          <Dot
            slide={slide}
            style={{ height: '100%', width: '100%', display: 'contents', padding: 0, margin: 0 }}
          >
            {/*<Image alt="dot" src={image.src} height={70} width={70}></Image>*/}
            <img alt='dot' src={image.src} style={{ maxHeight: '100%', maxWidth: '100%' }} />
          </Dot>
        </IconButton>
      ))}
    </Stack>
  )
}

export type ImageCarouselProps = {
  showZoom?: boolean
  images: Array<{ src: string }>
  alt: string
  onThumbnailClick?: (index: number) => void
  width: number
  height: number
  layout?: 'fixed' | 'intrinsic' | 'responsive' | undefined
  priority?: boolean
  fill?: boolean
  loading?: 'eager' | 'lazy'
  sizes?: string
  currentSlide?: number
}

const ImageCarousel: FC<ImageCarouselProps> = ({
  images,
  onThumbnailClick,
  showZoom,
  currentSlide,
  ...imageProps
}) => {
  const [showZoomDialog, setShowZoomDialog] = useState<string>()
  return (
    <>
      <Dialog open={!!showZoomDialog} fullScreen={false} onClick={() => setShowZoomDialog(undefined)}>
        <Box sx={{}}>
          <img
            src={showZoomDialog}
            style={{
              alignSelf: 'center',
              maxWidth: '-webkit-fill-available',
              maxHeight: '-webkit-fill-available',
            }}
          />
        </Box>
      </Dialog>
      <CarouselProvider
        currentSlide={currentSlide}
        naturalSlideWidth={1}
        naturalSlideHeight={1}
        hasMasterSpinner={false}
        totalSlides={images.length}
      >
        <Stack direction={'column'} spacing={2} sx={{ alignItems: 'center' }}>
          <Box
            sx={{
              height: { xs: '256px', lg: '260px', xl: '354px' },
              width: { xs: '256px', lg: '260px', xl: '354px' },
            }}
          >
            <Slider style={{ height: 'inherit' }}>
              {images.map((image, index) => (
                <Slide index={index} key={index}>
                  <Box
                    id={'slide'}
                    sx={{
                      height: '100%',
                      display: 'flex',
                      placeContent: 'center',
                    }}
                  >
                    {showZoom ? (
                      <ImageWithZoom
                        bgImageProps={{
                          style: {
                            maxWidth: 'inherit',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPositionX: 'center',
                          },
                        }}
                        imageClassName={styles.imageWithZoom}
                        src={image.src}
                      />
                    ) : (
                      <>
                        <img
                          src={image.src}
                          className={styles.imageWithoutZoom}
                          onClick={() => setShowZoomDialog(image.src)}
                        />
                        {/*    <Image src={image.src} {...imageProps} />*/}
                      </>
                    )}
                  </Box>
                </Slide>
              ))}
            </Slider>
          </Box>
          <Box>
            {images.length > 1 && (
              <CustomDotGroup {...imageProps} onThumbnailClick={onThumbnailClick} images={images} />
            )}
          </Box>
        </Stack>
      </CarouselProvider>
    </>
  )
}

export default ImageCarousel
