import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { selectedStoreId, stores } from 'config/cache'
import { GET_LABEL_APPROVAL_WORKFLOW_STEP } from 'lib/graphql/getLabelApprovalWorkflowStep'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import { UPDATE_LABEL_APPROVAL_WORKFLOW_STEP } from 'lib/graphql/updateLabelApprovalWorkflowStep.ts'
import _ from 'lodash'
import { ReviewLabel } from 'components/ReviewLabel'
import { currentUser } from 'store/user.ts'
import { useRecoilValueLoadable } from 'recoil'

function formatDateTime(v: Date) {
  return v ? dayjs(v).format('M/D/YYYY h:mm A') : ''
}

export const ReviewLabelPage = () => {
  const [idToReview, setIdToReview] = useState<string>()
  const [workflowStep, setWorkflowStep] = useState<any>()
  const [isOpen, setIsOpen] = useState(false)
  const [rejectionReason, setRejectionReason] = useState('')
  const selectedStoreIdVar = useReactiveVar(selectedStoreId)
  const storesVar = useReactiveVar(stores)

  const user = useRecoilValueLoadable(currentUser)
  const email = (user as any)?.contents?.email as string

  let [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('id')) {
      setIdToReview(searchParams.get('id') || '')
    }
  }, [searchParams])

  const {
    refetch: refetchWorkflowStep,
    data: dataWorkflowStep,
    loading: loadingWorkflowStep,
    error: errorWorkflowStep,
  } = useQuery(GET_LABEL_APPROVAL_WORKFLOW_STEP, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        id: idToReview,
      },
    },
    skip: !idToReview,
  })

  const [updateLabelApprovalWorkflowStep, { loading: submitting }] = useMutation(
    UPDATE_LABEL_APPROVAL_WORKFLOW_STEP,
    {
      onCompleted() {
        refetchWorkflowStep()
      },
    }
  )

  const updateStep = async (data: { status: string; rejectionReason?: string }) => {
    if (dataWorkflowStep) {
      await updateLabelApprovalWorkflowStep({
        variables: {
          where: {
            id: workflowStep.id,
          },
          data,
        },
      })
      handleClose()
    }
  }

  useEffect(() => {
    if (dataWorkflowStep) {
      setWorkflowStep(dataWorkflowStep.getLabelApprovalWorkflowStep)
    }
  }, [dataWorkflowStep])

  if (!selectedStoreIdVar || !storesVar || !workflowStep) return <></>

  const buttonsDisabled =
    loadingWorkflowStep || submitting || _.includes(['Approved', 'Rejected'], workflowStep.status)
  const metadata = JSON.parse(workflowStep.workflow.metadata || '{}')

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleReject = () => {
    updateStep({ status: 'Rejected', rejectionReason })
  }

  const handleChangeRequested = async () => {
    updateStep({ status: 'ChangeRequested', rejectionReason })
  }

  const currentStep = workflowStep?.workflow?.steps.find(
    (x: any) => x.stepNumber === workflowStep?.workflow.activeStepNumber
  )
  const buttonsShow =
    !!email && currentStep?.assignedTo?.email === email && currentStep?.status === 'Received'

  return (
    <>
      <Box sx={{ paddingBottom: 2 }}>
        <Typography variant={'h5'}>
          {storesVar.find((x) => x.id === selectedStoreIdVar).name} - Review Label
        </Typography>
      </Box>
      {workflowStep && (
        <Box sx={{ width: '100%' }}>
          <ReviewLabel
            buttonsDisabled={buttonsDisabled}
            buttonsShow={buttonsShow}
            metadata={metadata}
            workflowInstance={workflowStep.workflow}
            updateStep={updateStep}
            setIsOpen={setIsOpen}
            idToReview={workflowStep.id}
            open={isOpen}
            handleClose={handleClose}
            handleConfirm={handleChangeRequested}
            reason={rejectionReason}
            setReason={setRejectionReason}
            canRestart={false}
            handleReload={() => {}}
            maxImageHeight={'600px'}
          />
        </Box>
      )}
    </>
  )
}
