import { FC } from 'react'
import { ProductGrid, ProductGridProps } from '../ProductGrid/ProductGrid.js'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'

interface Props {
  className?: string
  children?: any
  collection: string | any // ShopifyBuy.Collection once their types are up to date
  productGridOptions: Omit<ProductGridProps, 'products'>
}

const CollectionPreview: FC<Props> = ({ collection, productGridOptions }) => {
  if (!collection) {
    return <>There are no products available</>
  }
  const { title, description, products } = collection

  const titleElement = <Typography variant={'h5'}>{title}</Typography>

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} key={collection.id}>
      <Box sx={{ padding: '0px' }}>
        {titleElement}
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Box>
      <Box sx={{ p: 5 }}>
        <ProductGrid {...productGridOptions} products={products} />
      </Box>
    </Box>
  )
}

export default CollectionPreview
