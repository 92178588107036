import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import AppBar from '@mui/material/AppBar'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import AppsIcon from '@mui/icons-material/Apps'
import _ from 'lodash'
import { useNavigate, useLocation } from 'react-router-dom'
import LogoutForm from 'pages/AuthPages/LogoutForm'
import { useRecoilState } from 'recoil'
import { navOpenState } from 'store/app.ts'

export default function CustomAppBar({ app }: { app: string }) {
  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useRecoilState(navOpenState)

  const location = useLocation()

  const shouldShowMenu =
    _.startsWith(location.pathname, '/vendor') ||
    _.startsWith(location.pathname, '/operations') ||
    _.startsWith(location.pathname, '/settings') ||
    _.startsWith(location.pathname, '/reports')

  const appConfig: {
    name: string
    home: string
  } = {
    admin: { name: 'Kaerwell Admin', home: '/' },
    barcode: { name: 'Kaerwell Barcodes', home: '/operations/barcodes' },
    'label-versions': { name: 'Kaerwell Label Versions', home: '/vendor/label-versions' },
    labels: { name: 'Kaerwell Private Labels', home: '/vendor/labels' },
    vendor: { name: 'Vendor Portal', home: '/vendor' },
    operations: { name: 'Kaerwell Operations', home: '/operations' },
    reports: { name: 'Kaerwell Reports', home: '/reports' },
  }[app] || { name: '', home: '/' }

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        {shouldShowMenu && (
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={() => {
              setIsOpen(!isOpen)
            }}
            sx={{ mr: 2, display: { lg: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        )}
        {shouldShowMenu && matches && (
          <IconButton
            onClick={() => navigate('/')}
            size='large'
            edge='start'
            color='inherit'
            aria-label='menu'
            sx={{ mr: 2 }}
          >
            <AppsIcon />
          </IconButton>
        )}
        <Typography
          variant='h6'
          component='div'
          sx={{ flexGrow: 1, cursor: 'pointer' }}
          onClick={() => navigate(appConfig.home)}
        >
          {appConfig.name}
        </Typography>
        <LogoutForm />
      </Toolbar>
    </AppBar>
  )
}
