import { useState, useEffect } from 'react'
import { DialogTitle, DialogContent, DialogActions, Button, Grid, Alert, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useMutation } from '@apollo/client'
import { useForm, Control } from 'react-hook-form'
import { CREATE_SKU } from '@/lib/graphql/createSku'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { FormSwitch } from 'components/Forms/FormSwitch.tsx'
import { FormTextField } from 'components/Forms/FormTextField.tsx'
import { Sku } from '@/lib/graphql/types'
import { UPDATE_SKU } from '@/lib/graphql/updateSku'
import { BrandSelect } from 'components/Forms/BrandSelect'

type SkuProps = Sku

const initialValues = {
  brandId: '',
  sku: '',
  size: '',
  privateLabelName: '',
  productName: '',
  skuWhiteLabel: '',
  isProp65: false,
}

const DisplayingErrorMessagesSchema = Yup.object().shape({
  brandId: Yup.string().required('Required'),
  isProp65: Yup.boolean().required(),
  sku: Yup.string().required('Required'),
  size: Yup.string().required('Required'),
  productName: Yup.string().required('Required'),
  privateLabelName: Yup.string().optional(),
  skuWhiteLabel: Yup.string().optional(),
  labelWidth: Yup.number().min(0, 'Invalid number').optional().typeError('Invalid number'),
  printedWidth: Yup.number().min(0, 'Invalid number').optional().typeError('Invalid number'),
  labelHeight: Yup.number().min(0, 'Invalid number').optional().typeError('Invalid number'),
  printedHeight: Yup.number().min(0, 'Invalid number').optional().typeError('Invalid number'),
  shrinkBandWidth: Yup.number().min(0, 'Invalid number').optional().typeError('Invalid number'),
  shrinkBandHeight: Yup.number().min(0, 'Invalid number').optional().typeError('Invalid number'),
})

type SkuFormProps = {
  isDirty: boolean
  isSubmitting: boolean
  isValid: boolean
  control: Control<SkuFieldProps, any>
  submitText: string
  submitError: string | undefined
  organizationId: string
  closeDialog: () => void
  handleSubmit: () => void
}

const SkuForm = ({
  handleSubmit,
  submitText,
  isDirty,
  isValid,
  isSubmitting,
  closeDialog,
  organizationId,
  submitError,
  control,
}: SkuFormProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Grid container spacing={2}>
          {submitError && (
            <Grid item xs={12}>
              <Alert severity={'error'}>{submitError}</Alert>
            </Grid>
          )}
          <Grid item xs={6}>
            <BrandSelect control={control} organizationId={organizationId} name='brandId' label='Brand' />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              name='size'
              label='Size'
              type='text'
              fullWidth
              variant='standard'
              required
              margin='dense'
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              margin='dense'
              name='sku'
              label='SKU'
              type='text'
              fullWidth
              variant='standard'
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              margin='dense'
              name='productName'
              label='Product Name'
              type='text'
              fullWidth
              variant='standard'
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              margin='dense'
              name='skuWhiteLabel'
              label='SKU Private Label'
              type='text'
              fullWidth
              variant='standard'
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              margin='dense'
              name='privateLabelName'
              label='Private Label Product Name'
              type='text'
              fullWidth
              variant='standard'
              required={false}
            />
          </Grid>
          <Grid item xs={6}>
            <Stack direction='row' spacing={1} alignItems='flex-end'>
              <FormTextField
                control={control}
                margin='dense'
                name='labelWidth'
                label='Label Width (in.)'
                type='number'
                fullWidth
                variant='standard'
                required={false}
              />
              <CloseIcon sx={{ fontSize: 14 }} />
              <FormTextField
                control={control}
                margin='dense'
                name='labelHeight'
                label='Label Height (in.)'
                type='text'
                fullWidth
                variant='standard'
                required={false}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction='row' spacing={1} alignItems='flex-end'>
              <FormTextField
                control={control}
                margin='dense'
                name='printedWidth'
                label='Printed Width (in.)'
                type='text'
                fullWidth
                variant='standard'
                required={false}
              />
              <CloseIcon sx={{ fontSize: 14 }} />
              <FormTextField
                control={control}
                margin='dense'
                name='printedHeight'
                label='Printed Height (in.)'
                type='text'
                fullWidth
                variant='standard'
                required={false}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction='row' spacing={1} alignItems='flex-end'>
              <FormTextField
                control={control}
                margin='dense'
                name='shrinkBandWidth'
                label='Shrink Band Width (mm)'
                type='number'
                fullWidth
                variant='standard'
                required={false}
              />
              <CloseIcon sx={{ fontSize: 14 }} />
              <FormTextField
                control={control}
                margin='dense'
                name='shrinkBandHeight'
                label='Shrink Band Height (mm)'
                type='text'
                fullWidth
                variant='standard'
                required={false}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <FormSwitch control={control} name='isProp65' label='Product subject to CA Proposition 65' />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isSubmitting}
          onClick={() => {
            closeDialog()
          }}
        >
          Cancel
        </Button>
        <Button type='submit' disabled={isSubmitting || !(isDirty && isValid)}>
          {submitText}
        </Button>
      </DialogActions>
    </form>
  )
}

type SkuFieldProps = {
  brandId: string
  sku: string
  isProp65: boolean
  size: string
  privateLabelName?: string
  productName: string
  skuWhiteLabel?: string
  labelWidth?: string
  printedWidth?: string
  labelHeight?: string
  printedHeight?: string
  shrinkBandWidth?: string
  shrinkBandHeight?: string
}

const createDataInput = (values: SkuFieldProps) => ({
  brand: { connect: { id: values.brandId } },
  sku: values.sku,
  skuWhiteLabel: values.skuWhiteLabel,
  skuFact: {
    create: {
      isProp65: values.isProp65,
      size: values.size,
      privateLabelName: values.privateLabelName,
      productName: values.productName,
      dimensions: JSON.stringify({
        label: {
          width: values.labelWidth,
          height: values.labelHeight,
          units: 'in.',
        },
        printedLabel: {
          width: values.printedWidth,
          height: values.printedHeight,
          units: 'in.',
        },
        shrinkBand: {
          width: values.shrinkBandWidth,
          height: values.shrinkBandHeight,
          units: 'mm',
        },
      }),
    },
  },
})

const updateDataInput = (values: SkuFieldProps, data: SkuProps) => {
  const dimensions = JSON.parse(data.skuFact.dimensions || '{}')
  return {
    sku: values.sku,
    skuWhiteLabel: values.skuWhiteLabel,
    skuFact: {
      update: {
        isProp65: values.isProp65,
        size: values.size,
        privateLabelName: values.privateLabelName,
        productName: values.productName,
        dimensions: JSON.stringify({
          ...dimensions,
          label: {
            width: values.labelWidth,
            height: values.labelHeight,
            units: 'in.',
          },
          printedLabel: {
            width: values.printedWidth,
            height: values.printedHeight,
            units: 'in.',
          },
          shrinkBand: {
            width: values.shrinkBandWidth,
            height: values.shrinkBandHeight,
            units: 'mm',
          },
        }),
      },
    },
  }
}

const transformDataToSkuFields = (data: SkuProps): SkuFieldProps => {
  const dimensions = JSON.parse(data.skuFact.dimensions || '{}')
  return {
    brandId: data.brandId,
    sku: data.sku,
    skuWhiteLabel: data.skuWhiteLabel,
    isProp65: data.skuFact.isProp65,
    size: data.skuFact.size,
    privateLabelName: data.skuFact.privateLabelName,
    productName: data.skuFact.productName,
    labelWidth: dimensions.label?.width,
    labelHeight: dimensions.label?.height,
    printedWidth: dimensions.printedLabel?.width,
    printedHeight: dimensions.printedLabel?.height,
    shrinkBandWidth: dimensions.shrinkBand?.width,
    shrinkBandHeight: dimensions.shrinkBand?.height,
  }
}

const SkuCreateOrUpdateDialog = ({
  onSuccess,
  currentOrganizationId,
  mode,
  data,
  closeDialog,
}: {
  mode: 'create' | 'update'
  onSuccess: () => void
  currentOrganizationId: string
  data?: SkuProps
  closeDialog: () => void
}) => {
  const [submitError, setSubmitError] = useState<string>()

  const [createProduct] = useMutation(CREATE_SKU, {
    onCompleted() {
      closeDialog()
      onSuccess()
    },
    onError(error) {
      setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
    },
  })

  const [updateProduct] = useMutation(UPDATE_SKU, {
    onCompleted() {
      closeDialog()
      onSuccess()
    },
    onError(error) {
      setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
    },
  })

  const create = async (values: SkuFieldProps) =>
    await createProduct({
      variables: {
        data: createDataInput(values),
      },
    })

  const update = async (values: SkuFieldProps) =>
    await updateProduct({
      variables: {
        where: {
          id: data?.id,
        },
        data: updateDataInput(values, data!),
      },
    })

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm<SkuFieldProps>({
    shouldFocusError: true,
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver<SkuFieldProps>(DisplayingErrorMessagesSchema as any),
    defaultValues: mode === 'create' || !data ? initialValues : transformDataToSkuFields(data),
  })

  const onSubmit = async (values: SkuFieldProps) => {
    mode === 'create' ? await create(values) : await update(values)
  }

  // this is needed because textField valeus are always strings
  // if the user deletes the whole input, it goes back to a empty string but it fails
  // on Yup validation, so we need to set it to undefined.
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (
        name &&
        [
          'labelWidth',
          'printedWidth',
          'labelHeight',
          'printedHeight',
          'shrinkBandWidth',
          'shrinkBandHeight',
        ].includes(name) &&
        value[name] === ''
      ) {
        setValue(name, undefined, { shouldValidate: true, shouldDirty: false })
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, setValue])

  if (!data && mode === 'update') {
    return <></>
  }

  return (
    <>
      <DialogTitle>{mode === 'update' ? 'Update' : 'Create New'} SKU</DialogTitle>
      <SkuForm
        isSubmitting={isSubmitting}
        isDirty={isDirty}
        isValid={isValid}
        control={control}
        organizationId={currentOrganizationId}
        submitText={mode === 'update' ? 'Update' : 'Create'}
        submitError={submitError}
        closeDialog={closeDialog}
        handleSubmit={handleSubmit(onSubmit)}
      />
    </>
  )
}

export default SkuCreateOrUpdateDialog
