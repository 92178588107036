import { useReactiveVar } from '@apollo/client'
import { Box, Button, Dialog, Typography } from '@mui/material'
import { organizationId } from 'config/cache.ts'
import { LabelFactsApp } from './LabelFactsApp.tsx'
import { getUserRole, ROLES } from 'lib/rbac.tsx'
import { useState } from 'react'
import LabelFactsImportDialog from 'pages/VendorApp/Dialogs/LabelFactsImportDialog.tsx'

export const LabelFacts = () => {
  const currentOrganizationId = useReactiveVar(organizationId)
  const userRole = getUserRole()
  const [showImportModal, setShowImportModal] = useState(false)

  if (!currentOrganizationId) return <></>

  return (
    <>
      <Box sx={{ paddingBottom: 2 }}>
        <Typography variant={'h5'}>Label Facts</Typography>
      </Box>
      {userRole === ROLES.ADMIN && (
        <Box sx={{ width: '100', display: 'flex', justifyContent: 'right' }}>
          <Button onClick={() => setShowImportModal(true)}>Import Label Facts</Button>
        </Box>
      )}
      <LabelFactsApp />
      <Dialog open={showImportModal} onClose={() => setShowImportModal(false)} fullWidth maxWidth='md'>
        <LabelFactsImportDialog
          closeDialog={() => setShowImportModal(false)}
          currentOrganizationId={currentOrganizationId}
          onSuccess={async () => {
            // setRowSelectionModel([])
            // await refetchCatalog()
          }}
        />
      </Dialog>
    </>
  )
}
