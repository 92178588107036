import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { Alert, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { useQuery } from '@apollo/client'
import { GET_LABEL } from 'lib/graphql/getLabel.js'

interface LabelViewerProps {
  selectedLabel: string
}

export const LabelViewer = ({ selectedLabel }: LabelViewerProps) => {
  const { data } = useQuery(GET_LABEL, {
    variables: {
      where: {
        id: selectedLabel,
      },
    },
  })
  if (!data) return <></>
  return (
    <Box>
      <Paper
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingY: 1 }}
        square
      >
        {/*<Typography color='#001D35' fontWeight={700} fontSize='18px' sx={{ flex: 1 }} textAlign='end'>*/}
        {/*  Order {selectedLabel}*/}
        {/*</Typography>*/}
        <Box display='hidden' sx={{ flexGrow: 1 }} />
      </Paper>
      <Box paddingX={3} sx={{ backgroundColor: '#F9F9FC' }} py={1}>
        <Typography color='#001D35' fontWeight={500} fontSize='18px'>
          Label Viewer
        </Typography>
        <Table sx={{ maxWidth: '500px' }}>
          <TableBody>
            <TableRow>
              <TableCell>Order Number</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{data.getLabel.order.commonId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>SKU</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{data.getLabel.sku.sku}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Lot Number</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{data.getLabel.lot?.lotNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Expiration Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{data.getLabel.lot?.expirationDate}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Paper
          sx={{
            maxWidth: 'lg',
            width: '100%',
            mt: 2,
            display: 'flex',
            justifyContent: 'center',
            minHeight: '100px',
          }}
          square
          elevation={3}
        >
          {data.getLabel.url ? (
            <iframe height='400px' width='100%' src={data.getLabel.url}></iframe>
          ) : (
            <Alert severity='error' sx={{ alignSelf: 'center' }}>
              Label not available
            </Alert>
          )}
        </Paper>
      </Box>
    </Box>
  )
}
