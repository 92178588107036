import { gql } from '@apollo/client'

export const GET_PRESIGNED_URL_FOR_PUBLIC_ASSET = gql`
  query getPresignedUrlForPublicAsset(
    $type: String
    $storeId: String
    $orgId: String
    $filename: String
    $method: String
    $contentType: String
  ) {
    getPresignedUrlForPublicAsset(
      type: $type
      storeId: $storeId
      orgId: $orgId
      filename: $filename
      method: $method
      contentType: $contentType
    ) {
      bucket
      key
      presignedUrl
    }
  }
`
