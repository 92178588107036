import React from 'react'
import PaymentsIcon from '@mui/icons-material/Payments'
import ListAltIcon from '@mui/icons-material/ListAlt'
import { DrawerLayout, MenuType } from '@/layouts/DrawerLayout.tsx'

export const ReportsAppLayout = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
  const menu: MenuType = [
    {
      title: 'Reports',
      children: [
        {
          title: 'Billing',
          url: '/reports/billing',
          icon: <PaymentsIcon />,
        },
        {
          title: 'Orders',
          url: '/reports/orders',
          icon: <ListAltIcon />,
        },
        {
          title: 'Payments',
          url: '/reports/payments',
          icon: <ListAltIcon />,
        },
      ],
    },
  ]

  return (
    <DrawerLayout showOrgSelector={true} menu={menu}>
      {children}
    </DrawerLayout>
  )
}
