import { gql } from '@apollo/client'

export const CHECK_SHOPIFY_STORE_CONFIGURATION = gql`
  mutation checkShopifyStoreConfiguration($where: ShopifyStoreConfigurationWhereUniqueInput!) {
    checkShopifyStoreConfiguration(where: $where) {
      success
      configurationLastCheckedAt
      configurationErrors
    }
  }
`
