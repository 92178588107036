export const startSession = async () => {
  const response = await fetch(
    `${import.meta.env.VITE_SPI_BASE_URL}/api/v1/session/start/${import.meta.env.VITE_SPI_ACCESS_TOKEN}`,
    {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    }
  )
  const responseJson = await response.json()
  return responseJson.token
}

export const upsertInstance = async (
  templateId: string,
  name: string,
  templateVars: Record<string, string | undefined>,
  userAccessToken: string
) => {
  let instanceId = await createInstance(templateId, name, templateVars, userAccessToken)
  if (!instanceId) {
    instanceId = await updateInstance(name, templateVars, userAccessToken)
  }
  return instanceId
}

export const createInstance = async (
  templateId: string,
  username: string,
  templateVars: Record<string, string | undefined>,
  userAccessToken: string
) => {
  const body = {
    instance: {
      templateGUID: templateId,
      name: `${templateId}_${username}_${Date.now().toString()}`,
      variables: {
        spi_vars: Object.entries(templateVars)
          .filter(([, v]) => v)
          .map(([k, v]) => ({ name: k, value: v })),
      },
    },
  }
  const response = await fetch(`${import.meta.env.VITE_SPI_BASE_URL}/api/sd/v1/instance/create`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'x-spitoken-id': import.meta.env.VITE_SPI_ACCESS_TOKEN,
      'x-accesstoken-id': userAccessToken,
    },
    body: JSON.stringify(body),
  })
  const responseJson = await response.json()
  return responseJson.GUID
}

export const readInstance = async (instanceId: number, userAccessToken: string) => {
  const response = await fetch(
    `${import.meta.env.VITE_SPI_BASE_URL}/api/sd/v1/instance/read/${instanceId}?include_variables=spi_vars`,
    {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'x-spitoken-id': import.meta.env.VITE_SPI_ACCESS_TOKEN,
        'x-accesstoken-id': userAccessToken,
      },
    }
  )
  return response.json()
}

export const updateInstance = async (
  instanceIdOrName: string,
  templateVars: Record<string, string | undefined>,
  userAccessToken: string
) => {
  const body = {
    instance: {
      id: instanceIdOrName,
      produce: 'true',
      variables: {
        spi_vars: Object.entries(templateVars)
          .filter(([, v]) => v)
          .map(([k, v]) => ({ name: k, value: v })),
      },
    },
  }
  const response = await fetch(`${import.meta.env.VITE_SPI_BASE_URL}/api/sd/v1/instance/update`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'x-spitoken-id': import.meta.env.VITE_SPI_ACCESS_TOKEN,
      'x-accesstoken-id': userAccessToken,
    },
    body: JSON.stringify(body),
  })
  const responseJson = await response.json()
  return responseJson.GUID
}

export const getInstancePreviewUrl = async (instanceId: string, userAccessToken: string) => {
  const response = await fetch(
    `${import.meta.env.VITE_SPI_BASE_URL}/api/sd/v1/instance/${instanceId}/preview/0`,
    {
      method: 'GET',
      headers: {
        'x-spitoken-id': import.meta.env.VITE_SPI_ACCESS_TOKEN,
        'x-accesstoken-id': userAccessToken,
      },
    }
  )
  const responseBlob = await response.blob()
  return URL.createObjectURL(responseBlob)
}

export const getTemplate = async (templateName: string, session: string) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_SPI_BASE_URL}/api/v1/entity/templates/${templateName}?token=${session}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      }
    )
    const responseJson = await response.json()
    return {
      id: responseJson.entities[0].id,
    }
  } catch {
    return {}
  }
}

export const getTemplatePreviewUrl = (templateName: string, session: string) => {
  return `${
    import.meta.env.VITE_SPI_BASE_URL
  }/api/v1/entity/templates/${templateName}/preview/0?token=${session}`
}

export const getTemplateDesignerUrl = (userAccessToken: string, instanceId: string) => {
  const iframeUrl = new URL(import.meta.env.VITE_SPI_DESIGNER_URL)
  iframeUrl.search = new URLSearchParams({
    api_key: import.meta.env.VITE_SPI_ACCESS_TOKEN,
    userAccessToken,
    instanceId,
  }).toString()
  return iframeUrl
}
