import { gql } from '@apollo/client'

export const UPDATE_EXTENSIV_CONFIGURATION = gql`
  mutation updateExtensivConfiguration(
    $where: ExtensivConfigurationExtendedWhereUniqueInput!
    $data: ExtensivConfigurationUpdateInput
  ) {
    updateExtensivConfiguration(where: $where, data: $data) {
      id
      customerName
      organizationId
    }
  }
`
