import { Button, Grid, Paper, Typography } from '@mui/material'
import { Input } from 'components/Forms/Input.tsx'
import { FormTextField } from 'components/Forms/FormTextField.tsx'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { LIST_STORE_CONFIGURATION_FULFILLMENTS } from 'lib/graphql/listStoreConfigurationFulfillment.ts'
import { selectedStoreId } from 'config/cache.ts'
import _ from 'lodash'
import { GET_EXTENSIV_CONFIGURATION } from 'lib/graphql/getExtensivConfiguration.ts'
import { UPDATE_EXTENSIV_CONFIGURATION } from 'lib/graphql/updateExtensivConfiguration.ts'

type FulfillmentConfigurationData = {
  id?: string
  description?: string
  fulfillmentType?: string
  customerName?: string
  extensivConfigurationId?: string
}

type FulfillmentConfigurationFields = Omit<FulfillmentConfigurationData, 'id' | 'extensivConfigurationId'>

const validationSchema = Yup.object().shape({
  id: Yup.string().required(),
  description: Yup.string(),
  fulfillmentType: Yup.string(),
  customerName: Yup.string(),
})

export const FulfillmentSettings = () => {
  const selectedStoreIdVar = useReactiveVar(selectedStoreId)
  const [fulfillmentData, setFulfillmentData] = useState<FulfillmentConfigurationData>()
  const [extensivId, setExtensivId] = useState<string>()

  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors, isSubmitting, isDirty, isValid },
    reset,
    getValues,
  } = useForm<FulfillmentConfigurationFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver<FulfillmentConfigurationFields>(validationSchema),
  })

  const {
    refetch: refetchFulfillmentConfigurations,
    data: dataFulfillmentConfigurations,
    loading: loadingFulfillmentConfigurations,
    error: errorFulfillmentConfigurations,
  } = useQuery(LIST_STORE_CONFIGURATION_FULFILLMENTS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      where: {
        storeConfigurationId: { equals: selectedStoreIdVar },
      },
    },
    skip: !selectedStoreIdVar,
  })

  const {
    refetch: refetchExtensivConfiguration,
    data: dataExtensivConfiguration,
    loading: loadingExtensivConfiguration,
    error: errorExtensivConfiguration,
  } = useQuery(GET_EXTENSIV_CONFIGURATION, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      where: {
        id: extensivId,
      },
    },
    skip: !extensivId,
  })

  const transformDataToFormValues = (data: FulfillmentConfigurationData) => {
    return {
      id: data.id,
      description: data.description,
      fulfillmentType: data.fulfillmentType,
      customerName: data.customerName,
    }
  }

  useEffect(() => {
    if (!loadingFulfillmentConfigurations && dataFulfillmentConfigurations) {
      if (dataFulfillmentConfigurations.listStoreConfigurationFulfillments.length === 1) {
        const _data = dataFulfillmentConfigurations.listStoreConfigurationFulfillments[0]
        const newData = {
          ...fulfillmentData,
          id: _data.id,
          description: _data.description,
          fulfillmentType: _data.fulfillmentType,
          customerName: '',
        }
        if (_data.fulfillmentType === 'Extensiv') {
          setExtensivId(_data.fulfillmentConfigurationId)
          refetchExtensivConfiguration()
        }
        setFulfillmentData(newData)
        reset(transformDataToFormValues(newData))
      }
    }
  }, [dataFulfillmentConfigurations, loadingFulfillmentConfigurations])

  useEffect(() => {
    if (!loadingExtensivConfiguration && dataExtensivConfiguration?.getExtensivConfiguration) {
      const newData = {
        ...fulfillmentData,
        extensivConfigurationId: dataExtensivConfiguration.getExtensivConfiguration.id,
        customerName: dataExtensivConfiguration.getExtensivConfiguration.customerName,
      }
      setFulfillmentData(newData)
      reset(transformDataToFormValues(newData))
    }
  }, [dataExtensivConfiguration, loadingExtensivConfiguration])

  const [updateExtensivConfiguration] = useMutation(UPDATE_EXTENSIV_CONFIGURATION)

  const toGraphQL = (data: any) => {
    return {
      customerName: data.customerName,
    }
  }

  const onSubmit: SubmitHandler<FulfillmentConfigurationData> = async (data) => {
    fulfillmentData?.extensivConfigurationId &&
      (await updateExtensivConfiguration({
        variables: {
          where: {
            id: fulfillmentData.extensivConfigurationId,
          },
          data: toGraphQL(data),
        },
      })) &&
      (await refetchFulfillmentConfigurations())
  }

  const onReset = async () => {
    fulfillmentData && reset(transformDataToFormValues(fulfillmentData))
  }

  if (!selectedStoreIdVar || _.isEmpty(fulfillmentData)) return <></>

  return (
    <Paper
      elevation={5}
      sx={{
        height: 'inherit',
        display: 'grid',
        gridTemplateRows: 'auto 1f auto',
        borderRadius: '16px',
        p: 1,
      }}
    >
      <Grid sx={{ padding: '0px 20px 20px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input type='hidden' control={control} name='id' />
          <Grid
            container
            direction={'row'}
            spacing={4}
            sx={{
              marginTop: '5px',
              marginBottom: '5px',
              justifyContent: 'space-between',
            }}
          >
            <Grid item>
              <Typography variant='h5'>Fulfillment</Typography>
            </Grid>
            <Grid item sx={{ alignSelf: 'right' }}>
              <Button
                variant={'outlined'}
                disabled={isSubmitting}
                onClick={() => {
                  onReset()
                }}
              >
                Reset
              </Button>

              <Button
                sx={{ marginLeft: '10px' }}
                variant={'contained'}
                type='submit'
                disabled={isSubmitting || !(isDirty && isValid)}
              >
                Save
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: '5px' }}>
            <Grid item xs={12} sx={{ fontWeight: 'bold' }}></Grid>
            <Grid item xs={12} lg={6} sx={{ paddingTop: '10px' }}>
              <FormTextField
                name='description'
                label='Description'
                type='text'
                fullWidth
                variant='standard'
                disabled={loadingFulfillmentConfigurations || true}
                control={control}
              />
            </Grid>
            <Grid item xs={4} sm={4} sx={{ paddingTop: '10px' }}>
              <FormTextField
                name='fulfillmentType'
                label='WMS'
                type='text'
                fullWidth
                variant='standard'
                disabled={loadingFulfillmentConfigurations || true}
                control={control}
              />
            </Grid>
            <Grid item xs={4} sm={8} sx={{ paddingTop: '10px' }}>
              <FormTextField
                name='customerName'
                label='Identifier'
                type='text'
                fullWidth
                variant='standard'
                disabled={loadingFulfillmentConfigurations}
                control={control}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Paper>
  )
}
