import React, { useEffect, useState } from 'react'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useMutation, useReactiveVar } from '@apollo/client'
import * as Yup from 'yup'
import { DesignerTemplate } from 'lib/graphql/types.ts'
import style from './dialogs.module.css'
import { Input } from 'components/Forms/Input.tsx'
import { FormTextField } from 'components/Forms/FormTextField.tsx'
import { yupResolver } from '@hookform/resolvers/yup'
import { CREATE_TEMPLATE } from 'lib/graphql/createTemplate.ts'
import { UPDATE_TEMPLATE } from 'lib/graphql/updateTemplate.ts'
import { getTemplate, getTemplatePreviewUrl, startSession } from '@/lib/sdi'
import { organizationId } from 'config/cache.ts'

type TemplateFieldProps = Omit<DesignerTemplate, 'sku' | 'skuId' | 'id'>

const validationSchema = Yup.object().shape({
  templateId: Yup.string().required(),
  imageUrl: Yup.string().required(),
  displayName: Yup.string().required(),
})

interface TemplateCreateOrUpdateProps {
  mode: 'create' | 'update'
  skuId: string
  skuName: string
  data: any
  onSuccess: () => void
  closeDialog: () => void
}

export const TemplateCreateOrUpdateDialog: React.FC<TemplateCreateOrUpdateProps> = ({
  mode,
  data,
  skuId,
  skuName,
  onSuccess,
  closeDialog,
}) => {
  const initialValues: TemplateFieldProps = {
    templateId: '',
    imageUrl: '',
    displayName: '',
  }
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, isDirty, isValid, dirtyFields },
    reset,
    getValues,
  } = useForm<TemplateFieldProps>({
    reValidateMode: 'onChange',
    resolver: yupResolver<TemplateFieldProps>(validationSchema),
    defaultValues: initialValues,
  })

  const [session, setSession] = useState<string>()
  const [internalData] = useState(data)
  const [submitError, setSubmitError] = useState<string>()
  const organizationIdVar = useReactiveVar(organizationId)

  const [createTemplate] = useMutation(CREATE_TEMPLATE, {
    onCompleted() {
      closeDialog()
      onSuccess()
    },
    onError(error) {
      setSubmitError(error.message)
    },
  })

  const [updateTemplate] = useMutation(UPDATE_TEMPLATE, {
    onCompleted() {
      closeDialog()
      onSuccess()
    },
    onError(error) {
      setSubmitError(error.message)
    },
  })

  const transformDataToFormValues = (data: any) => {
    return {
      ...data,
      templateId: data.templateId?.trim(),
    }
  }

  const graphqlDataInput = (values: any, skuId?: string) => ({
    sku: {
      connect: {
        id: skuId,
      },
    },
    organizationId: organizationIdVar,
    templateId: values.templateId?.trim(),
    displayName: values.displayName?.trim(),
    imageUrl: values.imageUrl,
  })

  const create = async (values: TemplateFieldProps) => {
    await createTemplate({
      variables: {
        data: graphqlDataInput(values, skuId),
      },
    })
  }

  const update = async (values: TemplateFieldProps) => {
    await updateTemplate({
      variables: {
        where: { id: data.id },
        data: graphqlDataInput(values, skuId),
      },
    })
  }

  const fetchTemplate = async () => {
    if (!session) {
      console.log('No session available, exiting fetchTemplate')
      return
    }
    const templateId = getValues('templateId')
    if (!templateId) {
      console.log('No template name, exiting fetchTemplate')
      return
    }
    const templateData = await getTemplate(templateId.trim(), session)

    const options = {
      shouldValidate: true,
      shouldDirty: true,
    }
    if (templateData.id) {
      setValue('imageUrl', await getTemplatePreviewUrl(templateData.id, session), options)
    } else {
      setValue('imageUrl', '', options)
    }
  }

  useEffect(() => {
    mode === 'update' && reset(transformDataToFormValues(internalData))
  }, [mode])

  useEffect(() => {
    if (!session) {
      const run = async () => {
        setSession(await startSession())
      }
      run()
    }
  }, [session, setSession])

  const onSubmit: SubmitHandler<TemplateFieldProps> = async (data) => {
    mode === 'create' ? await create(data) : await update(data)
  }

  useEffect(() => {
    if (dirtyFields?.templateId) {
      setValue('imageUrl', '')
    }
  }, [dirtyFields.templateId])

  return (
    <Box sx={{ height: 'auto' }}>
      <DialogTitle>
        {mode === 'update' ? 'Update' : 'Create New'} Designer Template for {skuName}
      </DialogTitle>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input type='hidden' control={control} name='id' />
        <DialogContent>
          <Box sx={{ minHeight: '390px' }}>
            <Box>{submitError && <Alert severity={'error'}>{submitError}</Alert>}</Box>
            <Grid container spacing={2} className={style.dialogTabDetails}>
              <Grid item xs={12} sm={8}>
                <FormTextField
                  control={control}
                  name='templateId'
                  label='Template ID/Name'
                  type='text'
                  fullWidth
                  variant='standard'
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button onClick={async () => fetchTemplate()}>Load Preview</Button>
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  name='displayName'
                  label='Display Name'
                  type='text'
                  fullWidth
                  variant='standard'
                  control={control}
                  // disabled={!watch('templateId')}
                  rules={{ required: false }}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '10px' }}>
                {watch('imageUrl') && <img src={watch('imageUrl')} width={'100%'} />}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            onClick={() => {
              closeDialog()
            }}
          >
            Cancel
          </Button>
          <Button type='submit' disabled={isSubmitting || !(isDirty && isValid)}>
            {mode === 'update' ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Box>
  )
}
