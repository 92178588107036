import Box from '@mui/material/Box'
import CustomAppBar from '../Appbar/Appbar'
import Toolbar from '@mui/material/Toolbar'
import React from 'react'

export const Layout = ({ app, children }: { app: string; children: React.ReactNode | React.ReactNode[] }) => {
  return (
    <Box id='layout' sx={{ display: 'flex', width: '100vw' }}>
      <CustomAppBar app={app} />
      <Box component='main' sx={{ flexGrow: 1 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  )
}
