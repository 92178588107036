import { gql } from '@apollo/client'

export const UPDATE_LABEL_APPROVAL_WORKFLOW_CONFIGURATIONS = gql`
  mutation updateLabelApprovalWorkflowConfiguration(
    $where: LabelApprovalWorkflowConfigurationWhereUniqueInput!
    $data: LabelApprovalWorkflowConfigurationUpdateInput
  ) {
    updateLabelApprovalWorkflowConfiguration(where: $where, data: $data) {
      id
    }
  }
`
