import React, { useEffect, useState } from 'react'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
} from '@mui/material'
import { withMask } from 'use-mask-input'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import * as Yup from 'yup'
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser'
import { Input } from 'components/Forms/Input.tsx'
import { FormTextField } from 'components/Forms/FormTextField.tsx'
import { yupResolver } from '@hookform/resolvers/yup'
import { ShopifyCustomer, ACCOUNT_STATUS } from 'lib/types.ts'
import { PROXY_SHOPIFY_MUTATION } from 'lib/graphql/proxyShopifyMutation.js'

export type CustomerFieldProps = Omit<
  ShopifyCustomer,
  'brandAcl' | 'defaultAddress' | 'website' | 'displayName' | 'privateLabels' | 'id' | 'addresses'
> & {
  websiteUrl?: string
  id?: string
}

const validationSchema = Yup.object().shape({
  id: Yup.string().optional(),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required'),
  websiteUrl: Yup.string().url().optional(),
  phone: Yup.string(),
})

interface CustomerCreateOrUpdateProps {
  storeId?: string
  data: any
  onSuccess: () => void
  closeDialog: () => void
}

export const UpdateCustomerInfoDialog: React.FC<CustomerCreateOrUpdateProps> = ({
  data,
  storeId,
  onSuccess,
  closeDialog,
}) => {
  const [internalData, setInternalData] = useState(data)
  const [submitError, setSubmitError] = useState<string>()

  const [updateCustomer] = useMutation(PROXY_SHOPIFY_MUTATION, {
    onCompleted(data) {
      closeDialog()
      onSuccess()
    },
    onError(error) {
      setSubmitError(error.message)
      // setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
    },
  })

  const transformDataToFormValues = (data: any) => {
    return {
      ...data,
      websiteUrl: data?.website?.value,
    }
  }

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
    reset,
    getValues,
  } = useForm<CustomerFieldProps>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver<CustomerFieldProps>(validationSchema),
    defaultValues: transformDataToFormValues(data),
  })

  const graphqlDataInput = (values: any) => ({
    firstName: values.firstName,
    lastName: values.lastName,
    phone: values.phone ? `+1${values.phone.replace(/[^\d]/g, '').replace(/^1/g, '')}` : undefined,
    email: values.email,
    metafields: [
      values?.original?.website?.id
        ? {
            id: values.original.website.id,
            value: values.websiteUrl || '',
          }
        : {
            namespace: 'custom',
            key: 'website',
            value: values.websiteUrl || '',
          },
    ],
  })

  const update = async (values: CustomerFieldProps) => {
    await updateCustomer({
      variables: {
        mutation: 'updateCustomer',
        shopifyStoreId: storeId,
        variables: JSON.stringify({
          ...graphqlDataInput(values),
          id: data.id,
        }),
      },
    })
  }
  useEffect(() => {
    reset(transformDataToFormValues(internalData))
  }, [])

  const onSubmit: SubmitHandler<CustomerFieldProps> = async (data) => {
    setSubmitError('')
    await update(data)
  }

  if (!data) return <></>

  return (
    <>
      <DialogTitle>Update Customer</DialogTitle>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input type='hidden' control={control} name='id' />
        <DialogContent sx={{ minWidth: '600px' }}>
          <Box>{submitError && <Alert severity={'error'}>{submitError}</Alert>}</Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormTextField
                name='firstName'
                label='First Name'
                type='text'
                fullWidth
                variant='standard'
                disabled={false}
                control={control}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextField
                name='lastName'
                label='Last Name'
                type='text'
                fullWidth
                variant='standard'
                disabled={false}
                control={control}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormTextField
                name='email'
                label='E-mail'
                type='text'
                fullWidth
                variant='standard'
                control={control}
                rules={{ required: true }}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormTextField
                name='phone'
                label='Phone'
                type='text'
                fullWidth
                variant='standard'
                control={control}
                rules={{ required: true }}
                mask={withMask('+1 (999) 999-9999', {
                  placeholder: ' ',
                })}
              />
            </Grid>
            {/*{getValues('websiteUrl') && (*/}
            {/*  <Grid item xs={1} sm={1}>*/}
            {/*    <Link href={getValues('websiteUrl')}>*/}
            {/*      <OpenInBrowserIcon sx={{ verticalAlign: '-webkit-baseline-middle' }} />*/}
            {/*    </Link>*/}
            {/*  </Grid>*/}
            {/*)}*/}
            <Grid item xs={11} sm={11}>
              <FormTextField
                name='websiteUrl'
                label='Website'
                type='text'
                fullWidth
                variant='standard'
                control={control}
                mask={withMask('url', { nullable: true })}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ pt: 3 }}>
          <Button
            disabled={isSubmitting}
            onClick={() => {
              closeDialog()
            }}
          >
            Cancel
          </Button>
          <Button type='submit' disabled={isSubmitting || !(isDirty && isValid)}>
            Update
          </Button>
        </DialogActions>
      </form>
    </>
  )
}
