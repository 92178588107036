import { useState, useEffect } from 'react'
import { Typography, Button, Grid, Paper, Tooltip, ListItem, List, Box, ListItemIcon } from '@mui/material'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import HelpIcon from '@mui/icons-material/Help'
import AddIcon from '@mui/icons-material/Add'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import _ from 'lodash'

import { FormSelect } from 'components/Forms/FormSelect'
import { Input } from 'components/Forms/Input.tsx'
import { organizationId, selectedStoreId, stores } from 'config/cache.ts'
import { LabelApprovalWorkflowConfiguration, User } from 'lib/types.ts'
import { LIST_USERS } from 'lib/graphql/listUsers.ts'
import { LIST_LABEL_APPROVAL_WORKFLOW_CONFIGURATIONS } from 'lib/graphql/listLabelApprovalWorkflowConfigurations.ts'
import { UPDATE_LABEL_APPROVAL_WORKFLOW_CONFIGURATIONS } from 'lib/graphql/updateLabelApprovalWorkflowConfiguration'
import { CREATE_LABEL_APPROVAL_WORKFLOW_CONFIGURATIONS } from 'lib/graphql/createLabelApprovalWorkflowConfiguration.ts'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'

interface LabelApprover {
  email: string
}

interface LabelApproverField {
  field: string
  email: string
}

type PrivateLabelConfigurationData = {
  labelApprovers?: LabelApprover[]
  labelApprovalWorkflowConfigurationId?: string
  labelApprovalWorkflowConfiguration?: LabelApprovalWorkflowConfiguration
}

type PrivateLabelConfigurationFields = Omit<
  PrivateLabelConfigurationData,
  'labelApprovalWorkflowConfigurationId' | 'labelApprovalWorkflowConfiguration'
>

const validationSchema = Yup.object().shape({
  labelApprovers: Yup.array().of(
    Yup.object({
      email: Yup.string().required(),
    })
  ),
})

const PAGE_SIZE = 50

export const PrivateLabelStoreSettings = () => {
  const [storeData, setStoreData] = useState<PrivateLabelConfigurationData>()
  const [userData, setUserData] = useState<Record<string, User>>({})
  const storesVar = useReactiveVar(stores)
  const organizationIdVar = useReactiveVar(organizationId)
  const selectedStoreIdVar = useReactiveVar(selectedStoreId)

  const [updateLabelApprovalWorkflowConfiguration] = useMutation(
    UPDATE_LABEL_APPROVAL_WORKFLOW_CONFIGURATIONS
  )

  const [createLabelApprovalWorkflowConfiguration] = useMutation(
    CREATE_LABEL_APPROVAL_WORKFLOW_CONFIGURATIONS
  )

  const { data: users } = useQuery(LIST_USERS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        organizations: {
          some: {
            organizationId: { equals: organizationIdVar },
          },
        },
      },
      orderBy: [{ email: 'ASC' }],
      limit: PAGE_SIZE,
    },
    onCompleted(dataUsers) {
      setUserData({
        ...userData,
        ..._.merge(
          {},
          ...dataUsers.listUsers.map((data: User) => ({
            [data.id]: data,
          }))
        ),
      })
    },
  })

  const { refetch } = useQuery(LIST_LABEL_APPROVAL_WORKFLOW_CONFIGURATIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        shopifyStoreConfigurationId: {
          equals: selectedStoreIdVar,
        },
      },
      take: 10,
      skip: 0,
    },
    onCompleted(dataLabelApprovalWorkflowConfigurations) {
      const configuration = dataLabelApprovalWorkflowConfigurations.listLabelApprovalWorkflowConfigurations[0]
      const newData: PrivateLabelConfigurationData = {
        ...(storeData as PrivateLabelConfigurationData),
        labelApprovalWorkflowConfigurationId: configuration?.id,
        labelApprovalWorkflowConfiguration: JSON.parse(configuration?.configuration || '{}'),
      }
      setStoreData(newData)
      reset(transformDataToFormValues(newData))
    },
  })

  const transformDataToFormValues = (data?: PrivateLabelConfigurationData) => {
    if (!data) return {}
    return {
      labelApprovers: (data.labelApprovalWorkflowConfiguration?.steps || []).map((v) => {
        return {
          email: userData[v.assignedToId]?.email || '',
        }
      }),
    }
  }

  const toGraphQLLabelApprovers = (data: any) => {
    const updatedLabelApprovers = data.labelApprovers.map((data: LabelApproverField, i: number) => ({
      stepNumber: i + 1,
      name: `Step ${i + 1}`,
      description: `Approver ${i + 1}`,
      assignedToId: _.find(userData, (x) => x.email === data.email)?.id,
    }))
    return updatedLabelApprovers
  }

  useEffect(() => {
    if (!_.isEmpty(userData)) {
      reset(transformDataToFormValues(storeData))
    }
  }, [userData])

  const onSubmit: SubmitHandler<PrivateLabelConfigurationData> = async (data) => {
    if (!storeData) return
    if (!storeData.labelApprovalWorkflowConfigurationId) {
      await createLabelApprovalWorkflowConfiguration({
        variables: {
          data: {
            name: `${storesVar?.find((x) => x.id === selectedStoreIdVar).name} Label Approval`,
            configuration: JSON.stringify({ steps: toGraphQLLabelApprovers(data) }),
            organization: {
              connect: { id: organizationIdVar },
            },
            shopifyStoreConfiguration: {
              connect: { id: selectedStoreIdVar },
            },
          },
        },
        onError: (error) => setError('root.serverError', { type: 'custom', message: error.message }),
      })
    } else {
      await updateLabelApprovalWorkflowConfiguration({
        variables: {
          where: {
            id: storeData.labelApprovalWorkflowConfigurationId,
          },
          data: {
            configuration: JSON.stringify({ steps: toGraphQLLabelApprovers(data) }),
          },
        },
        onError: (error) => setError('root.serverError', { type: 'custom', message: error.message }),
      })
    }
    await refetch()
  }

  const onReset = async () => {
    reset(transformDataToFormValues(storeData))
  }

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid, errors },
    reset,
    watch,
    getValues,
    setError,
  } = useForm<PrivateLabelConfigurationFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver<PrivateLabelConfigurationFields>(validationSchema),
  })

  const {
    fields: fieldsLabelApprovers,
    append: appendLabelApprover,
    remove: removeLabelApprover,
  } = useFieldArray({
    control,
    name: 'labelApprovers',
  })

  if (_.isEmpty(userData)) return <></>
  return (
    <Paper
      elevation={5}
      sx={{
        height: 'inherit',
        display: 'grid',
        gridTemplateRows: 'auto 1f auto',
        borderRadius: '16px',
        p: 1,
      }}
    >
      {' '}
      <Grid sx={{ marginTop: '5px', padding: '0px 20px 20px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input type='hidden' control={control} name='id' />
          <Grid
            container
            direction={'row'}
            spacing={4}
            sx={{
              marginTop: '5px',
              marginBottom: '5px',
              justifyContent: 'space-between',
            }}
          >
            <Grid item>
              <Typography variant='h5'>Private Labels</Typography>
            </Grid>
            <Grid item sx={{ alignSelf: 'right' }}>
              <Button
                variant={'outlined'}
                disabled={isSubmitting}
                onClick={() => {
                  onReset()
                }}
              >
                Reset
              </Button>

              <Button
                sx={{ marginLeft: '10px' }}
                variant={'contained'}
                type='submit'
                disabled={isSubmitting || !(isDirty && isValid)}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          {errors.root?.serverError && (
            <Grid item>
              <Typography variant={'body2'} color={'red'}>
                {errors.root?.serverError.message}
              </Typography>
            </Grid>
          )}
          <Grid container spacing={2} sx={{ marginTop: '5px', marginLeft: '1px' }}>
            <Grid item xs={12} sm={3}>
              New Label Approval&nbsp;
              <Tooltip title={'Tiered approval workflow: all approvers must approve in turn.'}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={9} sx={{ paddingTop: '10px' }}>
              <List>
                {fieldsLabelApprovers?.map((field, index) => {
                  return (
                    <ListItem
                      key={field.id}
                      sx={{ paddingTop: '0', paddingBottom: '0' }}
                      secondaryAction={
                        <IconButton edge='end' aria-label='delete' onClick={() => removeLabelApprover(index)}>
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemIcon sx={{ minWidth: '30px' }}>
                        <Typography>#{index + 1}</Typography>
                      </ListItemIcon>
                      <Box sx={{ marginTop: '6px' }}>
                        {watch() && (
                          <FormSelect
                            control={control}
                            data={
                              users?.listUsers
                                ?.filter(
                                  (user: User) =>
                                    !fieldsLabelApprovers.map((u) => u.email).includes(user.email) ||
                                    user.email === field.email
                                )
                                .map((user: { email: string; id: string }) => ({
                                  label: user.email,
                                  value: user.email,
                                })) || []
                            }
                            name={`labelApprovers[${index}].email` || ''}
                            withName
                          />
                        )}
                      </Box>
                    </ListItem>
                  )
                })}
                <ListItem sx={{ paddingTop: '0', paddingBottom: '0' }}>
                  <ListItemIcon sx={{ minWidth: '40px' }}>
                    <IconButton edge='end' onClick={() => appendLabelApprover([{ email: '' }])}>
                      <AddIcon />
                    </IconButton>
                  </ListItemIcon>
                  <Typography sx={{ color: 'darkgray' }}>Add another approver</Typography>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Paper>
  )
}
