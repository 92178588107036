import { Navigate, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'

import { cognitoOps } from 'lib/Cognito.tsx'
import { accessToken, currentUser, getUserData, loginState, logout } from 'store/user'
import { useEffect } from 'react'

export const Authenticated = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const location = useLocation()
  const state = btoa(`${location.pathname}${location.search}`)

  const setCurrentUser = useSetRecoilState(currentUser)
  const logoutUser = useSetRecoilState(logout)
  const setAccessToken = useSetRecoilState(accessToken)

  const isAuthenticated = useRecoilValueLoadable(loginState)

  useEffect(() => {
    if (!isAuthenticated.contents) {
      return
    }

    async function updateUserAndAccessToken(): Promise<void> {
      let updatedAccessToken = undefined
      try {
        const user = cognitoOps.getCurrentUser()
        const updatedSession = await cognitoOps.refreshSession()
        if (user && updatedSession) {
          updatedAccessToken = updatedSession?.getIdToken()?.getJwtToken()
          user.setSignInUserSession(updatedSession)
          const userData = await getUserData(user);
          Sentry.setUser({ email: userData?.email });
          setCurrentUser(userData)

          if (!updatedAccessToken) return
        }
      } catch (_e) {}
      updatedAccessToken ? setAccessToken(updatedAccessToken) : logoutUser()
    }

    updateUserAndAccessToken()
    const interval = setInterval(updateUserAndAccessToken, 15 * 60 * 1000)

    return () => clearInterval(interval)
  }, [setCurrentUser, setAccessToken, isAuthenticated, logoutUser])

  if (!isAuthenticated.contents) {
    return <Navigate to={`/login?state=${state}`} />
  }

  return <>{children}</>
}
