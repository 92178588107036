import { gql } from '@apollo/client'

export const GET_PRESIGNED_URL = gql`
  query getPresignedUrl(
    $type: String
    $prefix: String
    $filename: String
    $key: String
    $method: String
    $contentType: String
  ) {
    getPresignedUrl(
      type: $type
      prefix: $prefix
      filename: $filename
      key: $key
      method: $method
      contentType: $contentType
    ) {
      bucket
      key
      presignedUrl
    }
  }
`
