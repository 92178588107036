import { gql } from '@apollo/client'

export const GET_EXTENSIV_CONFIGURATION = gql`
  query getExtensivConfiguration($where: ExtensivConfigurationExtendedWhereUniqueInput!) {
    getExtensivConfiguration(where: $where) {
      id
      customerName
      organizationId
    }
  }
`
