import dayjs from 'dayjs'
import { useMutation } from '@apollo/client'
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, TextField } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import { UPDATE_LOT } from 'lib/graphql/updateLot'

const LotDeleteDialog = ({
  lot,
  exp,
  handleClose,
  open,
  id,
}: {
  id: string
  lot: number
  exp: string
  handleClose: () => void
  open: boolean
}) => {
  const [updateLot] = useMutation(UPDATE_LOT)

  const handleDelete = () => {
    updateLot({
      variables: {
        where: { id },
        data: { deletedAt: dayjs().format() },
      },
      onCompleted: () => {
        handleClose()
      },
    })
  }

  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ sx: { padding: 2 } }}>
      <DialogTitle>
        Lot #{lot} Exp: {exp}
      </DialogTitle>
      <DialogContent>
        <TextField
          margin='dense'
          id='name'
          label='Reason for deleting'
          type='text'
          fullWidth
          variant='standard'
        />
      </DialogContent>
      <DialogActions disableSpacing sx={{ flexDirection: 'column', alignItems: 'stretch' }}>
        <Button
          startIcon={<DeleteIcon />}
          variant='contained'
          color='error'
          sx={{ marginBottom: 1 }}
          onClick={handleDelete}
        >
          DELETE
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LotDeleteDialog
