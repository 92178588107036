import { gql } from '@apollo/client'

export const GET_ORDER_WITH_LABELS = gql`
  query getOrder($id: String) {
    getOrder(where: { id: $id }) {
      id
      createdAt
      commonId
      orderLabels {
        id
        url
        printed
        lot {
          lotNumber
          expirationDate
        }
        sku {
          sku
          skuWhiteLabel
          skuFact {
            dimensions
          }
        }
        bucketKey
      }
      messages {
        id
        createdAt
        requestType
        source {
          name
        }
        destination {
          name
        }
        body
        previousMessageId
        status
        statusMessage
      }
    }
  }
`
const GET_ORDER = gql`
  query getOrderWorkflow($id: String) {
    getOrderWorkflow(where: { id: $id }) {
      id
      createdAt
      commonId
      orderWorkflow {
        ordinal
        step
        status
        color
      }
      messages {
        id
        createdAt
        requestType
        source {
          name
        }
        destination {
          name
        }
        body
        previousMessageId
        status
        statusMessage
      }
    }
  }
`

export default GET_ORDER
