import { Box, Button, Grid } from '@mui/material'
import { FormTextField } from 'components/Forms/FormTextField.tsx'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useMutation, useReactiveVar } from '@apollo/client'
import { organizationId, selectedStoreId, stores } from 'config/cache.ts'
import _ from 'lodash'
import { FormSelect } from 'components/Forms/FormSelect.tsx'
import { UPDATE_WEBHOOK_REGISTRATION } from 'lib/graphql/updateWebhookRegistration.ts'
import { Input } from 'components/Forms/Input.tsx'
import { CREATE_STORE_CONFIGURATION_WEBHOOK } from 'lib/graphql/createStoreConfigurationWebhook.ts'
import DeleteIcon from '@mui/icons-material/Delete'
import { DELETE_STORE_CONFIGURATION_WEBHOOK } from 'lib/graphql/deleteStoreConfigurationWebhook.ts'

enum webhookEventTypeEnum {
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_UPDATED = 'ORDER_UPDATED',
  SHIPMENT_CREATED = 'SHIPMENT_CREATED',
  SHIPMENT_UPDATED = 'SHIPMENT_UPDATED',
}

const WebhookEventTypes = Object.keys(webhookEventTypeEnum)

type WebhookEventType = 'ORDER_CREATED' | 'ORDER_UPDATED' | 'SHIPMENT_CREATED' | 'SHIPMENT_UPDATED'

type StoreConfigurationWebhook = {
  id: string
  storeType: string
  storeConfigurationId: string
  webhookRegistrationId: string
  organizationId: string
}

type WebhookRegistrationData = {
  id?: string
  eventKey?: WebhookEventType
  url?: string
  signingSecret?: string
  storeConfigurationWebhook: StoreConfigurationWebhook
  createdAt?: string
  updatedAt?: string
}

type WebhookRegistrationFields = Omit<
  WebhookRegistrationData,
  'createdAt' | 'updatedAt' | 'eventKey' | 'storeConfigurationWebhook'
> & {
  eventKey?: string
}

const validationSchema = Yup.object().shape({
  id: Yup.string(),
  eventKey: Yup.string().optional(),
  url: Yup.string(),
  signingSecret: Yup.string(),
})

export const WebhookRegistration = ({
  isLoading,
  data,
  refetch,
  createCallback,
  createStoreType,
}: {
  isLoading: boolean
  data: WebhookRegistrationData | undefined
  createStoreType?: string
  createCallback?: () => void
  refetch: () => void
}) => {
  const selectedStoreIdVar = useReactiveVar(selectedStoreId)
  const storesVar = useReactiveVar(stores)
  const organizationIdVar = useReactiveVar(organizationId)
  const [registration, setRegistration] = useState<WebhookRegistrationData>()

  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors, isSubmitting, isDirty, isValid },
    reset,
    getValues,
  } = useForm<WebhookRegistrationFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver<WebhookRegistrationFields>(validationSchema),
    defaultValues: {
      id: '',
      eventKey: '',
      url: '',
      signingSecret: '',
    },
  })

  const [updateWebhookRegistration] = useMutation(UPDATE_WEBHOOK_REGISTRATION)
  const [createStoreConfigurationWebhook] = useMutation(CREATE_STORE_CONFIGURATION_WEBHOOK)
  const [deleteStoreConfigurationWebhook] = useMutation(DELETE_STORE_CONFIGURATION_WEBHOOK)

  useEffect(() => {
    if (!isLoading && registration !== data) {
      setRegistration(data)
      reset(transformDataToFormValues(data))
    }
  }, [isLoading, data])

  const toGraphQL = async (values: any) => {
    return {
      eventKey: values.eventKey,
      url: values.url,
      signingSecret: values.signingSecret,
    }
  }

  const transformDataToFormValues = (data?: WebhookRegistrationData) => {
    return {
      id: data?.id,
      eventKey: webhookEventTypeEnum[data?.eventKey!],
      url: data?.url,
      signingSecret: data?.signingSecret,
    }
  }

  const onDelete = async () => {
    await deleteStoreConfigurationWebhook({
      variables: {
        where: {
          id: data?.storeConfigurationWebhook.id,
        },
      },
    })
    await refetch()
  }

  const onSubmit: SubmitHandler<WebhookRegistrationFields> = async (values) => {
    const inputData = await toGraphQL(values)
    if (_.isEmpty(data)) {
      await createStoreConfigurationWebhook({
        variables: {
          data: {
            storeType: createStoreType!,
            storeConfigurationId: selectedStoreIdVar,
            organization: {
              connect: {
                id: organizationIdVar,
              },
            },
            webhookRegistration: {
              create: inputData,
            },
          },
        },
      })
      if (createCallback) {
        await createCallback()
      }
      refetch()
    } else {
      await updateWebhookRegistration({
        variables: {
          where: {
            id: data.id,
          },
          data: inputData,
        },
      })
    }
    await refetch()
  }

  const onReset = async () => {
    registration && reset(transformDataToFormValues(registration))
  }

  return (
    <Box sx={{ border: '1px gray solid', minHeight: '40px', padding: '20px' }}>
      <Grid item sx={{ marginTop: '5px', padding: '0px 20px 20px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input type='hidden' control={control} name='id' />
          <Grid container direction={'row'}>
            <Grid item xs={8}>
              <FormSelect
                name='eventKey'
                label='Webhook Type'
                data={WebhookEventTypes.map((t) => ({ value: t, label: t }))}
                // readonly={isSubmitting}
                control={control}
                withName
              />
            </Grid>
            <Grid item xs={4} sx={{ textAlignLast: 'right' }}>
              {!_.isEmpty(data) && (
                <Button
                  variant={'text'}
                  sx={{ color: '#e57373' }}
                  disabled={isSubmitting}
                  onClick={() => {
                    onDelete()
                  }}
                >
                  <DeleteIcon />
                </Button>
              )}
              <Button
                sx={{ marginLeft: '0px' }}
                variant={'outlined'}
                disabled={isSubmitting}
                onClick={() => {
                  _.isEmpty(data) ? createCallback && createCallback() : onReset()
                }}
              >
                {_.isEmpty(data) ? 'Cancel' : 'Reset'}
              </Button>
              <Button
                sx={{ marginLeft: '5px' }}
                variant={'contained'}
                type='submit'
                disabled={isSubmitting || !(isDirty && isValid)}
              >
                {_.isEmpty(data) ? 'Create' : 'Save'}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ paddingTop: '10px' }}>
            <FormTextField
              name='url'
              label='URL'
              type='text'
              fullWidth
              variant='standard'
              // inputProps={{ readOnly: isSubmitting }}
              control={control}
            />
          </Grid>
          <Grid item xs={12} lg={6} sx={{ paddingTop: '10px' }}>
            <FormTextField
              name='signingSecret'
              label='Signing Secret'
              type='text'
              fullWidth
              variant='standard'
              // inputProps={{ readOnly: isSubmitting }}
              control={control}
            />
          </Grid>
        </form>
      </Grid>
    </Box>
  )
}
