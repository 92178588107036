import { TextFieldProps } from '@mui/material'
import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import { Controller } from 'react-hook-form'

function debounce(func: (...arg: any) => void, wait = 500) {
  let timeout: NodeJS.Timeout

  function debounced(...args: any) {
    const later = () => {
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }

  debounced.clear = () => {
    clearTimeout(timeout)
  }

  return debounced
}

export const TextFieldWithDebounce = (
  props: TextFieldProps<'standard'> & { control: any; onAccept: (value: string | null) => void }
) => {
  const { value: valueProp, onAccept, onChange, name, control, ...other } = props

  const [value, setValue] = useState<string | null>(valueProp as string)

  // Debounced function needs to be memoized to keep the same timeout beween each render.
  // For the same reason, the `onAccept` needs to be wrapped in useCallback.
  const debouncedOnAccept = React.useMemo(() => debounce(onAccept, 1000), [onAccept])

  return (
    <Controller
      name={name!}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          value={value}
          onChange={(event) => {
            setValue(event.target.value)
            debouncedOnAccept(event)
            onChange?.(event)
          }}
          {...other}
        />
      )}
    />
  )
}
