import { gql } from '@apollo/client'

export const LIST_LABEL_APPROVAL_WORKFLOW_CONFIGURATIONS = gql`
  query listLabelApprovalWorkflowConfigurations(
    $where: LabelApprovalWorkflowConfigurationWhereInput
    $orderBy: [LabelApprovalWorkflowConfigurationOrderByInput!]
    $skip: Int
    $take: Int
  ) {
    listLabelApprovalWorkflowConfigurations(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      id
      name
      shopifyStoreConfigurationId
      configuration
    }
  }
`
