import { useEffect, useRef, useState } from 'react'
import { DialogTitle, DialogContent, DialogActions, Button, Alert, Box, Typography } from '@mui/material'
import { useMutation, useQuery } from '@apollo/client'
import { useForm, Control } from 'react-hook-form'
import { CREATE_SKU } from '@/lib/graphql/createSku'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { UPDATE_SKU } from '@/lib/graphql/updateSku'
import { BrandSelect } from 'components/Forms/BrandSelect'
import { useDropzone } from 'react-dropzone'
import DownloadIcon from '@mui/icons-material/Download'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { getDataFromExcelFile, getLabelSize } from 'lib/helpers/import.ts'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { GetAllCatalogSkus, GetAllCatalogSkusRef } from 'pages/VendorApp/Common/GetAllCatalogSkus.tsx'
import _ from 'lodash'
import { Sku } from 'lib/graphql/types.ts'

const initialValues = {
  brandId: '',
  data: [],
}

const DisplayingErrorMessagesSchema = Yup.object().shape({
  brandId: Yup.string().required('Required'),
  data: Yup.array(Yup.object()).required(),
})

type CatalogImportFieldProps = {
  brandId: string
  data: any[]
}

const JSONtoApiObject = (brandId: string) => (jsonObj: any) => {
  const brandLabelDimensions = getLabelSize(jsonObj['Brand Label Size'])
  const label = brandLabelDimensions && {
    width: brandLabelDimensions[0],
    height: brandLabelDimensions[1],
    units: 'in.',
  }
  const printedLabelDimensions = getLabelSize(jsonObj['KW standardized label size'])
  const printedLabel = printedLabelDimensions && {
    width: printedLabelDimensions[0],
    height: printedLabelDimensions[1],
    units: 'in.',
  }

  return {
    sku: jsonObj['Branded SKU'],
    create: {
      brand: { connect: { id: brandId } },
      sku: jsonObj['Branded SKU'],
      skuWhiteLabel: jsonObj['Unlabeled SKU'],
      skuFact: {
        create: {
          privateLabelName: jsonObj['Product Name'],
          size: jsonObj['Size'] || 'Unknown',
          productName: jsonObj['Product Name'],
          dimensions: JSON.stringify({
            label,
            printedLabel,
          }),
        },
      },
    },
    update: {
      sku: jsonObj['Branded SKU'],
      skuWhiteLabel: jsonObj['Unlabeled SKU'],
      skuFact: {
        update: {
          privateLabelName: jsonObj['Product Name'],
          size: jsonObj['Size'] || 'Unknown',
          productName: jsonObj['Product Name'],
          dimensions: JSON.stringify({
            label,
            printedLabel,
          }),
        },
      },
    },
  }
}

const makeRequestBody = (skuList: Sku[]) => (objectToCreateOrUpdate: any) => {
  const sku = skuList.find((x) => x.sku === `${objectToCreateOrUpdate.sku}`)
  if (sku?.id) {
    return {
      where: {
        id: sku.id,
      },
      data: objectToCreateOrUpdate.update,
    }
  } else {
    return {
      data: objectToCreateOrUpdate.create,
    }
  }
}

const FileUploader = ({ setData }: { setData: (v: any[]) => void }) => {
  const [files, setFiles] = useState<any[]>([])

  const { getInputProps, getRootProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'text/csv': [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    },
    onDrop: async (acceptedFiles) => {
      const data = await getDataFromExcelFile(acceptedFiles[0])
      setData(data)
      setFiles(acceptedFiles)
    },
  })

  return (files || []).length ? (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
      <div>
        <AttachFileIcon />
      </div>
      {files[0].name}
    </Box>
  ) : (
    <Box
      {...getRootProps({
        // style,
      })}
      sx={{
        border: '1px dashed #008bbd',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingX: '20px',
        margin: '20px',
      }}
    >
      <div {...getInputProps()} />
      <Box my={1} sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
        <DownloadIcon fontSize='large' />
        <Typography variant='body1' sx={{ fontWeight: 400 }}>
          Drag-and-drop or click to upload file
        </Typography>
      </Box>
    </Box>
  )
}

const CatalogImportDialog = ({
  onSuccess,
  currentOrganizationId,
  closeDialog,
}: {
  onSuccess: () => void
  currentOrganizationId: string
  closeDialog: () => void
}) => {
  const [submitError, setSubmitError] = useState<string>()
  const [numCreated, setNumCreated] = useState<number>(0)
  const [numUpdated, setNumUpdated] = useState<number>(0)
  const [numErrors, setNumErrors] = useState<number>(0)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [brandId, setBrandId] = useState<string>()

  const [loadingCatalog, setLoadingCatalog] = useState<boolean>(false)
  const [dataCatalog, setDataCatalog] = useState<any>(undefined)
  const getAllCatalogSkusRef = useRef<GetAllCatalogSkusRef>(null)

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm<CatalogImportFieldProps>({
    shouldFocusError: true,
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver<CatalogImportFieldProps>(DisplayingErrorMessagesSchema as any),
    defaultValues: initialValues,
  })

  const [createProduct] = useMutation(CREATE_SKU, {
    onCompleted() {
      setNumCreated((v) => v + 1)
    },
    onError(error) {
      setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
      setNumErrors((v) => v + 1)
    },
  })

  const [updateProduct] = useMutation(UPDATE_SKU, {
    onCompleted() {
      setNumUpdated((v) => v + 1)
    },
    onError(error) {
      setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
      setNumErrors((v) => v + 1)
    },
  })

  const onSubmit = async (values: CatalogImportFieldProps) => {
    const requestsBody = (values.data || []).map(JSONtoApiObject(values.brandId))
    const listedRequestBody = requestsBody.map(makeRequestBody(dataCatalog))

    for (const x of listedRequestBody) {
      if (x.where) {
        await updateProduct({
          variables: x,
        })
      } else {
        await createProduct({
          variables: x,
        })
      }
    }
    setSubmitted(true)
  }

  useEffect(() => {
    !_.isEmpty(getValues('brandId')) && setBrandId(getValues('brandId'))
  }, [watch('brandId')])

  return (
    <>
      <GetAllCatalogSkus
        ref={getAllCatalogSkusRef}
        brandId={brandId}
        onLoading={(v) => setLoadingCatalog(v)}
        onData={(d) => setDataCatalog(d)}
      />
      <DialogTitle>Import Catalog</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', paddingX: '30px' }}>
            {submitError && <Alert severity={'error'}>{submitError}</Alert>}
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                alignItems: 'baseline',
              }}
            >
              <Box>Select Brand</Box>
              <Box sx={{ flexGrow: '1' }}>
                <BrandSelect
                  control={control}
                  organizationId={currentOrganizationId}
                  name='brandId'
                  label='Brand'
                />
              </Box>
            </Box>
            <FileUploader setData={(v) => setValue('data', v)} />
            <Box
              sx={{
                width: '100%',
                height: '270px',
                fontSize: '10px',
                overflow: 'auto',
                border: '1px solid gray',
              }}
            >
              {watch('data') && (
                <DataGridPremium
                  hideFooter={true}
                  density={'compact'}
                  columns={[
                    {
                      field: 'Type',
                      width: 90,
                      disableColumnMenu: true,
                      renderCell: (row) => {
                        if (!dataCatalog) {
                          return '<pending>'
                        }
                        const found = dataCatalog.find((x: any) => x.sku === row.id)
                        return found ? (
                          <Box sx={{ color: 'darkorange', fontWeight: 700 }}>Update</Box>
                        ) : (
                          <Box sx={{ color: 'darkgreen', fontWeight: 700 }}>New</Box>
                        )
                      },
                    },
                    {
                      field: 'Product Name',
                      width: 200,
                    },
                    { field: 'Branded SKU', width: 250 },
                    { field: 'Unlabeled SKU', width: 250 },
                    { field: 'Size', width: 250 },
                    { field: 'Brand Label Size', width: 250 },
                    { field: 'KW standardized label size', width: 250 },
                  ]}
                  rows={getValues('data') || []}
                  getRowId={(x: any) => x['Branded SKU']}
                />
              )}
              {/*<pre>{watch('data') && JSON.stringify(getValues('data'), null, 2)}</pre>*/}
            </Box>
            {(getValues('data').length || numCreated > 0 || numUpdated > 0 || numErrors > 0) && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                <div>Read {getValues('data').length} records.</div>
                <div>
                  {numCreated > 0 && <>Created {numCreated} SKUs.</>}
                  {numUpdated > 0 && <>Updated {numUpdated} SKUs.</>}
                  {numErrors > 0 && <>{numErrors} errors.</>}
                </div>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            onClick={() => {
              onSuccess()
              closeDialog()
            }}
          >
            Cancel
          </Button>
          {submitted ? (
            <Button onClick={() => closeDialog()}>Close</Button>
          ) : (
            <Button
              type='submit'
              disabled={!dataCatalog || isSubmitting || !(isDirty && isValid) || !getValues('data').length}
            >
              Import
            </Button>
          )}
        </DialogActions>
      </form>
    </>
  )
}

export default CatalogImportDialog
