import { useState } from 'react'
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, TextField, MenuItem } from '@mui/material'
import { useMutation } from '@apollo/client'

import { UPDATE_LOT } from 'lib/graphql/updateLot'

const LotMoveDialog = ({
  id,
  lot,
  exp,
  handleClose,
  open,
  majorVersionUuid,
  majorVersions,
}: {
  id: string
  lot: number
  exp: string
  handleClose: () => void
  open: boolean
  majorVersionUuid: string
  majorVersions: any
}) => {
  const [selectedVersion, setSelectedVersion] = useState('')
  const [updateLot] = useMutation(UPDATE_LOT, { refetchQueries: ['listLots'] })

  const handleMove = () => {
    if (!selectedVersion) return
    updateLot({
      variables: {
        where: { id },
        data: { supplementFactMajorUuid: selectedVersion },
      },
      onCompleted: () => {
        handleClose()
      },
    })
  }

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSelectedVersion(event.target.value)
  }

  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ sx: { padding: 2 } }}>
      <DialogTitle>
        Lot #{lot} Exp: {exp}
      </DialogTitle>
      <DialogContent>
        <TextField
          margin='dense'
          id='name'
          label='Move to Version'
          type='email'
          fullWidth
          variant='standard'
          required
          select
          value={selectedVersion}
          onChange={handleChange}
        >
          {majorVersions
            .filter((version: any) => version.id !== majorVersionUuid && version.status === 'APPROVED')
            .map((version: any) => (
              <MenuItem key={version.id} value={version.id}>
                Version {version.version}
              </MenuItem>
            ))}
        </TextField>
        <TextField margin='dense' id='name' label='Reason' type='email' fullWidth variant='standard' />
      </DialogContent>
      <DialogActions disableSpacing sx={{ flexDirection: 'column', alignItems: 'stretch' }}>
        <Button variant='outlined' color='primary' sx={{ marginBottom: 1 }} onClick={handleMove}>
          MOVE
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LotMoveDialog
