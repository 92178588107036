import { useState } from 'react'
import dayjs from 'dayjs'
import { useQuery } from '@apollo/client'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import DialogContentText from '@mui/material/DialogContentText'
import { LIST_LOTS } from 'lib/graphql/listLots.ts'
import { LIST_SUPPLEMENT_FACTS } from '@/lib/graphql/listSupplementFacts'

import { Lot, SupplementFact } from '@/lib/graphql/types'

type Props = {
  open: boolean
  handleClose: () => void
  skuWhiteLabel: string
  updateOrderLabel: (s: any) => void
  regeneratePrivateLabel: (s: any) => void
  orderId: string
}

const AssignLotDialog = ({ open, handleClose, skuWhiteLabel, updateOrderLabel, regeneratePrivateLabel, orderId }: Props) => {
  const [selectedLot, setSelectedLot] = useState('')

  const {
    data: dataLot,
    loading: loadingLot,
    error: errorLot,
    refetch: refetchLot,
  } = useQuery<{ listLots?: Lot[] }>(LIST_LOTS, {
    variables: {
      take: 50,
      skip: 0,
      where: {
        sku: {
          skuWhiteLabel: { equals: skuWhiteLabel },
        },
      },
    },
    skip: !skuWhiteLabel,
  })

  const {
    data: dataSupplementFacts,
    loading: loadingSupplementFacts,
    error: errorSupplementFacts,
    refetch: refetchSupplementFact,
  } = useQuery<{ listSupplementFacts?: SupplementFact[] }>(LIST_SUPPLEMENT_FACTS, {
    variables: {
      take: 50,
      skip: 0,
      where: {
        status: { equals: 'APPROVED' },
        sku: { skuWhiteLabel: { equals: skuWhiteLabel } },
      },
    },
    skip: !skuWhiteLabel,
  })

  const majorUuidVersions = (dataSupplementFacts?.listSupplementFacts || []).map((supplementFact) => ({
    majorVersionUuid: supplementFact.majorVersionUuid,
    version: `${supplementFact.majorVersion}.${supplementFact.minorVersion}`,
  }))

  const lotsOptions = (dataLot?.listLots || []).map((lot: Lot) => ({
    number: lot.lotNumber,
    expDate: lot.expirationDate,
    id: lot.id,
    version: majorUuidVersions.find(
      (majorUuidVersion) => majorUuidVersion.majorVersionUuid === lot.supplementFactMajorUuid
    )?.version,
  }))

  const handleConfirm = async () => {
    await updateOrderLabel({
      variables: {
        where: {
          id: orderId,
        },
        data: {
          lot: {
            connect: {
              id: selectedLot
            }
          }
        }
      },
    })

    await regeneratePrivateLabel({
      variables: {
        orderLabelId: orderId,
      },
    })

    handleClose();
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='sm'
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Assign Lot</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' sx={{ color: '#001D35' }}>
          Select a lot to assign to the Order
        </DialogContentText>
        <Select
          label='Lot'
          name='lot'
          fullWidth
          variant='standard'
          value={selectedLot}
          onChange={(event) => setSelectedLot(event.target.value)}
          sx={{ marginTop: '22px' }}
        >
          {lotsOptions.map((lot) => {
            return (
              <MenuItem key={lot.id} value={lot.id}>
                Lot #{lot.number}, Exp. {dayjs(lot.expDate).format('MMM DD, YYYY')}, Label Version{' '}
                {lot.version}
              </MenuItem>
            )
          })}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm} disabled={!selectedLot}>Assign Lot</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AssignLotDialog
