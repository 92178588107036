import React, { useEffect, useState } from 'react'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  SelectChangeEvent,
} from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import * as Yup from 'yup'
import { CustomerGroup, SkuListing } from 'lib/graphql/types.ts'
import { CREATE_SKU_LISTING } from 'lib/graphql/createSkuListing.ts'
import { UPDATE_SKU_LISTING } from 'lib/graphql/updateSkuListing.ts'
import { SkuSelect } from 'components/Forms/SkuSelect'
import { CustomerGroupSelect } from 'components/Forms/CustomerGroupSelect'
import _ from 'lodash'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import style from './dialogs.module.css'
import { GET_SKU } from 'lib/graphql/getSku'
import { useDropzone } from 'react-dropzone'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { GET_PRESIGNED_URL } from 'lib/graphql/getPresignedUrl'
import { Input } from 'components/Forms/Input.tsx'
import { FormTextField } from 'components/Forms/FormTextField.tsx'
import { yupResolver } from '@hookform/resolvers/yup'
import { CustomTabPanel, customTabProps } from 'components/CustomTabs.tsx'
import { FormSwitch } from 'components/Forms/FormSwitch.tsx'

type SkuListingImageProps = {
  key?: string
  path?: string
  preview?: string
  alt?: string
  contentType?: string
}

type SkuListingFieldProps = Omit<
  SkuListing,
  | 'wholesaleCustomerGroups'
  | 'whiteLabelCustomerGroups'
  | 'shopifyStoreConfiguration'
  | 'shopifyStoreConfigurationId'
  | 'sku'
> & {
  images: SkuListingImageProps[]
  whiteLabelCustomerGroups?: string[]
  wholesaleCustomerGroups?: string[]
}

type PresignedImage = {
  key: string
  url: string
}

const initialValues: SkuListingFieldProps = {
  id: '',
  title: '',
  skuId: '',
  images: [],
  description: '',
  wholesaleAvailable: false,
  whiteLabelAvailable: false,
  retailAvailable: false,
  patientAvailable: false,
  wholesaleMinQuantity: 0,
  wholesaleMaxQuantity: 0,
  wholesaleBoxQuantity: 0,
  wholesalePricing: 0,
  whiteLabelPricing: 0,
  whiteLabelMinQuantity: 0,
  whiteLabelMaxQuantity: 0,
  whiteLabelBoxQuantity: 0,
  patientPricing: 0,
  retailPricing: 0,
  wholesaleCustomerGroups: [],
  whiteLabelCustomerGroups: [],
}

const validationSchema = Yup.object().shape({
  id: Yup.string(),
  title: Yup.string().required('Product name is required'),
  skuId: Yup.string().required(),
  description: Yup.string().optional(),
  wholesaleAvailable: Yup.bool().default(false).required(),
  whiteLabelAvailable: Yup.bool().default(false).required(),
  patientAvailable: Yup.bool().default(false).required(),
  retailAvailable: Yup.bool().default(false).required(),
  wholesaleMinQuantity: Yup.number().default(1).min(0).required(),
  wholesaleMaxQuantity: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .optional()
    .nullable()
    .when(['wholesaleMinQuantity'], ([minQ], schema) => {
      return schema.min(minQ)
    }),
  wholesaleBoxQuantity: Yup.number().default(1).min(0).required(),
  wholesalePricing: Yup.number().default(0).min(0).required(),
  whiteLabelPricing: Yup.number().default(0).min(0).required(),
  whiteLabelMinQuantity: Yup.number().default(1).min(0).required(),
  whiteLabelMaxQuantity: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .optional()
    .nullable()
    .when(['whiteLabelMinQuantity'], ([minQ], schema) => {
      return schema.min(minQ)
    }),
  whiteLabelBoxQuantity: Yup.number().default(1).min(0).required(),
  patientPricing: Yup.number().default(0).min(0).required(),
  retailPricing: Yup.number().default(0).min(0).required(),
  images: Yup.array(Yup.object<SkuListingImageProps>()).required().default([]),
  whiteLabelCustomerGroups: Yup.array(Yup.string().required()).optional(),
  wholesaleCustomerGroups: Yup.array(Yup.string().required()).optional(),
})

interface SkuListingCreateOrUpdateProps {
  mode: 'create' | 'update'
  organizationId: string
  storeId: string
  data: any
  onSuccess: () => void
  closeDialog: () => void
}

export const SkuListingCreateOrUpdateDialog: React.FC<SkuListingCreateOrUpdateProps> = ({
  mode,
  data,
  organizationId,
  storeId,
  onSuccess,
  closeDialog,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    setValue,
    reset,
    getValues,
    trigger,
  } = useForm<SkuListingFieldProps>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver<SkuListingFieldProps>(validationSchema),
    defaultValues: initialValues,
  })

  const [internalData, setInternalData] = useState(data)
  const [presignedImages, setPresignedImages] = useState<PresignedImage[]>([])
  const [submitError, setSubmitError] = useState<string>()
  const [getPresignedUrl] = useLazyQuery(GET_PRESIGNED_URL)

  const [createSkuListing] = useMutation(CREATE_SKU_LISTING, {
    onCompleted(data) {
      closeDialog()
      onSuccess()
    },
    onError(error) {
      setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
    },
  })

  const [updateSkuListing] = useMutation(UPDATE_SKU_LISTING, {
    onCompleted(data) {
      closeDialog()
      onSuccess()
    },
    onError(error) {
      setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
    },
  })

  const transformDataToFormValues = (data: any) => {
    return {
      ...data,
      wholesaleMaxQuantity: data.wholesaleMaxQuantity <= 0 ? null : data.wholesaleMaxQuantity,
      whiteLabelMaxQuantity: data.whiteLabelMaxQuantity <= 0 ? null : data.whiteLabelMaxQuantity,
      description: data.description || '',
      images: JSON.parse(data.images || '[]'),
      whiteLabelCustomerGroups: data?.whiteLabelCustomerGroups?.map((group: CustomerGroup) => group.id) || [],
      wholesaleCustomerGroups: data?.wholesaleCustomerGroups.map((group: CustomerGroup) => group.id) || [],
    }
  }

  const graphqlDataInput = (values: any, storeId?: string) => ({
    title: values.title,
    images: JSON.stringify(
      values.images.map((img: SkuListingImageProps) => {
        return _.pick(img, ['alt', 'contentType', 'key', 'externalId'])
      })
    ),
    description: values.description,
    sku: { connect: { id: values.skuId } },
    wholesalePricing: values.wholesalePricing,
    wholesaleAvailable: values.wholesaleAvailable,
    wholesaleCustomerGroups: _.isEmpty(values.wholesaleCustomerGroups)
      ? {}
      : mode === 'create'
      ? {
          connect: values.wholesaleCustomerGroups.map((id: string) => ({ id })),
        }
      : {
          set: values.wholesaleCustomerGroups.map((id: string) => ({ id })),
        },
    wholesaleMinQuantity: values.wholesaleMinQuantity,
    wholesaleMaxQuantity: values.wholesaleMaxQuantity ?? -1,
    wholesaleBoxQuantity: values.wholesaleBoxQuantity,
    retailPricing: values.retailPricing,
    retailAvailable: values.retailAvailable,
    whiteLabelPricing: values.whiteLabelPricing,
    whiteLabelAvailable: values.whiteLabelAvailable,
    whiteLabelMinQuantity: values.whiteLabelMinQuantity,
    whiteLabelMaxQuantity: values.whiteLabelMaxQuantity ?? -1,
    whiteLabelBoxQuantity: values.whiteLabelBoxQuantity,
    whiteLabelCustomerGroups: _.isEmpty(values.whiteLabelCustomerGroups)
      ? {}
      : mode === 'create'
      ? {
          connect: values.whiteLabelCustomerGroups.map((id: string) => ({ id })),
        }
      : {
          set: values.whiteLabelCustomerGroups.map((id: string) => ({ id })),
        },
    shopifyStoreConfiguration: { connect: { id: storeId } },
  })

  async function readFile(filename: string) {
    return new Promise(async (resolve, reject) => {
      const reader = new FileReader()

      reader.onabort = () => reject()
      reader.onerror = () => reject()
      reader.onload = () => resolve(reader.result)

      fetch(filename).then((response) => {
        return response.blob().then((data) => {
          return reader.readAsArrayBuffer(data)
        })
      })
    })
  }

  async function getPresignedUploadUrl(file: { filename: string; contentType: string }) {
    const response = await getPresignedUrl({
      variables: {
        type: 'image',
        prefix: organizationId,
        filename: file.filename,
        method: 'PUT',
        contentType: file.contentType,
      },
    })

    return response.data.getPresignedUrl
  }

  const { getInputProps, getRootProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: async (acceptedFiles) => {
      const copyImages = getValues('images').concat(
        acceptedFiles.map((file) => {
          return {
            ...file,
            alt: file.name,
            contentType: file.type,
            preview: URL.createObjectURL(file),
          }
        })
      )
      setValue('images', copyImages, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      })
    },
  })

  const thumb = (file: any) => {
    let url
    if (file.preview) {
      url = file.preview
    } else {
      const presignedFile = presignedImages?.find((data) => data.key === file.key)
      url = presignedFile?.url
    }

    return (
      <Box className={style.thumb}>
        <Box className={style.thumbInner}>
          {url ? <img src={url} className={style.productImg} /> : <>Could not load image.</>}
        </Box>
      </Box>
    )
  }

  async function uploadFile(file: SkuListingImageProps) {
    const [presignedUploadObject, data] = await Promise.all([
      getPresignedUploadUrl({
        filename: file.path as string,
        contentType: file.contentType as string,
      }),
      readFile(file.preview as string),
    ])

    const res = await fetch(presignedUploadObject.presignedUrl, {
      method: 'PUT',
      body: data as BodyInit,
      headers: {
        'Content-Type': file.contentType as string,
      },
    })

    if (res.ok) {
      return {
        ...presignedUploadObject,
        contentType: 'IMAGE',
        alt: file.path,
      }
    } else {
      console.log('ERROR')
      console.log(res)
    }
  }

  const create = async (values: SkuListingFieldProps) => {
    const results = await Promise.all((values.images || []).filter((image) => image.path).map(uploadFile))
    await createSkuListing({
      variables: {
        data: graphqlDataInput(
          {
            ...values,
            images: results,
          },
          storeId
        ),
      },
    })
  }

  const update = async (values: SkuListingFieldProps) => {
    const preExistingFiles = (values.images || []).filter((image) => image.key)
    // Uploading only new files
    const results = await Promise.all((values.images || []).filter((image) => image.path).map(uploadFile))
    await updateSkuListing({
      variables: {
        where: { id: values.id },
        data: graphqlDataInput(
          {
            ...values,
            // combining already uploaded with newly uploaded files
            images: preExistingFiles.concat(results),
          },
          storeId
        ),
      },
    })
  }

  const [tabValue, setTabValue] = useState(0)
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  useEffect(() => {
    mode === 'update' && reset(transformDataToFormValues(internalData))
    setPresignedImages(internalData?.imagePresignedUrls || [])
  }, [mode])

  const [selectedSku, setSelectedSku] = useState<string>()

  const {
    refetch: refetchSku,
    data: dataSku,
    loading: loadingSku,
    error: errorSku,
  } = useQuery(GET_SKU, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        id: selectedSku,
      },
    },
    skip: !selectedSku,
  })

  useEffect(() => {
    if (dataSku) {
      setValue('title', dataSku.getSku.skuFact.productName)
    }
  }, [dataSku])

  const skuHandleChange = (event: SelectChangeEvent<string>) => {
    setSelectedSku(event.target.value)
  }

  const onSubmit: SubmitHandler<SkuListingFieldProps> = async (data) => {
    mode === 'create' ? await create(data) : await update(data)
  }

  return (
    <>
      <DialogTitle>{mode === 'update' ? 'Update' : 'Create New'} SKU Listing</DialogTitle>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input type='hidden' control={control} name='id' />
        <DialogContent>
          <Box>
            <Box>{submitError && <Alert severity={'error'}>{submitError}</Alert>}</Box>
            <Box className={style.dialogTabHeader}>
              <Tabs value={tabValue} onChange={handleTabChange} className={style.dialogTabs}>
                <Tab label='SKU' className={style.dialogTab} {...customTabProps(0, tabValue)} />
                <Tab label='Images' className={style.dialogTab} {...customTabProps(1, tabValue)} />
                <Tab label='Wholesale' className={style.dialogTab} {...customTabProps(2, tabValue)} />
                <Tab label='Private Label' className={style.dialogTab} {...customTabProps(3, tabValue)} />
                <Tab label='Retail' className={style.dialogTab} {...customTabProps(4, tabValue)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              <Grid container spacing={2} className={style.dialogTabDetails}>
                <Grid item xs={6}>
                  <SkuSelect
                    organizationId={organizationId}
                    name='skuId'
                    label='SKU'
                    control={control}
                    archived={false}
                    customOnChange={skuHandleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField
                    name='title'
                    label='Product Name'
                    type='text'
                    fullWidth
                    variant='standard'
                    control={control}
                    disabled={loadingSku}
                    // error={touched.title && Boolean(errors.title)}
                    // helperText={touched.title && errors.title}
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField
                    name='description'
                    label='Description'
                    type='text'
                    fullWidth
                    variant='standard'
                    // defaultValue={defaultValues?.description || ''}
                    disabled={loadingSku}
                    // error={touched.title && Boolean(errors.title)}
                    // helperText={touched.title && errors.title}
                    control={control}
                    rules={{ required: false }}
                  />
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel index={tabValue} value={1}>
              <Grid container spacing={2} className={style.dialogTabDetails}>
                <Grid item xs={12}>
                  <Box {...getRootProps({ style })} className={style.dropzone}>
                    <div {...getInputProps()} />
                    <p>Drag-and-drop or click to add image(s)</p>
                  </Box>
                  {/*{touched.images && errors.images && <div>{errors.images}</div>}*/}
                </Grid>
                {(getValues('images') || []).map((image) => {
                  return (
                    <Grid item xs={6} key={image.path || image.key}>
                      <Box className={style.thumbsContainer}>
                        {thumb(image)}
                        <IconButton
                          sx={{ color: 'red', position: 'absolute', bottom: '10px', right: '10px' }}
                          onClick={async () => {
                            setValue(
                              'images',
                              (getValues('images') || []).filter(
                                (i) => i.path !== image.path || i.key !== image.key
                              ),
                              {
                                shouldValidate: true,
                                shouldDirty: true,
                                shouldTouch: true,
                              }
                            )
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  )
                })}
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
              <Grid container spacing={2} className={style.dialogTabDetails}>
                <Grid item xs={6}>
                  <FormSwitch control={control} name='wholesaleAvailable' label='Sell Wholesale' />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    name='wholesalePricing'
                    label='Wholesale Unit Price'
                    type='text'
                    fullWidth
                    variant='standard'
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    }}
                    // defaultValue={defaultValues?.wholesalePricing}
                    control={control}
                    // error={touched.wholesalePricing && Boolean(errors.wholesalePricing)}
                    // helperText={touched.wholesalePricing && errors.wholesalePricing}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormTextField
                    // id='wholesaleMinQuantity'
                    name='wholesaleMinQuantity'
                    label='Minimum Quantity'
                    type='number'
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    fullWidth
                    variant='standard'
                    // defaultValue={defaultValues?.wholesaleMinQuantity}
                    control={control}
                    // error={touched.wholesaleMinQuantity && Boolean(errors.wholesaleMinQuantity)}
                    // helperText={touched.wholesaleMinQuantity && errors.wholesaleMinQuantity}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormTextField
                    name='wholesaleBoxQuantity'
                    label='Increment Quantity'
                    type='number'
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    fullWidth
                    variant='standard'
                    // defaultValue={defaultValues?.wholesaleBoxQuantity}
                    control={control}
                    // error={touched.wholesaleBoxQuantity && Boolean(errors.wholesaleBoxQuantity)}
                    // helperText={touched.wholesaleBoxQuantity && errors.wholesaleBoxQuantity}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormTextField
                    // id='wholesaleMinQuantity'
                    name='wholesaleMaxQuantity'
                    label='Maximum Quantity'
                    type='number'
                    fullWidth
                    variant='standard'
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    // defaultValue={defaultValues?.wholesaleMaxQuantity}
                    control={control}
                    // error={touched.wholesaleMinQuantity && Boolean(errors.wholesaleMinQuantity)}
                    // helperText={touched.wholesaleMinQuantity && errors.wholesaleMinQuantity}
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: '20px' }}>
                  Customer Groups
                </Grid>
                <Grid item xs={12}>
                  <CustomerGroupSelect
                    control={control}
                    name='wholesaleCustomerGroups'
                    label='Wholesale Visibility'
                    // defaultValue={defaultValues?.wholesaleCustomerGroups}
                    // value={values.wholesaleCustomerGroups}
                    // onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={3}>
              <Grid container spacing={2} className={style.dialogTabDetails}>
                <Grid item xs={6}>
                  <FormSwitch control={control} name='whiteLabelAvailable' label='Sell Private Label' />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    control={control}
                    name='whiteLabelPricing'
                    label='Private Label Pricing'
                    type='text'
                    fullWidth
                    variant='standard'
                    // defaultValue={defaultValues?.whiteLabelPricing}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    }}
                    // error={touched.whiteLabelPricing && Boolean(errors.whiteLabelPricing)}
                    // helperText={touched.whiteLabelPricing && errors.whiteLabelPricing}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormTextField
                    name='whiteLabelMinQuantity'
                    label='Minimum Quantity'
                    type='number'
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    fullWidth
                    variant='standard'
                    // defaultValue={defaultValues?.whiteLabelMinQuantity}
                    control={control}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormTextField
                    name='whiteLabelBoxQuantity'
                    label='Increment Quantity'
                    type='number'
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    fullWidth
                    variant='standard'
                    // defaultValue={defaultValues?.whiteLabelBoxQuantity}
                    control={control}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormTextField
                    name='whiteLabelMaxQuantity'
                    label='Maximum  Quantity'
                    type='number'
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    fullWidth
                    variant='standard'
                    // defaultValue={defaultValues?.whiteLabelMaxQuantity}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: '20px' }}>
                  Customer Groups
                </Grid>
                <Grid item xs={12}>
                  <CustomerGroupSelect
                    control={control}
                    name='whiteLabelCustomerGroups'
                    label='Private Label Visibility'
                    // defaultValue={defaultValues?.whiteLabelCustomerGroups}
                  />
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={4}>
              <Grid container spacing={2} className={style.dialogTabDetails}>
                <Grid item xs={6}>
                  <FormSwitch control={control} name='retailAvailable' label='Sell Retail' />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    control={control}
                    name='retailPricing'
                    label='Retail Pricing'
                    type='text'
                    fullWidth
                    variant='standard'
                    // defaultValue={defaultValues?.retailPricing}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    }}
                    // error={touched.retailPricing && Boolean(errors.retailPricing)}
                    // helperText={touched.retailPricing && errors.retailPricing}
                  />
                </Grid>
              </Grid>
            </CustomTabPanel>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            onClick={() => {
              closeDialog()
            }}
          >
            Cancel
          </Button>
          <Button type='submit' disabled={isSubmitting || !(isDirty && isValid)}>
            {mode === 'update' ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </>
  )
}
