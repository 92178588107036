import { gql } from '@apollo/client'

export const GET_CUSTOMER_ID = gql`
  query getCustomerId($where: CustomerIdExtendedWhereUniqueInput!) {
    getCustomerId(where: $where) {
      id
      brandAccount {
        id
        name
        sellsInCalifornia
        prop65Waiver
      }
    }
  }
`
