import { gql } from '@apollo/client'

export const LIST_ORDERS = gql`
  query listOrders($where: OrderWhereInput, $orderBy: [OrderOrderByInput], $limit: Int, $offset: Int) {
    listOrders(where: $where, orderBy: $orderBy, take: $limit, skip: $offset) {
      id
      storeId
      organization {
        name
      }
      commonId
      orderLabels {
        id
        lot {
          lotNumber
          expirationDate
        }
        sku {
          sku
          skuWhiteLabel
          skuFact {
            dimensions
          }
        }
        printed
        bucketKey
      }
      createdAt
    }
  }
`

