import Stack from '@mui/material/Stack'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import dayjs from 'dayjs'
import Box from '@mui/material/Box'
import NativeSelect from '@mui/material/NativeSelect'
import { useReactiveVar } from '@apollo/client'
import _ from 'lodash'
import {
  fromDate,
  orderSearchFilter,
  orderSearchString,
  organizationId,
  selectedStoreId,
  toDate,
} from 'config/cache'
import { OrganizationSelect } from 'components/OrganizationSelect.tsx'
import React from 'react'

const Filters = () => {
  const currentOrganizationId = useReactiveVar(organizationId)

  const fromDateVar = useReactiveVar(fromDate)
  const toDateVar = useReactiveVar(toDate)

  const fromDateValue = fromDateVar && dayjs(fromDateVar)
  const toDateValue = toDateVar && dayjs(toDateVar)

  return (
    <Stack sx={{ px: 1, py: 2 }} spacing={2}>
      <Stack sx={{ px: 1, py: 0 }} spacing={2} direction={'row'}>
        <Box sx={{ width: '200px' }}>
          <OrganizationSelect
            label={'Organization'}
            value={currentOrganizationId}
            onChange={(ev) => {
              organizationId(ev.target.value)
              selectedStoreId(undefined)
            }}
          />
        </Box>
      </Stack>
      <Stack sx={{ px: 1, py: 0 }} spacing={2} direction={'row'}>
        <Box display='flex' maxWidth='350px'>
          <DesktopDatePicker
            value={fromDateValue || null}
            slotProps={{
              textField: { variant: 'standard' },
              openPickerButton: { color: 'primary' },
            }}
            onChange={(newValue) => {
              if (newValue) {
                fromDate(newValue?.startOf('day')?.format())
              } else {
                fromDate(undefined)
              }
            }}
          />
          <Typography
            sx={{
              display: 'inline-block',
              mx: 2,
              fontWeight: 'bold',
              color: '#526070',
            }}
          >
            -
          </Typography>
          <DesktopDatePicker
            slotProps={{
              textField: { variant: 'standard' },
              openPickerButton: { color: 'primary' },
            }}
            value={toDateValue || null}
            onChange={(newValue) => {
              if (newValue) {
                toDate(newValue?.endOf('day')?.format())
              } else {
                toDate(undefined)
              }
            }}
          />
        </Box>
        <Box sx={{ width: '250px' }}>
          <TextField
            fullWidth
            variant='standard'
            placeholder='Search Orders'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={useReactiveVar(orderSearchString)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              orderSearchString(event.target.value)
              if (event.target.value && _.size(event.target.value) >= 3) {
                orderSearchFilter(event.target.value)
              } else {
                orderSearchFilter('')
              }
            }}
          />
        </Box>
      </Stack>
    </Stack>
  )
}

export default Filters
