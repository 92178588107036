import React, { useEffect, useState } from 'react'
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import { useMutation } from '@apollo/client'
import * as Yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormTextField } from 'components/Forms/FormTextField.tsx'
import { CREATE_SHOPIFY_STORE_CONFIGURATION } from 'lib/graphql/createShopifyStoreConfiguration.ts'
import { UPDATE_SHOPIFY_STORE_CONFIGURATION } from 'lib/graphql/updateShopifyStoreConfiguration.ts'
import _ from 'lodash'

type ShopifyStoreConfigurationFieldProps = {
  id?: string | null
  name: string
  domain: string
  adminApiToken: string
  clientId?: string | null
  clientSecret?: string | null
  brandedDomain?: string | null
  fromEmail?: string | null
  shopId?: string | null
}

const validationSchema = Yup.object().shape({
  id: Yup.string().nullable().optional(),
  name: Yup.string().required('Required'),
  domain: Yup.string().required('Required'),
  adminApiToken: Yup.string().required('Required'),
  clientId: Yup.string().nullable().optional(),
  clientSecret: Yup.string().nullable().optional(),
  brandedDomain: Yup.string().nullable().optional(),
  fromEmail: Yup.string().nullable().optional(),
  shopId: Yup.string().nullable().optional(),
})

interface CreateOrUpdateShopifyStoreConfigurationProps {
  mode: 'create' | 'update'
  data: any
  organizationId: string
  onSuccess: () => void
  closeDialog: () => void
}

export const ShopifyStoreConfigurationCreateOrUpdateDialog: React.FC<
  CreateOrUpdateShopifyStoreConfigurationProps
> = ({ mode, data, onSuccess, closeDialog, organizationId }) => {
  const [submitError, setSubmitError] = useState<string>()
  const [internalData, setInternalData] = useState(data)

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    reset,
  } = useForm<ShopifyStoreConfigurationFieldProps>({
    reValidateMode: 'onChange',
    resolver: yupResolver<ShopifyStoreConfigurationFieldProps>(validationSchema),
    defaultValues: {
      name: '',
    },
  })

  const [createShopifyStoreConfiguration] = useMutation(CREATE_SHOPIFY_STORE_CONFIGURATION)
  const [updateShopifyStoreConfiguration] = useMutation(UPDATE_SHOPIFY_STORE_CONFIGURATION)

  const transformDataToFormValues = (data: any) => {
    return {
      ...data,
    }
  }

  const graphqlDataInput = (values: any) => ({
    ..._.pick(values, [
      'name',
      'domain',
      'adminApiToken',
      'clientId',
      'clientSecret',
      'brandedDomain',
      'fromEmail',
      'shopId',
    ]),
    organization: {
      connect: {
        id: organizationId,
      },
    },
  })

  const create = async (values: ShopifyStoreConfigurationFieldProps) => {
    setSubmitError(undefined)
    await createShopifyStoreConfiguration({
      variables: {
        data: graphqlDataInput(values),
      },
      onError: (err) => {
        setSubmitError(err.message)
      },
      onCompleted: () => {
        onSuccess()
      },
    })
  }

  const update = async (values: ShopifyStoreConfigurationFieldProps) => {
    setSubmitError(undefined)
    await updateShopifyStoreConfiguration({
      variables: {
        where: {
          id: values.id,
        },
        data: graphqlDataInput(values),
      },
      onError: (err) => {
        setSubmitError(err.message)
      },
      onCompleted: () => {
        onSuccess()
      },
    })
  }

  useEffect(() => {
    mode === 'update' && reset(transformDataToFormValues(internalData))
  }, [mode, internalData])

  const onSubmit: SubmitHandler<ShopifyStoreConfigurationFieldProps> = async (data) => {
    mode === 'create' ? await create(data) : await update(data)
  }

  if (mode === 'update' && !data) {
    return <></>
  }

  return (
    <>
      <DialogTitle>{mode === 'update' ? 'Update' : 'Create New'} Shopify Store Configuration</DialogTitle>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type='hidden' name='id' />
        <DialogContent>
          <Grid container spacing={2}>
            {submitError && (
              <Grid item xs={12}>
                <Alert severity={'error'}>{submitError}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormTextField
                name='name'
                label='Store Name'
                type='text'
                fullWidth
                variant='standard'
                control={control}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name='domain'
                label='Shopify Domain'
                type='text'
                fullWidth
                variant='standard'
                control={control}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name='adminApiToken'
                label='Shopify Admin API Token'
                type='text'
                fullWidth
                variant='standard'
                control={control}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name='clientId'
                label='Shopify Webhook Client ID'
                type='text'
                fullWidth
                variant='standard'
                control={control}
                required={false}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name='clientSecret'
                label='Shopify Webhook Client Secret'
                type='text'
                fullWidth
                variant='standard'
                control={control}
                required={false}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name='fromEmail'
                label='From Email'
                type='text'
                fullWidth
                variant='standard'
                control={control}
                required={false}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name='brandedDomain'
                label='Branded Domain'
                type='text'
                fullWidth
                variant='standard'
                control={control}
                required={false}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name='shopId'
                label='Shop ID'
                type='text'
                fullWidth
                variant='standard'
                control={control}
                required={false}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            onClick={() => {
              closeDialog()
            }}
          >
            Cancel
          </Button>
          <Button type='submit' disabled={isSubmitting || !(isDirty && isValid)}>
            {mode === 'update' ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </>
  )
}
