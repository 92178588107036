import { useState } from 'react'
import {
  Alert,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import LabelIcon from '@mui/icons-material/Label'
import InventoryIcon from '@mui/icons-material/Inventory'
import { ConnectMessage, OrderLabel, OrderLineItem } from 'lib/types.ts'
import DownloadIcon from '@mui/icons-material/Download'
import { useMutation, useQuery } from '@apollo/client'
import { GET_ORDER_WITH_LABELS } from 'lib/graphql/getOrder.ts'
import dayjs from 'dayjs'
import { renderLabelIconWithTooltip } from 'pages/OperationsApp/utils.tsx'
import IconButton from '@mui/material/IconButton'
import { Link } from 'react-router-dom'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { UPDATE_ORDER_LABEL } from 'lib/graphql/updateOrderLabel.ts'
import Button from '@mui/material/Button'
import { REGENERATE_PRIVATE_LABEL } from 'lib/graphql/regeneratePrivateLabel.ts'
import AssignLotDialog from './AssignLotDialog'

type OrderCardProps = {
  orderId: string
  orderMetadata: any
}

function formatDateTime(v: Date) {
  return v ? dayjs(v).format('MMM D, YYYY h:mm A') : ''
}

function expirationDateFormat(v: string) {
  return v ? dayjs(v).format('MM/DD/YY') : ''
}

function hasDimension(dimensions: string, thing: string) {
  const dims = JSON.parse(dimensions || '{}')
  return !_.isEmpty(_.omit(dims[thing], 'units'))
}

function renderDimensions(dimensions: string, thing: string) {
  const dims = JSON.parse(dimensions || '{}')
  if (_.isEmpty(dims[thing])) return ''
  return `${dims[thing].width} x ${dims[thing].height} ${dims[thing].units}`
}

const BackOrderDetails = ({ disabled, handleClick }: { disabled?: boolean; handleClick?: () => void }) => (
  <Box>
    <Typography variant={'body1'}>Back-ordered</Typography>
    <Button disabled={disabled} size={'small'} variant={'outlined'} onClick={handleClick}>
      Assign lot
    </Button>
  </Box>
)

function renderPdf({
  id,
  order,
  regeneratePrivateLabel,
  regenerating,
}: {
  id: string
  order: any
  regeneratePrivateLabel: (c: any) => void
  regenerating: boolean
}) {
  const reprocess = () => {
    regeneratePrivateLabel({
      variables: {
        orderLabelId: id,
      },
    })
  }

  const url = order.orderLabels.find((ol: OrderLabel) => id === ol.id)?.url
  return url ? (
    <Box sx={{ maxWidth: '375px' }}>
      <embed width='100%' height='140px' src={`${url}#view=Fit`} />
    </Box>
  ) : (
    <Box sx={{ maxWidth: '375px', alignItems: 'center' }}>
      <Alert severity={'warning'}>
        <Box sx={{ display: 'flex', gap: 3, alignItems: 'top' }}>
          <span>Label could not be found</span>
        </Box>
        <Button disabled={regenerating} size={'small'} variant={'outlined'} onClick={() => reprocess()}>
          Regenerate Label
        </Button>
      </Alert>
    </Box>
  )
}

type DownloadLabelProps = {
  children: any
  id: string
  order: any
}

const DownloadLabel = ({ children, id, order }: DownloadLabelProps) => {
  const url = order.orderLabels.find((ol: OrderLabel) => id === ol.id)?.url

  return url ? (
    <Link to={url} target={'_blank'} download>
      {children}
    </Link>
  ) : (
    children
  )
}

export const OrderCard = ({ orderId, orderMetadata }: OrderCardProps) => {
  const [openLotModal, setOpenLotModal] = useState(false)
  const [selectedSku, setSelectedSku] = useState('')
  const [selectedLabelId, setSelectedLabelId] = useState('')

  const {
    data: dataOrder,
    loading: loadingOrder,
    error: errorOrder,
    refetch: refetchOrder,
  } = useQuery(GET_ORDER_WITH_LABELS, {
    // fetchPolicy: 'no-cache',
    variables: {
      id: orderId,
    },
    notifyOnNetworkStatusChange: true,
    skip: !orderId,
  })

  const [updateOrderLabel] = useMutation(UPDATE_ORDER_LABEL, {
    onCompleted: () => {
      refetchOrder()
    },
  })

  const [regeneratePrivateLabel, { loading: regenerating }] = useMutation(REGENERATE_PRIVATE_LABEL)

  const togglePrinted = async (orderLabel: OrderLabel) => {
    await updateOrderLabel({
      variables: {
        where: {
          id: orderLabel.id,
        },
        data: {
          printed: !orderLabel.printed,
        },
      },
    })
  }

  if (!dataOrder?.getOrder || !orderMetadata) return <></>
  const order = dataOrder.getOrder
  const _order = order.messages.filter((x: ConnectMessage) => x.requestType === 'POST /connect/orders')
  if (_order?.length > 1) {
    console.log('Multiple order messages found')
  }

  const orderToFulfill = JSON.parse((_order && _order[0].body) || '{}')

  return (
    <>
      <Box sx={{ paddingTop: '30px' }}>
        <AssignLotDialog
          open={openLotModal}
          handleClose={() => setOpenLotModal(false)}
          skuWhiteLabel={selectedSku}
          updateOrderLabel={updateOrderLabel}
          orderId={selectedLabelId}
          regeneratePrivateLabel={regeneratePrivateLabel}
        />
        <Paper elevation={10}>
          <Stack sx={{ padding: '30px' }} direction={'column'}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Box>
                Order: <b>{orderToFulfill.orderHeader?.referenceNum}</b>
              </Box>
              <Box sx={{ height: '30px', display: 'flex', alignItems: 'center', minWidth: '50px' }}>
                {loadingOrder ? (
                  <CircularProgress size={20} />
                ) : (
                  <Button disabled={loadingOrder} onClick={() => refetchOrder()}>
                    Reload
                  </Button>
                )}
              </Box>
            </Box>
            <Box>
              <Typography sx={{ display: 'inline' }} component='span' variant='body2' color='text.primary'>
                Received on {formatDateTime(orderMetadata.createdAt)} for {orderMetadata.organization.name}
              </Typography>
            </Box>
            <Box sx={{ paddingTop: '10px' }}>
              <Typography sx={{ display: 'inline' }} component='span' variant='body1' color='text.primary'>
                Ship To:&nbsp;{orderToFulfill?.shipTo?.name},&nbsp;
                {_.trim(
                  (orderToFulfill?.shipTo.address1 || '<No address>') + orderToFulfill?.shipTo.address2
                )}
                ,&nbsp;{orderToFulfill?.shipTo.city || '<No city>'},&nbsp;
                {orderToFulfill?.shipTo.state || '<No state>'},&nbsp;
                {orderToFulfill?.shipTo.zip || '<No zip>'}
              </Typography>
            </Box>
            <List>
              {orderToFulfill.orderItems.map((orderItem: OrderLineItem, index: number) => {
                return (
                  <ListItem key={`order-item-${index}`}>
                    <ListItemAvatar sx={{ marginBottom: 'auto', marginTop: '10px' }}>
                      {orderItem.isLabel ? <LabelIcon /> : <InventoryIcon />}
                    </ListItemAvatar>
                    <ListItemText
                      primary={orderItem.itemIdentifier.sku}
                      secondary={
                        <>
                          <Typography
                            sx={{ display: 'inline' }}
                            component='span'
                            variant='body2'
                            color='text.primary'
                          >
                            Qty: {orderItem.qty}
                          </Typography>
                          <br />
                          {orderItem.serialNumber && <>Serial Number: {orderItem.serialNumber}</>}
                          <List>
                            {order.orderLabels
                              .filter(
                                (orderLabel: OrderLabel) =>
                                  orderLabel.sku.skuWhiteLabel === orderItem.itemIdentifier.sku
                              )
                              .map((orderLabel: OrderLabel, index: number) => (
                                <ListItem
                                  key={`order-label-${index}`}
                                  secondaryAction={
                                    <>
                                      {orderLabel.lot ? (
                                        <>
                                          <DownloadLabel id={orderLabel.id} order={order}>
                                            <Tooltip title={'Download'}>
                                              <IconButton
                                                disabled={!orderLabel.bucketKey}
                                                sx={{ color: 'green' }}
                                                edge='start'
                                                aria-label='delete'
                                              >
                                                <DownloadIcon />
                                              </IconButton>
                                            </Tooltip>
                                          </DownloadLabel>
                                          <Tooltip title={orderLabel.printed ? 'Printed' : 'Not Printed'}>
                                            <IconButton
                                              disabled={!orderLabel.bucketKey}
                                              sx={{ color: orderLabel.printed ? 'gray' : 'green' }}
                                              edge='start'
                                              aria-label='delete'
                                              onClick={() => togglePrinted(orderLabel)}
                                            >
                                              {orderLabel.printed ? (
                                                <CheckBoxIcon />
                                              ) : (
                                                <CheckBoxOutlineBlankIcon />
                                              )}
                                            </IconButton>
                                          </Tooltip>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  }
                                >
                                  <ListItemIcon
                                    sx={{ minWidth: '35px', marginBottom: 'auto', marginTop: '7px' }}
                                  >
                                    {renderLabelIconWithTooltip({ label: orderLabel, index })}
                                  </ListItemIcon>
                                  <ListItemText>
                                    {orderLabel.lot ? (
                                      <>
                                        Lot #<b>{orderLabel.lot?.lotNumber}</b> <br />
                                        Expiration Date{' '}
                                        <b>{expirationDateFormat(orderLabel.lot?.expirationDate)}</b>
                                        <br />
                                        {hasDimension(orderLabel.sku.skuFact.dimensions, 'printedLabel') && (
                                          <>
                                            Label Dimension{' '}
                                            <b>
                                              {renderDimensions(
                                                orderLabel.sku.skuFact.dimensions,
                                                'printedLabel'
                                              )}
                                            </b>
                                            <br />
                                          </>
                                        )}
                                        {hasDimension(orderLabel.sku.skuFact.dimensions, 'shrinkBand') && (
                                          <>
                                            Shrink Band Dimension{' '}
                                            <b>
                                              {renderDimensions(
                                                orderLabel.sku.skuFact.dimensions,
                                                'shrinkBand'
                                              )}
                                            </b>
                                            <br />
                                          </>
                                        )}
                                        {renderPdf({
                                          id: orderLabel.id,
                                          order: order,
                                          regeneratePrivateLabel: (x) => regeneratePrivateLabel(x),
                                          regenerating,
                                        })}
                                      </>
                                    ) : (
                                      <BackOrderDetails
                                        handleClick={() => {
                                          setOpenLotModal(true)
                                          setSelectedSku(orderItem.itemIdentifier.sku)
                                          setSelectedLabelId(orderLabel.id)
                                        }}
                                      />
                                    )}
                                  </ListItemText>
                                </ListItem>
                              ))}
                          </List>
                        </>
                      }
                    />
                  </ListItem>
                )
              })}
            </List>
          </Stack>
        </Paper>
      </Box>
    </>
  )
}
