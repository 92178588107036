import _ from 'lodash'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { DataGridPro, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { CHECK_SHOPIFY_STORE_CONFIGURATION } from '@/lib/graphql/checkShopifyStoreConfiguration'
import { LIST_SHOPIFY_STORE_CONFIGURATIONS } from '@/lib/graphql/listShopifyStoreConfigurations'
import { client } from 'config/cache'
import { CheckShopifyStoreConfiguration, ShopifyStoreConfiguration } from '@/lib/graphql/types'
import { Button } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'

export const ShopifyStoreConfigurationsDashboard = () => {
  const apiRef = useGridApiRef()

  const { loading, data } = useQuery(LIST_SHOPIFY_STORE_CONFIGURATIONS, {
    variables: {
      take: 100,
      skip: 0,
      where: {},
    },
    fetchPolicy: 'no-cache',
  })

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 0.5 },
    { field: 'domain', headerName: 'Domain', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 0.3, type: 'boolean' },
    { field: 'configurationLastCheckedAt', headerName: 'Last checked', flex: 1, type: 'dateTime' },
    {
      field: 'runCheck',
      headerName: 'Run Check',
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        const [checkShopifyStoreConfiguration, { loading }] = useMutation(CHECK_SHOPIFY_STORE_CONFIGURATION, {
          onCompleted: (response: { checkShopifyStoreConfiguration: CheckShopifyStoreConfiguration }) => {
            const { success, configurationLastCheckedAt, configurationErrors } =
              response.checkShopifyStoreConfiguration
            apiRef.current.updateRows([
              {
                id: params.row.id,
                status: success,
                configurationLastCheckedAt: new Date(Date.parse(configurationLastCheckedAt!)),
                configurationErrors: JSON.parse(configurationErrors || '[]'),
              },
            ])
          },
        })
        return (
          <Button
            disabled={loading}
            onClick={() =>
              checkShopifyStoreConfiguration({
                variables: {
                  where: {
                    id: params.row.id,
                  },
                },
              })
            }
          >
            Check now
          </Button>
        )
      },
    },
  ]

  const rows = (data?.listShopifyStoreConfigurations || []).map((c: ShopifyStoreConfiguration) => {
    return {
      id: c.id,
      name: c.name,
      domain: c.domain,
      status: _.isEmpty(JSON.parse(c.configurationErrors)),
      configurationErrors: JSON.parse(c.configurationErrors),
      configurationLastCheckedAt: new Date(Date.parse(c.configurationLastCheckedAt)),
      original: c,
    }
  })

  return (
    <>
      <DataGridPro
        apiRef={apiRef}
        loading={loading}
        rows={rows}
        columns={columns}
        getDetailPanelContent={({ row }) => (
          <div>
            Details:
            <pre>{JSON.stringify(row.configurationErrors, null, 4)}</pre>
          </div>
        )}
        getDetailPanelHeight={() => 500}
      />
    </>
  )
}
