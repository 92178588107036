import { useQuery, useReactiveVar } from '@apollo/client'
import { Alert, Box, Button, Dialog, Paper, Typography, TextField, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import {
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import React, { useEffect, useState } from 'react'
import { selectedStoreId, stores } from 'config/cache'
import { PROXY_SHOPIFY_QUERY } from 'lib/graphql/proxyShopifyQuery'
import dayjs from 'dayjs'
import CheckIcon from '@mui/icons-material/Check'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import _ from 'lodash'
import { ShopifyCustomer } from 'lib/types.ts'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { CustomerCreateOrUpdateDialog } from 'components/Dialogs/CustomerCreateOrUpdateDialog.tsx'
import { useSearchParams } from 'react-router-dom'
import { CustomerDetails } from 'pages/VendorApp/Customer/CustomerDetails.tsx'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { usdFormatter } from 'pages/ReportsApp/utils'

function renderApproval(v: any) {
  return v.value ? <CheckIcon sx={{ color: 'green' }} /> : <DoNotDisturbIcon sx={{ color: '#f5c3c3' }} />
}

function formatDateTime(v: Date, removeTime: boolean) {
  return v ? dayjs(v).format(removeTime ? 'M/D/YYYY' : 'M/D/YYYY h:mm A') : ''
}

const getSortingValue = (field: string) =>
  ({
    lastName: 'NAME',
    amountSpent: 'TOTAL_SPENT',
    numberOfOrders: 'ORDERS_COUNT',
    createdAt: 'CREATED_AT',
    lastOrderAt: 'LAST_ORDER_DATE',
  }[field])

const columns: GridColDef[] = [
  {
    field: 'approved',
    headerName: 'Approved',
    flex: 0.7,
    minWidth: 76,
    renderCell: (v) => renderApproval(v),
    sortable: false,
    filterable: false,
    align: 'center',
    headerAlign: 'center',
  },
  { field: 'lastName', headerName: 'Last Name', minWidth: 110, flex: 0.7 },
  {
    field: 'firstName',
    headerName: 'First Name',
    flex: 0.7,
    minWidth: 100,
    sortable: false,
  },
  {
    field: 'email',
    headerName: 'E-mail',
    flex: 1,
    sortable: false,
  },
  {
    field: 'amountSpent',
    headerName: 'Total Spent',
    flex: 1,
    align: 'right',
    headerAlign: 'right',
    valueGetter: (params) => {
      return usdFormatter.format(params.row.amountSpent.amount)
    },
    filterable: false,
  },
  {
    field: 'numberOfOrders',
    headerName: '# Orders',
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    type: 'number',
  },
  {
    field: 'lastOrderAt',
    headerName: 'Last Order',
    flex: 1,
    renderCell: (v) => formatDateTime(v.value, true),
    filterable: false,
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    flex: 1,
    renderCell: (v) => formatDateTime(v.value, true),
    filterable: false,
    align: 'right',
    headerAlign: 'right',
  },
]

type EditToolbarProps = {
  handleAdd: () => void
  handleEdit: () => void
  handleDelete: () => void
  enableEditButton: boolean
  enableDeleteButton: boolean
}

const CreateToolbar = ({ handleAdd, handleDelete, enableDeleteButton }: EditToolbarProps) => {
  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<AddIcon />} onClick={handleAdd}>
        New
      </Button>
      <Button
        color='primary'
        startIcon={<DeleteIcon />}
        onClick={handleDelete}
        disabled={!enableDeleteButton}
      >
        Delete
      </Button>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  )
}
const PAGE_SIZE = 15

type ShopifyCustomerProp = ShopifyCustomer & {
  metafields: any
  original: any
  approved: boolean
  accountStatus: any
}

export const ManageCustomers = () => {
  const [showModal, setShowModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState<string | undefined>()
  const selectedStoreIdVar = useReactiveVar(selectedStoreId)
  const storesVar = useReactiveVar(stores)
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([])
  const [focusedCustomer, setFocusedCustomer] = useState<ShopifyCustomerProp | undefined>()
  const [mode, setMode] = useState<'create' | 'update'>('create')

  const [queryOptions, setQueryOptions] = React.useState<{ sortModel: any[]; filterModel: any }>({
    sortModel: [{ field: 'lastOrderAt', sort: 'desc' }],
    filterModel: {},
  })

  const [searchText, setSearchText] = useState('')

  const [userToActivate, setUserToActivate] = useState<string>()

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE,
  })
  const mapPageToNextCursor = React.useRef<{
    [page: number]: GridRowId
  }>({})

  const [searchParams, setSearchParams] = useSearchParams()

  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    const newSortModel = _.isEmpty(sortModel) ? [{ field: 'lastOrderAt', sort: 'asc' }] : [...sortModel]
    setQueryOptions((queryOptions) => ({
      ...queryOptions,
      sortModel: newSortModel,
    }))
  }, [])

  const handleSearchText: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setSearchText(event.target.value)
  }

  useEffect(() => {
    if (searchParams.get('activate')) {
      setUserToActivate(searchParams.get('activate') || '')
    }
  }, [searchParams])

  const {
    refetch: refetchCustomers,
    data: dataCustomers,
    loading: loadingCustomers,
    error: errorCustomers,
    fetchMore,
  } = useQuery(PROXY_SHOPIFY_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: 'customers',
      shopifyStoreId: selectedStoreIdVar,
      variables: JSON.stringify({
        first: paginationModel.pageSize,
        after: mapPageToNextCursor.current[paginationModel.page],
        sortKey: getSortingValue(queryOptions?.sortModel[0]?.field),
        reverse: queryOptions?.sortModel[0]?.sort === 'desc',
        query: searchText,
      }),
    },
  })

  const customers = dataCustomers?.proxyShopifyQuery?.customers.data || []
  const pageInfo = dataCustomers?.proxyShopifyQuery?.customers.pageInfo || {}

  const { data: dataForUserToActivate } = useQuery(PROXY_SHOPIFY_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      query: 'customer',
      shopifyStoreId: selectedStoreIdVar,
      variables: JSON.stringify({
        id: userToActivate,
      }),
    },
    skip: !userToActivate,
  })

  useEffect(() => {
    if (dataForUserToActivate) {
      const response = JSON.parse(dataForUserToActivate.proxyShopifyQuery)
      if (!_.isEmpty(response)) {
        const node = response.data.customer
        if (!node) {
          setShowErrorModal('That customer account cannot be found')
          return
        }
        setFocusedCustomer({
          ...node,
          lastOrderAt: node?.lastOrder?.createdAt,
          approved: node?.accountStatus?.value === 'APPROVED',
          original: node,
        })
        setMode('update')
        setShowModal(true)
      }
    }
  }, [dataForUserToActivate])

  useEffect(() => {
    if (
      !loadingCustomers &&
      pageInfo?.hasNextPage &&
      mapPageToNextCursor.current[paginationModel.page] !== pageInfo?.endCursor
    ) {
      mapPageToNextCursor.current[paginationModel.page + 1] = pageInfo?.endCursor
    }
  }, [paginationModel.page, loadingCustomers, pageInfo])

  const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
    if (newPaginationModel.page === 0 || mapPageToNextCursor.current[newPaginationModel.page]) {
      setPaginationModel(newPaginationModel)
    }
  }

  function closeDialog() {
    setShowModal(false)
  }

  useEffect(() => {
    if (dataCustomers) {
      const focusedCustomerData = customers.find(
        (c: any) => c.id === focusedCustomer?.id
      )
      if (focusedCustomerData) setFocusedCustomer(focusedCustomerData)
    }
  }, [dataCustomers])

  const handleOnRowsScrollEnd = async () => {
    if (pageInfo?.hasNextPage) {
      await fetchMore({
        variables: {
          query: 'customers',
          shopifyStoreId: selectedStoreIdVar,
          variables: JSON.stringify({
            first: paginationModel.pageSize,
            after: pageInfo.endCursor,
            sortKey: getSortingValue(queryOptions?.sortModel[0]?.field),
            reverse: queryOptions?.sortModel[0]?.sort === 'desc',
            query: searchText,
          }),
        },
      })
    }
  }

  if (!selectedStoreIdVar || !storesVar || !customers) return <></>

  return (
    <>
      <Box sx={{ paddingBottom: 2 }}>
        <Typography variant={'h5'}>
          {storesVar.find((x) => x.id === selectedStoreIdVar).name} - Customers
        </Typography>
      </Box>
      <Box sx={{ width: '250px', paddingBottom: 2 }}>
        <TextField
          fullWidth
          variant='standard'
          placeholder='Search'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={searchText}
          onChange={handleSearchText}
        />
      </Box>
      {errorCustomers && <Alert severity={'error'}>{errorCustomers.message}</Alert>}
      {customers && (
        <Box sx={{ height: '500px' }}>
          <Paper
            elevation={5}
            sx={{
              height: 'inherit',
              display: 'grid',
              gridTemplateRows: 'auto 1f auto',
              borderRadius: '16px',
              p: 1,
            }}
          >
            <DataGridPremium
              initialState={{
                sorting: {
                  sortModel: queryOptions.sortModel,
                },
              }}
              sx={{
                borderLeftWidth: 0,
                borderRightWidth: 0,
                borderTopWidth: 0,
              }}
              density={'compact'}
              rows={customers}
              columns={columns}
              disableMultipleRowSelection={true}
              hideFooterRowCount={true}
              hideFooterPagination
              sortingMode='server'
              scrollEndThreshold={100}
              disableMultipleColumnsSorting
              onRowsScrollEnd={handleOnRowsScrollEnd}
              sortModel={[...queryOptions.sortModel]}
              onSortModelChange={handleSortModelChange}
              onPaginationModelChange={handlePaginationModelChange}
              paginationModel={paginationModel}
              loading={loadingCustomers}
              rowSelectionModel={rowSelectionModel}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel)
                if (_.size(newRowSelectionModel) === 1) {
                  const data = (customers || []).find((r: any) => r.id === newRowSelectionModel[0])
                  if (focusedCustomer?.id !== data?.id) {
                    setFocusedCustomer(data)
                  }
                } else {
                  setFocusedCustomer(undefined)
                }
              }}
              slots={{
                toolbar: CreateToolbar,
              }}
              slotProps={{
                toolbar: {
                  enableEditButton: !!focusedCustomer,
                  handleAdd: () => {
                    setMode('create')
                    setShowModal(true)
                  },
                  handleDelete: async () => {
                    await refetchCustomers()
                  },
                },
              }}
            />
          </Paper>
        </Box>
      )}
      <Box sx={{ paddingTop: '20px', width: '100%' }}>
        <CustomerDetails customer={focusedCustomer} refetchCustomers={refetchCustomers} />
      </Box>
      <Dialog fullWidth={true} maxWidth='md' open={showModal} onClose={() => closeDialog()}>
        <CustomerCreateOrUpdateDialog
          mode={mode}
          data={focusedCustomer}
          closeDialog={() => closeDialog()}
          storeId={selectedStoreIdVar}
          onSuccess={async () => {
            setRowSelectionModel([])
            setFocusedCustomer(undefined)
            await refetchCustomers()
          }}
        />
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth='lg'
        open={!!showErrorModal}
        onClose={() => setShowErrorModal(undefined)}
      >
        <Alert onClose={() => setShowErrorModal(undefined)} severity='error'>
          Customer could not be found
        </Alert>
      </Dialog>
    </>
  )
}
