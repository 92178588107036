import { Navigate, useSearchParams } from 'react-router-dom'
import { useCallback, useEffect } from 'react'
import { CircularProgress } from '@mui/material'
import axios from 'axios'
import { useRecoilState } from 'recoil'
import { accessToken } from 'store/user.ts'
import { UPDATE_USER } from '@/lib/graphql/updateUser'
import { useMutation } from '@apollo/client'
import { cognitoOps, parseJwt } from '@/lib/Cognito.tsx'

export const OAuthCallback = () => {
  const [params] = useSearchParams()
  const authorizationCode = params.get('code')
  const state = params.get('state')
  const [token, setAccessToken] = useRecoilState(accessToken)
  const [updateUser] = useMutation(UPDATE_USER)

  const googleExchangeAuthorizationCodeForAccessToken = async ({ authorizationCode }: any) => {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      withCredentials: true,
    }
    const params = new URLSearchParams({
      grant_type: 'authorization_code',
      code: authorizationCode,
      client_id: import.meta.env.VITE_COGNITO_CLIENT_ID,
      // client_secret: import.meta.env.VITE_COGNITO_CLIENT_SECRET,
      redirect_uri: `${import.meta.env.VITE_COGNITO_CALLBACK}/oauth`,
    })
    try {
      const response = await axios.post(
        `https://${import.meta.env.VITE_COGNITO_DOMAIN}/oauth2/token`,
        params.toString(),
        config
      )
      return {
        idToken: response.data.id_token,
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
      }
    } catch (err) {
      return
    }
  }

  const federatedLogin = useCallback(async () => {
    const accessToken = await googleExchangeAuthorizationCodeForAccessToken({ authorizationCode })
    if (accessToken) {
      await cognitoOps.processFederatedSignIn(accessToken)
      const idToken = parseJwt(accessToken.idToken)
      const { sub, email } = parseJwt(accessToken.idToken)
      // Determine if we need to finish linking CognitoUser to Admin Portal User
      if (idToken['custom:adminPortalUserId']) {
        setAccessToken(accessToken.accessToken)
      } else {
        // Finish user registration
        updateUser({
          variables: {
            where: { email: { equals: email } },
            data: {
              sub,
            },
          },
          onCompleted() {
            setAccessToken(accessToken.accessToken)
          },
        })
      }
    }
  }, [authorizationCode])

  useEffect(() => {
    federatedLogin()
  }, [])

  return token ? <Navigate to={`${atob(state as string)}`} /> : <CircularProgress />
}
