import { AUTH_TYPE } from 'aws-appsync-auth-link/lib/auth-link'

interface IAppSyncConfig {
  graphqlEndpoint?: string
  region?: string
  authenticationType?: AUTH_TYPE
  apiKey?: string
  jwtToken?: () => Promise<string>
}

const authType = import.meta.env.VITE_APP_APPSYNC_AUTH || 'AMAZON_COGNITO_USER_POOLS'

const AppSyncConfig: IAppSyncConfig = {
  graphqlEndpoint: import.meta.env.VITE_APPSYNC_ENDPOINT,
  region: import.meta.env.VITE_AWS_REGION,
  authenticationType: AUTH_TYPE[authType as keyof typeof AUTH_TYPE],
  apiKey: import.meta.env.VITE_APPSYNC_API_KEY,
}

export default AppSyncConfig
