import { gql } from '@apollo/client'

export const CUSTOMER_GROUPS_LIST = gql`
  query listCustomerGroups($where: CustomerGroupWhereInput) {
    listCustomerGroups(where: $where) {
      id
      name
      autoAssignNewUsers
      customerExternalIds
    }
  }
`
