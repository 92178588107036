import { gql } from '@apollo/client'
import { fragment as fragmentSkuListing } from './fragmentSkuListing'

export const CREATE_SKU_LISTING = gql`
  ${fragmentSkuListing}
  mutation CreateSkuListing($data: SkuListingCreateInput!) {
    createSkuListing(data: $data) {
      ...skuListingFragment
    }
  }
`
