import { gql } from '@apollo/client'

export const COUNT_LABEL_APPROVAL_WORKFLOW_INSTANCES = gql`
  query countLabelApprovalWorkflowInstances(
    $where: LabelApprovalWorkflowInstanceWhereInput
    $orderBy: [LabelApprovalWorkflowInstanceOrderByInput!]
    $skip: Int
    $take: Int
  ) {
    countLabelApprovalWorkflowInstances(where: $where, orderBy: $orderBy, skip: $skip, take: $take)
  }
`
