import { gql } from '@apollo/client'

export const ORDERS_LIST = gql`
  query listOrdersWorkflow(
    $limit: Int
    $offset: Int
    $where: OrderWhereInput
    $orderBy: [OrderOrderByInput]
  ) {
    listOrdersWorkflow(orderBy: $orderBy, take: $limit, skip: $offset, where: $where) {
      id
      orderWorkflow {
        ordinal
        step
        status
        color
      }
      storeId
      storeName
      organization {
        name
      }
      commonId
      createdAt
    }
  }
`
