import { gql } from '@apollo/client'
import { fragment as fragmentSkuListing } from './fragmentSkuListing'

export const SKU_LISTINGS_LIST = gql`
  ${fragmentSkuListing}
  query ($where: SkuListingWhereInput, $orderBy: [SkuListingOrderByInput], $offset: Int, $limit: Int) {
    listSkuListings(where: $where, orderBy: $orderBy, skip: $offset, take: $limit) {
      ...skuListingFragment
    }
  }
`
