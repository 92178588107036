import React, { useEffect, useState } from 'react'
import { useQuery, useReactiveVar, useMutation } from '@apollo/client'
import {
  Box,
  Typography,
  Button,
  Dialog,
  Grid,
  SelectChangeEvent,
  Paper,
  Stack,
  Tooltip,
  IconButton,
  Tab,
  Tabs,
} from '@mui/material'
import {
  GridColDef,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridRowSelectionModel,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  useGridApiContext,
  GridRenderCellParams,
  useGridSelector,
  gridDetailPanelExpandedRowsContentCacheSelector,
} from '@mui/x-data-grid-pro'
import _ from 'lodash'
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Lock as LockIcon } from '@mui/icons-material'
import { DesignerTemplate, Sku } from '@/lib/graphql/types'
import { organizationId } from 'config/cache.ts'
import { CATALOG_LIST } from 'lib/graphql/listCatalog.ts'
import dayjs from 'dayjs'
import ErrorIcon from '@mui/icons-material/Error'
import ImageIcon from '@mui/icons-material/Image'
import { SkuSelect } from 'components/Forms/SkuSelect.tsx'
import { useForm } from 'react-hook-form'
import { TEMPLATES_LIST } from 'lib/graphql/listTemplates.ts'
import { DELETE_TEMPLATE } from 'lib/graphql/deleteTemplate.ts'
import { TemplateCreateOrUpdateDialog } from 'components/Dialogs/TemplateCreateOrUpdateDialog.tsx'
import style from 'components/Dialogs/dialogs.module.css'
import { CustomTabPanel, customTabProps } from 'components/CustomTabs.tsx'
import { getUserRole, ROLES } from 'lib/rbac.tsx'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import TemplatesImportDialog from 'pages/VendorApp/Dialogs/TemplatesImportDialog.tsx'

function formatDateTime(v: Date) {
  return v ? dayjs(v).format('M/D/YYYY h:mm A') : ''
}

type TemplateProps = DesignerTemplate

type ToolbarProps = {
  handleAdd: () => void
  handleEdit: () => void
  handleDelete: () => void
  enableEditButton: boolean
  enableDeleteButton: boolean
}

const CreateToolbar = ({
  handleAdd,
  handleDelete,
  handleEdit,
  enableEditButton,
  enableDeleteButton,
}: ToolbarProps) => {
  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<AddIcon />} onClick={handleAdd}>
        New
      </Button>
      <Button color='primary' startIcon={<EditIcon />} onClick={handleEdit} disabled={!enableEditButton}>
        Edit
      </Button>
      <Button
        color='primary'
        startIcon={<DeleteIcon />}
        onClick={handleDelete}
        disabled={!enableDeleteButton}
      >
        Delete
      </Button>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  )
}

interface SkuSelectionProps {
  skuId: string
}

export const DesignerTemplates = () => {
  const userRole = getUserRole()
  const [mode, setMode] = useState<'create' | 'update'>('create')
  const [showModal, setShowModal] = useState(false)
  const [showTemplateModal, setShowTemplateModal] = useState<{ url: string; title: string } | undefined>(
    undefined
  )
  const [focusedTemplate, setFocusedTemplate] = useState<TemplateProps>()
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])
  const organizationIdVar = useReactiveVar(organizationId)
  const [selectedSku, setSelectedSku] = useState<string>()
  const [showImportModal, setShowImportModal] = useState(false)
  const currentOrganizationId = useReactiveVar(organizationId)

  const [lastOrg, setLastOrg] = useState()

  const [tabValue, setTabValue] = useState(0)
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  function PreviewToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
    const { id, value: isExpanded } = props
    const apiRef = useGridApiContext()

    // To avoid calling ´getDetailPanelContent` all the time, the following selector
    // gives an object with the detail panel content for each row id.
    const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector)

    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id])

    return (
      <IconButton size='small' tabIndex={-1} disabled={!hasDetail} aria-label={isExpanded ? 'Close' : 'Open'}>
        <ImageIcon
          sx={{
            transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
            transition: (theme) =>
              theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
              }),
          }}
          fontSize='inherit'
        />
      </IconButton>
    )
  }

  const columns: GridColDef[] = [
    { field: 'templateId', headerName: 'Template ID', flex: 1 },
    { field: 'displayName', headerName: 'Name', flex: 0.7 },
    {
      field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
      width: 50,
      headerName: '',
      renderCell: (params) => <PreviewToggle {...params} />,
    },
  ]

  const { setValue, control, reset } = useForm<SkuSelectionProps>({
    defaultValues: { skuId: '' },
    reValidateMode: 'onChange',
  })

  const {
    refetch: refetchCatalog,
    data: dataCatalog,
    loading: loadingCatalog,
    error: errorCatalog,
  } = useQuery(CATALOG_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      orderBy: [{ sku: 'ASC' }],
      where: {
        brand: {
          organization: {
            id: {
              equals: organizationIdVar,
            },
          },
        },
        archivedAt: null,
      },
    },
  })

  const {
    refetch: refetchTemplates,
    data: dataTemplates,
    loading: loadingTemplates,
    error: errorTemplates,
  } = useQuery(TEMPLATES_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        skuId: { equals: selectedSku },
        archivedAt: null,
      },
      limit: 300,
    },
    skip: !selectedSku,
  })

  useEffect(() => {
    if (!lastOrg || lastOrg !== organizationIdVar) {
      setSelectedSku('')
      setValue('skuId', '')
    }
  }, [organizationIdVar])

  const fetchTemplates = (x: SelectChangeEvent<string>) => {
    setSelectedSku(x.target.value)
  }

  const [deleteTemplate] = useMutation(DELETE_TEMPLATE)

  function closeDialog() {
    setShowModal(false)
  }

  const rows =
    !loadingTemplates &&
    (dataTemplates?.listDesignerTemplates || []).map((template: DesignerTemplate) => {
      return {
        ...template,
      }
    })

  if (!organizationIdVar) return <></>

  return (
    <>
      <Box sx={{ paddingBottom: 2 }}>
        <Typography variant={'h5'}>Designer Templates</Typography>
      </Box>
      {currentOrganizationId && userRole === ROLES.ADMIN && (
        <Box sx={{ width: '100', display: 'flex', justifyContent: 'right' }}>
          <Button onClick={() => setShowImportModal(true)}>Import Templates</Button>
        </Box>
      )}
      <Box sx={{ height: '80px', marginBottom: '20px' }}>
        <Paper
          elevation={5}
          sx={{
            height: 'inherit',
            paddingTop: '10px',
            marginLeft: '2px',
            marginRight: '2px',
            marginBottom: '30px',
            paddingLeft: '10px',
            borderRadius: '16px',
          }}
        >
          <form>
            <SkuSelect
              label={'Select Product'}
              usePlSku={true}
              organizationId={organizationIdVar}
              archived={false}
              control={control}
              customOnChange={fetchTemplates}
              withName={true}
              name='skuId'
            />
          </form>
        </Paper>
      </Box>

      {selectedSku && (
        <Box>
          <Tabs value={tabValue} onChange={handleTabChange} className={style.dialogTabs}>
            <Tab
              label={
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <div>Quick View</div>
                </Box>
              }
              className={style.dialogTab}
              {...customTabProps(0, tabValue)}
            />
            {userRole === ROLES.ADMIN && (
              <Tab
                label={
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <div>Templates</div>
                    <LockIcon />
                  </Box>
                }
                className={style.dialogTab}
                {...customTabProps(1, tabValue)}
              />
            )}
          </Tabs>
          <CustomTabPanel value={tabValue} index={0}>
            <Grid container>
              {(rows || []).map((row: any) => {
                return (
                  <Grid item xs={4}>
                    <Box
                      sx={{ margin: '5px', border: '2px gray solid', textAlignLast: 'center' }}
                      onClick={() => setShowTemplateModal({ url: row.imageUrl, title: row.displayName })}
                    >
                      <Tooltip title={row.displayName}>
                        {row.imageUrl ? (
                          <img width='100%' src={row.imageUrl} alt={row.templateId} />
                        ) : (
                          <Box sx={{ verticalAlign: 'middle' }}>
                            <ErrorIcon />{' '}
                          </Box>
                        )}
                      </Tooltip>
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <Box sx={{ height: 'calc(100vh - 340px)' }}>
              <Paper
                elevation={5}
                sx={{
                  height: 'inherit',
                  display: 'grid',
                  gridTemplateRows: 'auto 1f auto',
                  borderRadius: '16px',
                  p: 1,
                }}
              >
                <DataGridPremium
                  sx={{
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    borderTopWidth: 0,
                  }}
                  density={'compact'}
                  checkboxSelection
                  rows={rows || []}
                  columns={columns}
                  autoHeight={false}
                  loading={loadingTemplates}
                  localeText={{ noRowsLabel: 'No templates found' }}
                  disableMultipleRowSelection={true}
                  getDetailPanelContent={({ row }) => (
                    <Box sx={{ width: '100%', height: '100%' }}>
                      {row.imageUrl ? <img width='100%' src={row.imageUrl} alt={row.templateId} /> : <></>}
                    </Box>
                  )}
                  getDetailPanelHeight={({ row }) => 'auto'}
                  rowSelectionModel={rowSelectionModel}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel)
                    if (_.size(newRowSelectionModel) === 1) {
                      const data = rows.find((r: Sku) => r.id === newRowSelectionModel[0])
                      setFocusedTemplate(data)
                    } else {
                      setFocusedTemplate(undefined)
                    }
                  }}
                  slots={{
                    toolbar: CreateToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      enableEditButton: !!focusedTemplate,
                      enableDeleteButton: !!focusedTemplate,
                      handleAdd: () => {
                        setMode('create')
                        setShowModal(true)
                      },
                      handleEdit: () => {
                        setMode('update')
                        focusedTemplate && setShowModal(true)
                      },
                      handleDelete: async () => {
                        await deleteTemplate({
                          variables: {
                            where: {
                              id: focusedTemplate?.id,
                            },
                          },
                        })
                        await refetchTemplates()
                      },
                    },
                  }}
                />
                <Dialog open={showModal} onClose={() => setShowModal(false)}>
                  <TemplateCreateOrUpdateDialog
                    mode={mode}
                    data={focusedTemplate}
                    closeDialog={() => closeDialog()}
                    skuId={selectedSku || ''}
                    skuName={dataCatalog?.listSkus.find((x: Sku) => x.id === selectedSku)?.sku}
                    onSuccess={async () => {
                      setRowSelectionModel([])
                      setFocusedTemplate(undefined)
                      await refetchTemplates()
                    }}
                  />
                </Dialog>
              </Paper>
            </Box>
          </CustomTabPanel>
          <Dialog
            // className={styles.modelAddNewCustomerGroup}
            open={showTemplateModal !== undefined}
            onClose={() => setShowTemplateModal(undefined)}
            fullWidth
            maxWidth='lg'
          >
            <Stack direction={'column'} sx={{ padding: '15px' }}>
              <Paper elevation={5} sx={{ marginBottom: '15px', padding: '3px' }}>
                <Typography variant={'h5'}>{showTemplateModal?.title}</Typography>
              </Paper>
              <img
                src={showTemplateModal?.url}
                width='100%'
                onClick={() => setShowTemplateModal(undefined)}
              />
            </Stack>
          </Dialog>
        </Box>
      )}
      <Dialog open={showImportModal} onClose={() => setShowImportModal(false)} fullWidth={true} maxWidth='lg'>
        <TemplatesImportDialog
          closeDialog={() => setShowImportModal(false)}
          currentOrganizationId={currentOrganizationId!}
          onSuccess={async () => {
            setRowSelectionModel([])
            setFocusedTemplate(undefined)
            setShowImportModal(false)
            await refetchTemplates()
          }}
        />
      </Dialog>
    </>
  )
}
