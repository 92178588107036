import { DataGridPro, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Link } from 'react-router-dom'

import Filters from './Filters'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import Stack from '@mui/material/Stack'

const getColumns = (): GridColDef[] => [
  {
    field: 'upc',
    headerName: 'UPC',
    headerClassName: 'mui-grid-header',
    width: 130,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography color='#001D35' fontWeight={600}>
          {params.value}
        </Typography>
      )
    },
  },
  {
    field: 'productName',
    headerName: 'Product',
    headerClassName: 'mui-grid-header',
    width: 130,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography color='#001D35' fontWeight={600}>
          {params.value}
        </Typography>
      )
    },
  },
  {
    field: 'commonId',
    headerName: 'Order #',
    headerClassName: 'mui-grid-header',
    width: 130,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Stack>
          {params.row.orders.map((order: any) => (
            <Link to={`/connect/order/${order.id}/${order.commonId}`} key={`${order.commonId}`}>
              <Chip size='small' color='success' label={order.commonId} sx={{ marginBottom: '1px' }} />
            </Link>
          ))}
        </Stack>
      )
    },
  },
  {
    field: 'date',
    headerName: 'Date',
    width: 110,
    flex: 1, //!isExtraSmall ? 1 : undefined,
    headerClassName: 'mui-grid-header',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography color='#001D35' fontWeight={600}>
          {params.value}
        </Typography>
      )
    },
  },
  {
    field: 'companyName',
    headerName: 'Customer',
    width: 110,
    flex: 1, //!isExtraSmall ? 1 : undefined,
    headerClassName: 'mui-grid-header',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography color='#001D35' fontWeight={600}>
          {params.value}
        </Typography>
      )
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    // type: 'number',
    width: 120,
    flex: 1, //!isExtraSmall ? 1 : undefined,
    headerClassName: 'mui-grid-header',
    renderCell: (params) => {
      if (params.value === 'InUse') {
        return <Chip size='small' color='success' label={_.startCase(params.value)} />
      }
      if (params.value === 'PreMarket') {
        return <Chip size='small' color='info' label={_.startCase(params.value)} />
      }
      if (params.value === 'Available') {
        return <Chip size='small' variant='outlined' label={_.startCase(params.value)} />
      }
    },
    editable: false,
  },
]

export default function BarcodeTable({
  nRows,
  isLoading,
  rows,
  paginationModel,
  setPaginationModel,
}: {
  nRows: number
  isLoading: boolean
  rows: any
  paginationModel: {
    pageSize: number
    page: number
  }
  setPaginationModel: React.Dispatch<
    React.SetStateAction<{
      pageSize: number
      page: number
    }>
  >
}) {
  const theme = useTheme()
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const columns = getColumns()

  const [rowCountState, setRowCountState] = useState(nRows)
  useEffect(() => {
    setRowCountState((prevRowCountState) => (nRows !== undefined ? nRows : prevRowCountState))
  }, [nRows, setRowCountState])

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<any>({
    customer: !isExtraSmall,
  })

  useEffect(() => {
    setColumnVisibilityModel({ ...columnVisibilityModel, customer: !isExtraSmall })
  }, [isExtraSmall, setColumnVisibilityModel])

  return (
    <Box sx={{ width: '100%', height: '500px' }}>
      <>
        <Filters />
        <DataGridPro
          autoHeight
          getRowHeight={() => 'auto'}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel,
            },
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel({ ...columnVisibilityModel, ...newModel })
          }
          pagination
          rowCount={rowCountState}
          paginationMode='server'
          paginationModel={paginationModel}
          loading={isLoading}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 25]}
          disableRowSelectionOnClick
        />
      </>
    </Box>
  )
}
