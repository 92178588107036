import { Controller } from 'react-hook-form'
import TextField, { TextFieldProps } from '@mui/material/TextField'

export interface FormTextInputProps {
  name: string
  control: any
  rules?: any
  label: string
  setValue?: any
  required?: boolean
  type?: string
  fullWidth?: boolean
  disabled?: boolean
  defaultValue?: string | number | undefined
  variant?: 'outlined' | 'standard' | 'filled'
  color?: 'primary' | 'secondary'
  placeholder?: string
  InputProps?: any
  size?: 'small' | 'medium'
  mask?: any
}

export const FormTextField = ({
  name,
  control,
  label,
  type,
  fullWidth,
  disabled,
  variant,
  color,
  rules,
  defaultValue,
  placeholder,
  InputProps,
  margin,
  required,
  size,
  mask,
  ...textFieldProps
}: FormTextInputProps & TextFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <TextField
          {...textFieldProps}
          helperText={error ? error.message : null}
          size={size || 'small'}
          error={!!error}
          required={rules?.required || required}
          value={value}
          onChange={onChange}
          defaultValue={defaultValue}
          fullWidth={fullWidth || true}
          label={label}
          variant={variant || 'standard'}
          color={color || 'primary'}
          type={type || 'text'}
          disabled={disabled || false}
          placeholder={placeholder || label}
          InputProps={InputProps}
          margin={margin}
          inputProps={{
            ref: mask,
          }}
        />
      )}
    />
  )
}
