import { Tabs, Tab } from '@mui/material'
import React from 'react'
export interface ProductVariantButtonGroupProps {
  variants: { title: string }[]
  onVariantSelect: (variantTitle: string) => void
  selectedVariant: string
}

const ProductVariantButtonGroup: React.FC<ProductVariantButtonGroupProps> = ({
  variants,
  selectedVariant,
  onVariantSelect,
}) => {
  return (
    <Tabs value={selectedVariant} onChange={(e, v) => onVariantSelect(v)}>
      {variants.map((variant: { title: string }) => {
        return (
          <Tab
            label={variant.title === 'White label' ? 'Private label' : variant.title}
            key={variant.title}
            value={variant.title}
          />
        )
      })}
    </Tabs>
  )
}

export default ProductVariantButtonGroup
