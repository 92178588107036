import { AlertColor } from '@mui/material'
import { atom, DefaultValue, selector, useRecoilCallback } from 'recoil'

interface AppStateType {
  navOpen: boolean
  currentAccountId: string | null
}

export const appState = atom<AppStateType>({
  key: 'settings',
  default: {
    navOpen: true,
    currentAccountId: null,
  },
})

export const navOpenState = selector<boolean>({
  key: 'navOpenState',
  get: ({ get }) => {
    const { navOpen } = get(appState)
    return navOpen
  },
  set: ({ set, get }, navOpen) => {
    if (navOpen instanceof DefaultValue) {
      navOpen = false
    }
    set(appState, { ...get(appState), navOpen })
  },
})

export const currentAccount = selector<string | null>({
  key: 'currentAccount',
  get: ({ get }) => get(appState).currentAccountId,
  set: ({ set, get }, currentAccountId) => {
    if (currentAccountId instanceof DefaultValue) {
      currentAccountId = null
    }
    set(appState, { ...get(appState), currentAccountId })
  },
})

interface Notification {
  id?: string
  message: string
  severity: AlertColor
}

export const apiNotificationState = atom<Notification[]>({
  key: 'apiNotifications',
  default: [],
})

export const addNotificationsSelector = selector<Notification[]>({
  key: 'addNotifications',
  get: ({ get }) => {
    return get(apiNotificationState)
  },
  set: ({ set, get }, notifications) => {
    if (notifications instanceof DefaultValue) {
      return
    }

    set(apiNotificationState, [
      ...get(apiNotificationState),
      ...notifications.map((notification) => ({
        ...notification,
        id: crypto.randomUUID(),
      })),
    ])
  },
})

export const deleteNotificationSelector = selector<string>({
  key: 'deleteNotification',
  get: () => '',
  set: ({ set, get }, notificationId) => {
    if (notificationId instanceof DefaultValue) {
      return
    }
    const notifications = get(apiNotificationState)
    set(
      apiNotificationState,
      notifications.filter(({ id }) => id !== notificationId)
    )
  },
})
