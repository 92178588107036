import React, { useState } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { Typography, Button, Grid, Paper, Stack, Link, Box } from '@mui/material'
import { organizationId, selectedStoreId, stores } from 'config/cache.ts'
import { GET_SHOPIFY_STORE_CONFIGURATION } from 'lib/graphql/getShopifyStoreConfiguration.ts'
import _ from 'lodash'
import { Input } from 'components/Forms/Input.tsx'
import { DirectUpload } from 'components/DirectUpload'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { UPDATE_SHOPIFY_STORE_CONFIGURATION } from 'lib/graphql/updateShopifyStoreConfiguration.ts'
import { PrivateLabelStoreSettings } from './PrivateLabelStoreSettings.tsx'
import { ListImages } from './ListImages.tsx'

import { User } from 'lib/types.ts'
import { LIST_USERS } from 'lib/graphql/listUsers.ts'
import { CustomersStoreSettings } from 'pages/VendorApp/StoreSettings/CustomersStoreSettings.tsx'
import { v4 as uuidv4 } from 'uuid'
import { TextFieldWithDebounce } from 'components/TextFieldWithDebounce.tsx'

const assets = ['logo', 'banner']
type Asset = 'logo' | 'banner'

type StoreConfigurationData = {
  id: string
  brandedDomain?: string
  assetsDomain?: string
  fromEmail?: string | null | undefined
  assetsKeys?: {
    logo: string | null | undefined
    banner:
      | string
      | null
      | undefined
      | {
          desktop: { s3key: string }[]
          mobile: { s3key: string }[]
        }
  }
}

type StoreImageProps = {
  id: string
  assetType?: string
  key?: string
  path?: string
  preview?: string
  alt?: string
  contentType?: string
  field?: string
}

type StoreConfigurationFields = Omit<
  StoreConfigurationData,
  | 'brandedDomain'
  | 'assetsDomain'
  | 'assetsKeys'
  | 'labelApprovalWorkflowConfigurationId'
  | 'labelApprovalWorkflowConfiguration'
> & {
  mobileBannerImages: StoreImageProps[]
  desktopBannerImages: StoreImageProps[]
  logoImage: StoreImageProps | null
}

const validationSchema = Yup.object().shape({
  id: Yup.string().required(),
  // fromEmail: Yup.string().nullable(),
  mobileBannerImages: Yup.array<StoreImageProps>().nullable().default([]),
  mobileDwellTime: Yup.number().min(3).nullable().optional(),
  mobileBannerHeight: Yup.number().min(0).nullable().optional(),
  mobileBannerWidth: Yup.number().min(0).nullable().optional(),
  desktopBannerImages: Yup.array<StoreImageProps>().optional().default([]),
  desktopDwellTime: Yup.number().min(3).nullable().optional(),
  desktopBannerHeight: Yup.number().min(0).nullable().optional(),
  desktopBannerWidth: Yup.number().min(0).nullable().optional(),
  logoImage: Yup.object<StoreImageProps>().nullable().default({}),
})

export const StoreSettings = () => {
  const selectedStoreIdVar = useReactiveVar(selectedStoreId)
  const storesVar = useReactiveVar(stores)
  const [storeData, setStoreData] = useState<StoreConfigurationData>()
  const [busy, setBusy] = useState(false)

  const { refetch: refetch, loading: loading } = useQuery(GET_SHOPIFY_STORE_CONFIGURATION, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        id: selectedStoreIdVar,
      },
    },
    skip: !selectedStoreIdVar,
    onCompleted(data) {
      const newStoreData = {
        ...storeData,
        ...data.getShopifyStoreConfiguration,
      }

      setStoreData(newStoreData)
      reset(transformDataToFormValues({ ...newStoreData }))
    },
  })

  const [updateStoreConfiguration] = useMutation(UPDATE_SHOPIFY_STORE_CONFIGURATION)

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid, errors },
    reset,
    getValues,
    setValue,
  } = useForm<StoreConfigurationFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver<StoreConfigurationFields>(validationSchema as any),
    defaultValues: {
      mobileBannerImages: [],
      desktopBannerImages: [],
      logoImage: {},
    },
  })

  const onImageUpload = (value: string | undefined, asset: Asset, type?: 'mobile' | 'desktop') => {
    const newImage = {
      id: uuidv4(),
      field: _.capitalize(asset),
      s3key: value,
      preview: value ? `https://s3.amazonaws.com/${storeData?.assetsDomain}/${value}` : undefined,
    }

    if (!type) {
      return setValue('logoImage', newImage, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      })
    }

    if (type === 'desktop') {
      const desktopBannerImages = getValues('desktopBannerImages')
      const updatedImages = [...desktopBannerImages, newImage]
      setValue('desktopBannerImages', updatedImages, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      })
    } else {
      const mobileBannerImages = getValues('mobileBannerImages')
      const updatedImages = [...mobileBannerImages, newImage]
      setValue('mobileBannerImages', updatedImages, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      })
    }
  }

  const toGraphQL = async (data: any & { image: { field?: Asset; key?: string; s3key?: string } }) => {
    const _prep = {
      logo: data.logoImage ? data.logoImage.s3key : '',
      banner: {
        mobile: {
          dwellTime: data.mobileDwellTime,
          height: data.mobileBannerHeight,
          width: data.mobileBannerWidth,
          images: data.mobileBannerImages
            .filter((banner: any) => !!banner.s3key)
            .map((banner: any) => ({ s3key: banner.s3key })),
        },
        desktop: {
          dwellTime: data.desktopDwellTime,
          height: data.desktopBannerHeight,
          width: data.desktopBannerWidth,
          images: data.desktopBannerImages
            .filter((banner: any) => !!banner.s3key)
            .map((banner: any) => ({ s3key: banner.s3key })),
        },
      },
    }

    const updatedAssetsKeys = _.merge(
      {},
      ...assets.map((assetName: string) => ({
        [assetName]: _.get(_prep, assetName, {}),
      }))
    )

    return {
      fromEmail: data.fromEmail,
      assetsKeys: JSON.stringify(updatedAssetsKeys),
    }
  }

  const transformDataToFormValues = (data: any) => {
    const shopifyAssets = JSON.parse(data.assetsKeys || '{}')

    if (!data) return {}
    return {
      id: data.id,
      logoImage: !_.isEmpty(shopifyAssets.logo)
        ? {
            id: uuidv4(),
            s3key: shopifyAssets.logo.s3key || shopifyAssets.logo,
            preview: `https://s3.amazonaws.com/${data.assetsDomain}/${
              shopifyAssets.logo.s3key || shopifyAssets.logo
            }`,
          }
        : null,
      desktopBannerImages: (
        shopifyAssets.banner?.desktop?.images ||
        (!Array.isArray(shopifyAssets.banner) ? [{ s3key: shopifyAssets.banner }] : null) ||
        []
      ).map((image: any) => ({
        id: uuidv4(),
        s3key: image.s3key,
        preview: `https://s3.amazonaws.com/${data.assetsDomain}/${image.s3key}`,
      })),
      desktopDwellTime: shopifyAssets.banner?.desktop?.dwellTime,
      desktopBannerHeight: shopifyAssets.banner?.desktop?.height,
      desktopBannerWidth: shopifyAssets.banner?.desktop?.width,
      mobileBannerImages: (shopifyAssets.banner?.mobile?.images || []).map((image: any) => ({
        id: uuidv4(),
        s3key: image.s3key,
        preview: `https://s3.amazonaws.com/${data.assetsDomain}/${image.s3key}`,
      })),
      mobileDwellTime: shopifyAssets.banner?.mobile?.dwellTime,
      mobileBannerHeight: shopifyAssets.banner?.mobile?.height,
      mobileBannerWidth: shopifyAssets.banner?.mobile?.width,
      fromEmail: data?.fromEmail || '',
    }
  }

  const onSubmit: SubmitHandler<StoreConfigurationData> = async (data) => {
    const updateData = await toGraphQL(data)
    await updateStoreConfiguration({
      variables: {
        where: {
          id: selectedStoreIdVar,
        },
        data: updateData,
      },
    })
    await refetch()
  }

  const onReset = async () => {
    reset(transformDataToFormValues(storeData))
  }

  const [userData, setUserData] = useState<Record<string, User>>({})
  const organizationIdVar = useReactiveVar(organizationId)
  const PAGE_SIZE = 50

  const { data: users } = useQuery(LIST_USERS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        organizations: {
          some: {
            organizationId: { equals: organizationIdVar },
          },
        },
      },
      orderBy: [{ email: 'ASC' }],
      limit: PAGE_SIZE,
    },
    onCompleted(dataUsers) {
      setUserData({
        ...userData,
        ..._.merge(
          {},
          ...dataUsers.listUsers.map((data: User) => ({
            [data.id]: data,
          }))
        ),
      })
    },
  })

  const logoImage = getValues('logoImage')
  const desktopBannerImages = getValues('desktopBannerImages')
  const mobileBannerImages = getValues('mobileBannerImages')

  const handleDelete = (id: string) => {
    const mobileImagesIndex = mobileBannerImages.findIndex((image) => image.id === id)
    const isInMobile = mobileImagesIndex !== -1
    let updatedImages
    if (isInMobile) {
      updatedImages = mobileBannerImages.filter((image) => image.id !== id)
    } else {
      updatedImages = desktopBannerImages.filter((image) => image.id !== id)
    }

    setValue(isInMobile ? 'mobileBannerImages' : 'desktopBannerImages', updatedImages, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    })
  }

  if (!storesVar || !selectedStoreIdVar || _.isEmpty(storeData)) return <></>

  return (
    <>
      <Box sx={{ paddingBottom: 2, display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography variant={'h5'}>
          {storesVar.find((x) => x.id === selectedStoreIdVar).name} - Settings
        </Typography>
        <Paper
          elevation={5}
          sx={{
            height: 'inherit',
            display: 'grid',
            gridTemplateRows: 'auto 1f auto',
            borderRadius: '16px',
            p: 1,
          }}
        >
          {' '}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                marginTop: '5px',
                padding: '0px 20px 20px',
              }}
            >
              <Input type='hidden' control={control} name='id' />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '5px',
                  marginBottom: '5px',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Typography variant='h5'>Configuration</Typography>
                </Box>
                <Box sx={{ alignSelf: 'right' }}>
                  <Button
                    variant={'outlined'}
                    disabled={isSubmitting}
                    onClick={() => {
                      onReset()
                    }}
                  >
                    Reset
                  </Button>

                  <Button
                    sx={{ marginLeft: '10px' }}
                    variant={'contained'}
                    type='submit'
                    disabled={isSubmitting || !(isDirty && isValid)}
                  >
                    Save
                  </Button>
                </Box>
              </Box>

              <Box sx={{ marginTop: '5px' }}>
                <Box sx={{ fontWeight: 'bold' }}>Store Domain</Box>
                <Box sx={{ paddingTop: '10px' }}>
                  <Link href={`https://${storeData.brandedDomain}`}>https://{storeData.brandedDomain}</Link>
                </Box>
              </Box>

              <Box sx={{ marginTop: '5px' }}>
                <Box sx={{ fontWeight: 'bold' }}>From Email</Box>
                <Box sx={{ paddingTop: '10px' }}>
                  <Link href={`mailto:${storeData.fromEmail}`}>{storeData.fromEmail}</Link>
                </Box>
              </Box>

              <Box sx={{ marginTop: '15px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <Box sx={{ fontWeight: 'bold' }}>Logo Image</Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <DirectUpload
                    key={logoImage?.id}
                    assetType={'logo'}
                    storeId={selectedStoreIdVar}
                    disabled={busy}
                    value={logoImage?.preview}
                    onStarted={() => setBusy(true)}
                    onFinished={() => setBusy(false)}
                    setValue={(value) => {
                      onImageUpload(value, 'logo')
                    }}
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '5px' }}>
                <Box sx={{ fontWeight: 'bold' }}>Banner - Desktop</Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', paddingTop: '10px' }}>
                  {desktopBannerImages.length > 1 && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', paddingTop: '10px' }}>
                      <TextFieldWithDebounce
                        control={control}
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        onChange={() => {}}
                        onAccept={() => console.log()}
                        label='Width (pixels)'
                        name='desktopBannerWidth'
                        type='number'
                        fullWidth={false}
                      />
                      <TextFieldWithDebounce
                        control={control}
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        onChange={() => {}}
                        onAccept={() => console.log()}
                        label='Height (pixels)'
                        name='desktopBannerHeight'
                        type='number'
                        fullWidth={false}
                      />
                      <TextFieldWithDebounce
                        control={control}
                        InputProps={{
                          inputProps: { min: 3 },
                        }}
                        onChange={() => {}}
                        onAccept={() => console.log()}
                        label='Dwell time (seconds)'
                        name='desktopDwellTime'
                        type='number'
                        fullWidth={false}
                      />
                    </Box>
                  )}
                  <ListImages
                    images={desktopBannerImages}
                    setImages={(data: any) => {
                      setValue('desktopBannerImages', data, {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                      })
                    }}
                    handleDelete={handleDelete}
                    storeId={selectedStoreIdVar}
                    setValue={(value: any) => {
                      onImageUpload(value, 'banner', 'desktop')
                    }}
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '5px' }}>
                <Box sx={{ fontWeight: 'bold' }}>Banner - Mobile</Box>
                <Box sx={{ paddingTop: '10px' }}>
                  {mobileBannerImages.length > 1 && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', paddingTop: '10px' }}>
                      <TextFieldWithDebounce
                        control={control}
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        onChange={() => {}}
                        onAccept={() => console.log()}
                        label='Width'
                        name='mobileBannerWidth'
                        type='number'
                        fullWidth={false}
                      />
                      <TextFieldWithDebounce
                        control={control}
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        onChange={() => {}}
                        onAccept={() => console.log()}
                        label='Height (pixels)'
                        name='mobileBannerHeight'
                        type='number'
                        fullWidth={false}
                      />
                      <TextFieldWithDebounce
                        control={control}
                        InputProps={{
                          inputProps: { min: 3 },
                        }}
                        onChange={() => {}}
                        onAccept={() => console.log()}
                        label='Dwell time (in seconds)'
                        name='mobileDwellTime'
                        type='number'
                        fullWidth={false}
                      />
                    </Box>
                  )}
                  <ListImages
                    images={mobileBannerImages}
                    setImages={(data: any) => {
                      setValue('mobileBannerImages', data, {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                      })
                    }}
                    handleDelete={handleDelete}
                    storeId={selectedStoreIdVar}
                    setValue={(value: any) => {
                      onImageUpload(value, 'banner', 'mobile')
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </form>
        </Paper>
        <CustomersStoreSettings />
        <PrivateLabelStoreSettings />
      </Box>
    </>
  )
}
