import Box from '@mui/material/Box'
import { Tooltip } from '@mui/material'
type Status = any

const hexFromColor = (name: string): string => {
  if (name === 'green') {
    return '#64DD96'
  }
  if (name === 'yellow') {
    return '#ffd300'
  }
  if (name === 'red') {
    return '#880808'
  }
  return '#BAC8DB'
}

const StatusIndicatorCell = ({ status, size }: { status: Status[]; size?: "lg" | "sm" }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {status.map((status, index) => {
        return (
          <Tooltip key={index} title={`${status.step}: ${status.status ?? '(Pending)'}`}>
            <Box
              key={index}
              sx={{
                width: size === 'lg' ? '72px' : '24px',
                height: '8px',
                mx: 0.2,
                backgroundColor: hexFromColor(status.color),
              }}
            ></Box>
          </Tooltip>
        )
      })}
    </Box>
  )
}

export default StatusIndicatorCell
