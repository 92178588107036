import { useState } from 'react'
import { Link, Paper, Typography, IconButton, Menu, MenuItem } from '@mui/material'
import { ShopifyAddress, ShopifyCustomer } from 'lib/types.ts'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

type CustomerDetailCardProps = {
  customer: ShopifyCustomer & { metafields: any; original: any; approved: boolean; accountStatus: any }
  defaultAddress?: ShopifyAddress
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  setShowModalAddresses: React.Dispatch<React.SetStateAction<boolean>>
}

export const CustomerDetailCard = ({
  customer,
  defaultAddress,
  setShowModal,
  setShowModalAddresses,
}: CustomerDetailCardProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenEditCustomerInfo = () => {
    setShowModal(true)
    setAnchorEl(null)
  }

  const handleOpenManageAddresses = () => {
    setShowModalAddresses(true)
    setAnchorEl(null)
  }

  return (
    <Paper elevation={10} sx={{ p: 2, position: 'relative', borderRadius: '15px' }}>
      <IconButton
        size='medium'
        aria-label='display more actions'
        edge='end'
        color='inherit'
        sx={{ position: 'absolute', right: 10, top: 10 }}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleOpenEditCustomerInfo}>Edit Contact</MenuItem>
        <MenuItem onClick={handleOpenManageAddresses}>Manage Addresses</MenuItem>
      </Menu>
      <Typography variant={'h5'}>Customer</Typography>
      <Typography variant={'h6'} pb={1} pt={1}>
        Contact Information
      </Typography>
      <Typography variant={'body1'}>
        <Link href={`mailto:${customer.email}`}>{customer.email}</Link>
      </Typography>
      <Typography variant={'body1'}>
        <Link href={`tel:${customer.phone}`}>{customer.phone}</Link>
      </Typography>
      <Typography variant={'body1'}>
        <Link target='_blank' href={customer?.website?.value} color='primary' rel='noopener'>
          {customer?.website?.value}
        </Link>
      </Typography>
      <Typography variant={'h6'} pb={1} pt={1}>
        Default Address
      </Typography>
      <Typography variant={'body1'}>
        {customer.firstName} {customer.lastName}
      </Typography>
      <Typography variant={'body1'}>{defaultAddress?.company}</Typography>
      <Typography variant={'body1'}>{defaultAddress?.address1}</Typography>
      {defaultAddress?.address2 && <Typography variant={'body1'}>{defaultAddress?.address2}</Typography>}
      <Typography variant={'body1'}>
        {defaultAddress?.city} {defaultAddress?.provinceCode} {defaultAddress?.zip}
      </Typography>
      <Typography variant={'body1'}>{defaultAddress?.countryCodeV2}</Typography>
      <Typography variant={'body1'}>{defaultAddress?.phone}</Typography>
    </Paper>
  )
}
