import { gql } from '@apollo/client'

export const UPSERT_TEMPLATE = gql`
  mutation upsertDesignerTemplate(
    $data: DesignerTemplateUpdateInput!
  ) {
    upsertDesignerTemplate(data: $data) {
      id
    }
  }
`
