import { gql } from '@apollo/client'

export const UPDATE_EXTERNAL_STORE_CONFIGURATION = gql`
  mutation updateExternalStoreConfiguration(
    $where: ExternalStoreConfigurationExtendedWhereUniqueInput!
    $data: ExternalStoreConfigurationUpdateInput
  ) {
    updateExternalStoreConfiguration(where: $where, data: $data) {
      id
    }
  }
`
