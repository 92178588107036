import { gql } from '@apollo/client'

export const CREATE_SHOPIFY_STORE_CONFIGURATION = gql`
  mutation createShopifyStoreConfiguration($data: ShopifyStoreConfigurationCreateInput!) {
    createShopifyStoreConfiguration(data: $data) {
      id
      name
    }
  }
`
