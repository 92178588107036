import { gql } from '@refinedev/graphql'

export const fragment = gql`
  fragment skuListingFragment on SkuListing {
    id
    title
    description

    images
    imagePresignedUrls {
      key
      url
    }

    wholesaleAvailable
    wholesalePricing
    wholesaleMinQuantity
    wholesaleMaxQuantity
    wholesaleBoxQuantity
    wholesaleCustomerGroups {
      id
      name
    }

    retailAvailable
    retailPricing

    patientAvailable
    patientPricing

    whiteLabelAvailable
    whiteLabelPricing
    whiteLabelMinQuantity
    whiteLabelMaxQuantity
    whiteLabelBoxQuantity

    whiteLabelCustomerGroups {
      id
      name
    }

    shopifyStoreConfigurationId
    shopifyStoreConfiguration {
      id
      name
    }

    skuId
    sku {
      id
      sku
      skuWhiteLabel
    }
  }
`
