import { Controller } from 'react-hook-form'

interface ComponentProps {
  name: string
  control: any
  type: string
}

export function Input(props: ComponentProps) {
  const { name, control, type } = props
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return <input type={type} {...field} placeholder={name} />
      }}
    />
  )
}
