import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { Card, CardActionArea, CardContent } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard'
import SummarizeIcon from '@mui/icons-material/Summarize'
import LabelIcon from '@mui/icons-material/Label'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import _ from 'lodash'
import { useRecoilValueLoadable } from 'recoil'
import { currentUser } from 'store/user.ts'
import { ROLES } from 'lib/rbac.tsx'

const Item = styled(Paper)(() => ({
  backgroundColor: '#28609c',
  padding: 8,
  textAlign: 'center',
  color: 'black',
  width: 'fit-content',
}))

export const AppsDashboard = () => {
  const navigate = useNavigate()
  const user = useRecoilValueLoadable(currentUser)

  const role = user?.contents?.role
  // console.log('user', { user })
  if (user.state !== 'hasValue') return <></>
  if (role === undefined) return <></>
  const apps = [
    {
      url: '/vendor',
      beta: false,
      name: 'Vendor Portal',
      tagLine: 'Tools to manage your brand',
      icon: <DashboardIcon sx={{ fontSize: 60 }} />,
    },
    ...(role === ROLES.ADMIN
      ? [
          {
            url: '/operations',
            beta: false,
            name: 'Operations',
            tagLine: 'Manage orders and private labels',
            icon: <LabelIcon sx={{ fontSize: 60 }} />,
          },
          {
            url: '/reports',
            beta: false,
            name: 'Reports',
            tagLine: 'Analyze orders and financials',
            icon: <SummarizeIcon sx={{ fontSize: 60 }} />,
          },
          // {
          //   url: '/barcode',
          //   beta: false,
          //   name: 'Barcodes',
          //   tagLine: 'Monitor barcode allocation for private label products',
          //   icon: <QrCode sx={{ fontSize: 60 }} />,
          // },
          // {
          //   url: '/labels',
          //   beta: false,
          //   name: 'Private Labels',
          //   tagLine: 'View and print private labels',
          //   icon: <LabelIcon sx={{ fontSize: 60 }} />,
          // }
        ]
      : [{}]),
  ].filter((x) => !_.isEmpty(x))

  return (
    <Box sx={{ marginTop: '50px' }}>
      <Stack
        direction='row'
        flexWrap='wrap'
        gap={2}
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        {apps
          .filter((app) => !app.beta)
          .map((app) => {
            return (
              <Item key={app.name}>
                <Card
                  sx={{ minHeight: 190, minWidth: 280, maxWidth: 280, padding: 1 }}
                  onClick={() => navigate(app.url!)}
                >
                  <CardActionArea>
                    {app.icon}
                    <CardContent>
                      <Typography gutterBottom variant='h5' component='div'>
                        {app.name}
                      </Typography>
                      <Typography variant='body2' color='text.secondary'>
                        {app.tagLine}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Item>
            )
          })}
      </Stack>
    </Box>
  )
}
