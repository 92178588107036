import { gql } from '@apollo/client'

export const UPDATE_LABEL_APPROVAL_WORKFLOW_STEP = gql`
  mutation updateLabelApprovalWorkflowStep(
    $where: LabelApprovalWorkflowStepWhereUniqueInput!
    $data: LabelApprovalWorkflowStepUpdateInput
  ) {
    updateLabelApprovalWorkflowStep(where: $where, data: $data) {
      id
      name
      notes
      status
      workflow {
        id
        name
        status
        orderId
        metadata
      }
    }
  }
`
