import { useQuery } from '@apollo/client'
import { Box, Button, Checkbox, FormControlLabel, Tooltip, Typography } from '@mui/material'
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium'
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Order, OrderLabel } from 'lib/types.ts'
import { LIST_ORDERS } from 'lib/graphql/listOrders.ts'
import { COUNT_ORDERS } from 'lib/graphql/countOrders.ts'
import _ from 'lodash'
import { LIST_SHOPIFY_STORE_CONFIGURATIONS } from 'lib/graphql/listShopifyStoreConfigurations.ts'
import { LIST_EXTERNAL_STORE_CONFIGURATIONS } from 'lib/graphql/listExternalStoreConfigurations.ts'
import { ExternalStoreConfiguration, ShopifyStoreConfiguration } from 'lib/graphql/types.ts'
import { OrderCard } from 'pages/OperationsApp/OrderCard.tsx'
import { renderLabelIcon, renderLabelIconWithTooltip } from 'pages/OperationsApp/utils.tsx'

function renderLabelStatus(v: any) {
  if (_.isEmpty(v)) return
  return v.map((label: OrderLabel, index: number) => {
    return renderLabelIconWithTooltip({ label, index })
  })
}

function formatDateTime(v: Date) {
  return v ? dayjs(v).format('MMM D, YYYY h:mm A') : ''
}

type EditToolbarProps = {
  handleAdd: () => void
  handleEdit: () => void
  handleDelete: () => void
  enableEditButton: boolean
  enableDeleteButton: boolean
}

const PAGE_SIZE = 25

export const Orders = () => {
  const [orderLabels, setOrderLabels] = useState<OrderLabel[]>([])
  const [countOrderLabels, setCountOrderLabels] = useState<number>(0)
  const [skip, setSkip] = useState(0)
  const [selectedLabel, setSelectedLabel] = useState<string>()
  const [rowSelectionModel, setRowSelectionModel] = useState<string[]>()
  const [orderData, setOrderData] = useState<any>()

  const [showPrivateLabelsOnly, setShowPrivateLabelsOnly] = useState<boolean>(true)
  const [showUnprintedOrdersOnly, setShowUnprintedOrdersOnly] = useState<boolean>(true)

  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])

  const { data: dataCountOrders, refetch: refetchCount } = useQuery(COUNT_ORDERS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        orderLabels: showUnprintedOrdersOnly
          ? {
              some: {
                printed: { equals: false },
              },
            }
          : showPrivateLabelsOnly
          ? { some: {} }
          : {},
      },
    },
  })

  const {
    data: dataShopifyStores,
    loading: loadingShopifyStores,
    error: errorShopifyStores,
    refetch: refetchShopifyStores,
    fetchMore: fetchMoreShopifyStores,
  } = useQuery(LIST_SHOPIFY_STORE_CONFIGURATIONS, {
    variables: {
      where: {
        // organizations: {
        //   some: {
        //     organizationId: { equals: organizationIdVar },
        //   },
        // },
      },
      limit: 100,
    },
  })

  const {
    data: dataExternalStores,
    loading: loadingExternalStores,
    error: errorExternalStores,
    refetch: refetchExternalStores,
    fetchMore: fetchMoreExternalStores,
  } = useQuery(LIST_EXTERNAL_STORE_CONFIGURATIONS, {
    variables: {
      where: {
        // organizations: {
        //   some: {
        //     organizationId: { equals: organizationIdVar },
        //   },
        // },
      },
      limit: 100,
    },
  })

  const {
    data: dataOrders,
    loading: loadingOrders,
    error: errorOrders,
    refetch: refetchOrders,
    fetchMore,
  } = useQuery(LIST_ORDERS, {
    variables: {
      where: {
        orderLabels: showUnprintedOrdersOnly
          ? {
              some: {
                printed: { equals: false },
              },
            }
          : showPrivateLabelsOnly
          ? { some: {} }
          : {},
      },
      orderBy: [{ createdAt: 'DESC' }],
      limit: PAGE_SIZE,
    },
    skip: !dataCountOrders,
  })

  useEffect(() => {
    refetchCount()
    refetchOrders()
  }, [showPrivateLabelsOnly, showUnprintedOrdersOnly])

  const handleOnRowsScrollEnd = async () => {
    if (dataOrders?.listOrders?.length < dataCountOrders?.countOrders) {
      await fetchMore({
        variables: { offset: dataOrders?.listOrders.length, limit: PAGE_SIZE },
      })
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'commonId',
      headerName: 'Order',
      width: 90,
    },
    {
      field: 'organizationName',
      headerName: 'Organization',
      flex: 1,
      valueGetter: (params) => {
        return params.row.organization?.name
      },
    },
    {
      field: 'storeName',
      headerName: 'Store',
      flex: 1,
      valueGetter: (params) => {
        return (
          dataShopifyStores?.listShopifyStoreConfigurations?.find(
            (x: ShopifyStoreConfiguration) => x.id === params.row.storeId
          )?.name ||
          dataExternalStores?.listExternalStoreConfigurations?.find(
            (x: ExternalStoreConfiguration) => x.id === params.row.storeId
          )?.name
        )
      },
    },
    {
      field: 'labels',
      headerName: 'Labels',
      flex: 1,
      valueGetter: (params) => {
        return dataOrders?.orderLabels || []
      },
      renderCell: (v) => {
        return renderLabelStatus(v.row.orderLabels)
      },
    },
    {
      field: 'createdAt',
      headerName: 'Ordered On',
      width: 180,
      renderCell: (v) => formatDateTime(v.value),
    },
  ]

  return (
    <>
      <Box sx={{ paddingBottom: 2 }}>
        <Typography variant={'h5'}>Orders Received for Fulfillment</Typography>
      </Box>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={showPrivateLabelsOnly}
              onClick={() => {
                showPrivateLabelsOnly ? setShowUnprintedOrdersOnly(false) : null
                setShowPrivateLabelsOnly(!showPrivateLabelsOnly)
              }}
            />
          }
          label='Show only private label orders'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showUnprintedOrdersOnly}
              onClick={() => {
                !showUnprintedOrdersOnly && setShowPrivateLabelsOnly(true)
                setShowUnprintedOrdersOnly(!showUnprintedOrdersOnly)
              }}
            />
          }
          label='Show only unprinted orders'
        />
      </Box>
      <Box sx={{ display: 'grid', gridTemplateRows: 'auto 1f auto', height: 360 }}>
        <DataGridPremium
          // checkboxSelection
          rows={dataOrders?.listOrders || []}
          rowCount={dataCountOrders?.countOrders}
          columns={columns}
          density={'compact'}
          hideFooterPagination
          scrollEndThreshold={150}
          onRowsScrollEnd={handleOnRowsScrollEnd}
          loading={loadingOrders || loadingShopifyStores || loadingExternalStores}
          // slots={{
          //   loadingOverlay: LinearProgress,
          //   toolbar: CreateToolbar,
          // }}
          // slotProps={{
          //   toolbar: {
          //     enableEditButton: !!focusedUser,
          //     enableDeleteButton: !!focusedUser,
          //     handleAdd: () => {
          //       setMode('create')
          //       setShowModal(true)
          //     },
          //     handleDelete: () => {
          //       focusedUser &&
          //       deleteUser({
          //         variables: {
          //           where: {
          //             id: focusedUser.id,
          //             organizations: {
          //               every: {
          //                 organizationId: { equals: organizationIdVar },
          //               },
          //             },
          //           },
          //         },
          //       })
          //     },
          //     // handleEdit: () => {
          //     //   setMode('update')
          //     //   focusedUser && setShowModal(true)
          //     // },
          //   },
          // }}
          disableMultipleRowSelection={true}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={async (newRowSelectionModel) => {
            if (_.isEmpty(newRowSelectionModel)) {
              setRowSelectionModel(undefined)
              setOrderData(undefined)
              return
            }
            setRowSelectionModel(newRowSelectionModel as string[])
          }}
        />
      </Box>

      {!_.isEmpty(rowSelectionModel) && (
        <OrderCard
          orderMetadata={dataOrders?.listOrders?.find((order: Order) => order.id === rowSelectionModel![0])}
          orderId={rowSelectionModel![0] as string}
        />
      )}
    </>
  )
}
