import { gql } from '@apollo/client'

export const LIST_LOTS = gql`
  query listLots($where: LotWhereInput, $take: Int, $skip: Int) {
    listLots(
      where: $where
      take: $take
      skip: $skip
      orderBy: [{ expirationDate: DESC }]
    ) {
      id
      supplementFactMajorUuid
      lotNumber
      expirationDate
      sku {
        sku
        skuFact {
          privateLabelName
        }
      }
      createdAt
    }
  }
`
