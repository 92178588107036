import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useQuery } from '@apollo/client'

import { TEMPLATES_LIST } from 'lib/graphql/listTemplates.ts'
import { COUNT_TEMPLATES } from 'lib/graphql/countTemplates.ts'

type Props = {
  brandId?: string
  onLoading: (v: boolean) => void
  onData: (v: any) => void
}

export type GetAllDesignerTemplatesRef = {
  refresh: () => void
}

const PAGE_SIZE = 50

export const GetAllDesignerTemplates = forwardRef<GetAllDesignerTemplatesRef, Props>(
  ({ brandId, onLoading, onData }, ref) => {
    const [count, setCount] = useState<number | undefined>(undefined)
    const [data, setData] = useState<any>()

    const {
      refetch: refetchCount,
      loading: loadingCount,
      // error: errorCount,
    } = useQuery(COUNT_TEMPLATES, {
      fetchPolicy: 'no-cache',
      variables: {
        where: {
          sku: {
            brandId: { equals: brandId },
          },
          archivedAt: null,
        },
      },
      onCompleted: (data) => {
        setCount(data.countDesignerTemplates)
      },
      skip: !brandId,
    })

    const { refetch, loading, error, fetchMore } = useQuery(TEMPLATES_LIST, {
      variables: {
        where: {
          sku: {
            brandId: { equals: brandId },
          },
          archivedAt: null,
        },
        limit: PAGE_SIZE,
      },
      onCompleted: (data) => {
        setData(data?.listDesignerTemplates)
      },
      skip: !brandId || !count,
    })

    useEffect(() => {
      if (brandId && count && data?.length > 0 && data?.length < count) {
        fetchMore({
          variables: {
            offset: data?.length || 0,
            limit: PAGE_SIZE,
          },
        })
      }
    }, [count, brandId, data?.length])

    useEffect(() => {
      if (loading || loadingCount || (count !== undefined && data?.length < count)) {
        onLoading(true)
      } else {
        onLoading(false)
      }
    }, [loading, loadingCount, data?.length])

    useEffect(() => {
      if (count !== undefined && count === data?.length) {
        onData(data)
      }
    }, [count, data?.length])

    useImperativeHandle(ref, () => ({
      refresh: async () => {
        await refetchCount()
        await refetch()
      },
    }))

    return <></>
  }
)
