import { Brand, Sku } from '@/lib/graphql/types'
import { useQuery } from '@apollo/client'
import { CircularProgress, MenuItem, Select, SelectProps } from '@mui/material'
import { BRANDS_LIST } from 'lib/graphql/listBrands'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'

type BrandSelectProps = Partial<SelectProps<string>> & {
  organizationId: string
  control: any
}

export const BrandSelect = ({
  organizationId,
  defaultValue,
  name,
  label,
  control,
  disabled,
}: BrandSelectProps) => {
  const [defaultVal, setDefaultVal] = useState<string | undefined>()

  const { data, loading, error } = useQuery(BRANDS_LIST, {
    variables: {
      take: 100,
      skip: 0,
      where: {
        organizationId: {
          equals: organizationId,
        },
        archivedAt: null,
      },
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (defaultVal === undefined) {
      setDefaultVal(defaultValue || '')
    }
  }, [defaultValue])

  if (loading) {
    return <CircularProgress />
  }
  if (error) {
    return <>Error</>
  }
  return (
    <Controller
      name={name || ''}
      control={control}
      disabled={disabled}
      render={({ field: { onChange, value, disabled }, fieldState: { error } }) => (
        <Select
          label={label}
          name={name}
          fullWidth
          defaultValue={defaultVal}
          variant='standard'
          value={value}
          onChange={onChange}
          sx={{ marginTop: '22px' }}
        >
          {(data.listBrands || []).map((brand: Brand) => {
            return (
              <MenuItem key={brand.id} value={brand.id}>
                {brand.name}
              </MenuItem>
            )
          })}
        </Select>
      )}
    />
  )
}
