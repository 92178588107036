import React from 'react'
import SellIcon from '@mui/icons-material/Sell'
import ListAltIcon from '@mui/icons-material/ListAlt'
import ArtTrackIcon from '@mui/icons-material/ArtTrack'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import CableIcon from '@mui/icons-material/Cable'
import PrintIcon from '@mui/icons-material/Print'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PersonIcon from '@mui/icons-material/Person'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import PaymentsIcon from '@mui/icons-material/Payments'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { DrawerLayout, MenuType } from '@/layouts/DrawerLayout.tsx'

export const VendorAppLayout = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
  const menu: MenuType = [
    {
      collapsable: false,
      requiresOrganization: true,
      children: [
        {
          title: 'Brands',
          url: '/vendor/brands',
          icon: <SellIcon />,
        },
        {
          title: 'Catalog',
          url: '/vendor/catalog',
          icon: <MenuBookIcon />,
        },
        {
          title: 'Users',
          url: '/vendor/users',
          icon: <PersonIcon />,
        },
        {
          title: 'Customer Accounts',
          url: '/vendor/customer-accounts',
          icon: <SwitchAccountIcon />,
        },
      ],
    },
    {
      divider: true,
      title: 'Reports',
      collapsable: false,
      requiresOrganization: true,
      children: [
        {
          title: 'Orders',
          url: '/vendor/reports/orders',
          icon: <ListAltIcon />,
        },
        {
          title: 'Billing',
          url: '/vendor/reports/billing',
          icon: <PaymentsIcon />,
        },
        {
          title: 'Payments',
          url: '/vendor/reports/payments',
          icon: <MonetizationOnIcon />,
        },
      ],
    },
    {
      divider: true,
      title: 'Private Label',
      collapsable: false,
      requiresOrganization: true,
      children: [
        {
          title: 'Templates',
          url: '/vendor/templates',
          icon: <ArtTrackIcon />,
        },
        {
          title: 'Label Facts',
          url: '/vendor/label-facts',
          icon: <ListAltIcon />,
        },
        {
          title: 'Label Approval',
          url: '/vendor/label-approval',
          icon: <DashboardIcon />,
        },
      ],
    },
    {
      divider: true,
      title: 'Fulfillment',
      requiresOrganization: true,
      collapsable: true,
      children: [
        {
          title: 'Connect',
          url: '/vendor/connect',
          icon: <CableIcon />,
        },
        {
          title: 'Ordered Labels',
          url: '/vendor/labelops',
          icon: <PrintIcon />,
        },
        {
          title: 'Clients',
          url: '/vendor/fulfillment-clients',
          icon: <WarehouseIcon />,
        },
      ],
    },
    {
      title: 'Stores',
      requiresOrganization: true,
      requiresStores: true,
      divider: true,
    },
  ]

  return (
    <DrawerLayout showOrgSelector={true} menu={menu}>
      {children}
    </DrawerLayout>
  )
}
