import { useState } from 'react'
import _ from 'lodash'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import dayjs from 'dayjs'
import {
  GridColDef,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro'
import { useQuery, useReactiveVar } from '@apollo/client'
import { Box, Button, Dialog, Paper, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'

import { organizationId } from 'config/cache'
import { BrandAccountCreateOrUpdateDialog } from '@/components/Dialogs/BrandAccountCreateOrUpdateDialog'
import { LIST_BRAND_ACCOUNTS } from '@/lib/graphql/listBrandAccounts'
import { BrandAccount } from '@/lib/graphql/types'
import { COUNT_BRAND_ACCOUNTS } from '@/lib/graphql/countBrandAccounts'

function formatDateTime(v: Date, removeTime: boolean) {
  return v ? dayjs(v).format(removeTime ? 'M/D/YYYY' : 'M/D/YYYY h:mm A') : ''
}

function renderYesNo(v: any) {
  return v.value ? <CheckIcon sx={{ color: 'green' }} /> : <DoNotDisturbIcon sx={{ color: '#f5c3c3' }} />
}


const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Customer Account',
    flex: 1,
    minWidth: 120,
    sortable: false,
    filterable: false,
    align: 'left',
    headerAlign: 'left',
  },
  { field: 'accountManagerName', headerName: 'Account Manager', minWidth: 110, flex: 1 },
  {
    field: 'city',
    headerName: 'City',
    flex: 0.7,
    minWidth: 60,
    sortable: false,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'state',
    headerName: 'State',
    flex: 0.7,
    minWidth: 60,
    sortable: false,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'sellsInCalifornia',
    headerName: 'Sells in California',
    flex: 1,
    minWidth: 60,
    sortable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (v) => renderYesNo(v),
  },
  {
    field: 'prop65Waiver',
    headerName: 'Prop 65 Waiver',
    flex: 1,
    minWidth: 60,
    sortable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (v) => renderYesNo(v),
  },
  {
    field: 'numberOfCustomers',
    headerName: '# Customers',
    valueGetter: (v) => v?.row?.customers?.length,
    flex: 0.7,
    minWidth: 60,
    sortable: false,
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    flex: 1,
    renderCell: (v) => formatDateTime(v.value, true),
    filterable: false,
    align: 'right',
    headerAlign: 'right',
  },
]

type EditToolbarProps = {
  handleAdd: () => void
  handleEdit: () => void
  handleDelete: () => void
  enableEditButton: boolean
  enableDeleteButton: boolean
}

const CreateToolbar = ({
  handleAdd,
  handleDelete,
  handleEdit,
  enableDeleteButton,
  enableEditButton,
}: EditToolbarProps) => {
  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<AddIcon />} onClick={handleAdd}>
        New
      </Button>
      <Button color='primary' startIcon={<EditIcon />} onClick={handleEdit} disabled={!enableEditButton}>
        Edit
      </Button>
      <Button
        color='primary'
        startIcon={<DeleteIcon />}
        onClick={handleDelete}
        disabled={!enableDeleteButton}
      >
        Delete
      </Button>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  )
}

const PAGE_SIZE = 50

export const ManageAccounts = () => {
  const currentOrganizationId = useReactiveVar(organizationId)

  const [focusedAccount, setFocusedAccount] = useState<BrandAccount | undefined>(undefined)
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])

  const [showModal, setShowModal] = useState(false)
  const [mode, setMode] = useState<'create' | 'update'>('create')

  function closeDialog() {
    setShowModal(false)
  }

  const { data: dataCount, loading: loadingCount } = useQuery(COUNT_BRAND_ACCOUNTS, {
    variables: {
      where: {
        organizationId: {
          equals: currentOrganizationId,
        },
      },
    },
  })

  const { data, loading, fetchMore } = useQuery(LIST_BRAND_ACCOUNTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        organizationId: {
          equals: currentOrganizationId,
        },
      },
      take: PAGE_SIZE,
    },
  })

  const dataCountBrandAcconts = dataCount?.countBrandAccounts || 0
  const accounts = data?.listBrandAccounts || []

  const handleOnRowsScrollEnd = async () => {
    if (accounts.length < dataCountBrandAcconts) {
      await fetchMore({
        variables: { skip: accounts.length, limit: PAGE_SIZE },
      })
    }
  }

  if (!currentOrganizationId) return <></>

  return (
    <>
      <Box sx={{ paddingBottom: 2 }}>
        <Typography variant={'h5'}>Customer Accounts</Typography>
      </Box>
      <Dialog fullWidth={true} maxWidth='md' open={showModal} onClose={() => closeDialog()}>
        <BrandAccountCreateOrUpdateDialog
          mode={mode}
          data={focusedAccount}
          closeDialog={() => {
            closeDialog();
            setFocusedAccount(undefined)
          }}
        />
      </Dialog>

      <Box sx={{ height: '500px' }}>
        <Paper
          elevation={5}
          sx={{
            height: 'inherit',
            display: 'grid',
            gridTemplateRows: 'auto 1f auto',
            borderRadius: '16px',
            p: 1,
          }}
        >
          <DataGridPremium
            sx={{
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderTopWidth: 0,
            }}
            density={'compact'}
            rows={accounts}
            columns={columns}
            disableMultipleRowSelection={true}
            hideFooterPagination
            rowCount={dataCountBrandAcconts}
            scrollEndThreshold={100}
            onRowsScrollEnd={handleOnRowsScrollEnd}
            disableMultipleColumnsSorting
            loading={loading || loadingCount}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel)
              if (_.size(newRowSelectionModel) === 1) {
                const data = (accounts || []).find((r: any) => r.id === newRowSelectionModel[0])
                if (focusedAccount?.id !== data?.id) {
                  setFocusedAccount(data)
                }
              } else {
                setFocusedAccount(undefined)
              }
            }}
            slots={{
              toolbar: CreateToolbar,
            }}
            slotProps={{
              toolbar: {
                enableEditButton: !!focusedAccount,
                handleAdd: () => {
                  setMode('create')
                  setShowModal(true)
                },
                handleEdit: () => {
                  setMode('update')
                  setShowModal(true)
                },
              },
            }}
          />
        </Paper>
      </Box>
    </>
  )
}
