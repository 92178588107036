import { useState } from 'react'
import { useMutation } from '@apollo/client'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  Dialog,
  Typography,
  Grid,
  Backdrop,
  CircularProgress,
  Paper,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material'
import { ShopifyAddress, ShopifyCustomer } from 'lib/types.ts'
import { PROXY_SHOPIFY_MUTATION } from 'lib/graphql/proxyShopifyMutation.js'
import { UpdateAddressDialog } from './UpdateAddressDialog'

interface AddressComponentProps {
  isDefaultAddress: boolean
  address: ShopifyAddress
  storeId: string
  customer: ShopifyCustomer & { metafields: any; original: any; approved: boolean }
  refetchCustomers: () => any
}

const AddressComponent = ({
  customer,
  address,
  storeId,
  refetchCustomers,
  isDefaultAddress,
}: AddressComponentProps) => {
  const [showModal, setShowModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const [isOpen, setisOpen] = useState(false)

  const [updateCustomerDefaultAddress] = useMutation(PROXY_SHOPIFY_MUTATION, {
    async onCompleted() {
      await refetchCustomers()
      setisOpen(false)
    },
    onError(error) {
      setisOpen(false)
      // setSubmitError(error.message)
      // setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
    },
  })

  const handleUpdateDefaultAddress = async () => {
    setAnchorEl(null)
    setisOpen(true)
    await updateCustomerDefaultAddress({
      variables: {
        mutation: 'customerUpdateDefaultAddress',
        shopifyStoreId: storeId,
        variables: JSON.stringify({
          customerId: customer.id,
          addressId: address.id,
        }),
      },
    })
  }

  const closeDialog = () => {
    setShowModal(false)
  }

  const handleOpenUpdateModal = () => {
    setShowModal(true);
    handleClose();
  }

  return (
    <Grid item xs={12} md={6}>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isOpen}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Paper sx={{ border: '1px solid black', padding: '10px', position: 'relative' }}>
        <IconButton
          size='medium'
          aria-label='display more actions'
          edge='end'
          color='inherit'
          sx={{ position: 'absolute', right: 10, top: 0 }}
          onClick={handleClick}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleOpenUpdateModal}>Edit</MenuItem>
          {!isDefaultAddress && <MenuItem onClick={handleUpdateDefaultAddress}>Set as Default</MenuItem>}
        </Menu>
        <>
          <Typography variant={'body2'} fontWeight={500} visibility={isDefaultAddress ? 'visible' : 'hidden'}>
            Default Address
          </Typography>
          <Typography variant={'body1'}>{address.company}</Typography>
          <Typography variant={'body1'}>{address.address1}</Typography>
          <Typography variant={'body1'}>{address.address2}</Typography>
          <Typography variant={'body1'}>
            {address.city}, {address.provinceCode} {address.zip}
          </Typography>
          <Typography visibility={address.phone ? 'visible' : 'hidden'} variant={'body1'}>{address.phone || "a"}</Typography>
        </>
      </Paper>
      <Dialog fullWidth={true} maxWidth='md' open={showModal} onClose={() => closeDialog()}>
        <UpdateAddressDialog
          mode="update"
          address={address}
          customer={customer}
          closeDialog={() => closeDialog()}
          storeId={storeId}
          onSuccess={async () => {
            await refetchCustomers()
          }}
        />
      </Dialog>
    </Grid>
  )
}

export default AddressComponent
