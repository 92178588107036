import { gql } from '@apollo/client'

export const UPDATE_WEBHOOK_REGISTRATION = gql`
  mutation updateWebhookRegistration(
    $where: WebhookRegistrationExtendedWhereUniqueInput!
    $data: WebhookRegistrationUpdateInput
  ) {
    updateWebhookRegistration(where: $where, data: $data) {
      id
    }
  }
`
