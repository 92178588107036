import { gql } from '@apollo/client'

export const LIST_STORE_CONFIGURATION_FULFILLMENTS = gql`
  query listStoreConfigurationFulfillments(
    $where: StoreConfigurationFulfillmentWhereInput
    $orderBy: [StoreConfigurationFulfillmentOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    listStoreConfigurationFulfillments(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      organization {
        id
        name
      }
      description
      storeType
      storeConfigurationId
      fulfillmentType
      fulfillmentConfigurationId
    }
  }
`
