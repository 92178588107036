import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { OrganizationManager } from 'pages/AdminSettings/OrganizationManager.tsx'
import { ShopifyStoreManager } from 'pages/AdminSettings/ShopifyStoreManager.tsx'

export const AdminSettings = () => {
  const [organizationId, setOrganizationId] = useState<string>()

  return (
    <>
      <Box sx={{ paddingBottom: 2 }}>
        <Typography variant={'h5'}>Admin Settings</Typography>
      </Box>
      <Box
        sx={{
          height: 'calc(100vh - 240px)',
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: '16px',
        }}
      >
        <OrganizationManager onSelected={(data) => setOrganizationId(data)} />
        <ShopifyStoreManager organizationId={organizationId} />
      </Box>
    </>
  )
}
