import React, { useState, useCallback, useMemo, useEffect } from 'react'
import {
  DataGridPro,
  GridRenderCellParams,
  useGridApiRef,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridColDef,
  GridRowId,
  GridEventListener,
} from '@mui/x-data-grid-pro'
import { Link, useNavigate } from 'react-router-dom'
import { Typography, Button, Box } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

import LotList from 'pages/ProductsTable/LotList.tsx'
import Divider from '@mui/material/Divider'

const urlPathBase = `/vendor/label-facts`

declare module '@mui/x-data-grid-pro' {
  interface FooterPropsOverrides {
    client: string
    sku: string
  }
}

type Status = 'APPROVED' | 'DRAFT'

const isApproved = (status: Status) => status === 'APPROVED'

const FooterComponent = ({ organizationId, skuId }: { organizationId: string; skuId: string }) => {
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        p: 1,
        display: 'flex',
        borderTop: '1px solid #e0e0e0',
        paddingBottom: '10px',
        borderBottom: '1px solid #e0e0e0',
      }}
    >
      <Button
        variant='outlined'
        color='primary'
        onClick={() => {
          navigate(`${urlPathBase}/create/${organizationId}/${skuId}`)
        }}
      >
        + CREATE NEW VERSION
      </Button>
      {/*<Button variant='text' color='primary' disabled={true}>*/}
      {/*  View Older Versions*/}
      {/*</Button>*/}
    </Box>
  )
}

export const LabelFactsProductTable = ({
  privateLabelName,
  productName,
  rows,
  product,
  productId,
  organizationId,
}: {
  privateLabelName: any
  productName: string
  productId: string
  rows: any
  product: string
  organizationId: string
}) => {
  const apiRef = useGridApiRef()
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([])

  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds: GridRowId[]) => {
    setDetailPanelExpandedRowIds(newIds)
  }, [])

  useEffect(() => {
    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
      apiRef.current.toggleDetailPanel(params.row.id)
    }

    return apiRef.current.subscribeEvent('rowClick', handleRowClick)
  }, [apiRef])

  const columns = useMemo(
    () => [
      {
        field: 'majorVersion',
        headerName: 'Major Version',
        headerClassName: 'mui-grid-header--mobile',
        minWidth: 130,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Link to={`${urlPathBase}/${organizationId}/${productId}/${params.value}`}>
              <Typography fontWeight={700} color='primary'>
                Version {params.value}
              </Typography>
            </Link>
          )
        },
      },
      {
        field: 'lots',
        headerName: 'Lots',
        flex: 1,
        headerClassName: 'mui-grid-header--mobile',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box sx={{ display: 'flex' }}>
              <Typography color='#001D35' fontWeight={600}>
                {/*{params.value}*/}
              </Typography>
              {detailPanelExpandedRowIds.indexOf(params.row.id) !== -1 ? (
                <ExpandLess sx={{ color: '#0062A0' }} />
              ) : (
                <ExpandMore sx={{ color: '#0062A0' }} />
              )}
            </Box>
          )
        },
      },
      {
        field: 'date',
        headerName: 'Date',
        width: 90,
        flex: 1,
        headerClassName: 'mui-grid-header--mobile',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Typography color='#001D35' fontWeight={600}>
              {params.value}
            </Typography>
          )
        },
      },
    ],
    [detailPanelExpandedRowIds, organizationId, product]
  )

  const getTogglableColumns = (columns: GridColDef[]) => {
    // Material creates a column for the toggle and we need to hide it
    return columns
      .filter((column) => column.field !== GRID_DETAIL_PANEL_TOGGLE_FIELD)
      .map((column) => column.field)
  }

  const majorVersions = rows.map((row: any) => ({
    version: row.majorVersion,
    id: row.majorVersionUuid,
    status: row.status,
  }))

  return (
    <>
      <Box
        sx={{
          padding: '5px 10px 0px 10px',
        }}
      >
        <Typography color={'#0062A0'}>
          Private Label Default Name: <b>{privateLabelName}</b>
        </Typography>
      </Box>
      <DataGridPro
        autoHeight
        apiRef={apiRef}
        getRowHeight={() => 'auto'}
        rows={rows}
        columns={columns}
        sx={{
          borderStyle: 'none',
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
            },
          },
        }}
        getDetailPanelContent={({ row }) => (
          <Box sx={{ background: '#f9f9f9' }}>
            <LotList
              product={product}
              skuId={productId}
              majorVersionUuid={row.majorVersionUuid}
              majorVersions={majorVersions}
              canCreate={row.canCreateLot}
            />
            <Divider />
          </Box>
        )}
        getDetailPanelHeight={() => 'auto'}
        disableRowSelectionOnClick
        hideFooterPagination
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
        slots={{
          footer: FooterComponent,
        }}
        slotProps={{
          columnsPanel: {
            getTogglableColumns,
          },
          // @ts-ignore
          footer: { organizationId, skuId: productId },
        }}
      />
    </>
  )
}
