import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js'
import * as Sentry from '@sentry/react'
import { resetVars } from 'config/cache.ts'

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export enum AuthChallengeType {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  MFA_SETUP = 'MFA_SETUP',
  MFA_CODE = 'MFA_CODE',
}

export class AuthChallengeError extends Error {
  readonly challengeType: AuthChallengeType
  readonly respondToChallenge: (arg1?: any, arg2?: any, arg3?: any, arg4?: any) => Promise<any>
  readonly data?: any

  constructor(
    challengeType: AuthChallengeType,
    respondToChallenge: (arg1?: any, arg2?: any, arg3?: any, arg4?: any) => Promise<any>,
    data?: any
  ) {
    super(`Received Auth Challenge of type ${challengeType}`) // (1)
    this.challengeType = challengeType
    this.respondToChallenge = respondToChallenge
    this.data = data
  }
}

export class CognitoOperations {
  readonly userPoolId: string
  readonly clientId: string
  private userPool: CognitoUserPool

  constructor(userPoolId: string, clientId: string) {
    this.userPoolId = userPoolId
    this.clientId = clientId
    this.userPool = new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: clientId,
    })
  }

  getCurrentUser(): CognitoUser | null {
    return this.userPool.getCurrentUser()
  }

  private getCognitoUser(username: string) {
    const userData = {
      Username: username,
      Pool: this.userPool,
    }
    return new CognitoUser(userData)
  }

  async processFederatedSignIn(data: any) {
    const idToken = parseJwt(data.idToken)
    const currentUser = this.getCognitoUser(idToken.email)
    const session = new CognitoUserSession({
      IdToken: new CognitoIdToken({ IdToken: data.idToken }),
      AccessToken: new CognitoAccessToken({ AccessToken: data.accessToken }),
      RefreshToken: new CognitoRefreshToken({ RefreshToken: data.refreshToken }),
    })
    await currentUser.setSignInUserSession(session)
  }

  async refreshSession(): Promise<CognitoUserSession> {
    const currentUser = this.getCurrentUser()

    const session: any = await this.getSession()
    return new Promise(function (resolve, reject) {
      currentUser?.refreshSession(session.refreshToken, function (err: any, session: CognitoUserSession) {
        if (err) {
          reject(err)
        }
        resolve(session)
      })
    })
  }

  async getSession(): Promise<CognitoUserSession | null> {
    const currentUser = this.getCurrentUser()

    return new Promise<CognitoUserSession | null>(function (resolve, reject) {
      if (!currentUser) {
        return resolve(null)
      }
      currentUser.getSession(function (err: any, session: CognitoUserSession | null) {
        if (err) {
          reject(err)
        } else {
          resolve(session)
        }
      })
    })
  }

  // interface UserSignupAttributes {
  //   email: string
  //   password: string
  //   name?: string
  //   province?: string
  //   sector?: string
  //   products: string[]
  // }
  // export async function signUpUserWithEmail(username: string, attributes: UserSignupAttributes) {
  //   return new Promise(function (resolve, reject) {
  //     const attributeList = [
  //       new CognitoUserAttribute({
  //         Name: 'email',
  //         Value: attributes.email || '',
  //       }),
  //       new CognitoUserAttribute({
  //         Name: 'name',
  //         Value: attributes.name || '',
  //       }),
  //       new CognitoUserAttribute({
  //         Name: 'zoneinfo',
  //         Value: DEFAULT_TIMEZONE,
  //       }),
  //       new CognitoUserAttribute({
  //         Name: 'custom:province',
  //         Value: attributes?.province || '',
  //       }),
  //       new CognitoUserAttribute({
  //         Name: 'custom:sector',
  //         Value: attributes?.sector || '',
  //       }),
  //       new CognitoUserAttribute({
  //         Name: 'custom:majorProducts',
  //         Value: JSON.stringify(attributes?.products || {}),
  //       }),
  //     ]
  //
  //     userPool.signUp(username, attributes.password, attributeList, [], function (err, res) {
  //       if (err) {
  //         reject(err)
  //       } else {
  //         resolve(res)
  //       }
  //     })
  //   }).catch((err) => {
  //     throw err
  //   })
  // }

  async getCurrentUserToken(): Promise<string> {
    const currentUser = this.getCurrentUser()

    if (currentUser && 'signInUserSession' in currentUser) {
      const session = currentUser.signInUserSession as CognitoUserSession

      const token = session.getIdToken().getJwtToken()
      return token
    }
    throw new Error('No access token found')
  }

  verifyCode(username: string, code: string) {
    const cognitoUser = this.getCognitoUser(username)
    return new Promise(function (resolve, reject) {
      cognitoUser.confirmRegistration(code, true, function (err: any, result: any) {
        if (err) {
          reject(err)
        } else {
          resolve(result)
        }
      })
    }).catch((err) => {
      throw err
    })
  }

  static getCallbacksForUser(currentUser: CognitoUser, reject: (e: AuthChallengeError) => any) {
    return {
      newPasswordRequired: function (userAttributes: Record<string, string>, _requiredAttributes: any) {
        // User was signed up by an admin and must provide new password to complete authentication.
        // @see: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html#API_InitiateAuth_ResponseElements

        // // the api doesn't accept this field back
        delete userAttributes.email_verified
        delete userAttributes.email

        const respondToChallenge = (newPassword: string) =>
          new Promise((resolve, reject) =>
            currentUser.completeNewPasswordChallenge(newPassword, userAttributes, {
              onSuccess: resolve,
              onFailure: reject,
              ...CognitoOperations.getCallbacksForUser(currentUser, reject),
            })
          )
        reject(new AuthChallengeError(AuthChallengeType.NEW_PASSWORD_REQUIRED, respondToChallenge))
      },
      mfaSetup: async function () {
        const secretCode = await new Promise((resolve, reject) => {
          currentUser.associateSoftwareToken({
            associateSecretCode: resolve,
            onFailure: reject,
          })
        })

        const respondToMfaSetUpChallenge = async (mfaCode: string) =>
          new Promise((resolve, reject) =>
            currentUser.verifySoftwareToken(mfaCode, 'Scaly', {
              onSuccess: resolve,
              onFailure: reject,
            })
          )

        reject(new AuthChallengeError(AuthChallengeType.MFA_SETUP, respondToMfaSetUpChallenge, secretCode))
      },
      totpRequired() {
        const respondToMfaChallenge = (mfaCode: string) =>
          new Promise((resolve, reject) =>
            currentUser.sendMFACode(
              mfaCode,
              {
                onSuccess: resolve,
                onFailure: reject,
              },
              'SOFTWARE_TOKEN_MFA'
            )
          )

        reject(new AuthChallengeError(AuthChallengeType.MFA_CODE, respondToMfaChallenge))
      },
    }
  }

  signInWithEmail(username: string, password: string) {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    })

    const currentUser = this.getCognitoUser(username)

    return new Promise(function (resolve, reject) {
      currentUser.authenticateUser(authenticationDetails, {
        onSuccess: function (res: any) {
          resetVars()
          Sentry.setUser({ email: username });
          resolve(res)
        },
        onFailure: function (err: any) {
          reject(err)
        },
        ...CognitoOperations.getCallbacksForUser(currentUser, reject),
      })
    }).catch((err) => {
      throw err
    })
  }

  signOut() {
    const currentUser = this.getCurrentUser()

    return new Promise<void>((resolve) => currentUser?.signOut(resolve) || resolve())
  }

  getAttributes() {
    const currentUser = this.getCurrentUser()

    return new Promise(function (resolve, reject) {
      currentUser?.getUserAttributes(function (err: any, attributes: any) {
        if (err) {
          reject(err)
        } else {
          resolve(attributes)
        }
      })
    }).catch((err) => {
      throw err
    })
  }

  resendVerificationCode(username: string) {
    const cognitoUser = this.getCognitoUser(username)
    return new Promise(function (resolve, reject) {
      if (!cognitoUser) {
        reject(`could not find ${username}`)
        return
      }

      cognitoUser.resendConfirmationCode((err: any, res: any) => {
        if (err) {
          reject(err)
        } else {
          resolve(res)
        }
      })
    }).catch((err) => {
      throw err
    })
  }

  sendCode(username: string) {
    const cognitoUser = this.getCognitoUser(username)
    return new Promise(function (resolve, reject) {
      if (!cognitoUser) {
        reject(`could not find ${username}`)
        return
      }

      cognitoUser.forgotPassword({
        onSuccess: function (res: any) {
          resolve(res)
        },
        onFailure: function (err: any) {
          reject(err)
        },
      })
    }).catch((err) => {
      throw err
    })
  }

  forgotPassword(username: string, code: string, password: string) {
    const cognitoUser = this.getCognitoUser(username)
    return new Promise(function (resolve, reject) {
      if (!cognitoUser) {
        reject(`could not find ${username}`)
        return
      }

      cognitoUser.confirmPassword(code, password, {
        onSuccess: function () {
          resolve({ status: 'success', message: 'Password updated' })
        },
        onFailure: function (err: any) {
          reject(err)
        },
      })
    })
  }

  changePassword(oldPassword: string, newPassword: string) {
    const currentUser = this.getCurrentUser()
    if (currentUser) {
      return new Promise(function (resolve, reject) {
        currentUser.changePassword(oldPassword, newPassword, function (err: any, res: any) {
          if (err) {
            reject(err)
          } else {
            resolve(res)
          }
        })
      })
    } else {
      return { status: 'fail', message: 'Password not updated. The current user cannot be determined.' }
    }
  }
}

export const cognitoOps = new CognitoOperations(
  import.meta.env.VITE_COGNITO_USER_POOL_ID,
  import.meta.env.VITE_COGNITO_CLIENT_ID
)
