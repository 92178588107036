import { Alert, Box, Button, CircularProgress, FormLabel, Stack, TextField } from '@mui/material'
// @ts-ignore
import { MuiOtpInput } from 'mui-one-time-password-input'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { userState } from 'store/user'

import { kaerwellAdminFetch } from 'lib/PublicApiClient'
import { RESEND_CONFIRMATION } from 'lib/graphql/resendConfirmation'
import { cognitoOps } from 'lib/Cognito.tsx'

enum ResetFlowStep {
  lookupEmail = 'lookupEmail',
  confirmationCode = 'confirmationCode',
}

async function resendConfirmation(email: string) {
  return await kaerwellAdminFetch<any>({
    query: RESEND_CONFIRMATION,
    variables: {
      email,
    },
  })
}

const ResetPasswordForm: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [showResendMessage, setShowResendMessage] = useState(false)
  const [email, setEmail] = useState('')
  const [confirmationCode, setConfirmationCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [passwordAlert, setPasswordAlert] = useState('')
  const [currentResetFlowStep, setCurrentResetFlowStep] = useState<ResetFlowStep>(ResetFlowStep.lookupEmail)

  const navigate = useNavigate()

  const resetPasswordForEmail = async () => {
    setLoading(true)
    setError(undefined)
    try {
      await cognitoOps.sendCode(email)

      setCurrentResetFlowStep(ResetFlowStep.confirmationCode)
    } catch (err: any) {
      if (err.code === 'NotAuthorizedException') {
        const res = await resendConfirmation(email)
        if (res.status === 200) setShowResendMessage(true)
      } else {
        setError(err.message || err.erros[0].message)
      }
    } finally {
      setLoading(false)
    }
  }

  const submitNewUserPassword = async () => {
    setLoading(true)
    setError(undefined)
    try {
      await cognitoOps.forgotPassword(email, confirmationCode, newPassword)

      navigate('/login')
    } catch (err: any) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setPasswordAlert(newPassword !== confirmNewPassword ? 'Passwords do not match.' : '')
  }, [newPassword, confirmNewPassword])

  return (
    <div style={{ width: '50%' }}>
      {showResendMessage && (
        <Alert sx={{ mb: 4 }} severity='success'>
          Check your e-mail for instructions.
        </Alert>
      )}
      {error && (
        <Alert sx={{ mb: 4 }} severity='error'>
          {error}
        </Alert>
      )}
      {currentResetFlowStep === ResetFlowStep.lookupEmail && (
        <form onSubmit={(e: any) => e.preventDefault() || resetPasswordForEmail()}>
          <Stack spacing={4}>
            <TextField
              label='Email'
              required
              variant='standard'
              color='secondary'
              fullWidth
              disabled={loading}
              onChange={(ev) => setEmail(ev.target.value)}
            />
            <Button
              variant='contained'
              color='primary'
              disabled={loading}
              onClick={() => {
                setShowResendMessage(false)
                resetPasswordForEmail()
              }}
            >
              Reset Password
              {loading && (
                <>
                  &nbsp;&nbsp; <CircularProgress color='info' />
                </>
              )}
            </Button>
          </Stack>
        </form>
      )}
      {currentResetFlowStep === ResetFlowStep.confirmationCode && (
        <form onSubmit={(e: any) => e.preventDefault() || submitNewUserPassword()}>
          <Stack spacing={4}>
            <TextField
              name='newPassword'
              value={newPassword}
              label='New Password'
              required
              variant='standard'
              color='secondary'
              type='password'
              fullWidth
              disabled={loading}
              onChange={(ev) => setNewPassword(ev.target.value)}
            />
            <TextField
              name='confirmNewPassword'
              value={confirmNewPassword}
              label='Confirm New Password'
              required
              variant='standard'
              color='secondary'
              type='password'
              fullWidth
              disabled={loading}
              onChange={(ev) => setConfirmNewPassword(ev.target.value)}
            />
            {passwordAlert && <Alert severity='error'>{passwordAlert}</Alert>}
            <Box>
              <FormLabel>Confirmation Code</FormLabel>
              <MuiOtpInput
                TextFieldsProps={{ inputProps: { sx: { padding: '16px 0px' } } }}
                sx={{ mt: 1, gap: '5px' }}
                value={confirmationCode}
                length={6}
                // disabled={loading}
                onChange={(value: any) => setConfirmationCode(value)}
              />
            </Box>
            <Button variant='contained' color='primary' disabled={loading} type='submit'>
              Reset Password
              {loading && (
                <>
                  &nbsp;&nbsp; <CircularProgress color='info' />
                </>
              )}
            </Button>
          </Stack>
        </form>
      )}
    </div>
  )
}

export default ResetPasswordForm
