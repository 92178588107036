import React, { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { Box, Button, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { today } from 'pages/ReportsApp/utils.tsx'
import { DatePicker } from '@mui/x-date-pickers-pro'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'

dayjs.extend(quarterOfYear)

export const ReportPeriodOptions = [
  { label: 'This Month', id: 'thisMonth' },
  { label: 'Last Month', id: 'lastMonth' },
  { label: 'This Quarter', id: 'thisQuarter' },
  { label: 'Last Quarter', id: 'lastQuarter' },
  { label: 'This Year', id: 'thisYear' },
  { label: 'Last Year', id: 'lastYear' },
  { label: 'Custom', id: 'custom' },
]

export const getDatesForPresetReportPeriod = (id: string) => {
  if (id === 'thisMonth') {
    return {
      from: dayjs().startOf('month'),
      to: dayjs().endOf('month'),
    }
  }
  if (id === 'lastMonth') {
    const lastMonth = dayjs().startOf('month').subtract(1, 'day')
    return {
      from: lastMonth.startOf('month'),
      to: lastMonth.endOf('month'),
    }
  }
  if (id === 'thisQuarter') {
    return {
      from: dayjs().startOf('quarter'),
      to: dayjs().endOf('quarter'),
    }
  }
  if (id === 'lastQuarter') {
    const lastQuarter = dayjs().subtract(1, 'quarter')
    return {
      from: lastQuarter.startOf('quarter'),
      to: lastQuarter.endOf('quarter'),
    }
  }
  if (id === 'thisYear') {
    return {
      from: dayjs().startOf('year'),
      to: dayjs().endOf('year'),
    }
  }
  if (id === 'lastYear') {
    const lastYear = dayjs().subtract(1, 'year')
    return {
      from: lastYear.startOf('year'),
      to: lastYear.endOf('year'),
    }
  }
  return {
    from: dayjs().startOf('month'),
    to: dayjs().endOf('month'),
  }
}

export type ReportFiltersType = {
  dateFilter: {
    type: string
    from: dayjs.Dayjs | null
    to: dayjs.Dayjs | null
  }
}

type Props = {
  onChange?: (v: ReportFiltersType) => void
  onSubmit?: (v: ReportFiltersType) => void
  disabled?: boolean
}

const ReportFilters = ({ onChange, onSubmit, disabled }: Props) => {
  const [selectedDateFilter, setSelectedDateFilter] = useState(ReportPeriodOptions[0].id)

  const [fromDate, setFromDate] = useState<Dayjs | null>(dayjs())
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs())

  const filters: ReportFiltersType = {
    dateFilter: {
      type: selectedDateFilter,
      ...(selectedDateFilter === 'custom'
        ? {
            from: fromDate,
            to: toDate,
          }
        : getDatesForPresetReportPeriod(selectedDateFilter)),
    },
  }

  useEffect(() => {
    handleSubmit()
  }, [])

  useEffect(() => {
    if (onChange) {
      onChange(filters)
    }
  }, [selectedDateFilter, fromDate, toDate])

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(filters)
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2, justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <span>Report Period:</span>
        <Select
          disabled={disabled}
          variant={'standard'}
          value={selectedDateFilter.toString()}
          onChange={(e: SelectChangeEvent) => setSelectedDateFilter(e.target.value)}
          inputProps={{ 'aria-label': 'Date Filter' }}
        >
          {ReportPeriodOptions.map((option, index) => {
            return (
              <MenuItem key={index} value={option.id}>
                {option.label}
              </MenuItem>
            )
          })}
        </Select>
        {selectedDateFilter === 'custom' ? (
          <>
            <span>From:</span>
            <DatePicker
              sx={{
                width: '130px',
              }}
              disabled={disabled}
              slotProps={{
                textField: {
                  variant: 'standard',
                },
              }}
              localeText={{ start: 'From', end: 'To' }}
              value={fromDate}
              maxDate={today}
              onChange={(newValue) => setFromDate(newValue)}
            />
            <span>To:</span>
            <DatePicker
              sx={{
                width: '130px',
              }}
              disabled={disabled}
              slotProps={{
                textField: {
                  variant: 'standard',
                },
              }}
              localeText={{ start: 'From', end: 'To' }}
              value={toDate}
              // maxDate={today}
              minDate={fromDate || undefined}
              onChange={(newValue) => setToDate(newValue)}
            />
          </>
        ) : (
          <></>
        )}
      </Box>
      <Box>
        <Button disabled={disabled} onClick={handleSubmit} variant={'outlined'}>
          Run Report
        </Button>
      </Box>
    </Box>
  )
}

export default ReportFilters
