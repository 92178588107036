import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { Stack, Typography } from '@mui/material'
import HistoryIcon from '@mui/icons-material/History'
import Button from '@mui/material/Button'
import { Check, ChevronLeft, DonutLarge, ErrorOutline } from '@mui/icons-material'
import _ from 'lodash'
import dayjs from 'dayjs'

import GET_ORDER from 'lib/graphql/getOrder'
import StatusIndicatorCell from 'components/Connect/ConnectTable/StatusIndicatorCell'
import Timeline from 'components/Connect/Timeline/Timeline'

const BASE_URI = '/vendor/connect'
export const ConnectOrderDetail = () => {
  const navigate = useNavigate()
  const params = useParams()

  const { data } = useQuery(GET_ORDER, {
    variables: { id: params.id },
  })

  const receivedMessages = data?.getOrderWorkflow?.messages?.filter((message: any) => !!message.source) || []
  const sentMessages = data?.getOrderWorkflow?.messages?.filter((message: any) => !message.source) || []
  const sentMessagesById = _.groupBy(sentMessages, 'previousMessageId')
  const orderedMessages = _.orderBy(receivedMessages, (message) => dayjs(message.createdAt).unix(), ['desc'])
  const groupedMessages = _.groupBy(orderedMessages, (message) => {
    return dayjs(message.createdAt).format('YYYY-MM-DD')
  })
  const lastCommunication = orderedMessages[0]
    ? dayjs(orderedMessages[0].createdAt).format('MMMM D, YYYY [at] h:mm A')
    : '-'

  const status = data?.getOrderWorkflow?.orderWorkflow || []

  const isError = status.some(({ color }: { color: string }) => color === 'red')
  const isSuccess = status.every(({ color }: { color: string }) => color === 'green')

  return (
    <Box>
      <Paper
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingY: 1 }}
        square
      >
        <Button variant='text' onClick={() => navigate(BASE_URI)}>
          <ChevronLeft /> BACK
        </Button>
        <Typography color='#001D35' fontWeight={700} fontSize='18px' sx={{ flex: 1 }} textAlign='end'>
          Order {params?.commonId}
        </Typography>
        <Box display='hidden' sx={{ flexGrow: 1 }} />
      </Paper>
      <Box paddingX={1} sx={{ backgroundColor: '#F9F9FC' }} py={1}>
        <Typography color='#001D35' fontWeight={500} fontSize='18px'>
          Order Summary
        </Typography>
        <Stack spacing={1} direction='row' alignItems='center' mt={1}>
          <StatusIndicatorCell status={status} size='lg' />
          {isError && <ErrorOutline sx={{ color: '#BA1A1A' }} />}
          {isSuccess && <Check sx={{ color: '#64DD96' }} />}
          {!isError && !isSuccess && <DonutLarge sx={{ color: '#526070' }} />}
        </Stack>
        <Stack direction='column' spacing={2} mt={1} mb={2}>
          {isError && (
            <Stack spacing={1} direction='row'>
              <ErrorOutline sx={{ color: '#BA1A1A' }} />
              <Typography color='#BA1A1A' fontSize='16px' fontWeight={700}>
                Error
              </Typography>
            </Stack>
          )}
          {isSuccess && (
            <Stack spacing={1} direction='row'>
              <Check sx={{ color: '#64DD96' }} />
              <Typography color='#526070' fontSize='16px' fontWeight={700}>
                Complete
              </Typography>
            </Stack>
          )}
          {!isSuccess && !isError && (
            <Stack spacing={1} direction='row'>
              <DonutLarge sx={{ color: '#526070' }} />
              <Typography color='#526070' fontSize='16px' fontWeight={700}>
                In Progress
              </Typography>
            </Stack>
          )}
          <Stack spacing={1} direction='row'>
            <HistoryIcon />
            <Typography color='#526070' fontSize='16px' fontWeight={700}>
              Last Activity:{' '}
            </Typography>
            <Typography color='#526070' fontSize='16px' fontWeight={500}>
              {lastCommunication}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
      </Box>
      <Paper sx={{ width: '100%', mt: 1, display: 'flex', justifyContent: 'center' }} square elevation={0}>
        <Box sx={{ width: 600 }}>
          <Typography color='#001D35' fontWeight={500} fontSize='18px' sx={{ pl: 2 }}>
            Timeline
          </Typography>
          <Timeline
            receivedMessages={groupedMessages}
            sentMessagesById={sentMessagesById}
            commonId={params.commonId || ''}
            orderId={params.id || ''}
          />
        </Box>
      </Paper>
    </Box>
  )
}
