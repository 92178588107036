import { CognitoUserPool } from 'amazon-cognito-identity-js'

export class SpiClient {
  readonly baseUrl: string
  readonly accessToken: string
  private sessionToken?: string

  constructor() {
    this.baseUrl = import.meta.env.VITE_SPI_BASE_URL
    this.accessToken = import.meta.env.VITE_SPI_ACCESS_TOKEN
  }

  private async startSession() {
    const response = await fetch(`${this.baseUrl}/api/v1/session/start/${this.accessToken}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    })
    const responseJson = await response.json()
    this.sessionToken = responseJson.token
  }

  private async getTemplatesUrl(options?: any) {
    if (!this.sessionToken) {
      await this.startSession()
    }
    let s = `${this.baseUrl}/api/v1/entity/templates?token=${this.sessionToken}&limit=10000`
    if (options.category) {
      s = `${s}&categories[]=${options.category}`
    }
    return s
  }

  async getTemplatePreviewUrl(templateName: string) {
    if (!this.sessionToken) {
      await this.startSession()
    }
    return `${this.baseUrl}/api/v1/entity/templates/${templateName}/preview/0?token=${this.sessionToken}`
  }

  async listTemplates(options?: any) {
    try {
      const response = await fetch(await this.getTemplatesUrl(options), {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      })
      const responseJson = await response.json()
      if (!responseJson || !responseJson.entities) {
        console.log('Not found')
        return []
      }
      return responseJson.entities
    } catch (err) {
      console.log('error: ', err)
      return {}
    }
  }

  async readInstance(instanceId: number, userAccessToken: string) {
    const response = await fetch(
      `${this.baseUrl}/api/sd/v1/instance/read/${instanceId}?include_variables=spi_vars`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'x-spitoken-id': import.meta.env.VITE_SPI_ACCESS_TOKEN,
          'x-accesstoken-id': userAccessToken,
        },
      }
    )
    return response.json()
  }

  async updateInstance(
    instanceIdOrName: string,
    templateVars: Record<string, string | undefined>,
    userAccessToken: string
  ) {
    const body = {
      instance: {
        id: instanceIdOrName,
        produce: 'true',
        variables: {
          spi_vars: Object.entries(templateVars)
            .filter(([, v]) => v)
            .map(([k, v]) => ({ name: k, value: v })),
        },
      },
    }
    const response = await fetch(`${import.meta.env.VITE_SPI_BASE_URL}/api/sd/v1/instance/update`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'x-spitoken-id': import.meta.env.VITE_SPI_ACCESS_TOKEN,
        'x-accesstoken-id': userAccessToken,
      },
      body: JSON.stringify(body),
    })
    const responseJson = await response.json()
    return responseJson.GUID
  }
}
