import { useDropzone } from 'react-dropzone'
import { useLazyQuery } from '@apollo/client'
import { useCallback, useRef, useState } from 'react'
import { useDrag, useDrop, XYCoord } from 'react-dnd'
import type { Identifier } from 'dnd-core'
import update from 'immutability-helper'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { Box, Dialog, Typography, IconButton } from '@mui/material'
import { GET_PRESIGNED_URL_FOR_PUBLIC_ASSET } from 'lib/graphql/getPresignedUrlForPublicAsset'

import styles from './style.module.css'

const ASSET_TYPE = 'banner'

type StoreImageProps = {
  assetType: string
  key?: string
  path?: string
  preview?: string
  alt?: string
  contentType?: string
  field?: string
  stored: boolean
}

type ThumbImage = { preview?: string; id: string; moveImage: any; index: number; handleDelete: any }
type ThumbImageList = Omit<ThumbImage, 'moveImage' | 'index' | 'handleDelete'>[]

const Thumb = ({ preview, id, moveImage, index, handleDelete }: ThumbImage) => {
  const ref = useRef<HTMLDivElement>(null)
  const [showZoomDialog, setShowZoomDialog] = useState<string | undefined>('')

  const [{ handlerId }, drop] = useDrop<ThumbImage, void, { handlerId: Identifier | null }>({
    accept: 'thumb',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: ThumbImage, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get horizontal middle
      const hoverMiddleX = (hoverBoundingRect.left - hoverBoundingRect.right) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the right
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.right

      // Only perform the move when the mouse has crossed half of the items width
      // When dragging right, only move when the cursor is below 50%
      // When dragging left, only move when the cursor is above 50%

      // Dragging left
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return
      }

      // Dragging right
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return
      }

      // Time to actually perform the action
      moveImage(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'thumb',
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  return (
    <>
      <Dialog
        open={!!showZoomDialog}
        onClick={(event) => {
          event.stopPropagation()
          setShowZoomDialog('')
        }}
        maxWidth='xl'
        fullWidth
      >
        <Box sx={{}}>
          <img
            src={showZoomDialog}
            style={{
              alignSelf: 'center',
              maxWidth: '-webkit-fill-available',
              maxHeight: '-webkit-fill-available',
            }}
          />
        </Box>
      </Dialog>
      <div ref={ref} data-handler-id={handlerId} style={{ position: 'relative' }}>
        <img
          src={preview}
          className={styles.bannerThumb}
          onClick={(event) => {
            event.stopPropagation()
            setShowZoomDialog(preview)
          }}
        />
        <IconButton
          sx={{
            color: 'red',
            position: 'absolute',
            bottom: '0px',
            right: '0px',
            background: '#ffc0c0',
            borderRadius: '50%',
          }}
          onClick={async (event) => {
            event.stopPropagation()
            handleDelete(id)
          }}
        >
          <DeleteForeverIcon sx={{ width: '16px', height: '16px' }} />
        </IconButton>
      </div>
    </>
  )
}

export const ListImages = ({
  images,
  setImages,
  title,
  setValue,
  handleDelete,
  storeId,
}: {
  images: ThumbImageList
  setImages: any
  title?: string
  setValue: any
  handleDelete: any
  storeId: string
}) => {
  const [busy, setBusy] = useState(false)

  const [getPresignedUrlForPublicAsset] = useLazyQuery(GET_PRESIGNED_URL_FOR_PUBLIC_ASSET)
  const { getInputProps, getRootProps } = useDropzone({
    // disabled,
    accept: {
      'image/*': [],
    },
    onDrop: async (acceptedFiles) => {
      const copyImages = acceptedFiles.map((file) => {
        return {
          ...file,
          stored: false,
          assetType: ASSET_TYPE,
          alt: file.name,
          contentType: file.type,
          preview: URL.createObjectURL(file),
        }
      })
      const result = await uploadFile(copyImages[0])
      setValue(result.key)
    },
  })

  async function readFile(filename: string) {
    return new Promise(async (resolve, reject) => {
      const reader = new FileReader()

      reader.onabort = () => reject()
      reader.onerror = () => reject()
      reader.onload = () => resolve(reader.result)

      const response = await fetch(filename)
      const responseBlob = await response.blob()
      return reader.readAsArrayBuffer(responseBlob)
    })
  }

  async function getPresignedUploadUrl(file: {
    filename: string
    contentType: string
    storeId?: string
    orgId?: string
  }) {
    const response = await getPresignedUrlForPublicAsset({
      variables: {
        storeId: file.storeId,
        type: ASSET_TYPE,
        orgId: file.orgId,
        filename: file.filename,
        method: 'PUT',
        contentType: file.contentType,
      },
    })
    if (response.error) {
      throw new Error(JSON.stringify(response.error))
    }
    return response.data.getPresignedUrlForPublicAsset
  }

  async function uploadFile(file: StoreImageProps) {
    const [presignedUploadObject, data] = await Promise.all([
      getPresignedUploadUrl({
        filename: file.path!,
        contentType: file.contentType!,
        storeId: storeId,
      }),
      readFile(file.preview as string),
    ])

    const res = await fetch(presignedUploadObject.presignedUrl, {
      method: 'PUT',
      body: data as BodyInit,
      headers: {
        'Content-Type': file.contentType as string,
      },
    })

    if (res.ok) {
      return {
        ...presignedUploadObject,
        assetType: 'banner',
        contentType: 'IMAGE',
        alt: file.path,
        stored: true,
      }
    } else {
      console.log('ERROR')
      console.log(res)
    }
  }

  const moveImage = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setImages(
        update(images, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, images[dragIndex]],
          ],
        })
      )
    },
    [setImages, images]
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '16px',
        border: '1px dashed #008bbd',
        background: '#f0f0f0',
        mb: 2,
        position: 'relative',
      }}
      {...getRootProps()}
    >
      {title && (
        <Typography component='span' sx={{ backgroundColor: '#FFF', width: 'fit-content' }}>
          {title}
        </Typography>
      )}
      <Typography
        component='span'
        sx={{ fontWeight: 400, position: 'absolute', top: 0, right: '16px', backgroundColor: '#FFF' }}
      >
        Drag-and-drop or click to add an image
      </Typography>
      <Box
        sx={{
          display: 'flex',
          columnGap: '8px',
          padding: 2,
          alignItems: 'center',
          flexWrap: 'wrap',
          minHeight: '140px',
        }}
      >
        {images
          .filter((image) => !!image.preview)
          .map((image, index) => (
            <Thumb
              key={image.id}
              preview={image.preview}
              id={image.id}
              moveImage={moveImage}
              index={index}
              handleDelete={handleDelete}
            />
          ))}
      </Box>
    </Box>
  )
}
