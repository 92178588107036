import { useState } from 'react'
import { Button, Stack, TablePagination, CircularProgress, Box } from '@mui/material'
import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'

import LotElement from './LotElement'
import { COUNT_LOTS } from 'lib/graphql/countLots'
import { LIST_LOTS } from 'lib/graphql/listLots'
import AddLotDialog from './AddLotDialog'

const formatDateString = (dateString: string) => dayjs(dateString).format('MM/DD/YY')

const LotList = ({
  product,
  skuId,
  majorVersionUuid,
  majorVersions,
  canCreate,
}: {
  product: string
  skuId: string
  majorVersionUuid: string
  canCreate: boolean
  majorVersions: any
}) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [createLotOpen, setCreateLotOpen] = useState(false)

  const {
    loading: loadingCountLots,
    data: dataCountLots,
    error: errorCountLots,
  } = useQuery(COUNT_LOTS, { variables: { sku: product, majorUuid: majorVersionUuid } })

  const {
    loading: loadingListLots,
    data: dataListLots,
    error: errorListLots,
    refetch,
  } = useQuery(LIST_LOTS, {
    variables: {
      where: { sku: { sku: { equals: product } }, supplementFactMajorUuid: { equals: majorVersionUuid } },
      take: rowsPerPage,
      skip: rowsPerPage * page,
    },
  })

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleClose = () => {
    setCreateLotOpen(false)
  }

  if (loadingCountLots || loadingListLots) return <CircularProgress />

  return (
    <Stack direction='column' ml={1} mr={1} mb={1} alignItems='flex-start'>
      <AddLotDialog
        open={createLotOpen}
        handleClose={handleClose}
        skuId={skuId}
        majorVersionUuid={majorVersionUuid}
        refetch={refetch}
      />
      <Button
        variant='text'
        sx={{ mt: 0 }}
        onClick={() => {
          setCreateLotOpen(true)
        }}
        disabled={!canCreate}
      >
        {!canCreate ? <>An approved label fact is required before lots can be assigned</> : <>+ Add Lot</>}
      </Button>
      <Box sx={{ paddingLeft: '30px' }}>
        {dataListLots.listLots.map((lotDetail: any) => (
          <LotElement
            lot={lotDetail.lotNumber}
            key={lotDetail.id}
            id={lotDetail.id}
            exp={formatDateString(lotDetail.expirationDate)}
            added={formatDateString(lotDetail.createdAt)}
            majorVersions={majorVersions}
            majorVersionUuid={majorVersionUuid}
          />
        ))}
      </Box>
      {dataCountLots.countLots ? (
        <TablePagination
          component='div'
          count={dataCountLots.countLots}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage=''
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        <Box sx={{ paddingBottom: '10px' }}></Box>
      )}
    </Stack>
  )
}

export default LotList
