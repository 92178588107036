import { Controller } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import { FormControlLabel, Switch } from '@mui/material'

export interface FormSwitchProps {
  name: string
  control: any
  rules?: any
  label: string
  setValue?: any
  required?: boolean
  type?: string
  fullWidth?: boolean
  disabled?: boolean
  variant?: 'outlined' | 'standard' | 'filled'
  color?: 'primary' | 'secondary'
  placeholder?: string
  InputProps?: any
}

export const FormSwitch = ({
  name,
  control,
  label,
  type,
  disabled,
  color,
  rules,
  placeholder,
  InputProps,
}: FormSwitchProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      disabled={disabled}
      render={({ field: { onChange, value, disabled }, fieldState: { error } }) => (
        <FormControlLabel
          disabled={disabled}
          label={label}
          control={
            <Switch
              // helperText={error ? error.message : null}
              // error={!!error}
              onChange={onChange}
              required={rules?.required}
              checked={value}
              color={color || 'primary'}
              disabled={disabled || false}
            />
          }
        />
      )}
    />
  )
}
