import { gql } from '@apollo/client'

export const TEMPLATES_LIST = gql`
  query listDesignerTemplates($where: DesignerTemplateWhereInput, $limit: Int, $offset: Int) {
    listDesignerTemplates(where: $where, take: $limit, skip: $offset, orderBy: [{ templateId: ASC }]) {
      id
      sku {
        id
        sku
      }
      templateId
      displayName
      imageUrl
      createdAt
    }
  }
`
