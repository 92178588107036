import { gql } from '@apollo/client'

export const LIST_EXTERNAL_STORE_CONFIGURATIONS = gql`
  query listExternalStoreConfigurations(
    $where: ExternalStoreConfigurationWhereInput
    $take: Int
    $skip: Int
  ) {
    listExternalStoreConfigurations(where: $where, take: $take, skip: $skip, orderBy: [{ name: ASC }]) {
      id
      name
      domain
      organizationId
      storeType
      createdAt
      updatedAt
    }
  }
`
