import { gql } from '@apollo/client'

// offset and limit are needed here to make Apollo cache work
// its needed for the infinite-scroll
export const LIST_USERS = gql`
  query listUsers($where: UserWhereInput, $orderBy: [UserOrderByInput!], $limit: Int, $offset: Int) {
    listUsers(where: $where, orderBy: $orderBy, take: $limit, skip: $offset) {
      id
      sub
      email
    }
  }
`
