import { gql } from '@apollo/client'

export const ORGANIZATIONS_LIST = gql`
  query listOrganizations($where: OrganizationWhereInput, $take: Int, $skip: Int) {
    listOrganizations(where: $where, take: $take, skip: $skip, orderBy: [{ name: ASC }]) {
      id
      name
    }
  }
`
