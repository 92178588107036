import { useEffect, useState } from 'react'
import { Backdrop, Paper, Typography, Box, TextField, Autocomplete, CircularProgress } from '@mui/material'
import { useQuery, useReactiveVar, useMutation } from '@apollo/client'

import { BrandAccount } from 'lib/graphql/types'
import { ShopifyCustomer } from 'lib/types'
import { LIST_BRAND_ACCOUNTS } from 'lib/graphql/listBrandAccounts'
import { GET_CUSTOMER_ID } from 'lib/graphql/getCustomerId'
import { CREATE_CUSTOMER_ID } from 'lib/graphql/createCustomerId'
import { UPDATE_CUSTOMER_ID } from 'lib/graphql/updateCustomerId'
import { organizationId } from 'config/cache'
import { debounce } from '@mui/material/utils'

type Option = {
  id: string
  label: string
}

type Props = {
  customer: ShopifyCustomer
}

export const CustomerAccountCard = ({ customer }: Props) => {
  const currentOrganizationId = useReactiveVar(organizationId)

  const [selectedAccount, setSelectedAccount] = useState<Option | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [debounceValue, setDebouncedValue] = useState('')

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue)
    }, 300)

    return () => {
      clearTimeout(handler)
    }
  }, [inputValue])

  const [updateCustomerId, { loading: loadingUpdateCustomerId }] = useMutation(UPDATE_CUSTOMER_ID)
  const [createCustomerId, { loading: loadingCreateCustomerId }] = useMutation(CREATE_CUSTOMER_ID)

  const { data, loading } = useQuery(LIST_BRAND_ACCOUNTS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        name: {
          contains: debounceValue,
          mode: 'insensitive',
        },
        organizationId: {
          equals: currentOrganizationId,
        },
      },
    },
  })

  const { loading: loadingCustomer, data: dataCustomerId } = useQuery(GET_CUSTOMER_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        customerId: customer.id,
      },
    },
    onCompleted(data) {
      const customerAccount = data?.getCustomerId.brandAccount
      setSelectedAccount({ label: customerAccount.name, id: customerAccount.id })
    },
    onError() {
      setSelectedAccount(null)
    },
  })

  const customerIdRelation = dataCustomerId?.getCustomerId?.id || ''
  const listBrandAccounts = data?.listBrandAccounts || []

  const options: Option[] = listBrandAccounts.map((brandAccount: BrandAccount) => ({
    label: brandAccount.name,
    id: brandAccount.id,
  }))

  const handleChangeAccount = (newValue: Option | null) => {
    setSelectedAccount(newValue)
    if (!newValue) return

    customerIdRelation
      ? updateCustomerId({
          variables: {
            where: { id: customerIdRelation },
            data: {
              brandAccount: {
                connect: {
                  id: newValue?.id,
                },
              },
            },
          },
        })
      : createCustomerId({
          variables: {
            data: {
              customerId: customer.id,
              brandAccount: {
                connect: {
                  id: newValue?.id,
                },
              },
            },
          },
        })
  }

  return (
    <Paper elevation={10} sx={{ p: 2, position: 'relative', borderRadius: '15px' }}>
      <Backdrop sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })} open={loadingCustomer}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Box>
          <Typography variant={'h5'}>Customer Account</Typography>
        </Box>
      </Box>
      <Box>
        <Autocomplete
          id='controlled-demo'
          value={selectedAccount}
          options={options}
          disabled={loadingUpdateCustomerId || loadingCreateCustomerId}
          onChange={(event: any, newValue: Option | null) => {
            handleChangeAccount(newValue)
          }}
          loading={loading}
          filterOptions={(x) => x}
          getOptionLabel={(value) => value.label}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Customer Account'
              variant='standard'
              value={inputValue}
              onChange={(event) => setInputValue(event.target.value)}
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </Box>
    </Paper>
  )
}
