import { gql } from '@apollo/client'

export const GET_SUPPLEMENT_FACT = gql`
  query getSupplementFact($where: SupplementFactWhereUniqueInput!) {
    getSupplementFact(where: $where) {
      id
      sku {
        id
        sku
        skuWhiteLabel
      }
      skuId
      revisionNumber
      majorVersion
      minorVersion
      otherIngredients
      caution
      allergenStatement
      suggestedUse
      labelImage
      supplementLabelImage
      prop65WarningImage
      status
    }
  }
`
