import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'

export type ConfirmationDialogProps = {
  open: boolean
  handleClose: () => void
  handleConfirm: (v: any) => void
  reason: string
  setReason: (s: string) => void
  title: string
  description: string
}

const ConfirmationDialog = ({
  open,
  handleClose,
  handleConfirm,
  reason,
  setReason,
  title,
  description,
}: {
  open: boolean
  handleClose: () => void
  handleConfirm: (v: any) => void
  reason: string
  setReason: (s: string) => void
  title: string
  description: string
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description' sx={{ color: '#001D35' }}>
            {description}
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='reason'
            label='Reason'
            type='text'
            fullWidth
            multiline
            minRows={1}
            variant='standard'
            value={reason}
            onChange={(event) => setReason(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus disabled={reason.length < 3}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmationDialog
