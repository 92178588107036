import { gql } from '@apollo/client'

export const GET_EXTERNAL_STORE_CONFIGURATION = gql`
  query getExternalStoreConfiguration($where: ExternalStoreConfigurationExtendedWhereUniqueInput!) {
    getExternalStoreConfiguration(where: $where) {
      id
      name
      organization {
        id
        name
      }
      description
      storeType
      description
      domain
    }
  }
`
