import _ from 'lodash'
import { useState, forwardRef } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery, useMutation, useReactiveVar } from '@apollo/client'
import {
  Tabs,
  Tab,
  FormControl,
  CircularProgress,
  Box,
  Typography,
  Button,
  Paper,
  Grid,
  Select,
  MenuItem,
  Stack,
  Snackbar,
  Alert as MuiAlert,
  AlertProps,
  InputLabel,
} from '@mui/material'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { LIST_SUPPLEMENT_FACTS } from 'lib/graphql/listSupplementFacts'
import { GET_SUPPLEMENT_FACT } from 'lib/graphql/getSupplementFact'
import { CREATE_SUPPLEMENT_FACT } from 'lib/graphql/createSupplementFact'
import { UPDATE_SUPPLEMENT_FACT } from 'lib/graphql/updateSupplementFact'

import { CustomTabPanel, customTabProps } from 'components/CustomTabs.tsx'
import { FormTextField } from 'components/Forms/FormTextField.tsx'
import LabelFactsConfirmationDialog from 'components/LabelFacts/LabelFactsConfirmationDialog'

import style from 'components/Dialogs/dialogs.module.css'
import { GET_SKU } from '@/lib/graphql/getSku'
import { DirectUpload } from 'components/DirectUpload'
import { LabelFactsPreview } from 'components/LabelFacts/LabelFactsPreview'
import { organizationId } from '@/config/cache'

const buildBucketUrlFromKey = (key: string) => {
  if (_.isEmpty(key)) {
    return undefined
  }
  return `${import.meta.env.VITE_BUCKET_BASE_URL}${key}`
}

const urlPathBase = `/vendor/label-facts`
const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

type SupplementFactFields = {
  revisionNumber: string
  otherIngredients: string
  caution: string
  allergenStatement: string
  suggestedUse: string
  supplementLabelImage: string
  prop65WarningImage: string
}

const DisplayingErrorMessagesSchema = Yup.object().shape({
  revisionNumber: Yup.string().required('Required'),
  otherIngredients: Yup.string().optional().nullable(),
  caution: Yup.string().optional().nullable(),
  allergenStatement: Yup.string().optional().nullable(),
  suggestedUse: Yup.string().optional().nullable(),
  supplementLabelImage: Yup.string().optional().nullable(),
  prop65WarningImage: Yup.string().optional().nullable(),
})

const initialValues = {
  otherIngredients: '',
  caution: '',
  allergenStatement: '',
  suggestedUse: '',
  revisionNumber: '',
  supplementLabelImage: '',
  prop65WarningImage: '',
}

export const LabelFactsDetails = () => {
  const organizationIdVar = useReactiveVar(organizationId)
  const { id: supplementFactId, skuId } = useParams()
  const [busy, setBusy] = useState(false)
  const [reason, setReason] = useState('')
  const [tabValue, setTabValue] = useState(0)
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const [isBlankVersion, setIsBlankVersion] = useState(true) // flag variable to know wheter new version is blank
  const [open, setOpen] = useState(false)
  const [selectedSupplFact, setSelectedSupplFact] = useState('')
  const [successMessageOpen, setSuccessMessageOpen] = useState(false)

  const navigate = useNavigate()

  const { pathname } = useLocation()

  const isNewMajorVersion = pathname.includes('create')

  const { data: skuData } = useQuery(GET_SKU, {
    variables: {
      where: {
        id: skuId,
      },
    },
    skip: !skuId,
    fetchPolicy: 'no-cache',
  })

  const {
    loading: loadingListSupplementFact,
    data: dataListSupplementFact,
    error: _errorListSupplementFact,
  } = useQuery(LIST_SUPPLEMENT_FACTS, {
    variables: {
      where: {
        sku: {
          brand: {
            organizationId: { equals: organizationId },
          },
          id: { equals: skuId },
        },
      },
      skip: 0,
      take: 10,
    },
    skip: !!supplementFactId,
    fetchPolicy: 'no-cache',
  })

  const {
    loading: loadingSupplementFact,
    data: dataSupplementFact,
    error: _errorSupplementFact,
  } = useQuery(GET_SUPPLEMENT_FACT, {
    variables: {
      where: { id: supplementFactId },
    },
    fetchPolicy: 'no-cache',
    skip: !supplementFactId,
    onCompleted(data) {
      reset({
        revisionNumber: data.getSupplementFact?.revisionNumber || '',
        otherIngredients: data.getSupplementFact?.otherIngredients || '',
        caution: data.getSupplementFact?.caution || '',
        allergenStatement: data.getSupplementFact?.allergenStatement || '',
        suggestedUse: data.getSupplementFact?.suggestedUse || '',
        supplementLabelImage: data.getSupplementFact?.supplementLabelImage,
        prop65WarningImage: data.getSupplementFact?.prop65WarningImage
      })
    },
  })

  const {
    control,
    handleSubmit,
    reset,
    trigger,
    getValues,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<SupplementFactFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver<SupplementFactFields>(DisplayingErrorMessagesSchema as any),
    defaultValues: initialValues,
  })

  const {
    majorVersion,
    minorVersion,
    sku: { skuWhiteLabel: skuFallback },
  } = (!supplementFactId
    ? dataListSupplementFact?.listSupplementFacts?.find(
        (supplFact: any) => supplFact.id === selectedSupplFact
      )
    : dataSupplementFact?.getSupplementFact) || {
    sku: {
      client: {},
      otherIngredients: '',
      caution: '',
      allergenStatement: '',
      suggestedUse: '',
      revisionNumber: '',
    },
  }

  // last version number will be used to create a new version
  const lastVersion = dataListSupplementFact?.listSupplementFacts?.[0]?.majorVersion

  const [createSupplementFact, { loading: loadingCreateSupplementFact }] = useMutation(
    CREATE_SUPPLEMENT_FACT,
    {
      onCompleted: (data) => {
        navigate(`${urlPathBase}/${organizationIdVar}/${skuId}/${data.createSupplementFact.majorVersion}`)
      },
    }
  )

  const [updateSupplementFact, { loading: loadingUpdateSupplementFact }] = useMutation(
    UPDATE_SUPPLEMENT_FACT,
    {
      onCompleted: () => {
        setSuccessMessageOpen(true)
        navigate(-1)
      },
    }
  )

  const onSubmit = (status: string) => async (values: SupplementFactFields) => {
    supplementFactId
      ? await updateSupplementFact({
          variables: {
            data: {
              revisionNumber: values.revisionNumber?.trim(),
              otherIngredients: _.isEmpty(values.otherIngredients) ? null : values.otherIngredients?.trim(),
              caution: _.isEmpty(values.caution) ? null : values.caution?.trim(),
              allergenStatement: _.isEmpty(values.allergenStatement)
                ? null
                : values.allergenStatement?.trim(),
              suggestedUse: _.isEmpty(values.suggestedUse) ? null : values.suggestedUse?.trim(),
              ...(status === 'APPROVED' ? { status, signedReason: reason?.trim() } : {}),
              supplementLabelImage: values.supplementLabelImage?.trim(),
              prop65WarningImage: values.prop65WarningImage?.trim(),
            },
            where: { id: supplementFactId },
          },
        })
      : await createSupplementFact({
          variables: {
            data: {
              sku: {
                connect: {
                  id: skuId,
                },
              },
              majorVersion: (lastVersion || 0) + 1,
              minorVersion: 1,
              revisionNumber: values.revisionNumber?.trim(),
              otherIngredients: _.isEmpty(values.otherIngredients) ? null : values.otherIngredients?.trim(),
              caution: _.isEmpty(values.caution) ? null : values.caution?.trim(),
              ...(status === 'APPROVED' ? { status, signedReason: reason?.trim() } : {}),
              allergenStatement: _.isEmpty(values.allergenStatement)
                ? null
                : values.allergenStatement?.trim(),
              suggestedUse: _.isEmpty(values.suggestedUse) ? null : values.suggestedUse?.trim(),
              supplementLabelImage: values.supplementLabelImage?.trim(),
              prop65WarningImage: values.prop65WarningImage?.trim(),
            },
          },
        })
  }

  const status = dataSupplementFact?.getSupplementFact.status
  const isDraft = status === 'DRAFT'
  const isDisabledCreateButton =
    busy || isSubmitting || loadingUpdateSupplementFact || loadingCreateSupplementFact

  if (!skuData || loadingSupplementFact || loadingListSupplementFact) return <CircularProgress />

  return (
    <Stack direction={{ sm: 'column' }} alignItems={'center'}>
      <Box sx={{ maxWidth: 'md', width: '100%' }}>
        <Snackbar
          open={successMessageOpen}
          sx={{ width: '100%' }}
          autoHideDuration={4000}
          onClose={() => {
            setSuccessMessageOpen(false)
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => {
              setSuccessMessageOpen(false)
            }}
            severity='success'
            sx={{ width: '100%' }}
          >
            Version updated successfully!
          </Alert>
        </Snackbar>
        <Box sx={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Button variant='text' onClick={() => navigate(-1)}>
            <ChevronLeft /> BACK
          </Button>
          <Typography variant='h5' sx={{ flex: 1 }} textAlign='center'>
            {skuData?.getSku.skuFact.privateLabelName}
          </Typography>
          <Typography variant={'h6'} sx={{ flex: 1 }} textAlign='center'>
            {isNewMajorVersion ? (
              <>{skuData?.getSku.skuWhiteLabel} &mdash; Create New Major Version</>
            ) : (
              <>
                {skuFallback} &mdash; Version {majorVersion}.{minorVersion}
              </>
            )}
          </Typography>
          {!isNewMajorVersion && (
            <Typography sx={{ flex: 1, fontWeight: 500 }} textAlign='center'>
              Status: {_.capitalize(status)}
            </Typography>
          )}
          <Box display='hidden' sx={{ width: '78px' }} />
        </Box>
        <Paper
          elevation={1}
          sx={{
            padding: 2,
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box className={style.dialogTabHeader}>
            <Tabs value={tabValue} onChange={handleTabChange} className={style.dialogTabs}>
              <Tab label='Facts' className={style.dialogTab} {...customTabProps(0, tabValue)} />
              {(supplementFactId || isNewMajorVersion) && (
                <Tab label='Preview' className={style.dialogTab} {...customTabProps(1, tabValue)} />
              )}
            </Tabs>
          </Box>
          <form onSubmit={handleSubmit(onSubmit('DRAFT'))}>
            <LabelFactsConfirmationDialog
              open={open}
              reason={reason}
              setReason={setReason}
              handleClose={() => {
                setOpen(false)
              }}
              handleConfirm={() => onSubmit('APPROVED')(getValues())}
              disabled={isSubmitting || loadingUpdateSupplementFact}
            />
            <CustomTabPanel value={tabValue} index={0}>
              <Box sx={{ paddingBottom: '30px', paddingTop: '20px', paddingLeft: '20px' }}>
                {!supplementFactId && lastVersion && !isBlankVersion && (
                  <FormControl required={false} size='small' sx={{ minWidth: '250px', paddingRight: '20px' }}>
                    <InputLabel>Select version</InputLabel>
                    <Select
                      value={selectedSupplFact}
                      name='Major Version'
                      label='Major Version'
                      onChange={(event) => {
                        setSelectedSupplFact(event.target.value)
                        const {
                          revisionNumber,
                          otherIngredients,
                          caution,
                          allergenStatement,
                          suggestedUse,
                          supplementLabelImage,
                          prop65WarningImage,
                        } = dataListSupplementFact.listSupplementFacts.find(
                          (supplFact: any) => supplFact.id === event.target.value
                        )
                        reset({
                          ...getValues(),
                          supplementLabelImage,
                          prop65WarningImage,
                          revisionNumber,
                          otherIngredients,
                          caution,
                          allergenStatement,
                          suggestedUse,
                        })
                      }}
                    >
                      {dataListSupplementFact?.listSupplementFacts.map((supplementFact: any) => {
                        return (
                          <MenuItem key={supplementFact.id} value={supplementFact.id}>
                            {supplementFact.majorVersion}.{supplementFact.minorVersion}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                )}
                {isNewMajorVersion && lastVersion && (
                  <Button
                    sx={{ verticalAlign: 'bottom', marginLeft: '0px' }}
                    variant='outlined'
                    size='small'
                    onClick={() => {
                      if (!isBlankVersion) {
                        setSelectedSupplFact('')
                        reset(initialValues)
                      }
                      setIsBlankVersion((isBlank) => !isBlank)
                    }}
                  >
                    {isBlankVersion ? 'Initialize data from an existing version' : 'Reset form'}
                  </Button>
                )}
              </Box>
              <Grid
                container
                sx={{ maxWidth: '800px', paddingLeft: '20px', paddingRight: '20px' }}
                spacing={2}
              >
                <Grid item xs={12}>
                  <FormTextField
                    name='revisionNumber'
                    label='Revision Number'
                    type='text'
                    size='medium'
                    fullWidth
                    variant='outlined'
                    InputProps={{ readOnly: !(isDraft || isNewMajorVersion) }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField
                    name='otherIngredients'
                    label='Other ingredients'
                    type='text'
                    size='medium'
                    fullWidth
                    variant='outlined'
                    InputProps={{ readOnly: !(isDraft || isNewMajorVersion) }}
                    control={control}
                    multiline
                    minRows={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField
                    name='caution'
                    label='Caution'
                    type='text'
                    fullWidth
                    size='medium'
                    variant='outlined'
                    InputProps={{ readOnly: !(isDraft || isNewMajorVersion) }}
                    control={control}
                    multiline
                    minRows={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField
                    name='allergenStatement'
                    label='Allergen Statement'
                    type='text'
                    fullWidth
                    size='medium'
                    variant='outlined'
                    InputProps={{ readOnly: !(isDraft || isNewMajorVersion) }}
                    control={control}
                    multiline
                    minRows={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField
                    name='suggestedUse'
                    label='Suggested Use'
                    type='text'
                    fullWidth
                    size='medium'
                    variant='outlined'
                    InputProps={{ readOnly: !(isDraft || isNewMajorVersion) }}
                    control={control}
                    multiline
                    minRows={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>Supplement Fact Image</Typography>
                  <DirectUpload
                    assetType='supplementFact'
                    orgId={organizationIdVar}
                    disabled={!(isDraft || isNewMajorVersion)}
                    value={buildBucketUrlFromKey(watch('supplementLabelImage'))}
                    onStarted={() => setBusy(true)}
                    onFinished={() => setBusy(false)}
                    setValue={(value: any) => {
                      setValue('supplementLabelImage', value || '', {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                      })
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>CA Proposition 65 Warning Image</Typography>
                  <DirectUpload
                    assetType='prop65Warning'
                    orgId={organizationIdVar}
                    disabled={!(isDraft || isNewMajorVersion)}
                    value={buildBucketUrlFromKey(watch('prop65WarningImage'))}
                    onStarted={() => setBusy(true)}
                    onFinished={() => setBusy(false)}
                    setValue={(value: any) => {
                      setValue('prop65WarningImage', value || '', {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                      })
                    }}
                  />
                </Grid>
                {status !== 'APPROVED' && (
                  <Grid container columnSpacing={2}>
                    <Grid item xs={6}>
                      <Button variant='contained' fullWidth type='submit' disabled={isDisabledCreateButton}>
                        {isNewMajorVersion ? 'Save Draft' : 'Update Draft'}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant='contained'
                        fullWidth
                        type='button'
                        onClick={(e) => handleTabChange(e, 1)}
                        disabled={busy}
                      >
                        Preview
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <LabelFactsPreview
                skuDetails={{
                  product_supplement_facts: buildBucketUrlFromKey(getValues('supplementLabelImage')),
                  product_name: skuData.getSku.skuFact.privateLabelName,
                  product_caution: getValues('caution'),
                  product_ingredients: getValues('otherIngredients'),
                  product_suggested_use: getValues('suggestedUse'),
                  product_allergy_statement: getValues('allergenStatement'),
                  product_prop_65_warning: buildBucketUrlFromKey(getValues('prop65WarningImage'))
                }}
                skuId={dataSupplementFact?.getSupplementFact?.skuId || skuId}
              />
              {status !== 'APPROVED' && (
                <Stack direction='row' spacing={2} mt={4}>
                  <Button variant='contained' fullWidth type='submit' disabled={isDisabledCreateButton}>
                    {supplementFactId && status === 'DRAFT' ? 'Update Draft' : 'Save Draft'}
                  </Button>
                  <Button
                    variant='contained'
                    fullWidth
                    disabled={busy}
                    onClick={() => {
                      trigger().then((isValid) => {
                        if (isValid) setOpen(true)
                      })
                    }}
                  >
                    Publish
                  </Button>
                </Stack>
              )}
            </CustomTabPanel>
          </form>
        </Paper>
      </Box>
    </Stack>
  )
}
