import React, { useState, useCallback } from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { AuthChallengeError, AuthChallengeType, cognitoOps } from 'lib/Cognito.tsx'
import { Box, Container, Link, Paper, Typography } from '@mui/material'
import SignInForm from './SignInForm.tsx'
import SetNewPasswordForm from './SetNewPasswordForm.tsx'
import { accessToken } from 'store/user'
import { useRecoilState } from 'recoil'
import KaerwellLogo from 'assets/kaerwell-logo-2.webp'
import GoogleIcon from '@mui/icons-material/Google'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

export interface AuthFormProps {
  challenge?: AuthChallengeError
  callback: (challenge?: AuthChallengeError, user?: any) => any
}

export default function LoginPage(): React.ReactElement {
  const [authChallenge, setAuthChallenge] = useState<AuthChallengeError>()
  const [trigger, setTrigger] = useState(0.0)
  const navigate = useNavigate()
  const [token, setAccessToken] = useRecoilState(accessToken)

  const [searchParams] = useSearchParams()
  const state = searchParams.get('state') as string

  const title = {
    [AuthChallengeType.NEW_PASSWORD_REQUIRED]: 'New Password Required',
    [AuthChallengeType.MFA_SETUP]: 'Setup MFA',
    [AuthChallengeType.MFA_CODE]: 'Enter your MFA Code',
    default: 'Sign In',
  }[authChallenge?.challengeType || 'default']

  const message = {
    [AuthChallengeType.NEW_PASSWORD_REQUIRED]: 'Set and confirm your new password',
    [AuthChallengeType.MFA_SETUP]: 'Setup a one-time password generator with an authenticator app',
    [AuthChallengeType.MFA_CODE]: 'Enter a one-time password from your authenticator app',
    default: 'Enter your email address and password',
  }[authChallenge?.challengeType || 'default']

  const authCallback = useCallback(
    async (error: any, result: any) => {
      if (error instanceof AuthChallengeError) {
        return setAuthChallenge(error)
      }
      if (error) {
        alert(error)
      }
      setAuthChallenge(undefined)
      setTrigger(Math.random())
      if (result) {
        const session = await cognitoOps?.getSession()
        const idToken = session?.getIdToken().getJwtToken()

        const accessToken = session?.getAccessToken().getJwtToken()
        setAccessToken(accessToken)
      }
    },
    [setTrigger, navigate, cognitoOps]
  )

  const redirectToGoogleAuth = () => {
    window.location.href = `https://${
      import.meta.env.VITE_COGNITO_DOMAIN
    }/oauth2/authorize?identity_provider=Google&response_type=code&client_id=${
      import.meta.env.VITE_COGNITO_CLIENT_ID
    }&redirect_uri=${import.meta.env.VITE_COGNITO_CALLBACK}/oauth&access_type=offline&state=${state}`
  }
  return token ? (
    <Navigate to={'/'} />
  ) : (
    <Paper sx={{ background: '#d2e7f6', height: '100vh', minHeight: '700px' }}>
      <Box display='flex' justifyContent='center' alignItems='center'>
        <Container maxWidth='sm' sx={{ textAlign: 'center', px: '5%', py: '10%' }}>
          <div
            style={{
              background: 'white',
              borderRadius: '20px',
              border: '2px solid #406373',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img alt={'Kaerwell Inc.'} src={KaerwellLogo} style={{ width: '100%' }} />
            <Button
              onClick={() => redirectToGoogleAuth()}
              variant={'text'}
              sx={{ mb: '25px', padding: '2px 15px', borderRadius: '20px', border: '2px solid #406373' }}
            >
              <Stack direction={'row'}>
                <GoogleIcon sx={{ color: 'blue', marginTop: '10px' }} />
                <Typography
                  sx={{ textAlign: 'left', marginTop: '10px', mb: '.5rem', ml: '10px', mr: '10px' }}
                >
                  Kaerwell Team
                </Typography>
              </Stack>
            </Button>

            {message && (
              <Typography sx={{ textAlign: 'left', mb: '.5rem', ml: '10px', mr: '10px' }}>
                {message}
              </Typography>
            )}

            {!authChallenge && <SignInForm callback={authCallback} />}
            {authChallenge && (
              <>
                {authChallenge.challengeType === AuthChallengeType.NEW_PASSWORD_REQUIRED && (
                  <SetNewPasswordForm challenge={authChallenge} callback={authCallback} />
                )}
              </>
            )}
            <Box sx={{ mt: '1rem' }}>
              <Link href='/forgot-password' variant='subtitle2'>
                Forgot Password?
              </Link>
            </Box>
          </div>
        </Container>
      </Box>
    </Paper>
  )
}
