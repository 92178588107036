import { useMemo, useState } from 'react'
import {
  Paper,
  Button,
  Typography,
  Box,
  Stack,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { DataGridPro, GridRenderCellParams, GridToolbarContainer } from '@mui/x-data-grid-pro'
import { ChevronLeft, Add } from '@mui/icons-material'
import { useMutation, useQuery } from '@apollo/client'
import { Link, useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'

import { LIST_SUPPLEMENT_FACTS } from 'lib/graphql/listSupplementFacts'
import { CREATE_SUPPLEMENT_FACT } from 'lib/graphql/createSupplementFact'
import LotList from 'pages/ProductsTable/LotList.tsx'
import { GET_SKU } from 'lib/graphql/getSku.ts'

const urlPathBase = `/vendor/label-facts`

interface AddToolbarProps {
  handleCreateMinorVersion: () => void
  canCreateNewMinorVersion: boolean
  disabled?: boolean
}

const AddToolbar = ({ handleCreateMinorVersion, canCreateNewMinorVersion, disabled }: AddToolbarProps) => {
  return (
    <GridToolbarContainer>
      <Button
        color='primary'
        startIcon={<Add />}
        onClick={handleCreateMinorVersion}
        disabled={!canCreateNewMinorVersion || disabled}
      >
        Create new minor
      </Button>
    </GridToolbarContainer>
  )
}

type Status = 'APPROVED' | 'DRAFT'

const formatData = (data: any, version: number) => {
  return data
    .filter((supplementFact: any) => supplementFact.majorVersion === version)
    .sort((item: any, next: any) => next.minorVersion - item.minorVersion)
    .map((supplementFact: any) => ({
      id: supplementFact.id,
      version: `${supplementFact.majorVersion}.${supplementFact.minorVersion}`,
      status:
        supplementFact.status[0] + supplementFact.status.toLowerCase().slice(1, supplementFact.status.length),
      action: supplementFact.status === 'APPROVED' ? 'VIEW' : 'EDIT',
    }))
}

const isApproved = (status: Status) => status === 'APPROVED'

export const LabelFactsMajorVersion = () => {
  const params = useParams()

  const [selectedMajor, setSelectedMajor] = useState(params.version || '1')

  const navigate = useNavigate()
  const { organizationId, skuId, version } = useParams()

  const { data: skuData } = useQuery(GET_SKU, {
    variables: {
      where: {
        id: skuId,
      },
    },
    fetchPolicy: 'no-cache',
  })

  const { data, loading, error, refetch } = useQuery(LIST_SUPPLEMENT_FACTS, {
    variables: {
      where: {
        sku: { brand: { organizationId: { equals: organizationId } }, id: { equals: skuId } },
      },
      skip: 0,
      take: 50,
    },
    fetchPolicy: 'no-cache',
  })

  const [createSupplementFact, { loading: loadingCreateSupplementFact }] = useMutation(CREATE_SUPPLEMENT_FACT)

  const columns = useMemo(
    () => [
      {
        field: 'version',
        headerName: 'Version',
        headerClassName: 'mui-grid-header--mobile',
        minWidth: 130,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Typography fontWeight={700} color='primary'>
              {params.value}
            </Typography>
          )
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        headerClassName: 'mui-grid-header--mobile',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Typography color='#001D35' fontWeight={600}>
              {params.value}
            </Typography>
          )
        },
      },
      {
        field: 'action',
        headerName: 'Action',
        width: 90,
        flex: 1,
        headerClassName: 'mui-grid-header--mobile',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Link to={`${urlPathBase}/details/${organizationId}/${skuId}/${params.row.id}`}>
              <Button type='button' variant='contained' color='primary'>
                {params.value}
              </Button>
            </Link>
          )
        },
      },
    ],
    [organizationId, skuId]
  )

  if (!data) {
    return <></>
  }

  const mostRecentLabelDataVersion = data.listSupplementFacts
    .filter((supplFact: any) => supplFact.majorVersion.toString() === selectedMajor)
    .toSorted((item: any, next: any) => next.minorVersion - item.minorVersion)[0]

  const hasAnyApproved = data.listSupplementFacts
    .filter((supplFact: any) => supplFact.majorVersion.toString() === selectedMajor)
    .some((supplFact: any) => isApproved(supplFact.status))

  const canCreateNewMinorVersion = mostRecentLabelDataVersion.status === 'APPROVED'

  const majorVersions = data.listSupplementFacts.map((row: any) => ({
    version: row.majorVersion,
    id: row.majorVersionUuid,
    status: row.status,
  }))

  const handleCreateMinorVersion = () => {
    createSupplementFact({
      variables: {
        data: {
          sku: {
            connect: {
              id: mostRecentLabelDataVersion.sku.id,
            },
          },
          majorVersion: mostRecentLabelDataVersion.majorVersion,
          minorVersion: mostRecentLabelDataVersion.minorVersion + 1,
          revisionNumber: mostRecentLabelDataVersion.revisionNumber,
          otherIngredients: mostRecentLabelDataVersion.otherIngredients,
          caution: mostRecentLabelDataVersion.caution,
          allergenStatement: mostRecentLabelDataVersion.allergenStatement,
          suggestedUse: mostRecentLabelDataVersion.suggestedUse,
          supplementLabelImage: mostRecentLabelDataVersion.supplementLabelImage,
          prop65WarningImage: mostRecentLabelDataVersion.prop65WarningImage,
        },
      },
      onCompleted() {
        refetch()
      },
    })
  }

  const handleVersionChange = (event: SelectChangeEvent<string>) => {
    setSelectedMajor(event.target.value)
    // the replace prop is needed otherwise the Back button on this page wont work
    navigate(`../${event.target.value}`, { replace: true, relative: 'path' })
  }

  return (
    <Stack direction={{ sm: 'column' }} alignItems={'center'}>
      <Box sx={{ maxWidth: 'md', width: '100%' }}>
        <Paper
          elevation={0}
          sx={{
            paddingY: 1,
            marginBottom: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
          square
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignSelf: 'stretch',
            }}
          >
            <Button variant='text' onClick={() => navigate(`${urlPathBase}`)}>
              <ChevronLeft /> BACK
            </Button>
          </Box>
          <Box>
            <Typography variant='h5' sx={{ flex: 1 }} textAlign='center'>
              {skuData?.getSku.skuWhiteLabel} &mdash; {skuData?.getSku.skuFact.privateLabelName}
            </Typography>
            <Typography variant={'body1'} sx={{ flex: 1 }} textAlign='center'>
              Label Facts Manager
            </Typography>
          </Box>
        </Paper>
        <Paper
          elevation={1}
          sx={{
            padding: 2,
            marginBottom: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <FormControl required size='small' sx={{ minWidth: '280px', m: 1, maxWidth: 380 }}>
            <InputLabel>Major Version</InputLabel>
            <Select
              value={selectedMajor}
              name='Major Version'
              label='Major Version'
              autoWidth
              onChange={handleVersionChange}
            >
              {_.uniqBy(data?.listSupplementFacts || [], 'majorVersion').map((supplementFact: any) => {
                return (
                  <MenuItem key={supplementFact.id} value={supplementFact.majorVersion.toString()}>
                    {supplementFact.majorVersion}.x
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Paper>
        <Paper
          elevation={1}
          sx={{
            padding: 2,
            marginBottom: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant='h6' sx={{ flex: 1 }} textAlign='left'>
            Associated Lots
          </Typography>
          <LotList
            product={mostRecentLabelDataVersion.sku.sku as string}
            skuId={mostRecentLabelDataVersion.sku.id}
            majorVersionUuid={mostRecentLabelDataVersion.majorVersionUuid}
            majorVersions={majorVersions}
            canCreate={hasAnyApproved}
          />
        </Paper>
        <DataGridPro
          autoHeight
          rows={formatData(data?.listSupplementFacts || [], mostRecentLabelDataVersion.majorVersion)}
          columns={columns}
          slots={{
            toolbar: AddToolbar,
          }}
          // initialState={{
          //   sorting: {
          //     sortModel: [{ field: 'minorVersion', sort: 'asc' }],
          //   },
          // }}
          slotProps={{
            toolbar: {
              handleCreateMinorVersion,
              canCreateNewMinorVersion,
              disabled: loadingCreateSupplementFact,
            },
          }}
        />
      </Box>
    </Stack>
  )
}
