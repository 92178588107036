import { gql } from '@apollo/client'

export const GET_SKU = gql`
  query getSku($where: SkuWhereUniqueInput!) {
    getSku(where: $where) {
      id
      sku
      skuWhiteLabel
      skuFact {
        privateLabelName
        productName
      }
      createdAt
    }
  }
`
