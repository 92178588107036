import { apolloClient } from 'lib/AppSyncProvider'
import { useApolloClient, useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import { Box, Tooltip, Typography, Divider } from '@mui/material'
import {
  DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-premium'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import EmailIcon from '@mui/icons-material/MailOutline'
import CancelIcon from '@mui/icons-material/Cancel'
import ReportFilters, { ReportFiltersType } from 'pages/ReportsApp/ReportFilters.tsx'
import { PROXY_SHOPIFY_QUERY } from 'lib/graphql/proxyShopifyQuery.ts'
import { organizationId, selectedOrganization, stores } from 'config/cache.ts'
import { SKU_LISTINGS_LIST } from 'lib/graphql/listSkuListings.ts'
import { GridCsvExportOptions } from '@mui/x-data-grid-pro'
import { LIST_SHOPIFY_STORE_CONFIGURATIONS } from 'lib/graphql/listShopifyStoreConfigurations.ts'
import { LineItem } from 'pages/VendorApp/Shop/types.ts'
import { ShopifyStoreConfiguration, SkuListing } from 'lib/graphql/types.ts'
import { ShopifyLineItem, ShopifyOrder } from 'lib/types.ts'
import { currentDateTime, formatDate, formatDateTime, formatTime, usdFormatter } from 'pages/ReportsApp/utils'
import { renderOrderFinancialStatus, renderOrderFulfillmentStatus } from 'pages/ReportsApp/utils.tsx'

const PAGE_SIZE_STORE = 50
const PAGE_SIZE_ORDERS = 100
type EnrichedOrders = Partial<ShopifyOrder> & {
  storeId: string
  wholesalePricing: number
  serviceFeeAmount: number
  serviceFeePercent: number
  lineItem: ShopifyLineItem
}

export const BillingReport = () => {
  const selectedOrganizationIdVar = useReactiveVar(organizationId)
  const organization = useReactiveVar(selectedOrganization)
  const storesVar = useReactiveVar(stores)
  const [orgStores, setOrgStores] = useState<string[]>([])
  // const [orders, setOrders] = useState<EnrichedOrders[]>([])
  // const [ordersByStore, setOrdersByStore] = useState<{ [storeId: string]: EnrichedOrders[] }>({})
  const [reportFilters, setReportFilters] = useState<ReportFiltersType>()

  const { data: storeData, loading: loadingStores } = useQuery(LIST_SHOPIFY_STORE_CONFIGURATIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        organizationId: { equals: selectedOrganizationIdVar },
      },
      take: PAGE_SIZE_STORE,
    },
    skip: !selectedOrganizationIdVar,
    onCompleted: (data) => {
      // setOrdersByStore({})
      // setOrders([])
      setOrgStores(data?.listShopifyStoreConfigurations.map((x: ShopifyStoreConfiguration) => x.id))
    },
  })

  const { data: skuData, loading: loadingSkuListings } = useQuery(SKU_LISTINGS_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      organizationId: { id: organizationId },
      offset: 0,
      limit: 500,
    },
  })

  const [lazyShopifyQuery, { loading: loadingShopify, data }] = useLazyQuery(PROXY_SHOPIFY_QUERY)

  const generateQuery = (filters: ReportFiltersType) => {
    if (!filters?.dateFilter.to || !filters?.dateFilter.from) {
      return ''
    } else {
      return `created_at:>='${filters.dateFilter.from.format(
        'YYYY-MM-DD'
      )}' created_at:<='${filters.dateFilter.to.format('YYYY-MM-DD[T23:59:59Z]')}'`
    }
  }

  useEffect(() => {
    if (!reportFilters || !orgStores || orgStores?.length === 0) return
    console.log('Getting orders for', { orgStores })
    orgStores?.map((storeId) => {
      lazyShopifyQuery({
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        variables: {
          query: 'orders',
          shopifyStoreId: storeId,
          variables: JSON.stringify({
            first: PAGE_SIZE_ORDERS,
            reverse: true,
            query: generateQuery(reportFilters),
          }),
        },
      })
    })
  }, [orgStores, reportFilters, lazyShopifyQuery])

  const results = orgStores?.map((storeId) => {
    return apolloClient.readQuery({
      query: PROXY_SHOPIFY_QUERY,
      // Provide any required variables in this object.
      // Variables of mismatched types will return `null`.
      variables: {
        query: 'orders',
        shopifyStoreId: storeId,
        variables: JSON.stringify({
          first: 100,
          reverse: true,
          query: generateQuery(reportFilters!),
        }),
      },
    })
  })

  const resultsByStore: any = orgStores?.reduce(
    (prevValue, storeId, index) => ({
      ...prevValue,
      [storeId]: _.flatten(
        results?.[index]?.proxyShopifyQuery.orders?.data?.map((node: any) => {
          return node.lineItems.nodes.map((lineItem: LineItem, index: number) => {
            return {
              ...node,
              id: `${node.id}-${index}`,
              shopifyId: node.id,
              lineItem: {
                ...lineItem,
                productName: lineItem.customAttributes.find((x) => x.key === 'Product Name')?.value,
                serviceFeePercent:
                  !['PAID', 'PARTIALLY_REFUNDED'].includes(node.displayFinancialStatus) || node.cancelledAt
                    ? 0
                    : lineItem.variantTitle === 'White label'
                    ? 0.2
                    : 0.15,
              },
            }
          })
        })
      ),
    }),
    {}
  ) || {}

  const resultOrders = resultsByStore ? _.flatten(
    Object.keys(resultsByStore).map((storeId) => {
      return (resultsByStore[storeId] || []).map((order: any) => ({
        ...order,
        id: order.id,
        storeId: storeId,
        storeName: storesVar?.find((x) => x.id === storeId)?.name,
        wholesalePricing: parseFloat(
          skuData?.listSkuListings.find(
            (x: SkuListing) => x.sku.skuWhiteLabel === order.lineItem.sku || x.sku.sku === order.lineItem.sku
          )?.wholesalePricing || '0'
        ),
        serviceFeeAmount:
          (skuData?.listSkuListings.find(
            (x: SkuListing) => x.sku.skuWhiteLabel === order.lineItem.sku || x.sku.sku === order.lineItem.sku
          )?.wholesalePricing || 0) *
          (order.lineItem.serviceFeePercent || 0) *
          order.lineItem.currentQuantity,
      }))
    })
  ) : []

  const handleRunReport = (v: ReportFiltersType) => {
    setReportFilters(v)
  }

  const columns: GridColDef[] = [
    {
      field: 'storeName',
      headerName: 'Store',
      minWidth: 80,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Order',
      minWidth: 80,
      flex: 1,
    },
    {
      field: 'createdAtDate',
      headerName: 'Order Date',
      valueGetter: (params) => {
        return formatDate(params.row.createdAt)
      },
      renderCell: (params) => {
        return formatDate(params.row.createdAt)
      },
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'createdAtTime',
      headerName: 'Order Time',
      valueGetter: (params) => {
        return formatTime(params.row.createdAt)
      },
      renderCell: (params) => {
        return formatTime(params.row.createdAt)
      },
      minWidth: 80,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 80,
      aggregable: false,
      flex: 0,
      valueGetter: (params) => {
        return params.row.cancelledAt ? 'Cancelled' : 'Live'
      },

      renderCell: (params) => {
        if ((params.id as string).match(/footer/)) {
          return ''
        }
        return params.row.cancelledAt ? (
          <Tooltip title={'Cancelled'}>
            <CancelIcon sx={{ color: '#8B0000' }} />
          </Tooltip>
        ) : (
          <>
            <>
              {renderOrderFinancialStatus(params.row.displayFinancialStatus)}
              {renderOrderFulfillmentStatus(params.row.displayFulfillmentStatus)}
            </>
          </>
        )
      },
    },
    {
      field: 'cancelledAt',
      headerName: 'Cancelled At',
      valueGetter: (params) => {
        return formatDateTime(params.row.cancelledAt)
      },
      minWidth: 80,
      flex: 1,
    },
    {
      field: 'displayFinancialStatus',
      headerName: 'Payment',
    },
    {
      field: 'displayFulfillmentStatus',
      headerName: 'Shipping',
    },
    {
      field: 'displayName',
      headerName: 'Customer',
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => {
        return params.row.customer?.displayName
      },
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
            <Box>
              {params.row.customer?.email ? (
                <Tooltip title={params.row.customer?.email}>
                  <a href={`mailto:${params.row.customer?.email}`} style={{ display: 'flex' }}>
                    <EmailIcon />
                  </a>
                </Tooltip>
              ) : (
                <></>
              )}
            </Box>
            <div>{params.row.customer?.displayName}</div>
          </Box>
        )
      },
    },
    {
      field: 'accountName',
      headerName: 'Account',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return params.row.customer?.accountName
      },
    },
    {
      field: 'accountManager',
      headerName: 'Sales Representative',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return params.row.customer?.accountManager
      },
    },
    {
      field: 'email',
      headerName: 'E-mail',
      hideable: true,
      align: 'left',
      valueGetter: (params) => {
        return params.row.customer?.email
      },
    },
    {
      field: 'customerProductName',
      headerName: 'PL Name',
      valueGetter: (params) => {
        return params.row.lineItem?.productName
      },
      flex: 1,
      minWidth: 200,
      align: 'left',
      type: 'string',
    },
    {
      field: 'productName',
      headerName: 'Product Name',
      valueGetter: (params) => {
        return params.row.lineItem?.title
      },
      type: 'string',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'sku',
      headerName: 'SKU',
      valueGetter: (params) => {
        return params.row.lineItem?.sku
      },
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'currentQuantity',
      headerName: 'Quantity',
      valueGetter: (params) => {
        if (params.row.cancelledAt) {
          return null
        }
        return params.row.lineItem?.currentQuantity
      },
      width: 100,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
    },
    {
      field: 'total',
      headerName: 'Total ($)',
      valueGetter: (params) => {
        if (params.row.cancelledAt || params.row.lineItem?.currentQuantity == 0) {
          return null
        }
        return parseFloat(params.row.lineItem?.discountedTotalSet?.shopMoney.amount) || 0
      },
      valueFormatter: (value) => {
        if (!value.value) {
          return null
        }
        return usdFormatter.format(value.value)
      },
      width: 140,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
    },
    {
      field: 'netUnitPrice',
      type: 'number',
      headerName: 'Total ($/unit)',
      valueGetter: (params) => {
        if (params.row.cancelledAt || params.row.lineItem?.currentQuantity == 0) {
          return null
        }
        return (
          parseFloat(params.row.lineItem?.discountedTotalSet?.shopMoney.amount || '0') /
          (params.row.lineItem?.quantity || 1)
        )
      },
      valueFormatter: (value) => {
        if (!value.value) {
          return null
        }
        return usdFormatter.format(value.value)
      },
      minWidth: 100,
      flex: 1,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'billingPrice',
      headerName: 'Wholesale ($)',
      valueGetter: (params) => {
        if (params.row.cancelledAt) {
          return null
        }
        return (
          (params.row.lineItem?.currentQuantity || 0) *
          (resultOrders?.find((x) => x.id === params.row.id)?.wholesalePricing || 0)
        )
      },
      valueFormatter: (value) => {
        if (!value.value) {
          return null
        }
        return usdFormatter.format(value.value)
      },
      minWidth: 130,
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
    },
    {
      field: 'baseWholesalePrice',
      headerName: 'Wholesale ($/unit)',
      valueGetter: (params) => {
        if (params.row.cancelledAt || params.row.lineItem?.currentQuantity == 0) {
          return null
        }
        return resultOrders?.find((x) => x.id === params.row.id)?.wholesalePricing
      },
      valueFormatter: (value) => {
        if (!value.value) {
          return null
        }
        return usdFormatter.format(value.value)
      },
      align: 'right',
      headerAlign: 'right',
      minWidth: 130,
      flex: 1,
      type: 'number',
    },
    {
      field: 'serviceFeePercent',
      headerName: 'Service Fee (%)',
      valueGetter: (params) => {
        if (params.row.cancelledAt || params.row.lineItem?.currentQuantity == 0) {
          return null
        }
        return params.row.lineItem?.serviceFeePercent || 0
      },
      valueFormatter: (value) => {
        if (!value.value) {
          return null
        }
        return `${Math.round(value.value * 100)} %`
      },
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      minWidth: 170,
      type: 'number',
    },
    {
      field: 'serviceFeeAmount',
      headerName: 'Service Fee ($)',
      valueGetter: (params) => {
        if (params.row.cancelledAt) {
          return null
        }
        return resultOrders?.find((x) => x.id === params.row.id)?.serviceFeeAmount || 0
      },
      valueFormatter: (value) => {
        if (!value.value) {
          return null
        }
        return usdFormatter.format(value.value)
      },
      align: 'right',
      headerAlign: 'right',
      type: 'number',
      minWidth: 180,
    },
  ]

  const csvOptions: GridCsvExportOptions = {
    allColumns: true,
    fileName: `${currentDateTime()}_BillingReport_${selectedOrganizationIdVar}`,
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} csvOptions={csvOptions} />
      </GridToolbarContainer>
    )
  }

  if (!organization) return <></>
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ height: '50px' }}>
          <Typography variant={'h5'}>Billing Report </Typography>
        </Box>
        <ReportFilters disabled={loadingShopify} onSubmit={handleRunReport} />
        <Divider />
        <Box sx={{ height: 'calc(100vh - 220px)' }}>
          <Box
            sx={{
              paddingY: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'top',
            }}
          >
            <Typography variant={'h6'}>{organization?.name}</Typography>
            {reportFilters?.dateFilter?.from && reportFilters?.dateFilter?.to && (
              <Typography variant={'body1'}>
                {reportFilters.dateFilter.from.format('MMMM D, YYYY')}
                &nbsp;&ndash;&nbsp;
                {reportFilters.dateFilter.to.format('MMMM D, YYYY')}
              </Typography>
            )}

            <Typography variant={'body1'}>
              {loadingShopify
                ? '...'
                : _.sum(Object.keys(resultsByStore).map((x) => resultsByStore[x].length || 0))}{' '}
              Items in {loadingShopify ? '...' : _.uniq(resultOrders.map((x) => `${x.storeId}_${x.name}`)).length}{' '}
              Orders
            </Typography>
          </Box>
          <Box
            sx={{
              height: 'calc(100% - 80px)',
              display: 'grid',
              gridTemplateRows: 'auto 1f auto',
              borderRadius: '16px',
              p: 1,
            }}
          >
            <DataGridPremium
              sx={{
                // height: 'contain',
                borderLeftWidth: 0,
                borderRightWidth: 0,
                borderTopWidth: 0,
              }}
              initialState={{
                aggregation: {
                  model: {
                    currentQuantity: 'sum',
                    total: 'sum',
                    netUnitPrice: 'avg',
                    billingPrice: 'sum',
                    baseWholesalePrice: 'avg',
                    serviceFeePercent: 'avg',
                    serviceFeeAmount: 'sum',
                  },
                },
              }}
              aggregationFunctions={{
                ...GRID_AGGREGATION_FUNCTIONS,
              }}
              // filterMode={'server'}
              // onFilterModelChange={onFilterChange}
              columnVisibilityModel={{
                email: false,
                cancelledAt: false,
                displayFinancialStatus: false,
                displayFulfillmentStatus: false,
                createdAtTime: false,
              }}
              rows={resultOrders || []}
              hideFooterRowCount={true}
              hideFooter={true}
              columns={columns}
              columnHeaderHeight={90}
              density={'compact'}
              hideFooterPagination
              scrollEndThreshold={150}
              loading={loadingStores || loadingSkuListings || loadingShopify}
              slots={{
                toolbar: CustomToolbar,
              }}
              disableMultipleRowSelection={true}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
