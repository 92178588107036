import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Controller, Control, FieldValues } from 'react-hook-form'

export const FormSelect = ({
  name,
  label,
  control,
  withName,
  data,
  disabled,
  readonly,
}: {
  label?: string
  control: Control<FieldValues> | undefined
  withName: boolean
  data: { value: string; label: string }[]
  name: string
  disabled?: boolean
  readonly?: boolean
}) => {
  const minWidth = withName ? '300px' : '200px'

  return (
    <Controller
      name={name}
      control={control}
      disabled={disabled}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl sx={{ m: 1, minWidth, mt: 0 }}>
          {/* <InputLabel id='demo-simple-select-label'>{label}</InputLabel> */}
          <Select
            labelId='demo-simple-select-label'
            label={label}
            name={name}
            // inputProps={{ readOnly: readonly }}
            fullWidth
            value={value}
            variant='standard'
            sx={{ mt: 0 }}
            onChange={(v) => {
              onChange(v)
            }}
          >
            {data.map((item) => {
              return (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      )}
    />
  )
}
