import { gql } from '@apollo/client'

export const UPDATE_SHOPIFY_STORE_CONFIGURATION = gql`
  mutation updateShopifyStoreConfiguration(
    $where: ShopifyStoreConfigurationExtendedWhereUniqueInput!
    $data: ShopifyStoreConfigurationUpdateInput
  ) {
    updateShopifyStoreConfiguration(where: $where, data: $data) {
      id
    }
  }
`
