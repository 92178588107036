import { gql } from '@apollo/client'

export const BRANDS_LIST = gql`
  query listBrands($where: BrandWhereInput, $take: Int, $skip: Int) {
    listBrands(where: $where, take: $take, skip: $skip, orderBy: [{ name: ASC }]) {
      id
      name
      organization {
        name
      }
      archivedAt
    }
  }
`
