import { ThemeProvider, createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#0062A0',
    },
    background: {
      default: '#FAFAFA',
      paper: '#FFFFFF',
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          // Use existing space / prevents shifting content below field
          marginTop: 0,
          height: 0,
        },
      },
    },
  },
})

export const Theme = ({ children }: { children: JSX.Element | JSX.Element[] }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)
