import { useQuery, useReactiveVar } from '@apollo/client'
import { Alert, Box, Button, Dialog, LinearProgress, Paper, Typography } from '@mui/material'
import { DataGridPro, GridColDef, GridPaginationModel, GridRowId } from '@mui/x-data-grid-pro'
import React, { useEffect, useState } from 'react'
import { organizationId } from 'config/cache'
import dayjs from 'dayjs'
import CheckIcon from '@mui/icons-material/Check'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import { OrderLabel } from 'lib/types.ts'
import { useSearchParams } from 'react-router-dom'
import { LIST_ORDER_LABELS } from 'lib/graphql/listOrderLabels.ts'
import { COUNT_ORDER_LABELS } from 'lib/graphql/countOrderLabels.ts'
import { LabelViewer } from 'components/LabelViewer'
import { DataGridPremium } from '@mui/x-data-grid-premium'

function renderApproval(v: any) {
  return v.value ? <CheckIcon sx={{ color: 'green' }} /> : <DoNotDisturbIcon sx={{ color: '#f5c3c3' }} />
}

function formatDateTime(v: Date) {
  return v ? dayjs(v).format('M/D/YYYY h:mm A') : ''
}

const columns: GridColDef[] = [
  {
    field: 'orderNumber',
    headerName: 'Order',
    flex: 1,
  },
  {
    field: 'skuName',
    headerName: 'SKU',
    flex: 1,
  },
  {
    field: 'bucketKey',
    headerName: 'Label',
    flex: 0.5,
    renderCell: (v) => {
      return renderApproval(v)
    },
  },
  {
    field: 'lotNumber',
    headerName: 'Lot#',
    flex: 1,
  },
  {
    field: 'expirationDate',
    headerName: 'Expiration Date',
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Ordered On',
    flex: 1,
    renderCell: (v) => formatDateTime(v.value),
  },
]

type EditToolbarProps = {
  handleAdd: () => void
  handleEdit: () => void
  handleDelete: () => void
  enableEditButton: boolean
  enableDeleteButton: boolean
}

const PAGE_SIZE = 25

export const LabelOperations = () => {
  const organizationIdVar = useReactiveVar(organizationId)
  const [currentOrganization, setCurrentOrganization] = useState<string>()
  const [orderLabels, setOrderLabels] = useState<OrderLabel[]>([])
  const [countOrderLabels, setCountOrderLabels] = useState<number>(0)
  const [skip, setSkip] = useState(0)
  const [selectedLabel, setSelectedLabel] = useState<string>()

  useEffect(() => {
    if (organizationIdVar !== currentOrganization) {
      setCountOrderLabels(0)
      setOrderLabels([])
      setCurrentOrganization(organizationIdVar)
    }
  }, [organizationIdVar])

  const [pageInfo, setPageInfo] = useState<
    | {
        hasNextPage: boolean
        endCursor: string
      }
    | undefined
  >()

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: PAGE_SIZE,
  })
  const [rowCountState, setRowCountState] = React.useState(0)
  const mapPageToNextCursor = React.useRef<{
    [page: number]: GridRowId
  }>({})

  let [searchParams, setSearchParams] = useSearchParams()

  const { data: dataCountOrderLabels } = useQuery(COUNT_ORDER_LABELS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        order: {
          organization: {
            id: {
              equals: currentOrganization,
            },
          },
        },
      },
    },
    skip: !currentOrganization,
  })

  const {
    refetch: refetchOrderLabels,
    data: dataOrderLabels,
    loading: loadingOrderLabels,
    error: errorOrderLabels,
  } = useQuery(LIST_ORDER_LABELS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        order: {
          organization: {
            id: {
              equals: currentOrganization,
            },
          },
        },
      },
      take: PAGE_SIZE,
      skip,
      orderBy: [{ createdAt: 'DESC' }],
    },
    skip: !currentOrganization && !countOrderLabels,
  })

  const handleOnRowsScrollEnd = () => {
    if (orderLabels.length <= countOrderLabels) {
      setSkip(orderLabels.length)
    }
  }

  useEffect(() => {
    if (dataOrderLabels) {
      const loadedLabels = dataOrderLabels.listOrderLabels
      // setPageInfo(customers.pageInfo)
      setOrderLabels(
        (orderLabels || []).concat(
          loadedLabels.map((orderLabel: OrderLabel) => {
            return {
              ...orderLabel,
              orderNumber: orderLabel.order.orderNumber,
              skuName: orderLabel.sku.sku,
              path: [orderLabel.order.orderNumber, orderLabel.id],
              lotNumber: orderLabel.lot?.lotNumber,
              expirationDate: orderLabel.lot?.expirationDate,
            }
          })
        )
      )
    }
  }, [dataOrderLabels])

  useEffect(() => {
    if (dataCountOrderLabels) {
      const count = dataCountOrderLabels.countOrderLabels
      // setPageInfo(customers.pageInfo)
      setCountOrderLabels(count)
    }
  }, [dataCountOrderLabels, setCountOrderLabels])

  if (!currentOrganization) return <></>

  return (
    <>
      <Box sx={{ paddingBottom: 2 }}>
        <Typography variant={'h5'}>Ordered Private Labels</Typography>
      </Box>
      {/*{errorCustomers && <Alert severity={'error'}>{errorCustomers.message}</Alert>}*/}
      {orderLabels && (
        <Box sx={{ height: 'calc(40vh)' }}>
          <Paper
            elevation={5}
            sx={{
              height: 'inherit',
              display: 'grid',
              gridTemplateRows: 'auto 1f auto',
              borderRadius: '16px',
              p: 1,
            }}
          >
            <DataGridPremium
              sx={{
                borderLeftWidth: 0,
                borderRightWidth: 0,
                borderTopWidth: 0,
              }}
              density={'compact'}
              hideFooterRowCount={true}
              hideFooter={true}
              rows={orderLabels}
              rowCount={countOrderLabels}
              columns={columns}
              hideFooterPagination
              scrollEndThreshold={350}
              onRowsScrollEnd={handleOnRowsScrollEnd}
              loading={loadingOrderLabels}
              slots={{
                loadingOverlay: LinearProgress,
              }}
              disableMultipleRowSelection={true}
              // rowSelectionModel={rowSelectionModel}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                if (newRowSelectionModel.length === 1) {
                  setSelectedLabel(newRowSelectionModel[0] as string)
                }
              }}
            />
          </Paper>
        </Box>
      )}
      {selectedLabel && (
        <Box sx={{ paddingTop: '32px' }}>
          <Paper
            elevation={5}
            sx={{
              height: 'inherit',
              display: 'grid',
              gridTemplateRows: 'auto 1f auto',
              borderRadius: '16px',
              p: 1,
            }}
          >
            <LabelViewer selectedLabel={selectedLabel} />
          </Paper>
        </Box>
      )}
    </>
  )
}
