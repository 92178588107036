import { Image, ShopifyProduct } from '../../types.js'
import { Divider, Button, Card, Box, Link } from '@mui/material'
import style from '../../page.module.css'
import React from 'react'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import ProductImageComingSoon from '@/assets/product-image-coming-soon.jpeg'
import { shoppingProduct } from '@/config/cache.ts'

export interface ProductCardProps {
  className?: string
  product: ShopifyProduct
  imgWidth: number
  imgHeight: number
  imgLayout?: 'fixed' | 'intrinsic' | 'responsive' | undefined
  imgPriority?: boolean
  fillImage?: boolean
  imgLoading?: 'eager' | 'lazy'
  imgSizes?: string
}

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  imgWidth,
  imgHeight,
  imgPriority,
  imgLoading,
  imgSizes,
  imgLayout = 'responsive',
}) => {
  const { id } = product

  return (
    <Card
      sx={{
        // maxWidth: [700, imgWidth || 540],
        width: '100%',
        // height: [imgHeight],
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        // borderColor: '#c9c9c9',
        border: 0.5,
      }}
    >
      <Box sx={{ margin: 'auto' }} onClick={() => shoppingProduct(id)}>
        {!_.isEmpty(product.images) ? (
          <img src={(product.images as Image[])[0].src} width={'100%'} height='100%' alt={product.title} />
        ) : (
          <img src={ProductImageComingSoon} width={'100%'} height='100%' alt={'Product image coming soon'} />
        )}
      </Box>
      <div>
        <Divider />
        <div className={style.productCardText}>
          <Typography variant={'h6'} className={style.productCardTitle}>
            {product.title}
          </Typography>
        </div>
        <div className={style.productCardButton}>
          <Button
            color='inherit'
            variant={'outlined'}
            className={style.productDetailButton}
            onClick={() => {
              shoppingProduct(id)
            }}
          >
            View Details
          </Button>
        </div>
      </div>
    </Card>
  )
}

export default ProductCard
