import React from 'react'
import Button from '@mui/material/Button'
import { Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export default function LogoutForm() {
  const navigate = useNavigate()

  return (
    <Stack spacing={4}>
      <Button color={'inherit'} onClick={() => navigate('/logout')}>
        Logout
      </Button>
    </Stack>
  )
}
