import { useMemo } from 'react'
import { DataGridPremium, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid-premium'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'

import Filters from './Filters'
import StatusIndicatorCell from './StatusIndicatorCell'
import { Paper } from '@mui/material'

const BASE_URI = '/vendor/connect'

export default function ConnectTable({
  nRows,
  isLoading,
  rows,
  onRowsScrollEnd,
  handleSortModelChange,
  sortModel,
}: {
  nRows: number
  isLoading: boolean
  rows: any
  onRowsScrollEnd: () => void
  handleSortModelChange: (sortModel: GridSortModel) => void
  sortModel: any
}) {
  const columns = useMemo<GridColDef<any>[]>(
    () => [
      {
        field: 'commonId',
        headerName: 'Order #',
        headerClassName: 'mui-grid-header',
        minWidth: 225,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Link to={`${BASE_URI}/order/${params.id}/${encodeURIComponent(params.row.commonId)}`}>
              <Typography fontWeight={700} color='primary'>
                {params.value}
              </Typography>
            </Link>
          )
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        // type: 'number',
        minWidth: 200,
        sortable: false,
        flex: 1, //!isExtraSmall ? 1 : undefined,
        headerClassName: 'mui-grid-header',
        renderCell: (params) => {
          return <StatusIndicatorCell status={params.value} />
        },
        editable: false,
      },
      {
        field: 'createdAt',
        headerName: 'Date',
        minWidth: 180,
        flex: 1, //!isExtraSmall ? 1 : undefined,
        headerClassName: 'mui-grid-header',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Typography color='#001D35' fontWeight={600}>
              {params.value}
            </Typography>
          )
        },
      },
      {
        field: 'storeName',
        headerName: 'Store',
        minWidth: 200,
        sortable: false,
        flex: 1, //!isExtraSmall ? 1 : undefined,
        headerClassName: 'mui-grid-header',
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Typography color='#001D35' fontWeight={600}>
              {params.value}
            </Typography>
          )
        },
      },
    ],
    []
  )

  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 340px)',
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
      }}
    >
      <Filters />
      <Paper
        elevation={5}
        sx={{
          height: 'inherit',
          display: 'grid',
          gridTemplateRows: 'auto 1f auto',
          borderRadius: '16px',
          p: 1,
        }}
      >
        <DataGridPremium
          sx={{
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderTopWidth: 0,
          }}
          density={'compact'}
          getRowHeight={() => 'auto'}
          rows={rows}
          rowCount={nRows}
          columns={columns}
          hideFooterPagination={true}
          scrollEndThreshold={100}
          onRowsScrollEnd={onRowsScrollEnd}
          sortingMode={'server'}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          loading={isLoading}
          disableRowSelectionOnClick
        />
      </Paper>
    </Box>
  )
}
