import { makeVar } from '@apollo/client'
import { OrderScreenFilters } from 'lib/types'
import { Organization } from 'lib/graphql/types.ts'

export const orderSearchString = makeVar<string>('')
export const orderSearchFilter = makeVar<any>('')

export const orderFilter = makeVar<OrderScreenFilters>('all')

export const productsSearchString = makeVar<string>('')
export const productsSearchFilter = makeVar<string>('')

export const fromDate = makeVar<string | undefined>(undefined)
export const toDate = makeVar<string | undefined>(undefined)

export const client = makeVar<string | undefined>(undefined)

export const organizationId = makeVar<string | undefined>(undefined)

export const selectedOrganization = makeVar<Organization | undefined>(undefined)

export const selectedStoreId = makeVar<string | undefined>(undefined)
export const stores = makeVar<any[] | undefined>(undefined)

export const shoppingProduct = makeVar<string | undefined>(undefined)

export const resetVars = () => {
  client(undefined)
  fromDate(undefined)
  toDate(undefined)
  orderSearchFilter('')
  orderSearchString('')
  productsSearchString('')
  productsSearchFilter('')
  stores(undefined)
  selectedStoreId(undefined)
  organizationId(undefined)
  shoppingProduct(undefined)
  orderFilter('all')
  selectedOrganization(undefined)
}
