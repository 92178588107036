import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import Divider from '@mui/material/Divider'
import Toolbar from '@mui/material/Toolbar'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import PrintIcon from '@mui/icons-material/Print'
import _ from 'lodash'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import { LIST_SHOPIFY_STORE_CONFIGURATIONS } from 'lib/graphql/listShopifyStoreConfigurations'
import { OrganizationSelect } from 'components/OrganizationSelect.tsx'

import { organizationId, selectedStoreId, stores } from '@/config/cache'
import { GET_SHOPIFY_STORE_CONFIGURATION } from 'lib/graphql/getShopifyStoreConfiguration.ts'
import AppsIcon from '@mui/icons-material/Apps'
import { useRecoilState } from 'recoil'
import { navOpenState } from 'store/app.ts'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import StoreIcon from '@mui/icons-material/Store'
import SettingsIcon from '@mui/icons-material/Settings'
import { getUserRole, ROLES } from 'lib/rbac.tsx'
import LockIcon from '@mui/icons-material/Lock'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import GroupsIcon from '@mui/icons-material/Groups'
import CategoryIcon from '@mui/icons-material/Category'

const MAX_STORES: number = 100
const drawerWidth: number = 240

export type MenuEntry = {
  divider?: boolean
  title?: string
  icon?: React.ReactNode
  url?: string
  children?: MenuEntry[]
  collapsable?: boolean
  requiresOrganization?: boolean
  requiresStores?: boolean
}

export type MenuType = MenuEntry[]

export const DrawerLayout = ({
  menu,
  children,
  showOrgSelector,
}: {
  menu: MenuEntry[]
  children: React.ReactNode | React.ReactNode[]
  showOrgSelector?: boolean
}) => {
  const userRole = getUserRole()
  const currentOrganizationId = useReactiveVar(organizationId)
  const storesVar = useReactiveVar(stores)
  const [open, setOpen] = React.useState({
    default: true,
    Fulfillment: true,
  })
  const [isClosing, setIsClosing] = React.useState(false)
  const [mobileOpen, setMobileOpen] = useRecoilState(navOpenState)

  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const handleClick = (text: string) => {
    setOpen({
      ...open,
      [text]: !_.result(open, text),
    })
  }
  const handleDrawerClose = () => {
    setIsClosing(true)
    setMobileOpen(false)
  }

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false)
  }

  const handleNavigate = (path?: string) => {
    path && navigate(path)
    setMobileOpen(false)
  }

  const smartNavigate = () => {
    if (_.startsWith(location.pathname, '/vendor/brands')) return
    if (_.startsWith(location.pathname, '/vendor/catalog')) return
    if (_.startsWith(location.pathname, '/vendor/users')) return
    if (_.startsWith(location.pathname, '/vendor/connect')) return
    if (_.startsWith(location.pathname, '/vendor/labelops')) return
    if (_.startsWith(location.pathname, '/vendor/label-approval')) return
    if (_.startsWith(location.pathname, '/vendor/templates')) return
    if (_.startsWith(location.pathname, '/vendor/reports/')) return
    if (_.startsWith(location.pathname, '/reports/')) return
    navigate('/vendor')
  }

  const {
    refetch: refetchShopifyStoreConfigurations,
    data: dataShopifyStoreConfigurations,
    loading: loadingShopifyStoreConfigurations,
    error: errorShopifyStoreConfigurations,
  } = useQuery(LIST_SHOPIFY_STORE_CONFIGURATIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        organizationId: {
          equals: currentOrganizationId,
        },
      },
      take: MAX_STORES,
      skip: 0,
    },
    skip: !currentOrganizationId,
  })

  const [getStoreConfiguration] = useLazyQuery(GET_SHOPIFY_STORE_CONFIGURATION)

  const _stores = dataShopifyStoreConfigurations?.listShopifyStoreConfigurations

  useEffect(() => {
    if (!currentOrganizationId && params.storeId) {
      getStoreConfiguration({
        variables: {
          where: {
            id: params.storeId,
          },
        },
      }).then((response) => {
        organizationId(response.data?.getShopifyStoreConfiguration?.organizationId)
      })
    }
  }, [])

  useEffect(() => {
    if (params.storeId) {
      selectedStoreId(params.storeId)
    }
  }, [params])

  useEffect(() => {
    if (_stores) {
      stores(_stores)
    }
  }, [_stores])

  const drawer = (
    <>
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <ListItemButton onClick={() => handleNavigate(`/`)} sx={{ display: { lg: 'none' } }}>
          <ListItemIcon>
            <AppsIcon />
          </ListItemIcon>
          <ListItemText primary={'Apps'} />
        </ListItemButton>
        {showOrgSelector && (
          <Box sx={{ margin: '15px' }}>
            <OrganizationSelect
              label={'Organization'}
              value={currentOrganizationId}
              onChange={(ev) => {
                organizationId(ev.target.value)
                selectedStoreId(undefined)
                smartNavigate()
              }}
            />
          </Box>
        )}
        <Divider />

        <List>
          {menu
            .filter((x) => (x.requiresOrganization && currentOrganizationId) || !x.requiresOrganization)
            .filter(
              (x) => !x.requiresStores || (x.requiresStores && storesVar?.length) || !x.requiresOrganization
            )
            .map((entry, index) => {
              return (
                <React.Fragment key={index}>
                  {entry.divider && <Divider />}
                  {entry.title && (
                    <ListItem
                      key={entry.title}
                      disablePadding
                      onClick={(v) => entry.collapsable && handleClick(entry.title || 'default')}
                    >
                      <ListItemText sx={{ paddingTop: 1, paddingLeft: 2 }}>
                        <Typography variant={'h6'}>{entry.title}</Typography>
                      </ListItemText>
                      {entry.collapsable ? (
                        _.result(open, entry.title) ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )
                      ) : null}
                    </ListItem>
                  )}
                  <Collapse
                    in={!entry.collapsable || _.result(open, entry.title || 'default')}
                    timeout='auto'
                    unmountOnExit
                  >
                    {entry.children?.map((subEntry, index) => {
                      return (
                        <ListItem disablePadding key={index}>
                          <ListItemButton onClick={() => handleNavigate(subEntry.url)}>
                            <ListItemIcon>{subEntry.icon}</ListItemIcon>
                            <ListItemText primary={subEntry.title} />
                          </ListItemButton>
                        </ListItem>
                      )
                    })}
                    {!_.isEmpty(storesVar) && entry.requiresStores && (
                      <List>
                        {(storesVar || []).map(({ id, name }: { id: string; name: string }) => {
                          return (
                            <React.Fragment key={name}>
                              <ListItem disablePadding>
                                <ListItemButton onClick={(v) => handleClick(name)}>
                                  <ListItemIcon>
                                    <StoreIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={name} />
                                  {_.result(open, name) ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                              </ListItem>
                              <Collapse in={_.result(open, name)} timeout='auto' unmountOnExit>
                                <List component='div' disablePadding>
                                  <ListItemButton
                                    sx={{ pl: 4 }}
                                    onClick={() => handleNavigate(`/vendor/${id}/settings`)}
                                  >
                                    <ListItemIcon>
                                      <SettingsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Settings' />
                                  </ListItemButton>
                                  {userRole === ROLES.ADMIN && (
                                    <ListItemButton
                                      sx={{ pl: 4 }}
                                      onClick={() => handleNavigate(`/vendor/${id}/admin-settings`)}
                                    >
                                      <ListItemIcon>
                                        <SettingsIcon />
                                      </ListItemIcon>
                                      <ListItemText primary='Admin Settings' /> <LockIcon />
                                    </ListItemButton>
                                  )}
                                  <ListItemButton
                                    sx={{ pl: 4 }}
                                    onClick={() => handleNavigate(`/vendor/${id}/customers`)}
                                  >
                                    <ListItemIcon>
                                      <ManageAccountsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Customers' />
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{ pl: 4 }}
                                    onClick={() => handleNavigate(`/vendor/${id}/customerGroups`)}
                                  >
                                    <ListItemIcon>
                                      <GroupsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='Customer Groups' />
                                  </ListItemButton>
                                  <ListItemButton
                                    sx={{ pl: 4 }}
                                    onClick={() => handleNavigate(`/vendor/${id}/skuListings`)}
                                  >
                                    <ListItemIcon>
                                      <CategoryIcon />
                                    </ListItemIcon>
                                    <ListItemText primary='SKU Listings' />
                                  </ListItemButton>
                                </List>
                              </Collapse>
                            </React.Fragment>
                          )
                        })}
                      </List>
                    )}
                  </Collapse>
                </React.Fragment>
              )
            })}
        </List>
      </Box>
    </>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant='temporary'
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', lg: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant='permanent'
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          display: { xs: 'none', lg: 'block' },
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        {drawer}
      </Drawer>
      <Box sx={{ width: '100%', height: 'calc(100vh - 80px)', overflow: 'auto' }}>
        <Box sx={{ p: 2, backgroundColor: '#fafafa' }}>{children}</Box>
      </Box>
    </Box>
  )
}
