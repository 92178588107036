import { gql } from '@apollo/client'

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization(
    $where: OrganizationExtendedWhereUniqueInput!
    $data: OrganizationUpdateInput!
  ) {
    updateOrganization(where: $where, data: $data) {
      id
    }
  }
`
