import { ExternalStoreConfiguration, Sku } from '@/lib/graphql/types'
import { useQuery } from '@apollo/client'
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material'
import { LIST_EXTERNAL_STORE_CONFIGURATIONS } from 'lib/graphql/listExternalStoreConfigurations.ts'
import { Controller } from 'react-hook-form'

type ExternalStoreSelectProps = Partial<SelectProps<string>> & {
  organizationId: string
  control: any
  customOnChange: (event: SelectChangeEvent<string>) => void
  withName?: boolean
}

export const ExternalStoreSelect = ({
  organizationId,
  name,
  label,
  customOnChange,
  control,
  withName,
}: ExternalStoreSelectProps) => {
  const { data, loading, error } = useQuery(LIST_EXTERNAL_STORE_CONFIGURATIONS, {
    variables: {
      take: 100,
      skip: 0,
      where: {
        organizationId: { equals: organizationId },
      },
    },
    fetchPolicy: 'no-cache',
  })

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <>Error</>
  }

  const minWidth = withName ? '300px' : '200px'

  return (
    <Controller
      name={name || ''}
      control={control}
      render={({ field: { onChange, value, disabled }, fieldState: { error } }) => (
        <FormControl sx={{ m: 1, minWidth }}>
          <InputLabel id='demo-simple-select-label'>{label}</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            label={label}
            name={name}
            fullWidth
            value={value}
            variant='standard'
            onChange={(v) => {
              onChange(v)
              customOnChange(v)
            }}
            sx={{ marginTop: '15px' }}
          >
            {(data.listExternalStoreConfigurations || []).map((store: ExternalStoreConfiguration) => {
              return (
                <MenuItem key={store.id} value={store.id}>
                  {store.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      )}
    />
  )
}
