import { useEffect, useRef, useState } from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  Box,
  Typography,
  Switch,
  FormControlLabel,
  CircularProgress,
} from '@mui/material'
import { useMutation, useQuery } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import _ from 'lodash'

import Currency from 'components/Currency.tsx'
import CheckIcon from '@mui/icons-material/Check'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import { BrandSelect } from 'components/Forms/BrandSelect'
import { useDropzone } from 'react-dropzone'
import DownloadIcon from '@mui/icons-material/Download'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { getDataFromExcelFile } from 'lib/helpers/import.ts'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { Sku, SkuListing } from 'lib/graphql/types.ts'
import { CREATE_SKU_LISTING } from '@/lib/graphql/createSkuListing'
import { UPDATE_SKU_LISTING } from '@/lib/graphql/updateSkuListing'
import { CUSTOMER_GROUPS_LIST } from '@/lib/graphql/listCustomerGroups'
import { GetAllCatalogSkus, GetAllCatalogSkusRef } from 'pages/VendorApp/Common/GetAllCatalogSkus.tsx'
import { GetAllSkuListings, GetAllSkuListingsRef } from 'pages/VendorApp/Common/GetAllSkuListings.tsx'

const initialValues = {
  brandId: '',
  data: [],
}

const DisplayingErrorMessagesSchema = Yup.object().shape({
  brandId: Yup.string().required('Required'),
  data: Yup.array(Yup.object()).required(),
})

type SkuListingsImportFieldProps = {
  brandId: string
  data: any[]
}

function renderApproval(v: boolean) {
  return v ? <CheckIcon sx={{ color: 'green' }} /> : <DoNotDisturbIcon sx={{ color: '#f5c3c3' }} />
}

const makeRequestBody = (listSkuListings: SkuListing[]) => (skuListing: any) => {
  const found = (listSkuListings || []).find((x) => x?.sku?.id === skuListing.data.sku.connect.id)
  if (found) {
    return {
      where: {
        id: found.id,
      },
      data: skuListing.data,
    }
  } else {
    return {
      data: skuListing.data,
    }
  }
}

const FileUploader = ({ setData }: { setData: (v: any[]) => void }) => {
  const [files, setFiles] = useState<any[]>([])

  const { getInputProps, getRootProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'text/csv': [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    },
    onDrop: async (acceptedFiles) => {
      const data = await getDataFromExcelFile(acceptedFiles[0])
      setData(
        await Promise.all(
          data.map(async (x: any) => {
            return {
              ...x,
              id: `${_.get(x, 'Branded SKU')}`,
              sku: `${_.get(x, 'Branded SKU')}`,
              skuWhiteLabel: `${_.get(x, 'Unlabeled SKU')}`,
              wholesaleAvailable: (_.get(x, 'Wholsale Y/N') === 'Y' || _.get(x, 'Wholesale Y/N')) === 'Y',
              whiteLabelAvailable: _.get(x, 'PL Y/N') === 'Y',
              wholesalePricing: (Math.round(parseFloat(_.get(x, 'Wholesale Price')) * 100) / 100).toFixed(2),
              whiteLabelPricing: (Math.round(parseFloat(_.get(x, 'PL Price')) * 100) / 100).toFixed(2),
              wholesaleBoxQuantity: parseInt(_.get(x, 'Wholesale IQ') || _.get(x, 'IQ') || '1'),
              wholesaleMinQuantity: parseInt(_.get(x, 'Wholesale MOQ') || _.get(x, 'MOQ') || '1'),
              wholesaleMaxQuantity:
                parseInt(_.get(x, 'Wholesale XOQ') || _.get(x, 'XOQ') || '0') <= 0
                  ? null
                  : parseInt(_.get(x, 'Wholesale XOQ') || _.get(x, 'XOQ') || '0'),
              whiteLabelBoxQuantity: parseInt(_.get(x, 'PL IQ') || _.get(x, 'IQ') || '1'),
              whiteLabelMinQuantity: parseInt(_.get(x, 'PL MOQ') || _.get(x, 'MOQ') || '1'),
              whiteLabelMaxQuantity:
                parseInt(_.get(x, 'PL XOQ') || _.get(x, 'XOQ') || '0') <= 0
                  ? null
                  : parseInt(_.get(x, 'PL XOQ') || _.get(x, 'XOQ') || '0'),
            }
          })
        )
      )
      setFiles(acceptedFiles)
    },
  })

  return (files || []).length ? (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
      <div>
        <AttachFileIcon />
      </div>
      {files[0].name}
    </Box>
  ) : (
    <Box
      {...getRootProps({
        // style,
      })}
      sx={{
        border: '1px dashed #008bbd',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingX: '20px',
        margin: '20px',
      }}
    >
      <div {...getInputProps()} />
      <Box my={1} sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
        <DownloadIcon fontSize='large' />
        <Typography variant='body1' sx={{ fontWeight: 400 }}>
          Drag-and-drop or click to upload file
        </Typography>
      </Box>
    </Box>
  )
}

const SkuListingsImportDialog = ({
  onSuccess,
  currentOrganizationId,
  closeDialog,
  storeId,
}: {
  onSuccess: () => void
  currentOrganizationId: string
  closeDialog: () => void
  storeId: string
}) => {
  const [submitError, setSubmitError] = useState<string>()
  const [numCreated, setNumCreated] = useState<number>(0)
  const [numUpdated, setNumUpdated] = useState<number>(0)
  const [numErrors, setNumErrors] = useState<number>(0)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<any[]>()
  const [existingSkuListingsCount, setExistingSkuListingsCount] = useState<number>(0)

  const [disabledImportedListings, setDisabledImportedListings] = useState(true)

  const [brandId, setBrandId] = useState<string>()

  const [loadingCatalog, setLoadingCatalog] = useState<boolean>(false)
  const [dataCatalog, setDataCatalog] = useState<any>(undefined)
  const getAllCatalogSkusRef = useRef<GetAllCatalogSkusRef>(null)

  const [loadingSkuListings, setLoadingSkuListings] = useState<boolean>(false)
  const [dataSkuListings, setDataSkuListings] = useState<any>(undefined)
  const getAllSkuListingsRef = useRef<GetAllSkuListingsRef>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisabledImportedListings(event.target.checked)
  }

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm<SkuListingsImportFieldProps>({
    shouldFocusError: true,
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver<SkuListingsImportFieldProps>(DisplayingErrorMessagesSchema as any),
    defaultValues: initialValues,
  })

  const { data: customerGroups } = useQuery(CUSTOMER_GROUPS_LIST, {
    variables: {
      where: {
        shopifyStoreConfigurationId: {
          equals: storeId,
        },
        autoAssignNewUsers: { equals: true },
      },
    },
  })

  const defaultCustomerGroupId = customerGroups?.listCustomerGroups[0].id

  useEffect(() => {
    !_.isEmpty(getValues('brandId')) && setBrandId(getValues('brandId'))
  }, [watch('brandId')])

  useEffect(() => {
    getAllCatalogSkusRef.current?.refresh()
  }, [brandId])

  const [createSkuListing] = useMutation(CREATE_SKU_LISTING, {
    onCompleted() {
      setNumCreated((v) => v + 1)
    },
    onError(error) {
      setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
      setNumErrors((v) => v + 1)
    },
  })

  const [updateSkuListing] = useMutation(UPDATE_SKU_LISTING, {
    onCompleted() {
      setNumUpdated((v) => v + 1)
    },
    onError(error) {
      setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
      setNumErrors((v) => v + 1)
    },
  })

  const rows = getValues('data') || []

  const onSubmit = async () => {
    const makeRequestCreateData = (skuListing: any) => {
      return {
        data: {
          title: skuListing['Product Name'],
          description: skuListing['Description'],
          retailAvailable: false,
          retailPricing: 0,
          wholesaleAvailable: disabledImportedListings ? false : skuListing.wholesaleAvailable,
          wholesalePricing: skuListing.wholesalePricing,
          wholesaleBoxQuantity: parseInt(skuListing.wholesaleBoxQuantity || skuListing['MOQ'] || '1'),
          wholesaleMinQuantity: parseInt(skuListing.wholesaleMinQuantity || skuListing['IQ'] || '1'),
          wholesaleMaxQuantity: skuListing.wholesaleMaxQuantity,
          wholesaleCustomerGroups: {
            connect: [{ id: defaultCustomerGroupId }],
          },
          whiteLabelAvailable: disabledImportedListings ? false : skuListing.whiteLabelAvailable,
          whiteLabelPricing: skuListing.wholesalePricing,
          whiteLabelBoxQuantity: parseInt(skuListing.whiteLabelBoxQuantity || skuListing['MOQ'] || '1'),
          whiteLabelMinQuantity: parseInt(skuListing.whiteLabelMinQuantity || skuListing['IQ'] || '1'),
          whiteLabelMaxQuantity: skuListing.whiteLabelMaxQuantity,
          whiteLabelCustomerGroups: {
            connect: [{ id: defaultCustomerGroupId }],
          },
          sku: {
            connect: { id: dataCatalog?.find((x: Sku) => x.sku === `${skuListing['Branded SKU']}`).id },
          },
          shopifyStoreConfiguration: { connect: { id: storeId } },
        },
      }
    }

    const requestsBody = (selectedRows || []).map(makeRequestCreateData)
    const listedRequestBody = requestsBody.map(makeRequestBody(dataSkuListings))

    for (const x of listedRequestBody) {
      if (x.where) {
        await updateSkuListing({
          variables: x,
        })
      } else {
        await createSkuListing({
          variables: x,
        })
      }
    }
    onSuccess()
    setSubmitted(true)
  }

  return (
    <>
      <GetAllCatalogSkus
        ref={getAllCatalogSkusRef}
        brandId={brandId}
        onLoading={(v) => setLoadingCatalog(v)}
        onData={(d) => setDataCatalog(d)}
      />
      <GetAllSkuListings
        ref={getAllSkuListingsRef}
        brandId={brandId}
        storeId={storeId}
        onLoading={(v) => setLoadingSkuListings(v)}
        onData={(d) => setDataSkuListings(d)}
      />
      <DialogTitle>Import SKU Listings</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', paddingX: '30px' }}>
            {submitError && <Alert severity={'error'}>{submitError}</Alert>}
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                alignItems: 'baseline',
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ flexShrink: '2' }}>Select Brand:</Box>
              <Box sx={{ flexGrow: '2' }}>
                <BrandSelect
                  control={control}
                  organizationId={currentOrganizationId}
                  name='brandId'
                  label='Brand'
                />
              </Box>
              <Box sx={{ flexShrink: '2' }}>
                <FormControlLabel
                  control={<Switch checked={disabledImportedListings} onChange={handleChange} name='a' />}
                  label='Disable imported listings'
                />
              </Box>
            </Box>
            <FileUploader setData={(v) => setValue('data', v)} />
            <Box
              sx={{
                width: '100%',
                height: '270px',
                fontSize: '10px',
                overflow: 'auto',
                border: '1px solid gray',
              }}
            >
              {!brandId || loadingCatalog || loadingSkuListings ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  {brandId ? (
                    <>
                      <CircularProgress />
                      {loadingCatalog && <Typography variant={'h6'}>Loading catalog</Typography>}
                      {loadingSkuListings && (
                        <Typography variant={'h6'}>Loading known SKU listings</Typography>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              ) : (
                <></>
              )}
              {watch('data') && (
                <DataGridPremium
                  hideFooter={true}
                  density={'compact'}
                  checkboxSelection
                  columns={[
                    {
                      field: 'Type',
                      width: 90,
                      disableColumnMenu: true,
                      renderCell: (row) => {
                        const foundInCatalog = dataCatalog?.find((x: Sku) => x.sku === row.id)
                        if (!foundInCatalog) {
                          return <Box sx={{ color: 'darkred', fontWeight: 700 }}>Not in Catalog</Box>
                        }
                        if (!dataSkuListings) {
                          return '<pending>'
                        }
                        const found = (dataSkuListings || []).find((x: SkuListing) => x.sku?.sku === row.id)
                        return found ? (
                          <Box sx={{ color: 'darkorange', fontWeight: 700 }}>Update</Box>
                        ) : (
                          <Box sx={{ color: 'darkgreen', fontWeight: 700 }}>New</Box>
                        )
                      },
                    },
                    {
                      field: 'Product Name',
                      width: 200,
                    },
                    { field: 'Branded SKU', width: 250 },
                    {
                      field: 'Description',
                      width: 300,
                      renderCell: (row) => {
                        return (
                          <Typography
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '3',
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {row.value}
                          </Typography>
                        )
                      },
                    },
                    {
                      field: 'Wholesale Price',
                      headerName: 'Wholesale',
                      width: 150,
                      renderCell: (v) => (
                        <>
                          {renderApproval(v.row?.wholesaleAvailable)}&nbsp;
                          <Currency price={v.row?.wholesalePricing} />
                        </>
                      ),
                    },
                    {
                      field: 'wholesaleMinQuantity',
                      headerName: 'Wh. MOQ',
                      width: 120,
                      align: 'center',
                      headerAlign: 'center',
                      disableColumnMenu: true,
                    },
                    {
                      field: 'wholesaleBoxQuantity',
                      headerName: 'Wh. IQ',
                      width: 120,
                      align: 'center',
                      headerAlign: 'center',
                      disableColumnMenu: true,
                    },
                    {
                      field: 'wholesaleMaxQuantity',
                      headerName: 'Wh. XOQ',
                      width: 120,
                      align: 'center',
                      headerAlign: 'center',
                      disableColumnMenu: true,
                    },
                    {
                      field: 'PL Price',
                      headerName: 'PL',
                      width: 150,
                      renderCell: (v) => (
                        <>
                          {renderApproval(v.row?.whiteLabelAvailable)}&nbsp;
                          <Currency price={v.row?.whiteLabelPricing} />
                        </>
                      ),
                    },
                    {
                      field: 'whiteLabelMinQuantity',
                      headerName: 'PL MOQ',
                      width: 120,
                      align: 'center',
                      headerAlign: 'center',
                      disableColumnMenu: true,
                    },
                    {
                      field: 'whiteLabelBoxQuantity',
                      headerName: 'PL IQ',
                      width: 120,
                      align: 'center',
                      headerAlign: 'center',
                      disableColumnMenu: true,
                    },
                    {
                      field: 'whiteLabelMaxQuantity',
                      headerName: 'PL XOQ',
                      width: 120,
                      align: 'center',
                      headerAlign: 'center',
                      disableColumnMenu: true,
                    },
                  ]}
                  onRowSelectionModelChange={(ids) => {
                    setSelectedRows(ids.map((id) => rows.find((row) => row.id === id)))
                  }}
                  isRowSelectable={(params) => dataCatalog?.find((sku: Sku) => sku.sku === params.id)}
                  getRowHeight={(params) => 'auto'}
                  getEstimatedRowHeight={() => 200}
                  rows={rows}
                  getRowId={(x: any) => x.id}
                />
              )}
              {/*<pre>{watch('data') && JSON.stringify(getValues('data'), null, 2)}</pre>*/}
            </Box>
            {(getValues('data').length || numCreated > 0 || numUpdated > 0 || numErrors > 0) && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                <div>Read {getValues('data').length} records.</div>
                <div>
                  {numCreated > 0 && <>Created {numCreated} SKU Listings.</>}
                  {numUpdated > 0 && <>Updated {numUpdated} SKU Listings.</>}
                  {numErrors > 0 && <>{numErrors} errors.</>}
                </div>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            onClick={() => {
              closeDialog()
            }}
          >
            Cancel
          </Button>
          {submitted ? (
            <Button onClick={() => closeDialog()}>Close</Button>
          ) : (
            <Button
              type='submit'
              disabled={!dataCatalog || isSubmitting || !(isDirty && isValid) || !getValues('data').length}
            >
              Import
            </Button>
          )}
        </DialogActions>
      </form>
    </>
  )
}

export default SkuListingsImportDialog
