import { useState, forwardRef } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { Box, Stack, List, Skeleton, Pagination, Snackbar, CircularProgress } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

import { productsSearchString, productsSearchFilter, organizationId } from 'config/cache'
import { CATALOG_LIST } from 'lib/graphql/listCatalog.ts'
import { LabelFactsProductsList } from 'components/LabelFacts/LabeltFactsProductsList.tsx'

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export const LabelFactsApp = () => {
  // Needed to add this because it wasn't refetching using only directives inside the query
  const productSearchFilterVar = useReactiveVar(productsSearchFilter)
  const organizationIdVar = useReactiveVar(organizationId)

  // this needs to be here because there are cases when the hook wasn't being called
  // i.e. if there is not last page
  const productSearchStringVar = useReactiveVar(productsSearchString)

  const [createProductOpen, setCreateProductOpen] = useState(false)

  const [successMessageOpen, setSuccessMessageOpen] = useState(false)

  const handleCreateProductOpen = () => {
    setCreateProductOpen(false)
  }

  const handleOpenCreateProduct = () => {
    setCreateProductOpen(true)
  }

  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const [pageSize, setPageSize] = useState(1000)
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const {
    refetch: refetchCatalog,
    data: dataCatalog,
    loading: loadingCatalog,
    error: errorCatalog,
  } = useQuery(CATALOG_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      orderBy: [{ sku: 'ASC' }],
      where: {
        brand: {
          organization: {
            id: {
              equals: organizationIdVar,
            },
          },
        },
        archivedAt: null,
      },
      limit: pageSize,
    },
    skip: !organizationIdVar,
  })

  if (!lastPage) {
    return <></>
  }

  return (
    <Stack direction={{ sm: 'column' }} alignItems={'center'}>
      <Box sx={{ maxWidth: 'md', width: '100%' }}>
        <Snackbar
          open={successMessageOpen}
          autoHideDuration={4000}
          onClose={() => {
            setSuccessMessageOpen(false)
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => {
              setSuccessMessageOpen(false)
            }}
            severity='success'
            sx={{ width: '100%' }}
          >
            Product created successfully!
          </Alert>
        </Snackbar>

        {/*<Paper*/}
        {/*  elevation={1}*/}
        {/*  sx={{*/}
        {/*    padding: 2,*/}
        {/*    marginBottom: 2,*/}
        {/*    display: 'flex',*/}
        {/*    flexDirection: 'column',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Stack mx={3} px={1} direction={{ xs: 'column', sm: 'row' }} spacing={2}>*/}
        {/*    <TextField*/}
        {/*      fullWidth*/}
        {/*      variant='standard'*/}
        {/*      placeholder='Search SKU#'*/}
        {/*      InputProps={{*/}
        {/*        startAdornment: (*/}
        {/*          <InputAdornment position='start'>*/}
        {/*            <Search />*/}
        {/*          </InputAdornment>*/}
        {/*        ),*/}
        {/*      }}*/}
        {/*      value={productSearchStringVar}*/}
        {/*      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
        {/*        productsSearchString(event.target.value)*/}
        {/*        if (event.target.value && _.size(event.target.value) >= 3) {*/}
        {/*          productsSearchFilter(event.target.value)*/}
        {/*        } else {*/}
        {/*          productsSearchFilter('')*/}
        {/*        }*/}
        {/*      }}*/}
        {/*      sx={{ maxWidth: { sm: 300 }, pt: 1 }}*/}
        {/*    />*/}
        {/*  </Stack>*/}
        {/*</Paper>*/}
        <List>
          {loadingCatalog && (
            <Stack direction='column' spacing={1} width='100%' minWidth='400px'>
              <Skeleton variant='rectangular' height={46} />
              <Skeleton variant='rectangular' height={46} />
              <Skeleton variant='rectangular' height={46} />
              <Skeleton variant='rectangular' height={46} />
              <Skeleton variant='rectangular' height={46} />
              <Skeleton variant='rectangular' height={46} />
            </Stack>
          )}
          {!loadingCatalog && dataCatalog?.listSkus?.length === 0 ? (
            <CircularProgress />
          ) : (
            dataCatalog?.listSkus?.map(({ sku, skuFact, id }: { skuFact: any; sku: string; id: string }) => (
              <LabelFactsProductsList
                productId={id}
                product={sku}
                key={id}
                privateLabelName={skuFact.privateLabelName}
                productName={skuFact.productName}
                organizationId={organizationIdVar}
              />
            ))
          )}
        </List>
        <Pagination
          count={lastPage}
          color='primary'
          page={page}
          onChange={handleChange}
          sx={{
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'space-between',
            marginBottom: 2,
          }}
        />
      </Box>
    </Stack>
  )
}
