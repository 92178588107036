import { useState } from 'react'
import {
  DialogTitle,
  DialogContent,
  Box,
  Button,
  Grid,
  DialogActions,
  Dialog,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ShopifyAddress, ShopifyCustomer } from '@/lib/types'
import Address from './Address'
import { UpdateAddressDialog } from './UpdateAddressDialog'

type ManageAddressProps = {
  customer: ShopifyCustomer & { metafields: any; original: any; approved: boolean }
  storeId: string
  refetchCustomers: () => any
  closeManageAddressessDialog: () => void
}

export const ManageAddresses: React.FC<ManageAddressProps> = ({
  customer,
  storeId,
  refetchCustomers,
  closeManageAddressessDialog,
}) => {
  const [showModal, setShowModal] = useState(false)

  const closeUpdateAddressDialog = () => {
    setShowModal(false)
  }

  if (!customer) return null
  return (
    <>
      <DialogTitle>Manage Addresses</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={closeManageAddressessDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[600],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ minWidth: '600px' }}>
        <Box sx={{ paddingTop: '10px' }}>
          <Box sx={{ padding: '10px' }}>
            <Box sx={{ padding: '20px', display: 'flex' }}>
              <Grid container spacing={2}>
                {customer.addresses?.map((address: ShopifyAddress) => {
                  return (
                    <Address
                      address={address}
                      customer={customer}
                      key={address.id}
                      storeId={storeId || ''}
                      refetchCustomers={refetchCustomers}
                      isDefaultAddress={customer.defaultAddress?.id === address.id}
                    />
                  )
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
        <DialogActions sx={{ pt: 3 }}>
          <Button onClick={() => setShowModal(true)}>Add new address</Button>
        </DialogActions>
      </DialogContent>
      <Dialog fullWidth={true} maxWidth='md' open={showModal} onClose={closeUpdateAddressDialog}>
        <UpdateAddressDialog
          mode='create'
          customer={customer}
          closeDialog={closeUpdateAddressDialog}
          storeId={storeId}
          onSuccess={async () => {
            await refetchCustomers()
          }}
        />
      </Dialog>
    </>
  )
}
