import React, { useState } from 'react'
import { Paper, Typography, IconButton, Menu, MenuItem, Box, Dialog } from '@mui/material'
import { ShopifyCustomer } from 'lib/types.ts'
import _ from 'lodash'

type PrivateLabelsProps = {
  customer: ShopifyCustomer & { metafields: any; original: any; approved: boolean; accountStatus: any }
}

export const PrivateLabels = ({ customer }: PrivateLabelsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [showLabel, setShowLabel] = useState<string | undefined | null>()

  const privateLabels = JSON.parse(customer.privateLabels?.value || '[]')

  const renderGroup = (title: string, labels: any[]) => {
    return (
      <Box sx={{ paddingTop: '10px' }}>
        <Typography variant={'h6'}>
          {title} ({labels.length})
        </Typography>
        <Box
          sx={{
            overflowX: 'hidden',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContents: 'center',
              width: { xs: 'calc( 100vw - 60px)', lg: 'calc( 100vw - 300px)' },
              // flexWrap: 'wrap',
              overflow: 'auto',
              gap: '8px',
            }}
          >
            {labels.map((privateLabel: any, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    width: '325px',
                    flexShrink: 0,
                    border: '1px solid',
                    display: 'flex',
                    flex: '0 0 auto',
                  }}
                >
                  <img
                    src={privateLabel.instanceImage3DUrl}
                    width={'100%'}
                    onClick={() => setShowLabel(privateLabel.instanceImage3DUrl)}
                  />
                </Box>
              )
            })}
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Paper elevation={10} sx={{ p: 2, borderRadius: '15px', width: '100%' }}>
      <Box sx={{ width: '100%' }}>
        <Typography variant={'h5'}>Private Labels</Typography>
        {renderGroup(
          'Approved',
          privateLabels.filter((l: any) => l.status === 'APPROVED' && _.isEmpty(l.archivedAt))
        )}
        {renderGroup(
          'In Review',
          privateLabels.filter((l: any) => l.status === 'IN_REVIEW' && _.isEmpty(l.archivedAt))
        )}
        {renderGroup(
          'Draft',
          privateLabels.filter((l: any) => l.status === 'DRAFT' && _.isEmpty(l.archivedAt))
        )}
        {renderGroup(
          'Rejected',
          privateLabels.filter((l: any) => l.status === 'REJECTED' && _.isEmpty(l.archivedAt))
        )}
        {renderGroup(
          'Archived',
          privateLabels.filter((l: any) => !_.isEmpty(l.archivedAt))
        )}
      </Box>
      <Dialog open={!!showLabel} onClose={() => setShowLabel(undefined)} fullWidth maxWidth='lg'>
        <img src={showLabel!} width='100%' onClick={() => setShowLabel(undefined)} />
      </Dialog>
    </Paper>
  )
}
