export const customerUpdater = (apiResponse: any) => {
  const customersData = apiResponse.edges.map(({ node }: { node: any }) => ({
    ...node,
    lastOrderAt: node.lastOrder?.createdAt,
    approved: node.accountStatus?.value === 'APPROVED',
    original: node,
  }))

  return customersData
}

export const ordersUpdate = (apiResponse: any) => {
  const ordersData = apiResponse.data.orders.edges.map(({ node }: { node: any }) => ({
    ...node,
  }))


  return ordersData
}
