import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { Box, Button, Checkbox, Dialog, FormControlLabel, Paper, Tooltip, Typography } from '@mui/material'
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {
  Brand,
  ExternalStoreConfiguration,
  Organization,
  ShopifyStoreConfiguration,
} from 'lib/graphql/types.ts'
import { organizationId } from 'config/cache.ts'
import { GridRowSelectionModel, GridToolbarContainer, GridToolbarDensitySelector } from '@mui/x-data-grid-pro'
import styles from 'pages/VendorApp/styles.module.css'
import { BrandCreateOrUpdateDialog } from 'components/Dialogs/BrandCreateOrUpdateDialog.tsx'
import { ORGANIZATIONS_LIST } from 'lib/graphql/listOrganizations.ts'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import { OrganizationCreateOrUpdateDialog } from 'pages/AdminSettings/OrganizationCreateOrUpdateDialog.tsx'

type EditToolbarProps = {
  handleAdd: () => void
  handleEdit: () => void
  enableEditButton: boolean
}

const CreateToolbar = ({ handleAdd, handleEdit, enableEditButton }: EditToolbarProps) => {
  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<AddIcon />} onClick={handleAdd}>
        New
      </Button>
      <Button color='primary' startIcon={<EditIcon />} onClick={handleEdit} disabled={!enableEditButton}>
        Edit
      </Button>
      {/*<GridToolbarDensitySelector />*/}
    </GridToolbarContainer>
  )
}

const columns: GridColDef[] = [{ field: 'name', headerName: 'Organization', flex: 1 }]

type OrganizationManagerProps = {
  onSelected: (arg0?: string) => void
}

export const OrganizationManager = ({ onSelected }: OrganizationManagerProps) => {
  const [showModal, setShowModal] = useState(false)
  const [mode, setMode] = useState<'create' | 'update'>('create')
  const [focusedOrg, setFocusedOrg] = useState<Organization>()
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])

  const {
    refetch: refetchOrgs,
    data: dataOrgs,
    loading: loadingOrgs,
    error: errorOrgs,
  } = useQuery(ORGANIZATIONS_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      take: 1000,
      skip: 0,
    },
    onCompleted: () => {
      onSelected && onSelected(undefined)
      setFocusedOrg(undefined)
      setRowSelectionModel([])
    },
  })

  function closeDialog() {
    setShowModal(false)
  }

  const rows = dataOrgs?.listOrganizations || []

  if (!rows) return <></>

  return (
    <>
      <Box sx={{ flexBasis: '100%' }}>
        <Paper
          elevation={5}
          sx={{
            height: 'inherit',
            display: 'grid',
            gridTemplateRows: 'auto 1f auto',
            borderRadius: '16px',
            p: 1,
          }}
        >
          <DataGridPremium
            sx={{
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderTopWidth: 0,
              height: 'calc( 100vh - 200px )',
            }}
            density={'compact'}
            rows={rows}
            columns={columns}
            autoHeight={false}
            loading={loadingOrgs}
            disableMultipleRowSelection={true}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel)
              if (_.size(newRowSelectionModel) === 1) {
                const data = rows.find((r: Brand) => r.id === newRowSelectionModel[0])
                setFocusedOrg(data)
                onSelected && onSelected(data.id)
              } else {
                setFocusedOrg(undefined)
                onSelected && onSelected(undefined)
              }
            }}
            slots={{
              toolbar: CreateToolbar,
            }}
            slotProps={{
              toolbar: {
                enableEditButton: !!focusedOrg,
                enableDeleteButton: !!focusedOrg,
                handleAdd: () => {
                  setMode('create')
                  setShowModal(true)
                },
                handleEdit: () => {
                  setMode('update')
                  focusedOrg && setShowModal(true)
                },
              },
            }}
          />
        </Paper>
      </Box>
      <Dialog className={styles.modelAddNewBrand} open={showModal} onClose={() => closeDialog()}>
        <OrganizationCreateOrUpdateDialog
          mode={mode}
          data={focusedOrg}
          closeDialog={() => closeDialog()}
          onSuccess={async () => {
            await closeDialog()
            await refetchOrgs()
          }}
        />
      </Dialog>
    </>
  )
}
