import { atom, RecoilLoadable, selector, DefaultValue, useRecoilValue } from 'recoil'
import { CognitoOperations, cognitoOps } from '../lib/Cognito.tsx'
import _ from 'lodash'
import { CognitoUser, ICognitoUserAttributeData } from 'amazon-cognito-identity-js'
import { ROLES } from 'lib/rbac.tsx'

interface TUserState {
  token: string | null
  user: Record<string, any> | null
}

export const userState = atom<TUserState>({
  key: 'user',
  default: RecoilLoadable.of(
    (async () => {
      try {
        const user = await getUserWithSession(cognitoOps)
        if (user) {
          return {
            token: user.getSignInUserSession()!.getIdToken().getJwtToken(),
            user: await getUserData(user),
          }
        }
      } catch (e) {
        console.log(e)
      }
      return { token: null, user: null }
    })()
  ),
})

export const accessToken = selector<any>({
  key: 'accessToken',
  get: ({ get }) => {
    const { token } = get(userState)
    return token
  },
  set: ({ set }, token) => {
    set(userState, (old) => ({
      ...(token ? old : { user: null }),
      token,
    }))
  },
})

export const loginState = selector<boolean>({
  key: 'loginState',
  get: ({ get }) => {
    const { token } = get(userState)
    return !!token
  },
})

export const logout = selector<void>({
  key: 'logout',
  get: () => {},
  set: ({ set, get }) => {
    set(userState, {
      ...get(userState),
      user: null,
      token: null,
    })
  },
})

export const currentUser = selector<Record<string, any> | null>({
  key: 'currentUser',
  get: ({ get }) => get(userState).user,
  set: ({ set }, user) => {
    if (user instanceof DefaultValue) {
      user = null
    }
    set(userState, (old) => ({ ...old, user }))
  },
})

async function getUserWithSession(cognitoOps: CognitoOperations) {
  const user = cognitoOps.getCurrentUser()
  if (!user) {
    return
  }

  const session = await cognitoOps.getSession()
  if (!session?.isValid()) {
    return
  }

  user.setSignInUserSession(session)
  return user
}

export async function getUserData(user: CognitoUser): Promise<Record<string, any> | null> {
  const userAttributes: ICognitoUserAttributeData[] = await new Promise((resolve, reject) =>
    user.getUserData((err, userData) => (err ? reject(err) : resolve(userData!.UserAttributes)))
  )

  return {
    id: user?.getUsername(),
    role: user?.getUsername().includes('@kaerwell.com') ? ROLES.ADMIN : ROLES.BRAND,
    ..._.mapValues(_.keyBy(userAttributes, 'Name'), 'Value'),
  }
}
