import { useEffect, useRef, useState } from 'react'
import { Image, ProductVariant, ShopifyProduct } from './types.js'
import _ from 'lodash'
import React from 'react'
import CollectionPreview from './blocks/CollectionView/CollectionView'
import { ShopifyPageInfo, ShopifyCustomer, ShopifyMetafield } from 'lib/types.ts'
import { useLazyQuery } from '@apollo/client'
import { PROXY_SHOPIFY_QUERY } from 'lib/graphql/proxyShopifyQuery.ts'
import { Box, Button, LinearProgress } from '@mui/material'
import { useForm } from 'react-hook-form'
import { TextFieldWithDebounce } from 'components/TextFieldWithDebounce.tsx'

const isVisibleToSession = (variant: ProductVariant, brandAcl?: ShopifyMetafield) => {
  if (variant.title === 'Retail') {
    return true
  }
  if (!brandAcl) {
    return false
  }
  const customerGroupIds = JSON.parse(brandAcl.value)
  const variantGroupIds = variant.acl ? JSON.parse(variant.acl.value) : []
  return _.intersection(customerGroupIds, variantGroupIds).length > 0
}

const useElementOnScreen = (options: any) => {
  const containerRef = useRef(null)
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const callbackFunction = (entries: any) => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options)
    if (containerRef.current) {
      observer.observe(containerRef.current)
    }
    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current)
      }
    }
  }, [containerRef, options])

  return [containerRef, isVisible]
}

export default function ProductCatalog({
  customer,
  storeId,
}: {
  storeId: string
  customer: ShopifyCustomer
}) {
  const [products, setProducts] = useState<ShopifyProduct[]>()
  const [query, setQuery] = useState<string>('')
  const [pageInfo, setPageInfo] = useState<ShopifyPageInfo>()
  const [after, setAfter] = useState<string>()

  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: '100px',
    threshold: 1.0,
  })

  const updateSearchTerm = (ev: any) => {
    if (ev?.target?.value?.length > 2) {
      setAfter(undefined)
      setQuery(`title:*'${ev.target.value}'* OR tag:*'${ev.target.value}'*`)
    } else {
      setAfter(undefined)
      setQuery('title:*')
    }
  }

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    // resolver: yupResolver(validationSchema),
    defaultValues: {
      query: '',
    },
  })

  const loadMore = () => {
    if (pageInfo?.endCursor && pageInfo.endCursor !== after) {
      setAfter(pageInfo?.endCursor)
    }
  }
  useEffect(() => {
    loadMore()
  }, [isVisible])
  const [queryProducts] = useLazyQuery(PROXY_SHOPIFY_QUERY)

  // useQuery(PROXY_SHOPIFY_QUERY)

  useEffect(() => {
    const search = async (req: { after: string | undefined }) => {
      storeId &&
        queryProducts({
          fetchPolicy: 'no-cache',
          variables: {
            query: 'products',
            shopifyStoreId: storeId,
            variables: JSON.stringify({
              first: 50,
              sortKey: 'TITLE',
              query,
              after: req.after,
            }),
          },
          onCompleted: (data) => {
            const results = JSON.parse(data.proxyShopifyQuery)
            const filteredProducts = results.data.products.edges
              .map(({ node }: { node: any }) => {
                return {
                  ...node,
                  images: node.media?.edges.map(({ node }: { node: Image }) => ({
                    ...node,
                    src: node.preview?.image?.url,
                  })),
                  variants: node.variants.edges
                    .filter(({ node }: { node: ProductVariant }) =>
                      isVisibleToSession(node, customer.brandAcl)
                    )
                    .map(({ node }: { node: ProductVariant }) => node),
                }
              })
              .filter((product: ShopifyProduct) => !_.isEmpty(product.variants))

            if (req.after) {
              setProducts((prev) => [...(prev || []), ...filteredProducts])
            } else {
              setProducts(filteredProducts)
            }
            setPageInfo(results.data.products.pageInfo)
          },
        })
    }
    search({ after })
  }, [query, after])

  if (!products) {
    return (
      <Box sx={{ padding: '20px' }}>
        <LinearProgress />
      </Box>
    )
  }

  return (
    <>
      <Box sx={{ maxWidth: '300px', height: '50px' }}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <TextFieldWithDebounce
            control={control}
            onChange={() => {}}
            onAccept={updateSearchTerm}
            label='Search catalog'
            name='query'
            type='text'
            fullWidth
          />
        </form>
      </Box>
      <Box>
        {_.isEmpty(products) ? (
          <Box sx={{ padding: '50px' }}>No products found!</Box>
        ) : (
          <>
            <CollectionPreview
              productGridOptions={{
                cardProps: {
                  imgPriority: true,
                  imgLayout: 'responsive',
                  imgLoading: 'eager',
                  imgWidth: 500,
                  imgHeight: 500,
                },
                // offset: 0,
                // limit: 50,
              }}
              collection={{
                title: undefined,
                products,
              }}
            />
            <div ref={containerRef as React.MutableRefObject<any>}>
              {pageInfo?.hasNextPage ? (
                <Button onClick={loadMore}>
                  <Box>
                    Loading more
                    <LinearProgress sx={{ width: 100 }} />
                  </Box>
                </Button>
              ) : null}
            </div>
          </>
        )}
      </Box>
    </>
  )
}
