import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useQuery } from '@apollo/client'

import { COUNT_LABEL_FACTS } from 'lib/graphql/countLabelFacts.ts'
import { LIST_LABEL_FACTS } from 'lib/graphql/listLabelFacts.ts'

type Props = {
  brandId?: string
  onLoading: (v: boolean) => void
  onData: (v: any) => void
}

export type GetAllLabelFactsRef = {
  refresh: () => void
}

const PAGE_SIZE = 100

export const GetAllLabelFacts = forwardRef<GetAllLabelFactsRef, Props>(
  ({ brandId, onLoading, onData }, ref) => {
    const [count, setCount] = useState<number | undefined>(undefined)
    const [data, setData] = useState<any>()

    const {
      refetch: refetchCount,
      loading: loadingCount,
      error: errorCount,
    } = useQuery(COUNT_LABEL_FACTS, {
      fetchPolicy: 'no-cache',
      variables: {
        where: {
          sku: {
            brandId: { equals: brandId },
          },
        },
      },
      onCompleted: (data) => {
        setCount(data.countSupplementFacts)
        if (data.countSupplementFacts === 0) {
          setData([])
        }
      },
      skip: !brandId,
    })

    const { refetch, loading, error, fetchMore } = useQuery(LIST_LABEL_FACTS, {
      variables: {
        where: {
          sku: {
            brandId: { equals: brandId },
          },
        },
        limit: PAGE_SIZE,
        offset: 0,
      },
      onCompleted: (data) => {
        setData(data.listSupplementFacts)
      },
      skip: !brandId || !count,
    })

    useEffect(() => {
      if (brandId && count && data?.length > 0 && data?.length < count) {
        fetchMore({
          variables: {
            offset: data?.length || 0,
            limit: PAGE_SIZE,
          },
        })
      }
    }, [count, brandId, data?.length])

    useEffect(() => {
      if (loading || loadingCount || (count !== undefined && data?.length < count)) {
        onLoading(true)
      } else {
        onLoading(false)
      }
    }, [loading, loadingCount, data?.length])

    useEffect(() => {
      if (count !== undefined && count === data?.length) {
        onData(data)
      }
    }, [count, data?.length])

    useImperativeHandle(ref, () => ({
      refresh: async () => {
        await refetchCount()
        await refetch()
      },
    }))

    return <></>
  }
)
