import { useQuery, useReactiveVar } from '@apollo/client'
import { Box, Button, Dialog, Paper, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro'
import { CustomerGroup } from '@/lib/graphql/types'
import { CUSTOMER_GROUPS_LIST } from '@/lib/graphql/listCustomerGroups'
import React, { useEffect, useState } from 'react'
import { organizationId, selectedStoreId, stores } from 'config/cache'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import _ from 'lodash'
import styles from 'pages/VendorApp/styles.module.css'
import { CustomerGroupCreateOrUpdateDialog } from 'components/Dialogs/CustomerGroupCreateOrUpdateDialog'
import { PROXY_SHOPIFY_QUERY } from 'lib/graphql/proxyShopifyQuery.ts'
import { ShopifyCustomer } from 'lib/types.ts'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { DataGridPremium } from '@mui/x-data-grid-premium'

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Name', flex: 1 },
  {
    field: 'autoAssignNewUsers',
    headerName: 'Auto-assign new users',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return <>{params.value ? <CheckIcon sx={{ color: 'green' }} /> : <ClearIcon sx={{ color: 'red' }} />}</>
    },
  },
  {
    field: 'size',
    headerName: 'Size',
    width: 150,
  },
]

type EditToolbarProps = {
  handleAdd: () => void
  handleEdit: () => void
  handleDelete: () => void
  enableEditButton: boolean
  enableDeleteButton: boolean
}

const CreateToolbar = ({
  handleAdd,
  handleDelete,
  handleEdit,
  enableEditButton,
  enableDeleteButton,
}: EditToolbarProps) => {
  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<AddIcon />} onClick={handleAdd}>
        New
      </Button>
      <Button color='primary' startIcon={<EditIcon />} onClick={handleEdit} disabled={!enableEditButton}>
        Edit
      </Button>
      <Button
        color='primary'
        startIcon={<DeleteIcon />}
        onClick={handleDelete}
        disabled={!enableDeleteButton}
      >
        Delete
      </Button>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  )
}

export const ManageCustomerGroups = () => {
  const [showModal, setShowModal] = useState(false)
  const [mode, setMode] = useState<'create' | 'update'>('create')
  const [focusedCustomerGroup, setFocusedCustomerGroup] = useState<CustomerGroup | undefined>()
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])
  const organizationIdVar = useReactiveVar(organizationId)
  const selectedStoreIdVar = useReactiveVar(selectedStoreId)
  const storesVar = useReactiveVar(stores)
  const [customers, setCustomers] = useState<ShopifyCustomer[]>([])

  const {
    refetch: refetchCustomerGroups,
    data: dataListCustomerGroups,
    loading: loadingListCustomerGroups,
    error: errorListCustomerGroups,
  } = useQuery(CUSTOMER_GROUPS_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        shopifyStoreConfigurationId: { equals: selectedStoreIdVar },
      },
    },
    skip: !selectedStoreIdVar,
  })

  const {
    refetch: refetchCustomersList,
    data: dataListCustomersList,
    loading: loadingListCustomersList,
    error: errorListCustomersList,
  } = useQuery(PROXY_SHOPIFY_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      query: 'customersName',
      shopifyStoreId: selectedStoreIdVar,
      variables: JSON.stringify({
        first: 200,
      }),
    },
  })

  useEffect(() => {
    if (dataListCustomersList?.proxyShopifyQuery) {
      const data = JSON.parse(dataListCustomersList?.proxyShopifyQuery).data.customers
      setCustomers(data.edges.map((customer: { node: ShopifyCustomer }) => customer.node))
    }
  }, [dataListCustomersList])

  function closeDialog() {
    setShowModal(false)
  }

  const rows = (dataListCustomerGroups?.listCustomerGroups || []).map((customerGroup: CustomerGroup) => {
    return {
      id: customerGroup.id,
      name: customerGroup.name,
      autoAssignNewUsers: customerGroup.autoAssignNewUsers,
      size: customerGroup.customerExternalIds.length,
      customerExternalIds: customerGroup.customerExternalIds,
      original: customerGroup,
    }
  })

  if (!selectedStoreIdVar || !storesVar) return <></>

  if (
    _.isEmpty(
      storesVar.filter(
        (store) => store.organizationId === organizationIdVar && store.id === selectedStoreIdVar
      )
    )
  ) {
    return <></>
  }

  return (
    <>
      <Box sx={{ paddingBottom: 2 }}>
        <Typography variant={'h5'}>
          {storesVar.find((x) => x.id === selectedStoreIdVar).name} - Customer Groups
        </Typography>
      </Box>
      <Box sx={{ height: 'calc(100vh - 240px)' }}>
        <Paper
          elevation={5}
          sx={{
            height: 'inherit',
            display: 'grid',
            gridTemplateRows: 'auto 1f auto',
            borderRadius: '16px',
            p: 1,
          }}
        >
          <DataGridPremium
            sx={{
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderTopWidth: 0,
            }}
            density={'compact'}
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }],
              },
            }}
            checkboxSelection
            rows={rows}
            columns={columns}
            autoHeight={true}
            loading={loadingListCustomerGroups}
            disableMultipleRowSelection={true}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel)
              if (_.size(newRowSelectionModel) === 1) {
                const data = rows.find((r: CustomerGroup) => r.id === newRowSelectionModel[0])
                setFocusedCustomerGroup(data)
              } else {
                setFocusedCustomerGroup(undefined)
              }
            }}
            slots={{
              toolbar: CreateToolbar,
            }}
            slotProps={{
              toolbar: {
                enableEditButton: !!focusedCustomerGroup,
                enableDeleteButton: false, //!!focusedCustomerGroup,
                handleAdd: () => {
                  setMode('create')
                  setShowModal(true)
                },
                handleEdit: () => {
                  setMode('update')
                  focusedCustomerGroup && setShowModal(true)
                },
                handleDelete: async () => {
                  await refetchCustomerGroups()
                },
              },
            }}
          />
        </Paper>
      </Box>
      <Dialog className={styles.modelAddNewCustomerGroup} open={showModal} onClose={() => closeDialog()}>
        <CustomerGroupCreateOrUpdateDialog
          mode={mode}
          data={mode === 'update' ? focusedCustomerGroup : undefined}
          customers={customers}
          closeDialog={() => closeDialog()}
          storeId={selectedStoreIdVar}
          onSuccess={async () => {
            await refetchCustomerGroups()
            await closeDialog()
            setRowSelectionModel([])
          }}
        />
      </Dialog>
    </>
  )
}
