import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useQuery } from '@apollo/client'

import { SKU_LISTINGS_COUNT } from 'lib/graphql/countSkuListings.ts'
import { SKU_LISTINGS_LIST } from 'lib/graphql/listSkuListings.ts'

type Props = {
  brandId?: string
  storeId?: string
  onLoading: (v: boolean) => void
  onData: (v: any) => void
}

export type GetAllSkuListingsRef = {
  refresh: () => void
}

const PAGE_SIZE = 100

export const GetAllSkuListings = forwardRef<GetAllSkuListingsRef, Props>(
  ({ brandId, storeId, onLoading, onData }, ref) => {
    const [count, setCount] = useState<number | undefined>(undefined)
    const [data, setData] = useState<any>()

    const {
      refetch: refetchCount,
      loading: loadingCount,
      error: errorCount,
    } = useQuery(SKU_LISTINGS_COUNT, {
      fetchPolicy: 'no-cache',
      variables: {
        where: {
          shopifyStoreConfigurationId: {
            equals: storeId,
          },
          sku: {
            brandId: {
              equals: brandId,
            },
          },
          archivedAt: null,
        },
      },
      onCompleted: (data) => {
        setCount(data.countSkuListings)
      },
      skip: !brandId || !storeId,
    })

    const { refetch, loading, error, fetchMore } = useQuery(SKU_LISTINGS_LIST, {
      variables: {
        where: {
          shopifyStoreConfigurationId: {
            equals: storeId,
          },
          sku: {
            brandId: {
              equals: brandId,
            },
          },
          archivedAt: null,
        },
        orderBy: [{ title: 'ASC' }],
        limit: PAGE_SIZE,
        offset: 0,
      },
      onCompleted: (data) => {
        setData(data.listSkuListings)
      },
      skip: !brandId || !storeId || !count,
    })

    useEffect(() => {
      if (brandId && count && data?.length > 0 && data?.length < count) {
        fetchMore({
          variables: {
            offset: data?.length || 0,
            limit: PAGE_SIZE,
          },
        })
      }
    }, [count, brandId, data?.length])

    useEffect(() => {
      if (loading || loadingCount || (count !== undefined && data?.length < count)) {
        onLoading(true)
      } else {
        onLoading(false)
      }
    }, [loading, loadingCount, data?.length])

    useEffect(() => {
      if (count !== undefined && count === data?.length) {
        onData(data)
      }
    }, [count, data?.length])

    useImperativeHandle(ref, () => ({
      refresh: async () => {
        await refetchCount()
        await refetch()
      },
    }))

    return <></>
  }
)
