import { useState } from 'react'
import { Box, Paper, Typography, Button, Dialog, Switch } from '@mui/material'
import _ from 'lodash'
import { useMutation } from '@apollo/client'
import { ShopifyCustomer, ACCOUNT_STATUS } from 'lib/types.ts'
import { useReactiveVar } from '@apollo/client'
import { selectedStoreId } from '@/config/cache'
import { useNavigate } from 'react-router-dom'
import { PROXY_SHOPIFY_MUTATION } from 'lib/graphql/proxyShopifyMutation.js'
import { UpdateCustomerInfoDialog } from './UpdateCustomerInfoDialog'
import { ManageAddresses } from './ManageAddresses'
import { CustomerDetailCard } from './CustomerDetailCard'
import { CustomerOrderCard } from './CustomerOrderCard'
import { CustomerAccountCard } from './CustomerAccountCard'
import { PrivateLabels } from 'pages/VendorApp/Customer/PrivateLabels.tsx'

type CustomerDetailProps = {
  customer?: ShopifyCustomer & { metafields: any; original: any; approved: boolean; accountStatus: any }
  refetchCustomers: any
}

const graphqlDataInput = (customer: any, approved: boolean) => ({
  metafields: [
    {
      id: customer.original.accountStatus.id,
      value: approved ? ACCOUNT_STATUS.APPROVED : ACCOUNT_STATUS.REJECTED,
    },
  ],
})

export const CustomerDetails = ({ customer, refetchCustomers }: CustomerDetailProps) => {
  const [showModal, setShowModal] = useState(false)
  const [showModalAddresses, setShowModalAddresses] = useState(false)
  const navigate = useNavigate()
  const selectedStoreIdVar = useReactiveVar(selectedStoreId)

  const [updateCustomer, { loading: isUpdatingCustomer }] = useMutation(PROXY_SHOPIFY_MUTATION, {
    async onCompleted() {
      await refetchCustomers()
    },
  })

  if (_.isEmpty(customer)) return <></>

  const defaultAddress = customer.addresses?.find((a) => a.id === customer.defaultAddress?.id)

  const closeDialog = () => {
    setShowModal(false)
  }

  const closeDialogAddresses = () => {
    setShowModalAddresses(false)
  }

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const response = await updateCustomer({
      variables: {
        mutation: 'updateCustomer',
        shopifyStoreId: selectedStoreIdVar,
        variables: JSON.stringify({
          ...graphqlDataInput(customer, event.target.checked),
          id: customer.id,
        }),
      },
    })
  }

  if (!customer) return <></>

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ paddingTop: '10px' }}>
        <Paper elevation={10} sx={{ borderRadius: '15px' }}>
          <Box sx={{ padding: '10px' }}>
            <Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Box>
                  <Typography variant={'h5'}>
                    {customer.firstName} {customer.lastName}
                  </Typography>
                  <Typography variant={'body1'} sx={{ display: 'inline' }}>
                    Account Status: {customer.accountStatus?.value}
                  </Typography>
                  <Switch disabled={isUpdatingCustomer} checked={customer.approved} onChange={handleChange} />
                </Box>
                <Box sx={{ alignSelf: 'center' }}>
                  <Button
                    variant={'outlined'}
                    sx={{ textAlign: 'center', verticalAlign: 'center' }}
                    onClick={() => {
                      navigate(`/vendor/${selectedStoreIdVar}/shop/${customer.id.split('/').pop()}`)
                    }}
                  >
                    Impersonate {customer.firstName} {customer.lastName}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box
        sx={{
          width: '100%',
          paddingTop: '12px',
          flexDirection: { xs: 'column', md: 'row' },
          gap: '16px',
        }}
      >
        <CustomerAccountCard customer={customer} />
      </Box>
      <Box
        sx={{
          width: '100%',
          paddingTop: '12px',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: '16px',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <CustomerOrderCard selectedStoreIdVar={selectedStoreIdVar || ''} customer={customer} />
        </Box>
        <Box sx={{ width: { sx: '100%', md: '300px' } }}>
          <CustomerDetailCard
            defaultAddress={defaultAddress}
            customer={customer}
            setShowModal={setShowModal}
            setShowModalAddresses={setShowModalAddresses}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          paddingTop: '12px',
        }}
      >
        <PrivateLabels customer={customer} />
      </Box>
      <Dialog fullWidth={true} maxWidth='md' open={showModal} onClose={() => closeDialog()}>
        <UpdateCustomerInfoDialog
          data={customer}
          closeDialog={() => closeDialog()}
          storeId={selectedStoreIdVar}
          onSuccess={async () => {
            await refetchCustomers()
          }}
        />
      </Dialog>
      <Dialog fullWidth={true} maxWidth='md' open={showModalAddresses} onClose={() => closeDialogAddresses()}>
        <ManageAddresses
          customer={customer}
          storeId={selectedStoreIdVar || ''}
          refetchCustomers={refetchCustomers}
          closeManageAddressessDialog={closeDialogAddresses}
        />
      </Dialog>
    </Box>
  )
}
