import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { Refine } from '@refinedev/core'
import routerProvider from '@refinedev/react-router-v6'
import dataProvider, { GraphQLClient } from '@refinedev/graphql'
import { CssBaseline } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { Theme } from 'theme/theme'
import { Layout } from 'components/Layout'
import { OAuthCallback } from 'components/OAuthCallback'
import LoginPage from 'pages/AuthPages/LoginPage'
import { Authenticated } from 'pages/AuthPages/Authenticated'
import { AppsDashboard } from 'pages/AppsDashboard'

import { LabelDetail } from 'pages/LabelDetail'
import ResetPasswordPage from 'pages/AuthPages/ResetPasswordPage.tsx'
import { BarcodeDashboard } from 'pages/OperationsApp/Barcodes.tsx'
import { ShopifyStoreConfigurationsDashboard } from 'pages/ShopifyStoreConfigurationsDashboard'
import { SkuListings } from 'pages/VendorApp/SkuListings'
import { ManageCustomerGroups } from 'pages/VendorApp/ManageCustomerGroups'
import { ManageCustomers } from 'pages/VendorApp/ManageCustomers'
import { ManageAccounts } from 'pages/VendorApp/ManageAccounts'
import { ReviewLabelPage } from 'pages/VendorApp/ReviewLabelPage'
import { Brands } from 'pages/VendorApp/Brands'
import { Catalog } from 'pages/VendorApp/Catalog'
import { Users } from 'pages/VendorApp/Users'
import { Connect } from 'pages/VendorApp/Connect'
import { ConnectOrderDetail } from 'pages/VendorApp/Connect/ConnectOrderDetail'
import { ConnectMessageDetail } from 'pages/VendorApp/Connect/ConnectMessageDetail'
import { LabelFacts } from 'pages/VendorApp/LabelFacts'
import { LabelFactsMajorVersion } from 'pages/VendorApp/LabelFacts/LabelFactsMajorVersion.tsx'
import { LabelFactsDetails } from 'pages/VendorApp/LabelFacts/LabelFactsDetails.tsx'
import { DesignerTemplates } from 'pages/VendorApp/DesignerTemplates.tsx'
import { LabelOperations } from 'pages/VendorApp/LabelOperations.tsx'
import { LabelApprovalDashboard } from 'pages/VendorApp/LabelApprovalDashboard.tsx'
import { StoreSettings } from 'pages/VendorApp/StoreSettings/StoreSettings'
import { StoreSettingsAdmin } from 'pages/VendorApp/StoreSettingsAdmin.tsx'
import { FulfillmentClients } from 'pages/VendorApp/FulfillmentClients.tsx'

import { VendorAppLayout } from '@/layouts/VendorAppLayout.tsx'
import { OperationsAppLayout } from '@/layouts/OperationsAppLayout.tsx'
import { Orders } from 'pages/OperationsApp/Orders.tsx'
import { Shop } from 'pages/VendorApp/Shop'
import { ReportsAppLayout } from '@/layouts/ReportsAppLayout.tsx'
import { BillingReport } from 'pages/ReportsApp/BillingReport.tsx'
import { OrdersReport } from 'pages/ReportsApp/OrdersReport.tsx'
import { PaymentsReport } from 'pages/ReportsApp/PaymentsReport'
import { OrderPage } from 'pages/ReportsApp/OrderPage.tsx'
import { AdminSettings } from 'pages/AdminSettings/AdminSettings'

import * as Sentry from '@sentry/react'
import React from 'react'
import LogoutPage from 'pages/AuthPages/LogoutPage.tsx'

const client = new GraphQLClient('API_URL')

if (import.meta.env.VITE_ENV !== 'dev') {
  Sentry.init({
    dsn: 'https://668f9cd305c327f9ed48784ebdbb350d@o4508015001600000.ingest.us.sentry.io/4508015003172864',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        networkDetailAllowUrls: [import.meta.env.VITE_APPSYNC_ENDPOINT],
        networkRequestHeaders: ['X-Custom-Header'],
        networkResponseHeaders: ['X-Custom-Header'],
      }),
    ],
    environment: import.meta.env.VITE_ENV,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', import.meta.env.VITE_APPSYNC_ENDPOINT],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CssBaseline />
      <Theme>
        <BrowserRouter>
          <Refine routerProvider={routerProvider} dataProvider={dataProvider(client)}>
            <Routes>
              <Route path='/oauth' element={<OAuthCallback />} />
              <Route path='/login' element={<LoginPage />} />
              <Route path='/logout' element={<LogoutPage />} />
              <Route path='/forgot-password' element={<ResetPasswordPage />} />
              <Route
                path='/'
                element={
                  <Authenticated>
                    <Layout app={'admin'}>
                      <Outlet />
                    </Layout>
                  </Authenticated>
                }
              >
                <Route index element={<AppsDashboard />} />
              </Route>

              <Route
                path='/labels'
                element={
                  <Authenticated>
                    <Layout app={'labels'}>
                      <Outlet />
                    </Layout>
                  </Authenticated>
                }
              >
                <Route index element={<LabelDetail />} />
              </Route>
              <Route
                path='/vendor'
                element={
                  <Authenticated>
                    <Layout app={'vendor'}>
                      <Outlet />
                    </Layout>
                  </Authenticated>
                }
              >
                <Route path=':storeId/shop/:customerId' element={<Shop />} />
              </Route>
              <Route
                path='/vendor'
                element={
                  <Authenticated>
                    <Layout app={'vendor'}>
                      <VendorAppLayout>
                        <Outlet />
                      </VendorAppLayout>
                    </Layout>
                  </Authenticated>
                }
              >
                <Route index element={<></>} />

                <Route path='reports' element={<Outlet />}>
                  <Route index element={<OrdersReport />} />
                  <Route path='orders/:storeId/:orderId' element={<OrderPage />} />
                  <Route path='orders' element={<OrdersReport />} />
                  <Route path='billing' element={<BillingReport />} />
                  <Route path='payments' element={<PaymentsReport />} />
                </Route>

                <Route path='connect' element={<Outlet />}>
                  <Route index element={<Connect />} />
                  <Route path='order/:id/:commonId' element={<ConnectOrderDetail />} />
                  <Route path='messages/:type/:messageId' element={<ConnectMessageDetail />} />
                </Route>

                <Route path='brands' element={<Brands />} />
                <Route path='catalog' element={<Catalog />} />
                <Route path='users' element={<Users />} />
                <Route path='customer-accounts' element={<ManageAccounts />} />
                <Route path='templates' element={<DesignerTemplates />} />
                <Route path='label-facts' element={<LabelFacts />} />
                <Route path='label-facts/create/:organizationId/:skuId' element={<LabelFactsDetails />} />
                <Route
                  path='label-facts/details/:organizationId/:skuId/:id'
                  element={<LabelFactsDetails />}
                />
                <Route
                  index
                  path='label-facts/:organizationId/:skuId/:version'
                  element={<LabelFactsMajorVersion />}
                />

                <Route path=':storeId/settings' element={<StoreSettings />} />
                <Route path=':storeId/admin-settings' element={<StoreSettingsAdmin />} />
                <Route path=':storeId/customers' element={<ManageCustomers />} />
                <Route path='labelops' element={<LabelOperations />} />
                <Route path='label-approval' element={<LabelApprovalDashboard />} />
                <Route path='fulfillment-clients' element={<FulfillmentClients />} />
                <Route path=':storeId/labels/review' element={<ReviewLabelPage />} />
                <Route path=':storeId/skuListings' element={<SkuListings />} />
                <Route path=':storeId/customerGroups' element={<ManageCustomerGroups />} />
              </Route>
              <Route
                path='/operations'
                element={
                  <Authenticated>
                    <Layout app={'operations'}>
                      <OperationsAppLayout>
                        <Outlet />
                      </OperationsAppLayout>
                    </Layout>
                  </Authenticated>
                }
              >
                <Route index element={<></>} />
                <Route path='orders' element={<Orders />} />
                <Route path='barcodes' element={<BarcodeDashboard />} />
              </Route>
              <Route
                path='/settings'
                element={
                  <Authenticated>
                    <Layout app={'operations'}>
                      <OperationsAppLayout>
                        <Outlet />
                      </OperationsAppLayout>
                    </Layout>
                  </Authenticated>
                }
              >
                <Route index element={<></>} />
                <Route path='admin' element={<AdminSettings />} />
              </Route>
              <Route
                path='/reports'
                element={
                  <Authenticated>
                    <Layout app={'reports'}>
                      <ReportsAppLayout>
                        <Outlet />
                      </ReportsAppLayout>
                    </Layout>
                  </Authenticated>
                }
              >
                <Route index element={<></>} />
                <Route path='billing' element={<BillingReport />} />
                <Route path='orders' element={<OrdersReport />} />
                <Route path='payments' element={<PaymentsReport />} />
              </Route>
              <Route
                path='/shopify'
                element={
                  <Authenticated>
                    <Layout app={'shopify'}>
                      <Outlet />
                    </Layout>
                  </Authenticated>
                }
              >
                <Route index element={<ShopifyStoreConfigurationsDashboard />} />
              </Route>
              <Route path='*' element={<Navigate to={'/'} />} />
            </Routes>
          </Refine>
        </BrowserRouter>
      </Theme>
    </LocalizationProvider>
  )
}

export default App
