import { gql } from '@apollo/client'

export const CREATE_BRAND_ACCOUNT = gql`
  mutation createBrandAccount($data: BrandAccountCreateInput!) {
    createBrandAccount(data: $data) {
      id
      name
      accountManagerName
      sellsInCalifornia
      prop65Waiver
      customers {
        id
        customerId
      }
      createdAt
    }
  }
`
