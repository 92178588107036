import { useLazyQuery, useReactiveVar } from '@apollo/client'
import { Box, LinearProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { PROXY_SHOPIFY_QUERY } from 'lib/graphql/proxyShopifyQuery.ts'
import { OrderDetails } from 'pages/ReportsApp/OrderDetails.tsx'
import { useParams } from 'react-router-dom'
import { organizationId, selectedOrganization } from 'config/cache.ts'

export const OrderPage = () => {
  const [selectedOrder, setSelectedOrder] = useState()

  const { storeId, orderId } = useParams()
  const organizationVar = useReactiveVar(selectedOrganization)
  const selectedOrganizationIdVar = useReactiveVar(organizationId)
  const [lazyShopifyQuery, { loading: loadingShopify }] = useLazyQuery(PROXY_SHOPIFY_QUERY)

  useEffect(() => {
    if (!storeId || !orderId) {
      setSelectedOrder(undefined)
    } else {
      lazyShopifyQuery({
        fetchPolicy: 'no-cache',
        variables: {
          query: 'order',
          shopifyStoreId: storeId,
          variables: JSON.stringify({
            id: `gid://shopify/Order/${orderId}`,
          }),
        },
        onCompleted: (data) => {
          setSelectedOrder(JSON.parse(data.proxyShopifyQuery || '{}')?.data?.order)
        },
      })
    }
  }, [storeId, orderId])

  return (
    <>
      <Box sx={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px', height: '100vh' }}>
        {selectedOrder ? <OrderDetails order={selectedOrder} storeId={storeId} /> : <LinearProgress />}
      </Box>
    </>
  )
}
