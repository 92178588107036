import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { ChevronLeft } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import { useRecoilValue } from 'recoil'
import JsonView from 'react18-json-view'
import dayjs from 'dayjs'
import 'react18-json-view/src/style.css'
import { messageState } from 'store/store'

const BASE_URI = '/vendor/connect'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: string
  hidden: boolean
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, hidden, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={hidden}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {!hidden && <Box>{children}</Box>}
    </div>
  )
}

export const ConnectMessageDetail = () => {
  const navigate = useNavigate()
  const params = useParams()

  const [value, setValue] = useState(params.type)

  const messageValue = useRecoilValue(messageState)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const notificationType = (request: string) => (request?.includes('shipment') ? 'Shipment' : 'Order')

  return (
    <Box sx={{ backgroundColor: '#F9F9FC' }}>
      <Paper
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingY: 1 }}
        square
      >
        <Button
          variant='text'
          onClick={() =>
            navigate(`${BASE_URI}/order/${messageValue.orderId}/${encodeURIComponent(messageValue.commonId)}`)
          }
        >
          <ChevronLeft /> BACK
        </Button>
        <Typography color='#001D35' fontWeight={700} fontSize='18px' sx={{ flex: 1 }} textAlign='end'>
          #{messageValue.commonId}
        </Typography>
        <Box display='hidden' sx={{ flexGrow: 1 }} />
      </Paper>
      <Tabs
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue)
          navigate(`${BASE_URI}/messages/${newValue}/${params.messageId}`)
        }}
      >
        <Tab label='INCOMING' value='incoming' />
        <Tab label='OUTGOING' value='outgoing' />
      </Tabs>
      <CustomTabPanel hidden={value !== 'incoming'} value='incoming' index={0}>
        <Paper sx={{ maxWidth: '100%', padding: '16px', m: 1 }}>
          <Typography color='#0F1D2A' fontSize='14px' fontWeight={500}>
            {notificationType(messageValue.incoming.requestType)} Notification from{' '}
            {messageValue.incoming.source.name}
          </Typography>
          <Typography color='#526070' fontSize={14} fontWeight={500}>
            {dayjs(messageValue.incoming.createdAt).format('MM/DD/YY h:mm a')}
          </Typography>
          <Box sx={{ m: 2 }}>
            <JsonView
              style={{ width: '100%', overflowX: 'auto', fontSize: '13px' }}
              src={JSON.parse(messageValue.incoming.body)}
            />
          </Box>
        </Paper>
      </CustomTabPanel>
      <CustomTabPanel hidden={value !== 'outgoing'} value='outgoing' index={1}>
        {messageValue.outgoing?.map((message: any, index: number) => {
          return (
            <Paper sx={{ maxWidth: '100%', padding: '16px', m: 1 }} key={index}>
              <Typography color='#0F1D2A' fontSize='14px' fontWeight={500}>
                {notificationType(message.requestType)} Notification sent to {message.destination.name}
              </Typography>
              <Typography color='#526070' fontSize='14px' fontWeight={500}>
                {dayjs(message.createdAt).format('MM/DD/YY h:mm a')}
              </Typography>
              <Box sx={{ m: 2 }}>
                <Typography color='#526070' fontSize={14} fontWeight={500}>
                  Request
                </Typography>
                <JsonView
                  style={{ width: '100%', overflowX: 'auto', fontSize: '13px' }}
                  src={JSON.parse(message.body)}
                />
              </Box>
              <Box sx={{ m: 2 }}>
                <Typography color='#526070' fontSize={14} fontWeight={500}>
                  Response
                </Typography>
                <JsonView
                  style={{ width: '100%', overflowX: 'auto', fontSize: '13px' }}
                  src={JSON.parse(message.statusMessage)}
                />
              </Box>
            </Paper>
          )
        })}
      </CustomTabPanel>
    </Box>
  )
}
