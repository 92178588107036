import { gql } from '@apollo/client'

export const LIST_WEBHOOK_REGISTRATIONS = gql`
  query listWebhookRegistrations(
    $where: WebhookRegistrationWhereInput
    $orderBy: [WebhookRegistrationOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    listWebhookRegistrations(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      eventKey
      url
      signingSecret
      storeConfigurationWebhook {
        id
        storeType
        storeConfigurationId
        organization {
          id
          name
        }
      }
    }
  }
`
