import { useEffect, useRef, useState } from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material'
import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { BrandSelect } from 'components/Forms/BrandSelect'
import { useDropzone } from 'react-dropzone'
import DownloadIcon from '@mui/icons-material/Download'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { getDataFromExcelFile } from 'lib/helpers/import.ts'
import { DataGridPremium } from '@mui/x-data-grid-premium'

import _ from 'lodash'
import { CREATE_SUPPLEMENT_FACT } from 'lib/graphql/createSupplementFact.ts'
import { UPDATE_SUPPLEMENT_FACT } from 'lib/graphql/updateSupplementFact.ts'
import { Sku } from 'lib/graphql/types.ts'
import { GetAllCatalogSkus, GetAllCatalogSkusRef } from 'pages/VendorApp/Common/GetAllCatalogSkus.tsx'
import { GetAllLabelFacts, GetAllLabelFactsRef } from 'pages/VendorApp/Common/GetAllLabelFacts.tsx'

const initialValues = {
  brandId: '',
  data: [],
}

const DisplayingErrorMessagesSchema = Yup.object().shape({
  brandId: Yup.string().required('Required'),
  data: Yup.array(Yup.object()).required(),
})

type LabelFactsImportFieldProps = {
  brandId: string
  data: any[]
}

const makeRequestBody = (brandId: string, dataCatalog: Sku[], labelFactsList: any[]) => (item: any) => {
  const sku = dataCatalog.find((x) => x.sku === `${item.sku}`)
  if (!sku) return {}
  const existingLabelFact = labelFactsList.find((x) => x.sku.sku === `${item.sku}`)
  const majorVersion = existingLabelFact?.majorVersion || 0

  if (existingLabelFact?.status === 'DRAFT') {
    return {
      where: {
        id: existingLabelFact.id,
      },
      data: {
        revisionNumber: item.revisionNumber,
        otherIngredients: item.otherIngredients,
        caution: item.caution,
        allergenStatement: item.allergenStatement,
        suggestedUse: item.suggestedUse,
        // supplementLabelImage: item.supplementLabelImage,
      },
    }
  } else {
    return {
      data: {
        sku: {
          connect: {
            id: sku.id,
          },
        },
        majorVersion: majorVersion + 1,
        minorVersion: 1,
        revisionNumber: item.revisionNumber,
        otherIngredients: item.otherIngredients,
        caution: item.caution,
        allergenStatement: item.allergenStatement,
        suggestedUse: item.suggestedUse,
        // supplementLabelImage: item.supplementLabelImage,
        status: 'DRAFT',
        signedReason: 'Bulk import',
      },
    }
  }
}

const removeNull = (s: string) =>
  s?.toLowerCase() === 'null' || s?.toLowerCase() === 'n/a' || s?.toLowerCase() === 'na' ? undefined : s

const FileUploader = ({ setData }: { setData: (v: any[]) => void }) => {
  const [files, setFiles] = useState<any[]>([])

  const { getInputProps, getRootProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'text/csv': [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    },
    onDrop: async (acceptedFiles) => {
      const data = await getDataFromExcelFile(acceptedFiles[0])
      setData(
        await Promise.all(
          data.map(async (x: any) => {
            return {
              ...x,
              id: `${_.get(x, 'Branded SKU')}`,
              sku: `${_.get(x, 'Branded SKU')}`,
              skuWhiteLabel: `${_.get(x, 'Unlabeled SKU')}`,
              caution: removeNull(_.get(x, 'Caution')),
              allergenStatement: removeNull(_.get(x, 'Allergen Statement')),
              suggestedUse: removeNull(_.get(x, 'Suggested Use')),
              otherIngredients: removeNull(_.get(x, 'Other Ingredients')),
              supplementFacts: _.get(x, 'Supplement facts image'),
              revisionNumber: _.get(x, 'Revision Number', '0'),
            }
          })
        )
      )
      setFiles(acceptedFiles)
    },
  })

  return (files || []).length ? (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
      <div>
        <AttachFileIcon />
      </div>
      {files[0].name}
    </Box>
  ) : (
    <Box
      {...getRootProps({
        // style,
      })}
      sx={{
        border: '1px dashed #008bbd',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingX: '20px',
        margin: '20px',
      }}
    >
      <div {...getInputProps()} />
      <Box my={1} sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
        <DownloadIcon fontSize='large' />
        <Typography variant='body1' sx={{ fontWeight: 400 }}>
          Drag-and-drop or click to upload file
        </Typography>
      </Box>
    </Box>
  )
}

const LabelFactsImportDialog = ({
  onSuccess,
  currentOrganizationId,
  closeDialog,
}: {
  onSuccess: () => void
  currentOrganizationId: string
  closeDialog: () => void
}) => {
  const [submitError, setSubmitError] = useState<string>()
  const [numCreated, setNumCreated] = useState<number>(0)
  const [numUpdated, setNumUpdated] = useState<number>(0)
  const [numErrors, setNumErrors] = useState<number>(0)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<any[]>()
  const [brandId, setBrandId] = useState<string>()

  const [loadingCatalog, setLoadingCatalog] = useState<boolean>(false)
  const [dataCatalog, setDataCatalog] = useState<any>(undefined)
  const getAllCatalogSkusRef = useRef<GetAllCatalogSkusRef>(null)

  const [loadingLabeLFacts, setLoadingLabelFacts] = useState<boolean>(false)
  const [dataLabelFacts, setDataLabelFacts] = useState<any>(undefined)
  const getAllLabelFactsRef = useRef<GetAllLabelFactsRef>(null)

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm<LabelFactsImportFieldProps>({
    shouldFocusError: true,
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver<LabelFactsImportFieldProps>(DisplayingErrorMessagesSchema as any),
    defaultValues: initialValues,
  })

  useEffect(() => {
    !_.isEmpty(getValues('brandId')) && setBrandId(getValues('brandId'))
  }, [watch('brandId')])

  useEffect(() => {
    getAllCatalogSkusRef.current?.refresh()
  }, [brandId])

  const [createLabelFact] = useMutation(CREATE_SUPPLEMENT_FACT, {
    onCompleted() {
      setNumCreated((v) => v + 1)
    },
    onError(error) {
      setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
      setNumErrors((v) => v + 1)
    },
  })

  const [updateLabelFact] = useMutation(UPDATE_SUPPLEMENT_FACT, {
    onCompleted() {
      setNumUpdated((v) => v + 1)
    },
    onError(error) {
      setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
      setNumErrors((v) => v + 1)
    },
  })

  const onSubmit = async (values: LabelFactsImportFieldProps) => {
    const requestBody = (selectedRows || []).map(makeRequestBody(values.brandId, dataCatalog, dataLabelFacts))

    for (const x of requestBody) {
      if (x.where) {
        await updateLabelFact({
          variables: x,
        })
      } else {
        await createLabelFact({
          variables: x,
        })
      }
    }
    setSubmitted(true)
  }

  const rows = getValues('data') || []
  return (
    <>
      <GetAllCatalogSkus
        ref={getAllCatalogSkusRef}
        brandId={brandId}
        onLoading={(v) => setLoadingCatalog(v)}
        onData={(d) => setDataCatalog(d)}
      />
      <GetAllLabelFacts
        ref={getAllLabelFactsRef}
        brandId={brandId}
        onLoading={(v) => setLoadingLabelFacts(v)}
        onData={(d) => {
          setDataLabelFacts(d || [])
        }}
      />
      <DialogTitle>Import Label Facts</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', paddingX: '30px' }}>
            {submitError && <Alert severity={'error'}>{submitError}</Alert>}
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                alignItems: 'baseline',
              }}
            >
              <Box>Select Brand</Box>
              <Box sx={{ flexGrow: '1' }}>
                <BrandSelect
                  control={control}
                  organizationId={currentOrganizationId}
                  name='brandId'
                  label='Brand'
                />
              </Box>
            </Box>
            <FileUploader setData={(v) => setValue('data', v)} />
            <Box
              sx={{
                width: '100%',
                height: '270px',
                fontSize: '10px',
                overflow: 'auto',
                border: '1px solid gray',
              }}
            >
              {!brandId || loadingCatalog || loadingLabeLFacts ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  {brandId ? (
                    <>
                      <CircularProgress />
                      {loadingCatalog && <Typography variant={'h6'}>Loading catalog</Typography>}
                      {loadingLabeLFacts && <Typography variant={'h6'}>Loading known facts</Typography>}
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              ) : (
                <></>
              )}
              {watch('data') && (
                <DataGridPremium
                  hideFooter={true}
                  density={'compact'}
                  columns={[
                    {
                      field: 'Type',
                      width: 120,
                      disableColumnMenu: true,
                      renderCell: (row) => {
                        if (!dataLabelFacts) {
                          return '<pending>'
                        }
                        const foundInCatalog = dataCatalog?.find((x: any) => x.sku === row.id)
                        if (!foundInCatalog) {
                          return <Box sx={{ color: 'darkred', fontWeight: 700 }}>Not in Catalog</Box>
                        }
                        const found = dataLabelFacts?.find((x: any) => x.sku.sku === row.id)
                        return found ? (
                          <>
                            {found.status === 'DRAFT' ? (
                              <Box sx={{ color: 'darkorange', fontWeight: 700 }}>
                                Update v{found.majorVersion}.{found.minorVersion}
                              </Box>
                            ) : (
                              <Box sx={{ color: 'dodgerblue', fontWeight: 700 }}>
                                Create v{found.majorVersion + 1}.1
                              </Box>
                            )}
                          </>
                        ) : (
                          <Box sx={{ color: 'darkgreen', fontWeight: 700 }}>New</Box>
                        )
                      },
                    },
                    {
                      field: 'Product Name',
                      width: 200,
                    },
                    { field: 'Branded SKU', width: 250 },
                    { field: 'revisionNumber', headerName: 'Revision', width: 130 },
                    { field: 'suggestedUse', headerName: 'Suggested Use', width: 250 },
                    { field: 'otherIngredients', headerName: 'Other Ingredients', width: 250 },
                    { field: 'caution', headerName: 'Caution', width: 250 },
                    { field: 'allergenStatement', headerName: 'Allergen Statement', width: 250 },
                    {
                      field: 'supplementFacts',
                      headerName: 'Supplement Facts',
                      width: 250,
                      renderCell: (params) => {
                        const url = params.row[params.field]
                        return url //<img src={url} alt={url} />
                      },
                    },
                  ]}
                  sx={{
                    '& .MuiDataGrid-row .MuiDataGrid-cellCheckbox .Mui-disabled ': {
                      // "hide" disabled checkboxes by making it translucent
                      color: 'rgba(0, 0, 0, 0.00)',
                    },
                  }}
                  onRowSelectionModelChange={(ids) => {
                    setSelectedRows(ids.map((id) => rows.find((row) => row.id === id)))
                  }}
                  isRowSelectable={(params) => dataCatalog?.find((sku: Sku) => sku.sku === params.row.id)}
                  checkboxSelection={true}
                  rows={rows}
                  getRowHeight={(params) => 'auto'}
                  getRowId={(x: any) => x.sku}
                />
              )}
              {/*<pre>{watch('data') && JSON.stringify(getValues('data'), null, 2)}</pre>*/}
            </Box>
            {(rows?.length || numCreated > 0 || numUpdated > 0 || numErrors > 0) && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                <div>
                  Found {rows?.length} label facts. Matched{' '}
                  {rows?.filter((x) => dataCatalog?.find((y: any) => y.sku === x.sku)).length} label facts by
                  SKU.
                </div>
                <div>{selectedRows?.length || 0} selected for import.</div>
                <div>
                  {numCreated > 0 && <>Created {numCreated} label facts. </>}
                  {numUpdated > 0 && <>Updated {numUpdated} label facts. </>}
                  {numErrors > 0 && <>{numErrors} errors.</>}
                </div>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            onClick={() => {
              onSuccess()
              closeDialog()
            }}
          >
            Cancel
          </Button>
          {submitted ? (
            <Button onClick={() => closeDialog()}>Close</Button>
          ) : (
            <Button
              type='submit'
              disabled={
                !dataCatalog ||
                !dataLabelFacts ||
                isSubmitting ||
                !(isDirty && isValid) ||
                !getValues('data').length
              }
            >
              Import
            </Button>
          )}
        </DialogActions>
      </form>
    </>
  )
}

export default LabelFactsImportDialog
