import { gql } from '@apollo/client'

export const LIST_LABEL_APPROVAL_WORKFLOW_INSTANCES = gql`
  query listLabelApprovalWorkflowInstances(
    $where: LabelApprovalWorkflowInstanceWhereInput
    $orderBy: [LabelApprovalWorkflowInstanceOrderByInput!]
    $offset: Int
    $limit: Int
  ) {
    listLabelApprovalWorkflowInstances(where: $where, orderBy: $orderBy, skip: $offset, take: $limit) {
      id
      name
      status
      rejectionReason
      orderId
      customerExternalId
      instanceId
      shopifyStoreConfiguration {
        id
        name
      }
      metadata
      steps {
        id
        name
        status
        notes
        updatedAt
        workflowId
        createdAt
        stepNumber
        assignedTo {
          id
          sub
          email
        }
      }
      createdAt
      activeStepNumber
    }
  }
`
