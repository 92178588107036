import { useState } from 'react'
import { gql, useMutation, useReactiveVar } from '@apollo/client'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { withMask } from 'use-mask-input'
import { useForm, SubmitHandler } from 'react-hook-form'
import * as Yup from 'yup'

import { FormSwitch } from 'components/Forms/FormSwitch.tsx'
import { FormTextField } from 'components/Forms/FormTextField.tsx'
import { Input } from 'components/Forms/Input.tsx'
import { CREATE_BRAND_ACCOUNT } from '@/lib/graphql/createBrandAccount'
import { UPDATE_BRAND_ACCOUNT } from 'lib/graphql/updateBrandAccount'
import { organizationId } from '@/config/cache'
import { BrandAccount } from '@/lib/graphql/types'

export type BrandAccountFieldProps = {
  id?: string
  name: string
  accountManager?: string
  city: string
  state: string
  sellsInCalifornia: boolean
  prop65Waiver: boolean
}

interface BrandAccountCreateOrUpdateProps {
  mode: 'create' | 'update'
  data?: BrandAccount
  closeDialog: () => void
}

const validationSchema = Yup.object().shape({
  id: Yup.string().optional(),
  name: Yup.string().required(),
  accountManager: Yup.string().optional(),
  city: Yup.string().required(),
  state: Yup.string().required(),
  sellsInCalifornia: Yup.boolean().required(),
  prop65Waiver: Yup.boolean().required(),
})

const initialValues: BrandAccountFieldProps = {
  id: '',
  name: '',
  accountManager: '',
  city: '',
  state: '',
  prop65Waiver: false,
  sellsInCalifornia: false,
}

const transformInitialValues = (data: BrandAccount) => ({
  id: data?.id,
  name: data?.name,
  accountManager: data?.accountManagerName,
  city: data?.city,
  state: data?.state,
  prop65Waiver: data?.prop65Waiver,
  sellsInCalifornia: data?.sellsInCalifornia,
})

export const BrandAccountCreateOrUpdateDialog: React.FC<BrandAccountCreateOrUpdateProps> = ({
  mode,
  data,
  closeDialog,
}) => {
  const [submitError, setSubmitError] = useState<string>()
  const currentOrganizationId = useReactiveVar(organizationId)

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
    reset,
    getValues,
  } = useForm<BrandAccountFieldProps>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver<BrandAccountFieldProps>(validationSchema),
    defaultValues: mode === 'create' ? initialValues : transformInitialValues(data!),
  })

  const [createBrandAccount] = useMutation(CREATE_BRAND_ACCOUNT, {
    onError(error) {
      setSubmitError(error.message)
      // setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
    },
    onCompleted() {
      closeDialog()
    },
    update(cache, { data: { createBrandAccount } }) {
      cache.modify({
        fields: {
          listBrandAccounts(existingAccounts = []) {
            const newAccountRef = cache.writeFragment({
              data: createBrandAccount,
              fragment: gql`
                fragment NewAccount on BrandAccount {
                  id
                  name
                  accountManagerName
                  sellsInCalifornia
                  prop65Waiver
                  customers {
                    id
                    customerId
                  }
                  createdAt
                }
              `,
            })
            return [...existingAccounts, newAccountRef]
          },
        },
      })
    },
  })

  const [updateBrandAccount] = useMutation(UPDATE_BRAND_ACCOUNT, {
    onError(error) {
      setSubmitError(error.message)
      // setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
    },
    onCompleted() {
      closeDialog()
    },
    update(cache, { data: { updateBrandAccount } }) {
      cache.modify({
        fields: {
          listBrandAccounts() {
            cache.writeFragment({
              data: updateBrandAccount,
              fragment: gql`
                fragment NewAccount on BrandAccount {
                  id
                  name
                  accountManagerName
                  sellsInCalifornia
                  prop65Waiver
                  customers {
                    id
                    customerId
                  }
                  createdAt
                }
              `,
            })
          },
        },
      })
    },
  })

  const create = async (values: BrandAccountFieldProps) => {
    await createBrandAccount({
      variables: {
        data: {
          name: values.name,
          accountManagerName: values.accountManager,
          city: values.city,
          state: values.state,
          sellsInCalifornia: values.sellsInCalifornia,
          prop65Waiver: values.prop65Waiver,
          organization: {
            connect: {
              id: currentOrganizationId,
            },
          },
        },
      },
    })
  }

  const update = async (values: BrandAccountFieldProps) => {
    await updateBrandAccount({
      variables: {
        where: {
          id: values.id,
        },
        data: {
          name: values.name,
          accountManagerName: values.accountManager,
          city: values.city,
          state: values.state,
          sellsInCalifornia: values.sellsInCalifornia,
          prop65Waiver: values.prop65Waiver,
        },
      },
    })
  }

  const onSubmit: SubmitHandler<BrandAccountFieldProps> = async (data) => {
    setSubmitError('')
    mode === 'create' ? await create(data) : await update(data)
  }

  return (
    <>
      <DialogTitle>{mode === 'update' ? 'Update' : 'Create New'} Customer Account</DialogTitle>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input type='hidden' control={control} name='id' />
        <DialogContent sx={{ minWidth: '600px' }}>
          <Box>
            <Box>{submitError && <Alert severity={'error'}>{submitError}</Alert>}</Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormTextField
                  name='name'
                  label='Customer Account'
                  type='text'
                  fullWidth
                  variant='standard'
                  disabled={false}
                  control={control}
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  name='accountManager'
                  label='Customer Account Manager'
                  type='text'
                  fullWidth
                  variant='standard'
                  disabled={false}
                  control={control}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  name='city'
                  label='City'
                  type='text'
                  fullWidth
                  variant='standard'
                  disabled={false}
                  control={control}
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  name='state'
                  label='State'
                  type='text'
                  fullWidth
                  variant='standard'
                  disabled={false}
                  control={control}
                  mask={withMask('AA', {
                    placeholder: ' ',
                  })}
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormSwitch control={control} name='sellsInCalifornia' label='Sells In California?' />
              </Grid>
              <Grid item xs={4}>
                <FormSwitch control={control} name='prop65Waiver' label='Prop 65 Waiver?' />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            onClick={() => {
              closeDialog()
            }}
          >
            Cancel
          </Button>
          <Button type='submit' disabled={isSubmitting || !(isDirty && isValid)}>
            {mode === 'update' ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </>
  )
}
