import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { Delete } from '@mui/icons-material'

const DialogLotActions = ({
  lot,
  exp,
  handleClose,
  open,
  handleMove,
  handleDelete,
}: {
  lot: number
  exp: string
  open: boolean
  handleClose: () => void
  handleMove: () => void
  handleDelete: () => void
}) => {
  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ sx: { padding: 2 } }}>
      <DialogTitle>
        Lot #{lot} Exp: {exp}
      </DialogTitle>
      <DialogActions disableSpacing sx={{ flexDirection: 'column', alignItems: 'stretch' }}>
        <Button variant='outlined' color='primary' sx={{ marginBottom: 1 }} onClick={handleMove}>
          MOVE TO DIFFERENT VERSION
        </Button>
        <Button variant='outlined' color='error' startIcon={<Delete />} onClick={handleDelete} disabled>
          DELETE LOT
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogLotActions
