import { gql } from '@apollo/client'

export const CREATE_LOT = gql`
  mutation createLot($data: LotCreateInput!) {
    createLot(data: $data) {
      lotNumber
      expirationDate
      sku {
        sku
        skuFact {
          privateLabelName
        }
      }
      createdAt
    }
  }
`
