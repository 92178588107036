import { useQuery, useReactiveVar } from '@apollo/client'
import { useCallback, useState, useMemo } from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import dayjs from 'dayjs'
import _ from 'lodash'
import ConnectTable from 'components/Connect/ConnectTable'
import { ORDERS_LIST } from 'lib/graphql/listOrdersWorkflow'
import { COUNT_ORDERS } from 'lib/graphql/countOrders'
import { organizationId, orderSearchString, fromDate, toDate, orderFilter } from 'config/cache'
import { Typography } from '@mui/material'
import { GridSortModel } from '@mui/x-data-grid-premium'

const filters = {
  all: [],
  orderProcessingFailed: [
    {
      messages: {
        none: {
          AND: [
            { status: { equals: 'Success' } },
            {
              requestType: {
                equals: 'POST /connect/orders',
              },
            },
          ],
        },
      },
    },
    {
      messages: {
        some: {
          requestType: {
            equals: 'POST /connect/orders',
          },
        },
      },
    },
  ],
  shipmentNotificationFailed: [
    {
      messages: {
        none: {
          AND: [
            { status: { equals: 'Success' } },
            {
              requestType: {
                equals: 'POST /connect/extensiv/shipment',
              },
            },
          ],
        },
      },
    },
  ],
  orderWebhookFailed: [],
  shipmentWebhookFailed: [],
}

const PAGE_SIZE = 100
export const Connect = () => {
  const organizationIdVar = useReactiveVar(organizationId)
  const orderSearchStringVar = useReactiveVar(orderSearchString)
  const fromDateVar = useReactiveVar(fromDate)
  const toDateVar = useReactiveVar(toDate)
  const orderFilterVar = useReactiveVar(orderFilter)
  const [queryOptions, setQueryOptions] = useState<{ sortModel: any[] }>({
    sortModel: [{ field: 'createdAt', sort: 'desc' }],
  })

  const {
    loading: loadingCountOrders,
    data: dataCountOrders,
    error: errorCountOrders,
  } = useQuery(COUNT_ORDERS, {
    variables: {
      where: {
        AND: [
          {
            organizationId: { equals: organizationIdVar },
            commonId: { contains: orderSearchStringVar },
            createdAt: { gte: fromDateVar, lte: toDateVar },
          },
          ...filters[orderFilterVar],
        ],
      },
    },
    skip: !organizationIdVar,
  })

  const {
    loading: loadingOrders,
    data: dataOrders,
    error: errorOrders,
    fetchMore,
  } = useQuery(ORDERS_LIST, {
    variables: {
      limit: PAGE_SIZE,
      offset: 0,
      where: {
        AND: [
          {
            organizationId: { equals: organizationIdVar },
            commonId: { contains: orderSearchStringVar },
            createdAt: { gte: fromDateVar, lte: toDateVar },
          },
          ...filters[orderFilterVar],
        ],
      },
      orderBy: queryOptions?.sortModel?.map((m) => ({ [m.field]: m.sort === 'asc' ? 'ASC' : 'DESC' })), //[{ createdAt: 'DESC' }],
    },
    skip: !organizationIdVar,
  })

  const countOrders = dataCountOrders?.countOrders || 0

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions({ sortModel: [...sortModel] })
  }, [])

  const handleOnRowsScrollEnd = async () => {
    if (dataOrders?.listOrdersWorkflow?.length < countOrders) {
      await fetchMore({
        variables: { offset: dataOrders?.listOrdersWorkflow.length, limit: PAGE_SIZE },
      })
    }
  }

  const rows = useMemo(
    () =>
      (dataOrders?.listOrdersWorkflow || []).map((order: any) => {
        return {
          id: order.id,
          commonId: order.commonId,
          createdAt: dayjs(order.createdAt).format('MM/DD/YY hh:mm a'),
          storeName: order.storeName,
          storeId: order.storeId,
          status: _.sortBy(order.orderWorkflow, ['ordinal']),
        }
      }),
    [dataOrders?.listOrdersWorkflow]
  )

  if (!organizationIdVar) return <></>

  return (
    <Box sx={{ paddingX: { xs: '5px', md: '20px' } }}>
      <Box sx={{ paddingBottom: 2 }}>
        <Typography variant={'h5'}>Fulfillment Connect</Typography>
      </Box>
      {errorOrders || errorCountOrders ? (
        <Box p='1'>
          <Alert variant='filled' severity='error'>
            There was an error loading the table.
          </Alert>
        </Box>
      ) : null}
      <ConnectTable
        nRows={countOrders}
        isLoading={loadingOrders || loadingCountOrders}
        rows={rows}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        handleSortModelChange={handleSortModelChange}
        sortModel={queryOptions.sortModel}
      />
    </Box>
  )
}
