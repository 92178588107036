import { useMutation, useQuery } from '@apollo/client'
import { Box, Button, Dialog, Paper, Typography } from '@mui/material'
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Brand, CheckShopifyStoreConfiguration, ShopifyStoreConfiguration } from 'lib/graphql/types.ts'
import { GridRenderCellParams, GridRowSelectionModel, GridToolbarContainer } from '@mui/x-data-grid-pro'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import { LIST_SHOPIFY_STORE_CONFIGURATIONS } from 'lib/graphql/listShopifyStoreConfigurations.ts'
import { ShopifyStoreConfigurationCreateOrUpdateDialog } from 'pages/AdminSettings/ShopifyStoreConfigurationCreateOrUpdateDialog.tsx'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { CHECK_SHOPIFY_STORE_CONFIGURATION } from 'lib/graphql/checkShopifyStoreConfiguration.ts'
import { formatFormalDateTime } from 'pages/ReportsApp/utils.tsx'
import ReplayIcon from '@mui/icons-material/Replay'

type EditToolbarProps = {
  handleAdd: () => void
  handleEdit: () => void
  enableEditButton: boolean
}

const CreateToolbar = ({ handleAdd, handleEdit, enableEditButton }: EditToolbarProps) => {
  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<AddIcon />} onClick={handleAdd}>
        New
      </Button>
      <Button color='primary' startIcon={<EditIcon />} onClick={handleEdit} disabled={!enableEditButton}>
        Edit
      </Button>
      {/*<GridToolbarDensitySelector />*/}
    </GridToolbarContainer>
  )
}

const columns: GridColDef[] = [
  { field: 'name', headerName: 'PL Shopify Store', flex: 1, disableColumnMenu: true },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams) => {
      return params.row.status ? <CheckIcon sx={{ color: 'green' }} /> : <ClearIcon sx={{ color: 'red' }} />
    },
  },
]

export const ShopifyStoreManager = ({ organizationId }: { organizationId?: string }) => {
  const [showModal, setShowModal] = useState(false)
  const [mode, setMode] = useState<'create' | 'update'>('create')
  const [focusedItem, setFocusedItem] = useState<
    ShopifyStoreConfiguration & {
      status: boolean
      configurationErrorsString?: string | null
    }
  >()
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])

  const { refetch, data, loading, error } = useQuery(LIST_SHOPIFY_STORE_CONFIGURATIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        organizationId: { equals: organizationId },
      },
      take: 100,
      skip: 0,
    },
    skip: !organizationId,
    onCompleted: (data) => {},
  })

  const [checkShopifyStoreConfiguration, { loading: loadingConfiguration }] = useMutation(
    CHECK_SHOPIFY_STORE_CONFIGURATION,
    {
      onCompleted: async (response: { checkShopifyStoreConfiguration: CheckShopifyStoreConfiguration }) => {
        await refetch()
        setFocusedItem((lastItem) => ({
          ...lastItem!,
          status:
            _.isEmpty(JSON.parse(response.checkShopifyStoreConfiguration.configurationErrors || '[]')) &&
            !!response.checkShopifyStoreConfiguration.configurationLastCheckedAt,
          configurationErrorsString: !_.isEmpty(
            JSON.parse(response.checkShopifyStoreConfiguration.configurationErrors || '[]')
          )
            ? JSON.stringify(
                JSON.parse(response.checkShopifyStoreConfiguration.configurationErrors!),
                null,
                2
              )
            : null,
          configurationLastCheckedAt: response.checkShopifyStoreConfiguration.configurationLastCheckedAt!,
        }))
      },
    }
  )

  function closeDialog() {
    setShowModal(false)
  }

  const rows = (data?.listShopifyStoreConfigurations || []).map((data: any) => ({
    ...data,
    status: _.isEmpty(JSON.parse(data.configurationErrors || '[]')) && !!data.configurationLastCheckedAt,
    configurationErrorsString: !_.isEmpty(JSON.parse(data.configurationErrors || '[]'))
      ? JSON.stringify(JSON.parse(data.configurationErrors), null, 2)
      : null,
  }))

  useEffect(() => {
    if (_.isEmpty(rows)) return
    setRowSelectionModel([rows[0].id])
    setFocusedItem(rows[0])
  }, [rows, setFocusedItem])

  if (!organizationId) return <></>

  return (
    <>
      <Box sx={{ flexBasis: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Paper
            elevation={5}
            sx={{
              height: 'inherit',
              display: 'grid',
              gridTemplateRows: 'auto 1f auto',
              borderRadius: '16px',
              p: 1,
            }}
          >
            <DataGridPremium
              sx={{
                borderLeftWidth: 0,
                borderRightWidth: 0,
                borderTopWidth: 0,
              }}
              density={'compact'}
              rows={rows}
              columns={columns}
              autoHeight={true}
              loading={loading}
              disableMultipleRowSelection={true}
              rowSelectionModel={rowSelectionModel}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel)
                if (_.size(newRowSelectionModel) === 1) {
                  const data = rows.find((r: Brand) => r.id === newRowSelectionModel[0])
                  setFocusedItem(data)
                } else {
                  setFocusedItem(undefined)
                }
              }}
              slots={{
                toolbar: CreateToolbar,
              }}
              slotProps={{
                toolbar: {
                  enableEditButton: !!focusedItem,
                  enableDeleteButton: !!focusedItem,
                  handleAdd: () => {
                    setMode('create')
                    setShowModal(true)
                  },
                  handleEdit: () => {
                    setMode('update')
                    focusedItem && setShowModal(true)
                  },
                },
              }}
            />
          </Paper>
          {focusedItem && (
            <Paper
              elevation={5}
              sx={{
                height: 'inherit',
                display: 'grid',
                gridTemplateRows: 'auto 1f auto',
                borderRadius: '16px',
                p: 1,
              }}
            >
              <Typography variant={'h6'} sx={{ paddingX: '10px' }}>
                {focusedItem.name}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                <Box
                  sx={{
                    height: 'inherit',
                    display: 'grid',
                    gridTemplateRows: 'auto 1f auto',
                    borderRadius: '16px',
                    p: 1,
                  }}
                >
                  <DataGridPremium
                    columnHeaderHeight={0}
                    hideFooter={true}
                    sx={{ border: 0 }}
                    density={'compact'}
                    columns={[
                      { field: 'id', width: 130 },
                      {
                        field: 'value',
                        flex: 1,
                        minWidth: 300,
                        renderCell: (params) => {
                          if (params.id === 'Configuration')
                            return (
                              <>
                                {params.value ? (
                                  <>
                                    <CheckIcon sx={{ color: 'green', marginRight: '5px' }} />{' '}
                                    {params.row.lastChecked} {params.row.checkIcon}
                                  </>
                                ) : (
                                  <>
                                    <ClearIcon sx={{ color: 'red', marginRight: '5px' }} />{' '}
                                    {params.row.lastChecked} {params.row.checkIcon}
                                  </>
                                )}
                              </>
                            )
                          return params.value
                        },
                      },
                    ]}
                    rows={[
                      { id: 'Shopify Domain', value: focusedItem.domain },
                      { id: 'Shopify Shop ID', value: focusedItem.shopId },
                      { id: 'From Email', value: focusedItem.fromEmail },
                      { id: 'Branded Domain', value: focusedItem.brandedDomain },
                      { id: 'Admin API Token', value: focusedItem.adminApiToken ? '**********' : '' },
                      { id: 'Client ID', value: focusedItem.clientId ? '**********' : '' },
                      { id: 'Client Secret', value: focusedItem.clientSecret ? '**********' : '' },
                      {
                        id: 'Configuration',
                        value: focusedItem.status,
                        lastChecked: focusedItem.configurationLastCheckedAt
                          ? ` ${formatFormalDateTime(focusedItem.configurationLastCheckedAt)}`
                          : ' Never checked',
                        checkIcon: (
                          <Button
                            variant={'text'}
                            size={'small'}
                            disabled={loadingConfiguration}
                            onClick={() =>
                              checkShopifyStoreConfiguration({
                                variables: {
                                  where: {
                                    id: focusedItem.id,
                                  },
                                },
                              })
                            }
                          >
                            <ReplayIcon />
                          </Button>
                        ),
                      },
                    ]}
                  />
                </Box>
                <Box sx={{ paddingX: '15px' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '12px',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  ></Box>
                  {focusedItem.configurationErrorsString && (
                    <Box sx={{ overflowY: 'scroll', maxHeight: '400px' }}>
                      Errors: <pre>{focusedItem.configurationErrorsString}</pre>
                    </Box>
                  )}
                </Box>
              </Box>
            </Paper>
          )}
        </Box>
      </Box>
      <Dialog open={showModal} fullWidth={true} onClose={() => closeDialog()}>
        <ShopifyStoreConfigurationCreateOrUpdateDialog
          mode={mode}
          data={focusedItem}
          organizationId={organizationId}
          closeDialog={() => closeDialog()}
          onSuccess={async () => {
            await closeDialog()
            await refetch()
          }}
        />
      </Dialog>
    </>
  )
}
