import React, { useEffect, useState } from 'react'
import { ShopifyCustomer } from 'lib/types.ts'
import { Box, FormControlLabel, Switch, ToggleButton, ToggleButtonGroup, TextField } from '@mui/material'
import { PrivateLabelCard } from './common/PrivateLabelCard.tsx'
import { LabelDesigner } from 'components/LabelDesigner'
import { useLazyQuery } from '@apollo/client'
import { PROXY_SHOPIFY_QUERY } from 'lib/graphql/proxyShopifyQuery.ts'
import { Image, PrivateLabel, ProductVariant } from 'pages/VendorApp/Shop/types.ts'
import _ from 'lodash'
import fuzzysort from 'fuzzysort'

export default function MyPrivateLabels({
  customer,
  storeId,
  refreshCustomer,
}: {
  storeId: string
  customer: ShopifyCustomer
  refreshCustomer: () => Promise<void>
}) {
  const [designerState, setDesignerState] = useState<any>({})

  const [getProduct] = useLazyQuery(PROXY_SHOPIFY_QUERY)
  const [product, setProduct] = useState<any>()
  const [showArchived, setShowArchived] = useState<boolean>(false)
  const [sortOrder, setSortOrder] = useState<string>('alphabetical')
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    const _getProduct = async (productId: string) => {
      if (!productId || !storeId || productId === product?.id) return
      const result = await getProduct({
        fetchPolicy: 'no-cache',
        variables: {
          query: 'product',
          shopifyStoreId: storeId,
          variables: JSON.stringify({
            id: productId,
          }),
        },
        // skip: !sto,
        onCompleted: (data) => {
          const _product = JSON.parse(data.proxyShopifyQuery).data.product
          setProduct({
            ..._product,
            images: _product.media?.edges.map(({ node }: { node: Image }) => ({
              ...node,
              src: node.preview?.image?.url,
            })),
            variants: _product.variants.edges.map(({ node }: { node: ProductVariant }) => node),
          })
        },
      })
    }
    _getProduct(designerState.productId)
  }, [designerState])

  const privateLabels = JSON.parse(customer.privateLabels?.value || '[]')
  const label = privateLabels.find(
    (label: any) => designerState?.instanceId && label.instanceId === designerState?.instanceId
  )

  const handleSorting = (event: React.MouseEvent<HTMLElement>, newSortOrder: string | null) => {
    setSortOrder(newSortOrder || 'alphabetical')
  }

  // If there is some text in the textbox, it filters accordingly.
  const filteredPrivateLabels = searchText
    ? fuzzysort
        .go(searchText, privateLabels, { keys: ['sku', 'brandedName', 'productName'], threshold: 0.5 })
        .map((privateLabel) => privateLabel.obj)
    : privateLabels

  return (
    <Box sx={{ padding: '10px' }}>
      <Box sx={{ paddingTop: '0px' }}>
        {designerState?.showLabelDesigner && !_.isEmpty(product) && label ? (
          <LabelDesigner
            open={true}
            onClose={async () => {
              await refreshCustomer()
              setDesignerState((cur: any) => ({ ...cur, showLabelDesigner: false }))
              // router.replace(router.pathname)
            }}
            customer={customer}
            product={product}
            sku={designerState.sku}
            variantId={designerState.variantId}
            label={label}
            mode={designerState.mode}
            referrer={'mpl'}
          />
        ) : (
          <>
            <Box
              sx={{
                paddingBottom: '20px',
                justifyContent: 'space-between',
                display: 'flex',
                gap: '16px',
                alignItems: 'center',
              }}
            >
              <div>
                <TextField
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  variant='outlined'
                  label='Search Private Labels'
                />
              </div>
              <Box
                sx={{
                  justifyContent: 'right',
                  display: 'flex',
                  gap: '16px',
                  alignItems: 'center',
                }}
              >
                <div>
                  <FormControlLabel
                    control={<Switch value={showArchived} onClick={() => setShowArchived(!showArchived)} />}
                    label='Archived'
                  />
                </div>
                <div>
                  <ToggleButtonGroup value={sortOrder} exclusive onChange={handleSorting}>
                    <ToggleButton size={'small'} value='alphabetical'>
                      Alphabetical
                    </ToggleButton>
                    <ToggleButton size={'small'} value='lastUpdated'>
                      Last Updated
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'start',
                gap: '16px',
                flexDirection: { xs: 'column', md: 'row' },
              }}
            >
              {filteredPrivateLabels
                .sort((a: PrivateLabel, b: PrivateLabel) =>
                  sortOrder === 'alphabetical'
                    ? a.productName! <= b.productName!
                      ? 1
                      : -1
                    : (a.updatedAt && !b.updatedAt) || (a.updatedAt || '') >= (b.updatedAt || '')
                    ? 1
                    : -1
                )
                .reverse()
                .filter((label: { archivedAt: string }) =>
                  showArchived ? label.archivedAt : _.isEmpty(label.archivedAt)
                )
                .map((label: any, index: number) => {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        alignSelf: 'stretch',
                        maxWidth: { xs: '100%', md: '48%' },
                      }}
                    >
                      <PrivateLabelCard
                        label={label}
                        setDesignerState={setDesignerState}
                        refreshCustomer={refreshCustomer}
                        storeId={storeId}
                        customerId={customer.id}
                        showArchived={showArchived}
                      />
                    </Box>
                  )
                })}
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}
