import { useState } from 'react'
import { Dayjs } from 'dayjs'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { useMutation } from '@apollo/client'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { CREATE_LOT } from 'lib/graphql/createLot'

const DisplayingErrorMessagesSchema = Yup.object().shape({
  lotNumber: Yup.string().required('Required'),
  expirationDate: Yup.string().required('Required'),
})

const createDataInput = (values: any) => ({
  lotNumber: values.lotNumber,
  expirationDate: `${values.expDate?.format('YYYY-MM-DD')}`,
  sku: {
    connect: {
      id: values.skuId,
    },
  },
  supplementFactMajorUuid: values.majorVersionUuid,
})

const AddLotDialog = ({
  open,
  handleClose,
  skuId,
  majorVersionUuid,
  refetch,
}: {
  open: boolean
  handleClose: () => void
  skuId: string
  majorVersionUuid: string
  refetch: any
}) => {
  const [createLot] = useMutation(CREATE_LOT, {
    onCompleted: () => {
      refetch()
      handleClose()
    },
  })

  const [expDate, setExpDate] = useState<Dayjs | null>(null)

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create new lot</DialogTitle>
      <Formik
        initialValues={{
          lotNumber: '',
          expirationDate: '',
        }}
        validationSchema={DisplayingErrorMessagesSchema}
        onSubmit={async (values) => {
          await createLot({
            variables: {
              data: createDataInput({ ...values, majorVersionUuid, skuId, expDate }),
            },
          })
        }}
      >
        {({ values, handleChange, errors, touched, handleSubmit, handleBlur, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                margin='dense'
                name='lotNumber'
                label='Lot Number'
                type='text'
                fullWidth
                variant='standard'
                value={values.lotNumber}
                error={touched.lotNumber && Boolean(errors.lotNumber)}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.lotNumber && errors.lotNumber}
                required
              />
              <DesktopDatePicker
                value={expDate}
                slotProps={{
                  textField: {
                    name: 'expirationDate',
                    variant: 'standard',
                    fullWidth: true,
                    label: 'Expiration Date',
                    onBlur: handleBlur,
                    required: true,
                    margin: 'dense',
                    error: touched.expirationDate && !expDate?.isValid(),
                  },
                  openPickerButton: { color: 'primary' },
                }}
                onChange={(newValue) => {
                  setExpDate(newValue)
                  setFieldValue('expirationDate', newValue?.format('YYYY-MM-DD'))
                }}
                disablePast
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type='submit'>Create</Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}

export default AddLotDialog
