import { gql } from '@apollo/client'

export const UPSERT_PRIVATE_LABEL = gql`
  mutation upsertPrivateLabel(
    $shopifyStoreConfigurationId: String!
    $customerId: String!
    $privateLabel: AWSJSON!
  ) {
    upsertPrivateLabel(
      shopifyStoreConfigurationId: $shopifyStoreConfigurationId
      customerId: $customerId
      privateLabel: $privateLabel
    )
  }
`
