import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { ChevronLeft } from '@mui/icons-material'
import FIND_LABEL from 'lib/graphql/findLabel'

type LabelDetailParams = {
  customerName: string
  orderNumber: string
  sku: string
  lotNumber?: string
  expirationDate?: string
}

export const LabelDetail = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()

  const variables: LabelDetailParams = {
    customerName: params.get('customerName')!,
    orderNumber: params.get('orderNumber')!,
    sku: params.get('sku')!,
    lotNumber: params.get('lotNumber') ?? undefined,
    expirationDate: params.get('expirationDate') ?? undefined,
  }

  const { data } = useQuery(FIND_LABEL, {
    variables,
  })

  if (!data) {
    return (
      <Stack padding={10}>
        <Box>Private label application coming soon. </Box>
        <Box paddingY={3}>For the moment, use the hyperlink in the order summary to retrieve labels.</Box>
      </Stack>
    )
  }
  return (
    <Box>
      <Paper
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingY: 1 }}
        square
      >
        <Button variant='text' onClick={() => navigate('/labels')}>
          <ChevronLeft /> BACK
        </Button>
        <Typography color='#001D35' fontWeight={700} fontSize='18px' sx={{ flex: 1 }} textAlign='end'>
          Order {variables.orderNumber}
        </Typography>
        <Box display='hidden' sx={{ flexGrow: 1 }} />
      </Paper>
      <Box paddingX={3} sx={{ backgroundColor: '#F9F9FC' }} py={1}>
        <Typography color='#001D35' fontWeight={500} fontSize='18px'>
          Label Summary
        </Typography>
        <Stack direction={'column'}>
          <Box>{`Order Number: ${data.findLabel.order.orderNumber}`}</Box>
          <Box>{`Lot Number: ${data.findLabel.lot.lotNumber}`}</Box>
          <Box>{`Expiration Date: ${data.findLabel.lot.expirationDate}`}</Box>
          <Box>{`SKU: ${data.findLabel.sku.sku}`}</Box>
        </Stack>
        <Paper
          sx={{ maxWidth: 'lg', width: '100%', mt: 2, display: 'flex', justifyContent: 'center' }}
          square
          elevation={3}
        >
          <iframe height='500px' width='100%' src={data.findLabel.url}></iframe>
        </Paper>
      </Box>
    </Box>
  )
}
