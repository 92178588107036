import { useState } from 'react'
import { Typography, Box, Stack, IconButton } from '@mui/material'
import { Edit } from '@mui/icons-material'

import DialogLotActions from './LotActions'
import MoveLotDialog from './MoveLotDialog'
import DeleteLotDialog from './DeleteLotDialog'

const LotElement = ({
  lot,
  exp,
  added,
  majorVersions,
  majorVersionUuid,
  id,
}: {
  id: string
  lot: number
  exp: string
  added: string
  majorVersions: any
  majorVersionUuid: string
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [openMoveDialog, setOpenMoveDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
    <Stack direction='row' spacing={1} sx={{ alignSelf: 'left' }}>
      <MoveLotDialog
        open={openMoveDialog}
        exp={exp}
        id={id}
        lot={lot}
        handleClose={() => {
          setOpenMoveDialog(false)
        }}
        majorVersions={majorVersions}
        majorVersionUuid={majorVersionUuid}
      />
      <DeleteLotDialog
        open={openDeleteDialog}
        exp={exp}
        id={id}
        lot={lot}
        handleClose={() => {
          setOpenDeleteDialog(false)
        }}
      />
      <DialogLotActions
        open={openDialog}
        exp={exp}
        lot={lot}
        handleDelete={() => {
          setOpenDeleteDialog(true)
          handleClose()
        }}
        handleClose={handleClose}
        handleMove={() => {
          setOpenMoveDialog(true)
          handleClose()
        }}
      />
      <Stack py={'5px'} direction={'row'} spacing={1} display='grid' gridTemplateColumns='repeat(3, 1fr)'>
        <Box>
          <Typography color='#001D35' fontWeight={700} component='span' sx={{ marginRight: 0.5 }}>
            Lot:{' '}
          </Typography>
          <Typography color='#526070' fontWeight={500} component='span'>
            {lot}
          </Typography>
        </Box>
        <Box display='flex'>
          <Typography color='#001D35' fontWeight={700} component='span' sx={{ marginRight: 0.5 }}>
            Exp:{' '}
          </Typography>
          <Typography color='#526070' fontWeight={500} component='span'>
            {exp}
          </Typography>
        </Box>
        <Box display='flex' alignItems='center'>
          <Typography color='#001D35' fontWeight={700} component='span' sx={{ marginRight: 0.5 }}>
            Added:{' '}
          </Typography>
          <Typography color='#526070' fontWeight={500} component='span'>
            {added}
          </Typography>
          <IconButton
            size='small'
            sx={{ marginLeft: 0 }}
            onClick={() => {
              setOpenDialog(true)
            }}
          >
            <Edit fontSize='inherit' />
          </IconButton>
        </Box>
      </Stack>
    </Stack>
  )
}

export default LotElement
