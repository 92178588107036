import React, { useState } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { Box, Typography, Button, Dialog, Paper } from '@mui/material'
import {
  DataGridPro,
  GridColDef,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro'
import { Brand } from '@/lib/graphql/types'
import { organizationId } from 'config/cache.ts'
import { BRANDS_LIST } from 'lib/graphql/listBrands.ts'
import _ from 'lodash'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import styles from './styles.module.css'
import { BrandCreateOrUpdateDialog } from 'components/Dialogs/BrandCreateOrUpdateDialog'
import { DELETE_BRAND } from 'lib/graphql/deleteBrand.ts'
import { DataGridPremium } from '@mui/x-data-grid-premium'

type EditToolbarProps = {
  handleAdd: () => void
  handleEdit: () => void
  handleDelete: () => void
  enableEditButton: boolean
  enableDeleteButton: boolean
}

const CreateToolbar = ({
  handleAdd,
  handleDelete,
  handleEdit,
  enableEditButton,
  enableDeleteButton,
}: EditToolbarProps) => {
  return (
    <GridToolbarContainer>
      <Button color='primary' startIcon={<AddIcon />} onClick={handleAdd}>
        New
      </Button>
      <Button color='primary' startIcon={<EditIcon />} onClick={handleEdit} disabled={!enableEditButton}>
        Edit
      </Button>
      <Button
        color='primary'
        startIcon={<DeleteIcon />}
        onClick={handleDelete}
        disabled={!enableDeleteButton}
      >
        Delete
      </Button>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  )
}

const columns: GridColDef[] = [
  { field: 'organization', headerName: 'Organization', flex: 1 },
  { field: 'brand', headerName: 'Brand', flex: 1 },
]

export const Brands = () => {
  const [showModal, setShowModal] = useState(false)
  const [mode, setMode] = useState<'create' | 'update'>('create')
  const currentOrganizationId = useReactiveVar(organizationId)
  const [focusedBrand, setFocusedBrand] = useState<Brand>()
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])

  const {
    refetch: refetchBrands,
    data: dataBrands,
    loading: loadingBrands,
    error: errorBrands,
  } = useQuery(BRANDS_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        organizationId: {
          equals: currentOrganizationId,
        },
      },
    },
  })

  const [deleteBrand] = useMutation(DELETE_BRAND, {
    onCompleted() {
      console.log('DELETED')
    },
  })

  function closeDialog() {
    setShowModal(false)
  }

  const rows = (dataBrands?.listBrands || [])
    .filter((brand: Brand) => !brand.archivedAt)
    .map((brand: Brand) => {
      return {
        id: brand.id,
        brand: brand.name,
        organization: brand.organization.name,
      }
    })

  if (!currentOrganizationId) return <></>

  return (
    <>
      <Box sx={{ paddingBottom: 2 }}>
        <Typography variant={'h5'}>Brands</Typography>
      </Box>
      <Box sx={{ height: 'calc(100vh - 240px)' }}>
        <Paper
          elevation={5}
          sx={{
            height: 'inherit',
            display: 'grid',
            gridTemplateRows: 'auto 1f auto',
            borderRadius: '16px',
            p: 1,
          }}
        >
          <DataGridPremium
            sx={{
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderTopWidth: 0,
            }}
            density={'compact'}
            checkboxSelection
            rows={rows}
            columns={columns}
            autoHeight={true}
            loading={loadingBrands}
            disableMultipleRowSelection={true}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel)
              if (_.size(newRowSelectionModel) === 1) {
                const data = rows.find((r: Brand) => r.id === newRowSelectionModel[0])
                setFocusedBrand(data)
              } else {
                setFocusedBrand(undefined)
              }
            }}
            slots={{
              toolbar: CreateToolbar,
            }}
            slotProps={{
              toolbar: {
                enableEditButton: !!focusedBrand,
                enableDeleteButton: !!focusedBrand,
                handleAdd: () => {
                  setMode('create')
                  setShowModal(true)
                },
                handleEdit: () => {
                  setMode('update')
                  focusedBrand && setShowModal(true)
                },
                handleDelete: async () => {
                  await deleteBrand({
                    variables: {
                      where: {
                        id: focusedBrand?.id,
                      },
                    },
                  })
                  await refetchBrands()
                },
              },
            }}
          />
        </Paper>
      </Box>
      <Dialog className={styles.modelAddNewBrand} open={showModal} onClose={() => closeDialog()}>
        <BrandCreateOrUpdateDialog
          mode={mode}
          data={focusedBrand}
          closeDialog={() => closeDialog()}
          organizationId={currentOrganizationId}
          onSuccess={async () => {
            await refetchBrands()
          }}
        />
      </Dialog>
    </>
  )
}
