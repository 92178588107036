import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import React from 'react'
import { Tooltip, Typography } from '@mui/material'
import { OrderLabel } from 'lib/types.ts'

export function renderLabelIconWithTooltip({ label, index }: { label: OrderLabel; index: number }) {
  return (
    <Tooltip
      placement={'left'}
      key={index}
      title={
        <Typography variant={'body2'}>
          SKU: {label.sku.sku}
          <br />
          {label.printed
            ? 'Label Printed'
            : label.bucketKey || label.url
            ? 'Ready to print'
            : label.lot
            ? 'Error generating label'
            : 'Back-ordered'}
        </Typography>
      }
    >
      {renderLabelIcon({
        bucketKey: label.bucketKey,
        printed: label.printed,
        lot: label.lot,
      })}
    </Tooltip>
  )
}

export function renderLabelIcon({
  bucketKey,
  printed,
  lot,
}: {
  bucketKey: string
  printed: boolean
  lot: any
}) {
  return bucketKey ? (
    <RadioButtonCheckedIcon sx={{ color: printed ? 'gray' : 'green' }} />
  ) : lot ? (
    <RadioButtonCheckedIcon sx={{ color: 'red' }} />
  ) : (
    <RadioButtonUncheckedIcon sx={{ color: 'red' }} />
  )
}
