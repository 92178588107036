import { gql } from '@apollo/client'

export const LIST_SHOPIFY_STORE_CONFIGURATIONS = gql`
  query listShopifyStoreConfigurations($where: ShopifyStoreConfigurationWhereInput, $take: Int, $skip: Int) {
    listShopifyStoreConfigurations(where: $where, take: $take, skip: $skip, orderBy: [{ name: ASC }]) {
      id
      name
      domain
      fromEmail
      shopId
      assetsDomain
      organizationId
      configurationLastCheckedAt
      configurationErrors
      adminApiToken
      clientId
      clientSecret
      brandedDomain
      createdAt
      updatedAt
    }
  }
`
