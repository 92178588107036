import { gql } from '@apollo/client'

export const LIST_BRAND_ACCOUNTS = gql`
  query listBrandAccounts($where: BrandAccountWhereInput, $take: Int, $skip: Int) {
    listBrandAccounts(where: $where, take: $take, skip: $skip, orderBy: [{ name: ASC }]) {
      id
      name
      accountManagerName
      sellsInCalifornia
      prop65Waiver
      city
      state
      customers {
        id
        customerId
      }
      createdAt
    }
  }
`
