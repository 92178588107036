import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import Timeline from '@mui/lab/Timeline'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import { useRecoilState } from 'recoil'

import TimelineMessage from '../TimelineMessage/TimelineMessage'
import { messageState } from 'store/store'

const BASE_URI = '/vendor/connect'

const CustomTimeline = ({
  receivedMessages,
  sentMessagesById,
  commonId,
  orderId,
}: {
  receivedMessages: any
  sentMessagesById: any
  commonId: string
  orderId: string
}) => {
  const [messageValue, setMessageValue] = useRecoilState(messageState)
  const navigate = useNavigate()

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
        mt: 0,
      }}
    >
      {Object.keys(receivedMessages).map((key: any) => {
        const messages = receivedMessages[key]
        return messages.map((message: any, index: number) => {
          const handleClick = (type: 'incoming' | 'outgoing') => {
            setMessageValue({
              id: message.id,
              incoming: message,
              outgoing: sentMessagesById[message.id],
              commonId,
              orderId,
            })

            navigate(`${BASE_URI}/messages/${type}/${message.id}`)
          }

          return (
            <Fragment key={message.id}>
              {index === 0 && (
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot color='primary' />
                    <TimelineConnector sx={{ backgroundColor: 'primary.main' }} />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography color='#0F1D2A' fontWeight={500} fontSize='16px'>
                      {dayjs(key).format('dddd, MMMM D')}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              )}
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color={message.status === 'Failed' ? 'error' : 'primary'} />
                  <TimelineConnector sx={{ backgroundColor: 'primary.main' }} />
                </TimelineSeparator>
                <TimelineContent>
                  <TimelineMessage
                    id={message.id}
                    skipped={message.status === 'Skipped'}
                    error={message.status === 'Failed'}
                    name={message.source.name}
                    requestType={message.requestType}
                    createdAt={message.createdAt}
                    responses={sentMessagesById[message.id]}
                    onClick={handleClick}
                  />
                </TimelineContent>
              </TimelineItem>
            </Fragment>
          )
        })
      })}
    </Timeline>
  )
}

export default CustomTimeline
