import { gql } from '@apollo/client'

export const CREATE_SKU = gql`
  mutation CreateSku($data: SkuCreateInput!) {
    createSku(data: $data) {
      id
      sku
      skuFact {
        productName
        privateLabelName
      }
    }
  }
`
