import Dialog from '@mui/material/Dialog'
import { Alert } from '@mui/material'

export type AlertDialog = {
  open: boolean
  handleClose: () => void
  description?: string
  severity?: 'error' | 'warning' | 'info' | 'success'
}

const AlertDialog = ({ open, severity, handleClose, description }: AlertDialog) => {
  return (
    <Dialog open={open}>
      <Alert sx={{ whiteSpace: 'pre-line' }} onClose={() => handleClose()} severity={severity}>
        {description}
      </Alert>
    </Dialog>
  )
}

export default AlertDialog
