import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { cognitoOps } from 'lib/Cognito.tsx'
import { accessToken } from 'store/user'
import { useSetRecoilState } from 'recoil'

export default function LogoutPage(): React.ReactElement {
  const navigate = useNavigate()
  const setAccessToken = useSetRecoilState(accessToken)

  useEffect(() => {
    cognitoOps.signOut().then(() => {
      setAccessToken(undefined)
      navigate('/')
    })
  }, [setAccessToken])

  return <></>
}
