import { FC } from 'react'
import ProductCard, { ProductCardProps } from '../ProductCard/ProductCard.js'
import { Box, Grid } from '@mui/material'
import { ShopifyProduct } from '../../types.ts'

interface HighlightedCardProps extends Omit<ProductCardProps, 'product'> {
  index: number
}

export interface ProductGridProps {
  products: ShopifyProduct[]
  productsList?: Array<{ product: string }>
  offset?: number
  limit?: number
  cardProps: Omit<ProductCardProps, 'product'>
  highlightCard?: HighlightedCardProps
}

export const ProductGrid: FC<ProductGridProps> = ({
  products,
  offset = 0,
  limit = 10000,
  cardProps,
  highlightCard,
}) => {
  return (
    <Box
      sx={{
        width: 'inherit',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        gap: { xs: '16px', lg: '32px' },
      }}
    >
      {products
        .sort((a, b) => (a.title?.toLowerCase() < b.title?.toLowerCase() ? -1 : 1))
        .slice(offset, limit)
        .map((product, i) => (
          <Box
            key={i}
            sx={{
              display: 'flex',
              width: {
                xs: 'calc(50% - 16px)',
                sm: 'calc(33% - 16px)',
                md: 'calc(25% - 16px)',
                lg: 'calc(20% - 32px)',
              },
            }}
          >
            <ProductCard {...(highlightCard?.index === i ? highlightCard : cardProps)} product={product} />
          </Box>
        ))}
    </Box>
  )
}
