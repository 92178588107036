import React, { useEffect, useState } from 'react'
import { getPrice } from '../../utils/product'
import ImageCarousel from '../../common/ImageCarousel.js'
import { Image, ProductVariant, ShopifyCustomer, ShopifyProduct } from '../../types.js'
import { LabelDesigner } from '@/components/LabelDesigner'
import { Box, Button, Stack, useMediaQuery } from '@mui/material'
import style from '../../page.module.css'
import ProductVariantButtonGroup from '../../common/ProductVariantButtonGroup'
import OrderQuantity from '../../common/OrderQuantity'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'
import ProductImageComingSoon from '@/assets/product-image-coming-soon.jpeg'
import { shoppingProduct } from 'config/cache.ts'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'

interface Props {
  className?: string
  children?: any
  product: ShopifyProduct
  customer: ShopifyCustomer
  refreshCustomer?: () => void
}

const sections = [
  {
    title: 'Product Description',
  },
]

const ProductBox: React.FC<Props> = ({ customer, product, refreshCustomer }) => {
  const [labelDesignerVisible, setLabelDesignerVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  // const addItem = useAddItemToCart()
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant>()

  const [selectedTab, setSelectedTab] = useState<string>(sections[0].title)

  const [showAddToCart, setShowAddToCart] = useState(false)
  const showDesignPrivateLabel: boolean = true
  // const { openSidebar } = useUI()
  const [quantity, setQuantity] = useState<number | null>(1)

  const allImages = (product.images as Image[])?.map((image: Image) => ({ src: image.src }))
  const variants: ProductVariant[] = product.variants as ProductVariant[]

  const theme = useTheme()
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'))
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'))

  const addToCart = async (variant: ProductVariant) => {
    setLoading(true)
    try {
      if (quantity) {
        // await addItem(variant.id, quantity, [
        //   {
        //     key: '__moq',
        //     value: variant.moq?.value || '1',
        //   },
        //   {
        //     key: '__iq',
        //     value: variant.iq?.value || '1',
        //   },
        // ])
        // openSidebar()
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    const variant = variants[0]
    setSelectedVariant(variant)
    setQuantity(parseInt(variant.moq?.value || '1'))
  }, [variants])

  const onVariantSelect = (variantTitle: string) => {
    if (selectedVariant?.title !== variantTitle) {
      const variant = variants.find((x: ProductVariant) => x.title === variantTitle)
      if (variant) {
        setSelectedVariant(variant)
        setQuantity(parseInt(variant.moq?.value || '1'))
      }
    }
  }

  if (!selectedVariant) return <></>
  return (
    <>
      {labelDesignerVisible ? (
        <LabelDesigner
          open={labelDesignerVisible}
          onClose={() => {
            refreshCustomer && refreshCustomer()
            setLabelDesignerVisible(false)
          }}
          product={product}
          customer={customer}
          variantId={selectedVariant.id}
          sku={selectedVariant.sku}
          referrer={'catalog'}
        />
      ) : (
        <>
          <Box
            sx={{
              paddingLeft: '50px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button onClick={() => shoppingProduct(undefined)}>
              <KeyboardDoubleArrowLeftIcon /> &nbsp;
              <Typography variant={'body1'}>View all products</Typography>
            </Button>
          </Box>
          <Box className={style.productView} sx={{ px: { xs: 4 }, mx: { md: 8 } }}>
            <Stack direction={{ sx: 'column', sm: 'row' }} spacing={3} sx={{ justifyContent: 'center' }}>
              <Box className={style.productImageColumn} sx={{ alignSelf: 'center' }}>
                <Box className={style.productImage}>
                  <ImageCarousel
                    showZoom={false}
                    alt={product.title}
                    layout={'responsive'}
                    priority
                    images={
                      allImages?.length > 0
                        ? allImages
                        : [
                            {
                              src: ProductImageComingSoon,
                            },
                          ]
                    }
                    height={0}
                    width={0}
                  />
                </Box>
              </Box>
              <Box className={style.productMetadataColumn} sx={{ alignSelf: 'center' }}>
                <Box sx={{ marginTop: 2 }}>
                  <Typography variant='h4' sx={{ fontSize: '1.875rem', fontWeight: 700 }}>
                    {product.title}
                  </Typography>
                </Box>
                <Box className={style.productShortDescription}>
                  <Typography variant='h5'>{product.shortDescription}</Typography>
                </Box>
                {selectedVariant && (
                  <Box className={style.productDetails}>
                    <Stack direction={'column'} spacing={2}>
                      <ProductVariantButtonGroup
                        variants={variants}
                        selectedVariant={selectedVariant.title}
                        onVariantSelect={onVariantSelect}
                      />
                      {selectedVariant && (
                        <>
                          <Typography color='#676767' fontSize='1.125rem'>
                            Sku: {selectedVariant.sku}
                          </Typography>
                          <Stack
                            direction='row'
                            alignItems='flex-end'
                            justifyContent='space-between'
                            flexWrap='wrap'
                          >
                            <Stack
                              spacing={1}
                              sx={{ paddingTop: '20px' }}
                              alignItems={{ xs: 'flex-start', lg: 'center' }}
                              direction={{ lg: 'row', xs: 'column' }}
                            >
                              <OrderQuantity
                                moq={parseInt(selectedVariant.moq?.value || '1')}
                                iq={parseInt(selectedVariant.iq?.value || '1')}
                                onChange={(v: number | null) => setQuantity(v)}
                                defaultQuantity={quantity}
                              />
                              <Stack
                                spacing={0}
                                direction={{ xs: 'column', md: 'row' }}
                                sx={{
                                  textAlignLast: 'right',
                                  fontSize: '14px',
                                  paddingTop: { lg: '15px' },
                                  marginLeft: 'auto',
                                }}
                              >
                                {selectedVariant.moq && (
                                  <Typography color='#676767' fontSize='0.75rem'>
                                    Minimum: {selectedVariant.moq.value} {matchesMd && '|'}
                                  </Typography>
                                )}
                                {selectedVariant.iq && (
                                  <Typography color='#676767' fontSize='0.75rem' sx={{ ml: 0.5 }}>
                                    {' '}
                                    Case size: {selectedVariant.iq.value}
                                  </Typography>
                                )}
                              </Stack>
                            </Stack>
                            <Box>
                              <Typography color='#676767' fontSize='0.75rem' component='span'>
                                TOTAL
                              </Typography>
                              <Typography
                                fontSize='1.25rem'
                                fontWeight={600}
                                component='span'
                                sx={{ ml: 0.5 }}
                              >
                                {!quantity
                                  ? '---'
                                  : getPrice(
                                      `${quantity * parseFloat(selectedVariant.price.amount)}`,
                                      selectedVariant.price.currencyCode
                                    )}
                              </Typography>
                              <Typography color='#676767' fontSize='0.75rem' sx={{ marginTop: 1 }}>
                                Unit price:{' '}
                                {getPrice(selectedVariant.price.amount, selectedVariant.price.currencyCode)}
                              </Typography>
                            </Box>
                          </Stack>
                        </>
                      )}

                      {selectedVariant.title === 'White label' ? (
                        <Box sx={{ paddingTop: '10px' }}>
                          <Button
                            className={style.designYourPrivateLabelButton}
                            variant={'contained'}
                            sx={{ backgroundColor: '#3D3D3D' }}
                            name='design-your-private-label'
                            disabled={loading}
                            onClick={() => setLabelDesignerVisible(true)}
                          >
                            Design your Private Label
                          </Button>
                        </Box>
                      ) : (
                        <Box sx={{ paddingTop: '10px' }}>
                          <Button
                            className={style.addToCartButton}
                            sx={{ backgroundColor: '#3D3D3D' }}
                            variant={'contained'}
                            name='add-to-cart'
                            disabled={true || loading || !quantity}
                            onClick={() => addToCart(selectedVariant)}
                          >
                            Add to Cart {loading && '...'}
                          </Button>
                        </Box>
                      )}
                    </Stack>
                  </Box>
                )}
              </Box>
            </Stack>
            <Box sx={{ py: 4, px: { lg: 6 } }}>
              <ProductVariantButtonGroup
                variants={sections}
                selectedVariant={selectedTab}
                onVariantSelect={setSelectedTab}
              />
              <Typography sx={{ mt: 2 }}>{product?.description}</Typography>
            </Box>
          </Box>
        </>
      )}
    </>
  )
}

const ProductView: React.FC<{
  customer: ShopifyCustomer
  product: ShopifyProduct
  renderSeo?: boolean
  description?: string
  title?: string
  refreshCustomer?: () => void
}> = ({ product, customer, ...props }) => {
  return <ProductBox {...props} customer={customer} product={product} />
}
export default ProductView
