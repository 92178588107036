import { gql } from '@apollo/client'

export const LIST_SUPPLEMENT_FACTS = gql`
  query listSupplementFacts($where: SupplementFactWhereInput, $skip: Int, $take: Int) {
    listSupplementFacts(
      where: $where
      orderBy: [{ majorVersion: DESC, minorVersion: DESC }]
      skip: $skip
      take: $take
    ) {
      id
      sku {
        sku
        skuWhiteLabel
        id
        brandId
      }
      createdAt
      majorVersion
      minorVersion
      revisionNumber
      status
      signedBy {
        email
      }
      signedReason
      majorVersionUuid
      otherIngredients
      revisionNumber
      suggestedUse
      allergenStatement
      caution
      supplementLabelImage
      prop65WarningImage
    }
  }
`
