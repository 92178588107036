import React, { useEffect, useState } from 'react'
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import { useMutation } from '@apollo/client'
import * as Yup from 'yup'
import { CREATE_CUSTOMER_GROUP } from 'lib/graphql/createCustomerGroup.ts'
import { UPDATE_CUSTOMER_GROUP } from 'lib/graphql/updateCustomerGroup.ts'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { ShopifyCustomer } from 'lib/types.ts'
import _ from 'lodash'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormTextField } from 'components/Forms/FormTextField.tsx'
import { FormSwitch } from 'components/Forms/FormSwitch.tsx'

type CustomerGroupFieldProps = {
  id?: string
  name: string
  autoAssignNewUsers: boolean
  customerExternalIds: string[]
}

const validationSchema = Yup.object().shape({
  id: Yup.string().optional(),
  name: Yup.string().required('Required'),
  autoAssignNewUsers: Yup.boolean().required(),
  customerExternalIds: Yup.array().of(Yup.string().required()).required(),
})

interface CreateOrUpdateCustomerGroupProps {
  mode: 'create' | 'update'
  storeId: string
  data: any
  customers: ShopifyCustomer[]
  onSuccess: () => void
  closeDialog: () => void
}

export const CustomerGroupCreateOrUpdateDialog: React.FC<CreateOrUpdateCustomerGroupProps> = ({
  mode,
  data,
  customers,
  storeId,
  onSuccess,
  closeDialog,
}) => {
  const [submitError, setSubmitError] = useState<string>()
  const [internalData, setInternalData] = useState(data)
  const [availableCustomers, setAvailableCustomers] = useState<{ id: string; name: string }[]>([])
  const [members, setMembers] = useState<{ id: string; name: string }[]>([])
  const customerColumns: GridColDef[] = [{ field: 'name', headerName: 'Customers', flex: 1 }]
  const memberColumns: GridColDef[] = [{ field: 'name', headerName: 'Group Members', flex: 1 }]

  if (mode === 'update' && !data) {
    return <></>
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    setValue,
    reset,
    getValues,
    watch,
  } = useForm<CustomerGroupFieldProps>({
    reValidateMode: 'onChange',
    resolver: yupResolver<CustomerGroupFieldProps>(validationSchema),
    defaultValues: {
      name: '',
      autoAssignNewUsers: false,
      customerExternalIds: [],
    },
  })

  watch('customerExternalIds')

  const [createCustomerGroup] = useMutation(CREATE_CUSTOMER_GROUP, {
    onCompleted() {
      onSuccess()
    },
    onError(error) {
      setSubmitError(error.message)
      // setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
    },
  })

  const [updateCustomerGroup] = useMutation(UPDATE_CUSTOMER_GROUP, {
    onCompleted() {
      onSuccess()
    },
    onError(error) {
      setSubmitError(error.message)
      // setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
    },
  })

  const transformDataToFormValues = (data: any) => {
    return {
      id: data.id,
      name: data.name,
      autoAssignNewUsers: data.autoAssignNewUsers,
      customerExternalIds: (data.customerExternalIds || []).filter((id: string) =>
        customers.find((customer) => customer.id === id)
      ),
      customers: customers,
    }
  }

  const graphqlDataInput = (values: any) => ({
    name: values.name,
    customerExternalIds: values.customerExternalIds,
    autoAssignNewUsers: values.autoAssignNewUsers,
    shopifyStoreConfiguration: storeId ? { connect: { id: storeId } } : undefined,
  })

  const create = async (values: CustomerGroupFieldProps) => {
    await createCustomerGroup({
      variables: {
        data: graphqlDataInput(values),
      },
    })
  }

  const update = async (values: CustomerGroupFieldProps) => {
    await updateCustomerGroup({
      variables: {
        where: {
          id: values.id,
        },
        data: graphqlDataInput(values),
      },
    })
  }

  useEffect(() => {
    mode === 'update' && reset(transformDataToFormValues(internalData))
  }, [mode, internalData])

  const onSubmit: SubmitHandler<CustomerGroupFieldProps> = async (data) => {
    mode === 'create' ? await create(data) : await update(data)
  }

  useEffect(() => {
    const _availableCustomers = _.sortBy(
      customers.filter((customer) => !_.includes(getValues('customerExternalIds'), customer.id)),
      'lastName'
    ).map((customer) => {
      return {
        id: customer.id,
        name: `${customer.lastName}, ${customer.firstName}`,
      }
    })

    const _members = (getValues('customerExternalIds') || [])
      .map((id) => customers.find((c) => c.id === id)!)
      .map((m) => {
        return {
          id: m?.id,
          name: `${m?.lastName}, ${m?.firstName}`,
        }
      })
    setAvailableCustomers(_availableCustomers)
    setMembers(_members)
  }, [customers, getValues('customerExternalIds')])

  return (
    <>
      <DialogTitle>{mode === 'update' ? 'Update' : 'Create New'} Customer Group</DialogTitle>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type='hidden' name='id' />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormTextField
                name='name'
                label='Customer Group Name'
                type='text'
                fullWidth
                variant='standard'
                control={control}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormSwitch
                name='autoAssignNewUsers'
                control={control}
                label={'Auto-assign new users to this group'}
              />
            </Grid>

            <Grid item xs={6}>
              <Box sx={{ height: '250px', border: '0.5px solid gray' }}>
                <DataGridPro
                  density={'compact'}
                  hideFooter={true}
                  columns={customerColumns}
                  rows={availableCustomers}
                  onCellDoubleClick={async (params, event) => {
                    event.defaultMuiPrevented = true
                    if (!_.size(getValues('customerExternalIds').find((x: string) => x === params.id))) {
                      const newList = getValues('customerExternalIds').concat(params.id as string)
                      setValue('customerExternalIds', newList, { shouldDirty: true })
                    }
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ height: '250px', border: '0.5px solid gray' }}>
                <DataGridPro
                  density={'compact'}
                  hideFooter={true}
                  columns={memberColumns}
                  rows={members}
                  onCellDoubleClick={async (params, event) => {
                    event.defaultMuiPrevented = true
                    const newList = getValues('customerExternalIds').filter((x) => x !== params.id)
                    setValue('customerExternalIds', newList, { shouldDirty: true })
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            onClick={() => {
              closeDialog()
            }}
          >
            Cancel
          </Button>
          <Button type='submit' disabled={isSubmitting || !(isDirty && isValid)}>
            {mode === 'update' ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </>
  )
}
