import { gql } from '@apollo/client'

export const BARCODES_LIST = gql`
  query listBarcodes($where: BarcodeWhereInput, $orderBy: [BarcodeOrderByInput], $skip: Int, $take: Int) {
    listBarcodes(
      where: $where

      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      upc
      status
      customerProductName
      customerCompanyName
      orders {
        id
        commonId
      }
      createdAt
    }
  }
`
