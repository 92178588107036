import React, { useState, useCallback, useEffect } from 'react'
import { AuthChallengeError } from 'lib/Cognito.tsx'
import { Alert, Stack } from '@mui/material'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { AuthFormProps } from './LoginPage.tsx'
import Box from '@mui/material/Box'

export default function SetNewPasswordForm({ challenge, callback }: AuthFormProps): React.ReactElement {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showWarning, setShowWarning] = useState(false)

  useEffect(() => {
    if (password === confirmPassword) setShowWarning(false)
  }, [password, confirmPassword, setShowWarning])

  const doPasswordsMatch = useCallback(() => {
    if (confirmPassword && password !== confirmPassword) setShowWarning(true)
  }, [password, confirmPassword, setShowWarning])

  const setNewPassword = useCallback(
    async (password: string) => {
      try {
        callback(undefined, await challenge?.respondToChallenge(password))
      } catch (e) {
        if (e instanceof AuthChallengeError) {
          callback(e)
        }
      }
    },
    [callback, challenge]
  )

  return (
    <Box sx={{ width: '50%' }}>
      <Stack spacing={4}>
        <TextField
          label="Password"
          name="password"
          type="password"
          onChange={(ev: React.ChangeEvent | any) => setPassword(ev.target.value)}
          onBlur={doPasswordsMatch}
          required
          variant="standard"
          color="secondary"
          fullWidth
          value={password}
        />
        <TextField
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          onChange={(ev: React.ChangeEvent | any) => setConfirmPassword(ev.target.value)}
          onBlur={doPasswordsMatch}
          required
          variant="standard"
          color="secondary"
          fullWidth
          value={confirmPassword}
        />
        {showWarning && <Alert severity="error">Passwords do not match.</Alert>}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setNewPassword(password)}
          disabled={!password || password !== confirmPassword}
        >
          Change Password
        </Button>
      </Stack>
    </Box>
  )
}
