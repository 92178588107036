import React, { useEffect, useState } from 'react'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
} from '@mui/material'
import { withMask } from 'use-mask-input'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import * as Yup from 'yup'
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser'
import { Input } from 'components/Forms/Input.tsx'
import { FormTextField } from 'components/Forms/FormTextField.tsx'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormSwitch } from 'components/Forms/FormSwitch.tsx'
import {
  ShopifyAddress,
  ShopifyCustomer,
  ACCOUNT_STATUS,
  SHOPIFY_NAMESPACE,
  SHOPIFY_CUSTOMER_METAFIELD,
} from 'lib/types.ts'
// import AddressComponent from 'components/AddressComponent.tsx'
import { PROXY_SHOPIFY_MUTATION } from 'lib/graphql/proxyShopifyMutation.js'

export type CustomerFieldProps = Omit<
  ShopifyCustomer,
  'brandAcl' | 'defaultAddress' | 'website' | 'displayName' | 'privateLabels' | 'id'
> & {
  approved: boolean
  websiteUrl?: string
  id?: string
}

const initialValues: CustomerFieldProps = {
  id: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  approved: false,
  websiteUrl: '',
  addresses: [
    {
      address1: '',
      city: '',
      country: 'US',
      countryCodeV2: 'US',
      province: '',
      provinceCode: '',
      zip: '',
      company: '',
    },
  ],
}

const validationSchema = Yup.object().shape({
  id: Yup.string().optional(),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required'),
  approved: Yup.boolean().required(),
  websiteUrl: Yup.string().url().optional(),
  addresses: Yup.array().of(
    Yup.object({
      address1: Yup.string().required('Address is required'),
      address2: Yup.string(),
      city: Yup.string().required('City is required'),
      zip: Yup.string().required('Postal Code is required'),
      company: Yup.string().optional(),
      provinceCode: Yup.string().required('State is required'),
    })
  ),
  phone: Yup.string(),
})

interface CustomerCreateOrUpdateProps {
  mode: 'create' | 'update'
  storeId: string
  data: any
  onSuccess: () => void
  closeDialog: () => void
}

export const CustomerCreateOrUpdateDialog: React.FC<CustomerCreateOrUpdateProps> = ({
  mode,
  data,
  storeId,
  onSuccess,
  closeDialog,
}) => {
  const [internalData, setInternalData] = useState(data)
  const [submitError, setSubmitError] = useState<string>()

  const [createCustomer] = useMutation(PROXY_SHOPIFY_MUTATION, {
    onCompleted(data) {
      const details = JSON.parse(data.proxyShopifyMutation)
      if (details?.data?.customerCreate?.userErrors.length > 0) {
        setSubmitError(details?.data?.customerCreate?.userErrors.map((e: any) => e.message))
      } else {
        closeDialog()
        onSuccess()
      }
    },
    onError(error) {
      setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
    },
  })

  const [updateCustomer] = useMutation(PROXY_SHOPIFY_MUTATION, {
    onCompleted(data) {
      closeDialog()
      onSuccess()
    },
    onError(error) {
      setSubmitError(error.message)
      // setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
    },
  })

  const transformDataToFormValues = (data: any) => {
    return {
      ...data,
      websiteUrl: data?.website?.value,
    }
  }

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
    reset,
    getValues,
  } = useForm<CustomerFieldProps>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver<CustomerFieldProps>(validationSchema),
    defaultValues: mode === 'update' ? transformDataToFormValues(data) : initialValues,
  })

  const graphqlDataInput = (values: any) => ({
    firstName: values.firstName,
    lastName: values.lastName,
    phone: values.phone ? `+1${values.phone.replace(/[^\d]/g, '').replace(/^1/g, '')}` : undefined,
    email: values.email,
    addresses: values?.addresses?.map((address: ShopifyAddress) => ({
      ...address,
      countryCode: address.countryCodeV2,
      countryCodeV2: 'US',
      province: address.provinceCode,
      phone: address.phone ? `+1${address.phone.replace(/[^\d]/g, '').replace(/^1/g, '')}` : undefined,
    })),
    metafields: [
      values?.original?.accountStatus?.id
        ? {
            id: values.original.accountStatus.id,
            value: values.approved ? ACCOUNT_STATUS.APPROVED : ACCOUNT_STATUS.REJECTED,
          }
        : {
            namespace: SHOPIFY_NAMESPACE.CUSTOM,
            key: SHOPIFY_CUSTOMER_METAFIELD.ACCOUNT_STATUS,
            value: values.approved ? ACCOUNT_STATUS.APPROVED : ACCOUNT_STATUS.CREATED,
          },
      values?.original?.website?.id
        ? {
            id: values.original.website.id,
            value: values.websiteUrl || '',
          }
        : {
            namespace: 'custom',
            key: 'website',
            value: values.websiteUrl || '',
          },
    ],
  })

  const create = async (values: CustomerFieldProps) => {
    await createCustomer({
      variables: {
        mutation: 'createCustomer',
        shopifyStoreId: storeId,
        variables: JSON.stringify({
          ...graphqlDataInput(values),
        }),
      },
    })
  }

  const update = async (values: CustomerFieldProps) => {
    const response = await updateCustomer({
      variables: {
        mutation: 'updateCustomer',
        shopifyStoreId: storeId,
        variables: JSON.stringify({
          ...graphqlDataInput(values),
          id: data.id,
        }),
      },
    })
    const details = JSON.parse(response.data.proxyShopifyMutation)
  }

  useEffect(() => {
    mode === 'update' && reset(transformDataToFormValues(internalData))
  }, [mode])

  const onSubmit: SubmitHandler<CustomerFieldProps> = async (data) => {
    setSubmitError('')
    mode === 'create' ? await create(data) : await update(data)
  }

  if (!data && mode === 'update') return <></>

  return (
    <>
      <DialogTitle>{mode === 'update' ? 'Update' : 'Create New'} Customer</DialogTitle>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input type='hidden' control={control} name='id' />
        <DialogContent sx={{ minWidth: '600px' }}>
          <Box>
            <Box>{submitError && <Alert severity={'error'}>{submitError}</Alert>}</Box>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormSwitch control={control} name='approved' label='Approved' />
              </Grid>
              <Grid item xs={9}></Grid>
              <Grid item xs={6}>
                <FormTextField
                  name='firstName'
                  label='First Name'
                  type='text'
                  fullWidth
                  variant='standard'
                  disabled={false}
                  control={control}
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  name='lastName'
                  label='Last Name'
                  type='text'
                  fullWidth
                  variant='standard'
                  disabled={false}
                  control={control}
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormTextField
                  name='email'
                  label='E-mail'
                  type='text'
                  fullWidth
                  variant='standard'
                  control={control}
                  rules={{ required: true }}
                  disabled={mode === 'update'}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormTextField
                  name='phone'
                  label='Phone'
                  type='text'
                  fullWidth
                  variant='standard'
                  control={control}
                  rules={{ required: true }}
                  mask={withMask('+1 (999) 999-9999', {
                    placeholder: ' ',
                  })}
                />
              </Grid>
              {getValues('websiteUrl') && (
                <Grid item xs={1} sm={1}>
                  <Link href={getValues('websiteUrl')}>
                    <OpenInBrowserIcon sx={{ verticalAlign: '-webkit-baseline-middle' }} />
                  </Link>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name='websiteUrl'
                  label='Website'
                  type='text'
                  fullWidth
                  variant='standard'
                  control={control}
                  mask={withMask('url', { nullable: true })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name={`addresses.0.company`}
                  label='Business Name'
                  type='text'
                  fullWidth
                  variant='standard'
                  disabled={false}
                  control={control}
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name={`addresses.0.address1`}
                  label='Address'
                  type='text'
                  fullWidth
                  variant='standard'
                  disabled={false}
                  control={control}
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  name={`addresses.0.address2`}
                  label='Apartment, suite, etc'
                  type='text'
                  fullWidth
                  variant='standard'
                  disabled={false}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormTextField
                  name={`addresses.0.city`}
                  label='City'
                  type='text'
                  fullWidth
                  variant='standard'
                  disabled={false}
                  control={control}
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormTextField
                  name={`addresses.0.provinceCode`}
                  label='State'
                  type='text'
                  fullWidth
                  variant='standard'
                  disabled={false}
                  control={control}
                  rules={{ required: true }}
                  mask={withMask('AA', {
                    placeholder: ' ',
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormTextField
                  name={`addresses.0.zip`}
                  label='Postal Code'
                  type='text'
                  fullWidth
                  variant='standard'
                  disabled={false}
                  control={control}
                  rules={{ required: true }}
                  mask={withMask('99999', {
                    placeholder: ' ',
                  })}
                />
              </Grid>
            </Grid>
            {/* <CustomTabPanel index={tabValue} value={1}>
              <Grid
                container={!matches}
                spacing={2}
                display={{ md: 'grid' }}
                flexDirection='row'
                gap={2}
                rowSpacing={2}
                gridTemplateColumns='repeat(2, 50%)'
              >
                {(fields || []).map((field, index) => {
                  return (
                    <Grid item xs={12} md={6} key={index} sx={{ height: '100%' }}>
                      <AddressComponent
                        address={field}
                        control={control}
                        index={index}
                        isDefaultAddress={data?.defaultAddress?.id === field?.id}
                        setSubmitError={setSubmitError}
                        storeId={storeId}
                        customerId={data?.id}
                        onSuccess={onSuccess}
                        remove={remove}
                      />
                    </Grid>
                  )
                })}
              </Grid>
              <Button variant={'outlined'} color={'primary'} sx={{ mt: 2 }} onClick={() => addNewAddress()}>
                New Address
              </Button>
            </CustomTabPanel> */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            onClick={() => {
              closeDialog()
            }}
          >
            Cancel
          </Button>
          <Button type='submit' disabled={isSubmitting || !(isDirty && isValid)}>
            {mode === 'update' ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </>
  )
}
