import { useEffect, useState } from 'react'
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import { Box, Typography, Divider } from '@mui/material'
import { GridCsvExportOptions } from '@mui/x-data-grid-pro'
import {
  DataGridPremium,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-premium'
import _ from 'lodash'
import { ReportFiltersType } from 'pages/ReportsApp/ReportFilters.tsx'
import { LIST_SHOPIFY_STORE_CONFIGURATIONS } from 'lib/graphql/listShopifyStoreConfigurations.ts'
import { PROXY_SHOPIFY_QUERY } from 'lib/graphql/proxyShopifyQuery.ts'
import { ShopifyStoreConfiguration } from 'lib/graphql/types.ts'
import { organizationId, selectedOrganization, stores } from 'config/cache.ts'
import { currentDateTime, formatDate, usdFormatter, renderShopifyPaymentStatus } from 'pages/ReportsApp/utils'

const columns: GridColDef[] = [
  {
    field: 'storeName',
    headerName: 'Store',
    minWidth: 90,
    flex: 0.2,
  },
  {
    field: 'issuedAt',
    headerName: 'Payout Date',
    width: 90,
    valueGetter: (params) => {
      return formatDate(params.row.issuedAt)
    },
    renderCell: (params) => {
      return formatDate(params.row.issuedAt)
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 80,
    type: 'string',
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => {
      if (!params.row.status) return null
      return <>{renderShopifyPaymentStatus(params.row.status)}</>
    },
  },
  // {
  //   field: 'bank',
  //   headerName: 'Bank Account',
  //   minWidth: 90,
  //   type: 'string',
  //   align: 'left',
  //   headerAlign: 'left',
  //   flex: 0.6,
  //   renderCell: (params) => {
  //     if (!params.row.bankAccount) return null
  //     return (
  //       <>
  //         {params.row.bankAccount.bankName} xxxxx{params.row.bankAccount.accountNumberLastDigits}
  //       </>
  //     )
  //   },
  // },
  {
    field: 'charges',
    headerName: 'Charges',
    flex: 0.2,
    type: 'number',
    minWidth: 90,
    valueGetter: (params) => {
      return parseFloat(params.row.summary?.chargesGross?.amount || '0')
    },
    valueFormatter: ({ value }) => {
      if (value === null) {
        return null
      }
      return usdFormatter.format(value)
    },
  },
  {
    field: 'refunds',
    headerName: 'Refunds',
    flex: 0.2,
    type: 'number',
    minWidth: 90,
    valueGetter: (params) => {
      return parseFloat(params.row.summary?.refundsFeeGross?.amount || '0')
    },
    valueFormatter: ({ value }) => {
      if (value === null) {
        return null
      }
      return usdFormatter.format(value)
    },
  },
  {
    field: 'adjustments',
    headerName: 'Adjustments',
    flex: 0.2,
    type: 'number',
    minWidth: 90,
    valueGetter: (params) => {
      return parseFloat(params.row.summary?.adjustmentsFeeGross?.amount || '0')
    },
    valueFormatter: ({ value }) => {
      if (value === null) {
        return null
      }
      return usdFormatter.format(value)
    },
  },
  {
    field: 'fees',
    headerName: 'Fees',
    flex: 0.2,
    type: 'number',
    minWidth: 90,
    valueGetter: (params) => {
      return parseFloat(params.row.summary?.chargesFee?.amount || '0') * -1
    },
    valueFormatter: ({ value }) => {
      if (value === null) {
        return null
      }
      return usdFormatter.format(value)
    },
  },
  {
    field: 'total',
    headerName: 'Total',
    type: 'number',
    flex: 0.2,
    minWidth: 90,
    valueGetter: (params) => {
      return parseFloat(params.row.net?.amount || '0')
    },
    valueFormatter: ({ value }) => {
      if (value === null) {
        return null
      }
      return usdFormatter.format(value)
    },
  },
]

export const PaymentsReport = () => {
  const selectedOrganizationIdVar = useReactiveVar(organizationId)
  const organizationVar = useReactiveVar(selectedOrganization)
  const storesVar = useReactiveVar(stores)

  const [reportFilters] = useState<ReportFiltersType>()
  const [orgStores, setOrgStores] = useState<string[]>()
  const [payments, setPayments] = useState<any[]>([])
  const [paymentsByStore, setPaymentsByStore] = useState<{ [storeId: string]: any[] }>({})

  const [lazyShopifyQuery, { loading: loadingShopify }] = useLazyQuery(PROXY_SHOPIFY_QUERY)

  const { data: storeData, loading: loadingStores } = useQuery(LIST_SHOPIFY_STORE_CONFIGURATIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        organizationId: { equals: selectedOrganizationIdVar },
      },
      take: 100,
    },
    skip: !selectedOrganizationIdVar,
    onCompleted: (data) => {
      setOrgStores(data?.listShopifyStoreConfigurations.map((x: ShopifyStoreConfiguration) => x.id))
      setPaymentsByStore({})
      setPayments([])
    },
  })

  useEffect(() => {
    orgStores?.map((storeId) => {
      lazyShopifyQuery({
        fetchPolicy: 'no-cache',
        variables: {
          query: 'payments',
          shopifyStoreId: storeId,
          variables: JSON.stringify({
            first: 100,
            reverse: true,
          }),
        },
        onCompleted: (data) => {
          setPaymentsByStore((prevData) => ({
            ...prevData,
            [storeId]: _.flatten(
              JSON.parse(data.proxyShopifyQuery || '{}').data?.shopifyPaymentsAccount?.payouts.edges.map(
                ({ node }: { node: any }) => {
                  return node
                }
              )
            ),
          }))
        },
      })
    })
  }, [orgStores, lazyShopifyQuery])

  useEffect(() => {
    if (!_.isEmpty(paymentsByStore) && !_.isEmpty(storesVar)) {
      setPayments(
        _.flatten(
          Object.keys(paymentsByStore).map((storeId) => {
            return (paymentsByStore[storeId] || []).map((payment) => ({
              ...payment,
              id: payment.id,
              storeId: storeId,
              storeName: storesVar?.find((x) => x.id === storeId)?.name,
            }))
          })
        )
      )
    }
  }, [paymentsByStore, storesVar])

  const csvOptions: GridCsvExportOptions = {
    allColumns: true,
    fileName: `${currentDateTime()}_BillingReport_${selectedOrganizationIdVar}`,
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} csvOptions={csvOptions} />
      </GridToolbarContainer>
    )
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ height: '50px' }}>
          <Typography variant={'h5'}>Payments Report </Typography>
        </Box>
        <Divider />
        <Box sx={{ height: 'calc(100vh - 220px)' }}>
          <Box
            sx={{
              paddingY: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'top',
            }}
          >
            <Typography variant={'h6'}>{organizationVar?.name}</Typography>
            <Typography variant={'body1'}>{loadingShopify ? '...' : payments.length} Payments</Typography>
          </Box>
          <Box
            sx={{
              height: 'calc(100% - 80px)',
              display: 'grid',
              gridTemplateRows: 'auto 1f auto',
              borderRadius: '16px',
              p: 1,
            }}
          >
            <DataGridPremium
              initialState={{
                aggregation: {
                  model: {
                    fees: 'sum',
                    adjustments: 'sum',
                    total: 'sum',
                    refunds: 'sum',
                    charges: 'sum',
                  },
                },
              }}
              sx={{
                borderLeftWidth: 0,
                borderRightWidth: 0,
                borderTopWidth: 0,
              }}
              showCellVerticalBorder={false}
              showColumnVerticalBorder={false}
              hideFooterRowCount={true}
              hideFooter={true}
              columnVisibilityModel={{
                email: false,
                cancelledAt: false,
                displayFinancialStatus: false,
                displayFulfillmentStatus: false,
              }}
              rows={payments || []}
              columns={columns}
              columnHeaderHeight={90}
              density={'compact'}
              hideFooterPagination
              scrollEndThreshold={150}
              loading={loadingStores || loadingShopify}
              slots={{
                toolbar: CustomToolbar,
              }}
              disableMultipleRowSelection={true}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
