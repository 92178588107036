import { gql } from '@apollo/client'

export const UPDATE_CUSTOMER_GROUP = gql`
  mutation UpdateCustomerGroup($where: CustomerGroupWhereUniqueInput!, $data: CustomerGroupUpdateInput!) {
    updateCustomerGroup(where: $where, data: $data) {
      id
      name
      customerExternalIds
    }
  }
`
