import { useQuery, useReactiveVar } from '@apollo/client'
import { useState } from 'react'
import dayjs from 'dayjs'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import BarcodeTable from 'components/Barcode/BarcodeTable'
import { BARCODES_LIST } from 'lib/graphql/listBarcodes.ts'
import { BARCODES_COUNT } from 'lib/graphql/countBarcodes.ts'
import { fromDate, orderSearchFilter, organizationId, toDate } from 'config/cache.ts'

const REFRESH_INTERVAL = 60000
export const BarcodeDashboard = () => {
  const organizationIdVar = useReactiveVar(organizationId)
  const fromDateVar = useReactiveVar(fromDate)
  const toDateVar = useReactiveVar(toDate)
  const orderSearchFilterVar = useReactiveVar(orderSearchFilter)

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  })

  const {
    loading: loadingCountBarcodes,
    data: dataCountBarcodes,
    error: errorCountBarcodes,
  } = useQuery(BARCODES_COUNT, {
    variables: {
      where: {
        status: { in: ['PreMarket', 'InUse'] },
        organizationId: { equals: organizationIdVar },
        instanceCreatedOn: { gte: fromDateVar, lte: toDateVar },
        ...(orderSearchFilterVar
          ? {
              orders: { some: { commonId: { contains: orderSearchFilterVar } } },
            }
          : {}),
      },
    },
    skip: !organizationIdVar,
    pollInterval: REFRESH_INTERVAL,
  })

  const {
    loading: loadingBarcodes,
    data: dataBarcodes,
    error: errorListBarcodes,
  } = useQuery(BARCODES_LIST, {
    variables: {
      take: paginationModel.pageSize,
      skip: paginationModel.pageSize * paginationModel.page,
      where: {
        status: { in: ['PreMarket', 'InUse'] },
        organizationId: { equals: organizationIdVar },
        instanceCreatedOn: { gte: fromDateVar, lte: toDateVar },
        ...(orderSearchFilterVar
          ? {
              orders: { some: { commonId: { contains: orderSearchFilterVar } } },
            }
          : {}),
      },
      orderBy: [{ createdAt: 'DESC' }],
    },
    skip: !organizationIdVar,
    pollInterval: REFRESH_INTERVAL,
  })

  const rows = (dataBarcodes?.listBarcodes || []).map((barcode: any) => {
    return {
      id: barcode.id,
      orders: barcode.orders,
      productName: barcode.customerProductName,
      companyName: barcode.customerCompanyName,
      upc: barcode.upc,
      date: dayjs(barcode.instanceCreate).format('MM/DD/YY'),
      client: barcode.client?.name,
      status: barcode.status,
    }
  })

  return (
    <>
      {errorCountBarcodes || errorListBarcodes ? (
        <Box p='1'>
          <Alert variant='filled' severity='error'>
            There was an error loading the table.
          </Alert>
        </Box>
      ) : null}
      <BarcodeTable
        nRows={dataCountBarcodes?.countBarcodes ?? 0}
        isLoading={loadingBarcodes || loadingCountBarcodes}
        rows={rows}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </>
  )
}
