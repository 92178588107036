import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import {
  Box,
  Typography,
  Button,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Paper,
  Stack,
} from '@mui/material'
import { organizationId, selectedStoreId, stores } from 'config/cache.ts'
import { GET_SHOPIFY_STORE_CONFIGURATION } from 'lib/graphql/getShopifyStoreConfiguration.ts'
import _ from 'lodash'
import { Input } from 'components/Forms/Input.tsx'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import { FormTextField } from 'components/Forms/FormTextField.tsx'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { CheckShopifyStoreConfiguration, CustomerGroup } from 'lib/graphql/types.ts'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { UPDATE_SHOPIFY_STORE_CONFIGURATION } from 'lib/graphql/updateShopifyStoreConfiguration.ts'
import { CHECK_SHOPIFY_STORE_CONFIGURATION } from 'lib/graphql/checkShopifyStoreConfiguration.ts'
import dayjs from 'dayjs'
import CheckIcon from '@mui/icons-material/Check'
import { LIST_STORE_CONFIGURATION_FULFILLMENTS } from 'lib/graphql/listStoreConfigurationFulfillment.ts'
import { FulfillmentSettings } from 'components/Settings/FulfillmentSettings.tsx'
import { WebhookRegistration } from 'components/Settings/WebhookRegistration.tsx'
import { WebhookSettings } from 'components/Settings/WebhookSettings.tsx'

function formatDateTime(v: Date | string | undefined) {
  return v ? dayjs(v).format('M/D/YYYY h:mm A') : ''
}

type StoreConfigurationData = {
  id?: string
  name?: string
  domain?: string
  brandedDomain?: string
  assetsDomain?: string
  adminApiToken?: string
  clientId?: string
  clientSecret?: string
  status?: boolean
  configurationLastCheckedAt?: string
  configurationErrors?: string
}
type StoreConfigurationFields = Omit<
  StoreConfigurationData,
  'configurationLastCheckedAt' | 'configurationErrors' | 'status'
>

const validationSchema = Yup.object().shape({
  id: Yup.string().required(),
  name: Yup.string().required('Store name is required'),
  domain: Yup.string(),
  assetsDomain: Yup.string(),
  brandedDomain: Yup.string(),
  adminApiToken: Yup.string(),
  clientId: Yup.string(),
  clientSecret: Yup.string(),
})

export const StoreSettingsAdmin = () => {
  const currentOrganizationId = useReactiveVar(organizationId)
  const selectedStoreIdVar = useReactiveVar(selectedStoreId)
  const storesVar = useReactiveVar(stores)
  const [storeData, setStoreData] = useState<StoreConfigurationData>()

  const {
    refetch: refetch,
    data: data,
    loading: loading,
    error: error,
  } = useQuery(GET_SHOPIFY_STORE_CONFIGURATION, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        id: selectedStoreIdVar,
      },
    },
    skip: !selectedStoreIdVar,
  })

  const [updateStoreConfiguration] = useMutation(UPDATE_SHOPIFY_STORE_CONFIGURATION)

  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors, isSubmitting, isDirty, isValid },
    reset,
    getValues,
  } = useForm<StoreConfigurationFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver<StoreConfigurationFields>(validationSchema),
  })

  const toGraphQL = (data: any) => {
    return {
      name: data.name,
      domain: data.domain,
      assetsDomain: data.assetsDomain,
      brandedDomain: data.brandedDomain,
      adminApiToken: data.adminApiToken,
      clientId: data.clientId,
      clientSecret: data.clientSecret,
    }
  }
  const transformDataToFormValues = (data: any) => {
    return {
      id: data.id,
      name: data.name,
      domain: data.domain,
      assetsDomain: data.assetsDomain,
      brandedDomain: data.brandedDomain,
      adminApiToken: data.adminApiToken,
      clientId: data.clientId,
      clientSecret: data.clientSecret,
    }
  }

  const [checkShopifyStoreConfiguration, { loading: loadingConfiguration }] = useMutation(
    CHECK_SHOPIFY_STORE_CONFIGURATION,
    {
      onCompleted: (response: { checkShopifyStoreConfiguration: CheckShopifyStoreConfiguration }) => {
        setStoreData({
          ...storeData,
          status: _.isEmpty(JSON.parse(response.checkShopifyStoreConfiguration.configurationErrors || '[]')),
          configurationErrors: response.checkShopifyStoreConfiguration.configurationErrors,
          configurationLastCheckedAt: response.checkShopifyStoreConfiguration.configurationLastCheckedAt,
        })
      },
    }
  )

  useEffect(() => {
    if (!loading && data?.getShopifyStoreConfiguration?.id) {
      setStoreData({
        ...data.getShopifyStoreConfiguration,
        status: _.isEmpty(JSON.parse(data.getShopifyStoreConfiguration.configurationErrors || '[]')),
      })
      reset(transformDataToFormValues(data.getShopifyStoreConfiguration))
    }
  }, [data, reset, setStoreData])

  const onSubmit: SubmitHandler<StoreConfigurationData> = async (data) => {
    await updateStoreConfiguration({
      variables: {
        where: {
          id: selectedStoreIdVar,
        },
        data: toGraphQL(data),
      },
    })
    await refetch()
  }

  const onReset = async () => {
    reset(transformDataToFormValues(storeData))
  }

  if (!storesVar || !selectedStoreIdVar || _.isEmpty(storeData)) return <></>

  return (
    <>
      <Box sx={{ paddingBottom: 2 }}>
        <Typography variant={'h5'}>
          {storesVar.find((x) => x.id === selectedStoreIdVar).name} - Admin Settings
        </Typography>
        <Box sx={{ paddingBottom: '20px', paddingTop: '20px' }}>
          <FulfillmentSettings />
        </Box>
        <Box sx={{ paddingBottom: '20px' }}>
          <WebhookSettings createStoreType={'Shopify'} />
        </Box>
        {/*<Paper*/}
        {/*  elevation={5}*/}
        {/*  sx={{*/}
        {/*    height: 'inherit',*/}
        {/*    display: 'grid',*/}
        {/*    gridTemplateRows: 'auto 1f auto',*/}
        {/*    borderRadius: '16px',*/}
        {/*    p: 1,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {' '}*/}
        {/*  <Grid sx={{ marginTop: '20px', padding: '0px 20px 20px' }}>*/}
        {/*    <form onSubmit={handleSubmit(onSubmit)}>*/}
        {/*      <Input type='hidden' control={control} name='id' />*/}
        {/*      <Grid*/}
        {/*        container*/}
        {/*        direction={'row'}*/}
        {/*        spacing={4}*/}
        {/*        sx={{*/}
        {/*          marginTop: '5px',*/}
        {/*          marginBottom: '5px',*/}
        {/*          justifyContent: 'space-between',*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <Grid item>*/}
        {/*          <Typography variant='h5'>Shopify Store</Typography>*/}
        {/*        </Grid>*/}
        {/*        <Grid item sx={{ alignSelf: 'right' }}>*/}
        {/*          <Button*/}
        {/*            variant={'outlined'}*/}
        {/*            disabled={isSubmitting}*/}
        {/*            onClick={() => {*/}
        {/*              onReset()*/}
        {/*            }}*/}
        {/*          >*/}
        {/*            Reset*/}
        {/*          </Button>*/}

        {/*          <Button*/}
        {/*            sx={{ marginLeft: '10px' }}*/}
        {/*            variant={'contained'}*/}
        {/*            type='submit'*/}
        {/*            disabled={isSubmitting || !(isDirty && isValid)}*/}
        {/*          >*/}
        {/*            Save*/}
        {/*          </Button>*/}
        {/*        </Grid>*/}
        {/*      </Grid>*/}
        {/*      <Grid container direction={'row'} spacing={2} sx={{ marginTop: '5px' }}>*/}
        {/*        <Grid item xs={6}>*/}
        {/*          <FormTextField*/}
        {/*            name='name'*/}
        {/*            label='Internal Store Name'*/}
        {/*            type='text'*/}
        {/*            fullWidth*/}
        {/*            variant='standard'*/}
        {/*            disabled={loading}*/}
        {/*            control={control}*/}
        {/*          />*/}
        {/*        </Grid>*/}
        {/*      </Grid>*/}
        {/*      <Grid container spacing={2} sx={{ marginTop: '5px' }}>*/}
        {/*        <Grid item xs={12} sx={{ fontWeight: 'bold' }}>*/}
        {/*          Domains*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs={12} lg={6} sx={{ paddingTop: '10px' }}>*/}
        {/*          <FormTextField*/}
        {/*            name='domain'*/}
        {/*            label='Shopify Domain'*/}
        {/*            type='text'*/}
        {/*            fullWidth*/}
        {/*            variant='standard'*/}
        {/*            disabled={loading}*/}
        {/*            control={control}*/}
        {/*          />*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs={12} lg={6} sx={{ paddingTop: '10px' }}>*/}
        {/*          <FormTextField*/}
        {/*            name='brandedDomain'*/}
        {/*            label='Branded Domain'*/}
        {/*            type='text'*/}
        {/*            fullWidth*/}
        {/*            variant='standard'*/}
        {/*            disabled={loading}*/}
        {/*            control={control}*/}
        {/*          />*/}
        {/*        </Grid>*/}
        {/*      </Grid>*/}
        {/*      <Grid container spacing={2} sx={{ marginTop: '5px' }}>*/}
        {/*        <Grid item xs={12} sx={{ fontWeight: 'bold' }}>*/}
        {/*          Assets*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs={12} lg={6} sx={{ paddingTop: '10px' }}>*/}
        {/*          <FormTextField*/}
        {/*            name='assetsDomain'*/}
        {/*            label='Assets Domain'*/}
        {/*            type='text'*/}
        {/*            fullWidth*/}
        {/*            variant='standard'*/}
        {/*            disabled={loading}*/}
        {/*            control={control}*/}
        {/*          />*/}
        {/*        </Grid>*/}
        {/*      </Grid>*/}
        {/*      <Grid container spacing={2} sx={{ marginTop: '5px' }}>*/}
        {/*        <Grid item xs={12} sx={{ fontWeight: 'bold' }}>*/}
        {/*          Shopify Tokens*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs={12} lg={6} sx={{ paddingTop: '10px' }}>*/}
        {/*          <FormTextField*/}
        {/*            name='clientId'*/}
        {/*            label='Headless Client ID'*/}
        {/*            type='text'*/}
        {/*            fullWidth*/}
        {/*            variant='standard'*/}
        {/*            disabled={loading}*/}
        {/*            inputProps={{ readOnly: true }}*/}
        {/*            control={control}*/}
        {/*          />*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs={12} lg={6} sx={{ paddingTop: '10px' }}>*/}
        {/*          <FormTextField*/}
        {/*            name='clientSecret'*/}
        {/*            label='Headless Client Secret'*/}
        {/*            type='text'*/}
        {/*            fullWidth*/}
        {/*            variant='standard'*/}
        {/*            disabled={loading}*/}
        {/*            inputProps={{ readOnly: true }}*/}
        {/*            control={control}*/}
        {/*          />*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs={12} lg={6} sx={{ paddingTop: '10px' }}>*/}
        {/*          <FormTextField*/}
        {/*            name='adminApiToken'*/}
        {/*            label='Admin API Token'*/}
        {/*            type='text'*/}
        {/*            fullWidth*/}
        {/*            variant='standard'*/}
        {/*            disabled={loading}*/}
        {/*            inputProps={{ readOnly: true }}*/}
        {/*            control={control}*/}
        {/*          />*/}
        {/*        </Grid>*/}
        {/*      </Grid>*/}
        {/*      <Grid container spacing={2} sx={{ marginTop: '5px' }}>*/}
        {/*        <Grid item xs={12} sx={{ fontWeight: 'bold' }}>*/}
        {/*          <Stack direction={'row'} spacing={3}>*/}
        {/*            <Box>Shopify Configuration</Box>*/}
        {/*            <Box>*/}
        {/*              {storeData.status ? (*/}
        {/*                <CheckIcon sx={{ color: 'green' }} />*/}
        {/*              ) : (*/}
        {/*                <ClearIcon sx={{ color: 'red' }} />*/}
        {/*              )}*/}
        {/*            </Box>*/}
        {/*          </Stack>*/}
        {/*        </Grid>*/}

        {/*        <Grid item xs={12} lg={6} sx={{ paddingTop: '10px' }}>*/}
        {/*          <Stack direction={'row'} spacing={3} sx={{ alignItems: 'center' }}>*/}
        {/*            <Box>*/}
        {/*              <Button*/}
        {/*                variant={'outlined'}*/}
        {/*                disabled={loadingConfiguration}*/}
        {/*                onClick={() =>*/}
        {/*                  checkShopifyStoreConfiguration({*/}
        {/*                    variables: {*/}
        {/*                      where: {*/}
        {/*                        id: selectedStoreIdVar,*/}
        {/*                      },*/}
        {/*                    },*/}
        {/*                  })*/}
        {/*                }*/}
        {/*              >*/}
        {/*                Check now*/}
        {/*              </Button>*/}
        {/*            </Box>*/}
        {/*            <Box>(Last Checked: {formatDateTime(storeData.configurationLastCheckedAt)})</Box>*/}
        {/*          </Stack>*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs={12} lg={6} sx={{ paddingTop: '10px' }}>*/}
        {/*          Errors: {storeData.configurationErrors}*/}
        {/*        </Grid>*/}
        {/*      </Grid>*/}
        {/*      /!*</Grid>*!/*/}
        {/*    </form>*/}
        {/*  </Grid>*/}
        {/*</Paper>*/}
      </Box>
    </>
  )
}
