import { gql } from '@apollo/client'

export const UPDATE_BRAND_ACCOUNT = gql`
  mutation updateBrandAccount($where: BrandAccountExtendedWhereUniqueInput!, $data: BrandAccountUpdateInput!) {
    updateBrandAccount(where: $where, data: $data) {
      id
      name
      accountManagerName
      sellsInCalifornia
      prop65Waiver
      city
      state
      customers {
        id
        customerId
      }
      createdAt
    }
  }
`
