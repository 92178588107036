import React, { useState, useCallback } from 'react'
import { AuthChallengeError, cognitoOps } from 'lib/Cognito.tsx'
import { CircularProgress, Stack } from '@mui/material'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { AuthFormProps } from './LoginPage.tsx'
import { useLocation } from 'react-router-dom'

export default function SignInForm({ callback }: AuthFormProps): React.ReactElement {
  const { state: routeState } = useLocation()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(routeState?.email || '')
  const [password, setPassword] = useState('')

  const login = useCallback(
    async (email: string, password: string) => {
      setLoading(true)
      try {
        callback(undefined, await cognitoOps?.signInWithEmail(email, password))
      } catch (e) {
        if (e instanceof AuthChallengeError) {
          return callback(e)
        }
      } finally {
        setLoading(false)
      }
    },
    [cognitoOps, callback]
  )

  return (
    <form onSubmit={(e: any) => e.preventDefault() || login(email, password)} style={{ width: '50%' }}>
      <Stack spacing={3}>
        <TextField
          label='Email'
          onChange={(e: any) => setEmail(e.target.value)}
          required
          variant='standard'
          color='secondary'
          type='email'
          fullWidth
          value={email}
          disabled={loading}
        />
        <TextField
          label='Password'
          onChange={(e: any) => setPassword(e.target.value)}
          required
          variant='standard'
          color='secondary'
          type='password'
          value={password}
          fullWidth
          disabled={loading}
        />
        <Button variant='contained' color='primary' disabled={loading} type='submit'>
          Sign in
          {loading && (
            <>
              &nbsp;&nbsp; <CircularProgress color='info' />
            </>
          )}
        </Button>
      </Stack>
    </form>
  )
}
