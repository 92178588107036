import { Sku } from '@/lib/graphql/types'
import { useQuery } from '@apollo/client'
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material'
import { CATALOG_LIST } from 'lib/graphql/listCatalog.ts'
import { useState } from 'react'
import { Controller } from 'react-hook-form'

type SkuSelectProps = Partial<SelectProps<string>> & {
  organizationId: string
  archived: boolean
  usePlSku?: boolean
  control: any
  customOnChange: (event: SelectChangeEvent<string>) => void
  withName?: boolean
}

export const SkuSelect = ({
  archived,
  organizationId,
  usePlSku,
  name,
  label,
  customOnChange,
  control,
  withName,
}: SkuSelectProps) => {
  const { data, loading, error } = useQuery(CATALOG_LIST, {
    variables: {
      limit: 500,
      offset: 0,
      orderBy: [{ sku: 'ASC' }],
      where: {
        brand: {
          organization: {
            id: {
              equals: organizationId,
            },
          },
        },
        archivedAt: archived ? undefined : null,
      },
    },
    fetchPolicy: 'no-cache',
  })

  if (loading) {
    return <CircularProgress />
  }
  if (error) {
    return <>Error</>
  }

  const minWidth = withName ? '300px' : '200px'

  return (
    <Controller
      name={name || ''}
      control={control}
      // rules={rules}
      // disabled={disabled}
      render={({ field: { onChange, value, disabled }, fieldState: { error } }) => (
        <FormControl sx={{ m: 1, minWidth }}>
          <InputLabel id='demo-simple-select-label'>{label}</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            label={label}
            name={name}
            fullWidth
            value={value}
            variant='standard'
            onChange={(v) => {
              onChange(v)
              customOnChange(v)
            }}
            sx={{ marginTop: '15px' }}
          >
            {(data.listSkus || []).map((sku: Sku) => {
              return (
                <MenuItem key={sku.id} value={sku.id}>
                  {usePlSku ? sku.skuWhiteLabel : sku.sku} {withName ? ` - ${sku.skuFact.productName}` : null}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      )}
    />
  )
}
