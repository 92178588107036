import { Box, Grid, Link, Paper, Typography, Button, ButtonGroup, LinearProgress } from '@mui/material'
import _ from 'lodash'
import { ShopifyCustomer } from 'lib/types.ts'

import { useQuery, useReactiveVar } from '@apollo/client'
import { organizationId, selectedStoreId, shoppingProduct, stores } from 'config/cache.ts'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { PROXY_SHOPIFY_QUERY } from 'lib/graphql/proxyShopifyQuery.ts'
import ProductCatalog from 'pages/VendorApp/Shop/ProductCatalog.tsx'
import ProductView from 'pages/VendorApp/Shop/blocks/ProductView/ProductView.tsx'
import { Image, ProductVariant, ShopifyProduct } from 'pages/VendorApp/Shop/types.ts'
import Tabs from '@mui/material/Tabs'
import style from 'components/Dialogs/dialogs.module.css'
import Tab from '@mui/material/Tab'
import { CustomTabPanel, customTabProps } from 'components/CustomTabs.tsx'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import MyPrivateLabels from 'pages/VendorApp/Shop/MyPrivateLabels.tsx'

export const Shop = () => {
  const [customer, setCustomer] = useState<ShopifyCustomer>()
  const [product, setProduct] = useState<ShopifyProduct>()
  const shoppingProductVar = useReactiveVar(shoppingProduct)
  const storesVar = useReactiveVar(stores)
  const { customerId, storeId } = useParams()
  const navigate = useNavigate()
  const [tabValue, setTabValue] = useState(0)

  useEffect(() => {
    shoppingProduct(undefined)
  }, [])
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const { refetch } = useQuery(PROXY_SHOPIFY_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      query: 'customer',
      shopifyStoreId: storeId,
      variables: JSON.stringify({
        id: `gid://shopify/Customer/${customerId}`,
      }),
    },
    skip: !customerId && !storeId,
    onCompleted: (data) => {
      setCustomer(JSON.parse(data.proxyShopifyQuery).data.customer)
    },
  })

  const { loading: isLoadingProduct } = useQuery(PROXY_SHOPIFY_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      query: 'product',
      shopifyStoreId: storeId,
      variables: JSON.stringify({
        id: shoppingProductVar,
      }),
    },
    skip: !shoppingProductVar,
    onCompleted: (data) => {
      const variantSort = ['white label', 'wholesale', 'retail', 'dispensary'].reverse()
      const _product = JSON.parse(data.proxyShopifyQuery).data.product
      setProduct({
        ..._product,
        description: _product.descriptionHtml,
        images: _product.media?.edges.map(({ node }: { node: Image }) => ({
          ...node,
          src: node.preview?.image.url,
        })),
        variants: _product.variants.edges
          .map(({ node }: any) => {
            return {
              ...node,
              price: {
                amount: node.price,
                currencyCode: 'USD',
              },
              moq: node.minQuantity,
              iq: node.boxQuantity,
            }
          })
          .sort(
            (a: ProductVariant, b: ProductVariant) =>
              variantSort.indexOf(b.title.toLowerCase()) - variantSort.indexOf(a.title.toLowerCase())
          ),
      })
    },
  })

  const refreshCustomer = async () => {
    await refetch()
  }

  if (!customer) {
    return <></>
  }

  if (_.isEmpty(storesVar)) {
    navigate('/vendor')
  }
  const storeData = (storesVar || []).find((store) => store.id === storeId)

  return (
    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
      <Box
        sx={{
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          placeSelf: 'center',
          alignSelf: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            padding: '10px',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <div>
              <Typography variant={'h5'}>
                {storeData?.name} &mdash; Impersonating {customer.firstName} {customer.lastName}
              </Typography>
            </div>
            <div>
              <Button
                onClick={() => {
                  navigate(`/vendor/${storeId}/customers`)
                }}
              >
                <ExitToAppIcon />
                &nbsp; Return to Admin
              </Button>
            </div>
          </Box>
        </Box>
        <Box>
          <Paper elevation={10}>
            {
              <Box sx={{ paddingTop: '10px' }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  className={style.dialogTabs}
                  sx={{ paddingLeft: '20px' }}
                >
                  <Tab
                    label={
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <div>Catalog</div>
                      </Box>
                    }
                    className={style.dialogTab}
                    {...customTabProps(0, tabValue)}
                  />
                  <Tab
                    label={
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <div>Private Labels</div>
                      </Box>
                    }
                    className={style.dialogTab}
                    {...customTabProps(1, tabValue)}
                  />
                </Tabs>
                <CustomTabPanel value={tabValue} index={0}>
                  {shoppingProductVar && product ? (
                    <Box sx={{ paddingLeft: '10px', paddingTop: '00px' }}>
                      {isLoadingProduct ? (
                        <Box sx={{ padding: '30px' }}>
                          <LinearProgress />
                        </Box>
                      ) : (
                        <ProductView
                          key={product!.id}
                          product={product}
                          customer={customer}
                          description={product.description}
                          title={product.title}
                          refreshCustomer={refreshCustomer}
                        />
                      )}
                    </Box>
                  ) : (
                    <Box sx={{ maxWidth: '1200px', margin: 'auto' }}>
                      <ProductCatalog customer={customer} storeId={storeId!} />
                    </Box>
                  )}
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                  <Box sx={{ maxWidth: '1200px', margin: 'auto' }}>
                    <MyPrivateLabels
                      customer={customer}
                      storeId={storeId!}
                      refreshCustomer={refreshCustomer}
                    />
                  </Box>
                </CustomTabPanel>
              </Box>
            }
          </Paper>
        </Box>
      </Box>
    </Box>
  )
}
