import React, { useEffect, useState } from 'react'
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import { useMutation } from '@apollo/client'
import * as Yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormTextField } from 'components/Forms/FormTextField.tsx'
import { CREATE_ORGANIZATION } from 'lib/graphql/createOrganization.ts'
import { UPDATE_ORGANIZATION } from 'lib/graphql/updateOrganization.ts'

type OrganizationFieldProps = {
  id?: string
  name: string
}

const validationSchema = Yup.object().shape({
  id: Yup.string().optional(),
  name: Yup.string().required('Required'),
})

interface CreateOrUpdateOrganizationProps {
  mode: 'create' | 'update'
  data: any
  onSuccess: () => void
  closeDialog: () => void
}

export const OrganizationCreateOrUpdateDialog: React.FC<CreateOrUpdateOrganizationProps> = ({
  mode,
  data,
  onSuccess,
  closeDialog,
}) => {
  const [submitError, setSubmitError] = useState<string>()
  const [internalData, setInternalData] = useState(data)

  if (mode === 'update' && !data) {
    return <></>
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    reset,
  } = useForm<OrganizationFieldProps>({
    reValidateMode: 'onChange',
    resolver: yupResolver<OrganizationFieldProps>(validationSchema),
    defaultValues: {
      name: '',
    },
  })

  const [createOrganization] = useMutation(CREATE_ORGANIZATION)
  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)

  const transformDataToFormValues = (data: any) => {
    return {
      id: data.id,
      name: data.name,
    }
  }

  const graphqlDataInput = (values: any) => ({
    name: values.name,
  })

  const create = async (values: OrganizationFieldProps) => {
    setSubmitError(undefined)
    await createOrganization({
      variables: {
        data: graphqlDataInput(values),
      },
      onError: (err) => {
        setSubmitError(err.message)
      },
      onCompleted: () => {
        onSuccess()
      },
    })
  }

  const update = async (values: OrganizationFieldProps) => {
    setSubmitError(undefined)
    await updateOrganization({
      variables: {
        where: {
          id: values.id,
        },
        data: graphqlDataInput(values),
      },
      onError: (err) => {
        setSubmitError(err.message)
      },
      onCompleted: () => {
        onSuccess()
      },
    })
  }

  useEffect(() => {
    mode === 'update' && reset(transformDataToFormValues(internalData))
  }, [mode, internalData])

  const onSubmit: SubmitHandler<OrganizationFieldProps> = async (data) => {
    mode === 'create' ? await create(data) : await update(data)
  }

  return (
    <>
      <DialogTitle>{mode === 'update' ? 'Update' : 'Create New'} Organization</DialogTitle>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type='hidden' name='id' />
        <DialogContent>
          <Grid container spacing={2}>
            {submitError && (
              <Grid item xs={12}>
                <Alert severity={'error'}>{submitError}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormTextField
                name='name'
                label='Organization Name'
                type='text'
                fullWidth
                variant='standard'
                control={control}
                required={true}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            onClick={() => {
              closeDialog()
            }}
          >
            Cancel
          </Button>
          <Button type='submit' disabled={isSubmitting || !(isDirty && isValid)}>
            {mode === 'update' ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </>
  )
}
