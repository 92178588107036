import {
  Backdrop,
  Box,
  Button,
  Card,
  Chip,
  ChipPropsColorOverrides,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from '@mui/material'
import style from './page.module.css'
import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/Delete'
import WarningIcon from '@mui/icons-material/Warning'
import _ from 'lodash'
import { OverridableStringUnion } from '@mui/types'
import { useNavigate } from 'react-router-dom'
import DialogContentText from '@mui/material/DialogContentText'
import { REFRESH_LABEL_PNG } from 'lib/graphql/refreshLabelPng.ts'
import { useMutation } from '@apollo/client'
import { ARCHIVE_LABEL_INSTANCE } from 'lib/graphql/archiveLabelInstance.ts'
import { RESTORE_LABEL_INSTANCE } from 'lib/graphql/restoreLabelInstance.ts'
import dayjs from 'dayjs'

const API_WAITING_TIME = 10000

export enum PrivateLabelStatus {
  DRAFT = 'DRAFT',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

type PrivateLabel = {
  sku: string
  variantId: string
  productId: string
  productName?: string
  instanceId: string
  templateId: string
  userAccessToken: string
  job3DUrl?: string
  instanceImage3DUrl?: string
  status: PrivateLabelStatus
  createdAt?: string
  updatedAt?: string
  revisionNumber?: string
  labelFactsVersion?: string
  brandedName?: string
}

export interface PrivateLabelCardProps {
  className?: string
  label: PrivateLabel
  imgWidth?: number
  imgHeight?: number
  imgLayout?: 'fixed' | 'intrinsic' | 'responsive' | undefined
  imgPriority?: boolean
  fillImage?: boolean
  imgLoading?: 'eager' | 'lazy'
  imgSizes?: string
  setDesignerState?: (v: any) => void
  refreshCustomer: () => Promise<void>
  storeId?: string
  customerId?: string
  showArchived: boolean
}

const LabelStatusColor = {
  REJECTED: 'error',
  IN_REVIEW: 'secondary',
  DRAFT: 'info',
  APPROVED: 'success',
}

export const PrivateLabelCard: React.FC<PrivateLabelCardProps> = ({
  setDesignerState,
  label,
  imgWidth,
  imgHeight,
  imgPriority,
  imgLoading,
  imgSizes,
  imgLayout = 'responsive',
  refreshCustomer,
  storeId,
  customerId,
  showArchived,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false)
  const [loadingOverlay, setLoadingOverlay] = useState<string | undefined>(undefined)
  const navigate = useNavigate()

  const [refreshLabelPng, { loading: isRefreshing }] = useMutation(REFRESH_LABEL_PNG)
  const [archiveLabelInstance, { loading: isArchiving }] = useMutation(ARCHIVE_LABEL_INSTANCE)
  const [restoreLabelInstance, { loading: isRestoring }] = useMutation(RESTORE_LABEL_INSTANCE)

  const editInstance = () => {
    setDesignerState &&
      setDesignerState({
        mode: 'edit',
        ...label,
        showLabelDesigner: true,
      })
  }

  const ArchiveButton = () => {
    return (
      <Button
        sx={{ marginLeft: 'auto' }}
        onClick={() => (showArchived ? restoreInstance() : setShowArchiveModal(true))}
        size={'small'}
        color={showArchived ? 'primary' : 'warning'}
        disabled={isArchiving}
        className={style.productDetailButton}
      >
        {!showArchived ? <DeleteIcon /> : <>Restore</>}
      </Button>
    )
  }

  const rerenderInstance = async () => {
    setLoadingOverlay('Rendering')
    await refreshLabelPng({
      variables: {
        storeId,
        customerId: customerId?.split('/').pop(),
        instanceId: label.instanceId,
      },
    })
    setLoadingOverlay('Syncing records')
    setTimeout(async () => {
      await refreshCustomer()
      setLoadingOverlay(undefined)
    }, API_WAITING_TIME)
  }

  const restoreInstance = async () => {
    setLoadingOverlay('Restoring')
    try {
      await restoreLabelInstance({
        variables: {
          storeId,
          customerId: customerId?.split('/').pop(),
          instanceId: label.instanceId,
        },
      })
    } catch (err) {
      setLoadingOverlay(undefined)
      console.log(err)
      return
    }
    setLoadingOverlay('Syncing records')
    setTimeout(async () => {
      await refreshCustomer()
      setLoadingOverlay(undefined)
    }, API_WAITING_TIME)
  }

  const archiveInstance = async () => {
    setLoadingOverlay('Archiving')
    setShowArchiveModal(false)
    try {
      await archiveLabelInstance({
        variables: {
          storeId,
          customerId: customerId?.split('/').pop(),
          instanceId: label.instanceId,
        },
      })
    } catch (err) {
      setLoadingOverlay(undefined)
      console.log(err)
      return
    }
    setLoadingOverlay('Syncing records')
    setTimeout(async () => {
      await refreshCustomer()
      setLoadingOverlay(undefined)
    }, API_WAITING_TIME)
  }

  const getButton = (status: string) => {
    switch (status) {
      case 'DRAFT': {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            <Button
              onClick={() => editInstance()}
              size={'small'}
              color='inherit'
              variant={'outlined'}
              className={style.productDetailButton}
            >
              Edit
            </Button>
            <Button
              onClick={() => rerenderInstance()}
              size={'small'}
              color='inherit'
              variant={'outlined'}
              disabled={isRefreshing}
              className={style.productDetailButton}
            >
              Re-render
            </Button>
            <ArchiveButton />
          </Box>
        )
      }
      case 'REJECTED': {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            <Button
              onClick={() => editInstance()}
              size={'small'}
              color='inherit'
              variant={'outlined'}
              className={style.productDetailButton}
            >
              Edit
            </Button>
            <Button
              onClick={() => rerenderInstance()}
              size={'small'}
              color='inherit'
              variant={'outlined'}
              disabled={isRefreshing}
              className={style.productDetailButton}
            >
              Re-render
            </Button>
            <ArchiveButton />
          </Box>
        )
      }
      case 'APPROVED': {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            <ArchiveButton />
          </Box>
        )
      }
    }
  }

  return (
    <Box sx={{ display: 'flex', height: 'auto', alignSelf: 'stretch' }}>
      <Card
        sx={{
          position: 'relative',
          maxWidth: [700, imgWidth || 540],
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          borderColor: '#c9c9c9',
          border: 1,
          background: '#fafafa',
        }}
      >
        <Typography variant={'body2'} className={style.productCardTitle} sx={{ marginTop: '-10px' }}>
          {label.updatedAt && <>Last updated on {dayjs(label.updatedAt).format('MM/DD/YY hh:mm A')}</>}
        </Typography>

        <img src={label.instanceImage3DUrl} width='100%' onClick={() => setShowModal(true)} />
        <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
        <div className={style.labelCardText}>
          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
            <div>
              <Typography variant={'h6'} className={style.productCardTitle}>
                {label.productName || label.sku}
              </Typography>
              <Typography
                variant={'body2'}
                className={style.productCardTitle}
                sx={{ marginTop: '-10px' }}
              ></Typography>
              <Typography variant={'body2'} className={style.productCardTitle} sx={{ marginTop: '-10px' }}>
                SKU <b>{label.sku}</b>. Instance ID: <b>{label.instanceId}</b>.&nbsp;
                {label.labelFactsVersion && (
                  <>
                    Facts v<b>{label.labelFactsVersion}</b>.&nbsp;
                  </>
                )}
                {label.revisionNumber && (
                  <>
                    Revision <b>{label.revisionNumber}</b>.
                  </>
                )}
              </Typography>
            </div>
            <Chip
              label={_.startCase(label.status)}
              color={
                LabelStatusColor[label.status] as
                  | OverridableStringUnion<
                      'error' | 'secondary' | 'info' | 'success' | 'default' | 'primary' | 'warning',
                      ChipPropsColorOverrides
                    >
                  | undefined
              }
            />
          </Stack>
        </div>
        <div>{getButton(label.status)}</div>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={!!loadingOverlay}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center' }}>
            <div>
              <Typography variant={'h6'}>{loadingOverlay}</Typography>
            </div>
            <div>
              <CircularProgress color='inherit' />
            </div>
          </Box>
        </Backdrop>
        <Dialog
          // className={styles.modelAddNewCustomerGroup}
          open={showModal}
          onClose={() => setShowModal(false)}
          fullWidth
          maxWidth='lg'
        >
          <img src={label.instanceImage3DUrl} width='100%' onClick={() => setShowModal(false)} />
        </Dialog>
        <Dialog open={showArchiveModal} onClose={() => setShowArchiveModal(false)} maxWidth='lg'>
          <DialogContent>
            <DialogTitle>
              <WarningIcon color={'error'} />
            </DialogTitle>
            <DialogContentText>Are you sure you want to archive this label?</DialogContentText>
            <DialogActions>
              <Button color={'success'} onClick={() => setShowArchiveModal(false)}>
                Cancel
              </Button>
              <Button color={'warning'} onClick={() => archiveInstance()}>
                Archive
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Card>
    </Box>
  )
}
