import { gql } from '@apollo/client'

export const GET_LABEL_APPROVAL_WORKFLOW_STEP = gql`
  query getLabelApprovalWorkflowStep($where: LabelApprovalWorkflowStepExtendedWhereUniqueInput!) {
    getLabelApprovalWorkflowStep(where: $where) {
      id
      name
      notes
      status
      workflow {
        id
        name
        status
        rejectionReason
        orderId
        metadata
        activeStepNumber
        steps {
          id
          stepNumber
          assignedTo {
            email
          }
          status
          notes
          updatedAt
        }
      }
    }
  }
`
