import { gql } from '@apollo/client'

export const COUNT_ORDERS = gql`
  query countOrders($where: OrderWhereInput) {
    countOrders(where: $where)
  }
`

export const ORDERS_COUNT = gql`
  query countOrders($where: String, $fromDate: AWSDateTime, $toDate: AWSDateTime, $organizationId: String) {
    orderSearchFilter @client @export(as: "where")
    fromDate @client @export(as: "fromDate")
    toDate @client @export(as: "toDate")
    organizationId @client @export(as: "organizationId")
    countOrders(
      where: {
        organizationId: { equals: $organizationId }
        commonId: { contains: $where }
        createdAt: { gte: $fromDate, lte: $toDate }
      }
    )
  }
`
