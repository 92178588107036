import { gql } from '@apollo/client'

export const CREATE_CUSTOMER_GROUP = gql`
  mutation CreateCustomerGroup($data: CustomerGroupCreateInput!) {
    createCustomerGroup(data: $data) {
      id
      name
    }
  }
`
