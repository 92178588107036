import AppSyncConfig from 'config/AppSyncConfig'

type ExtractVariables<T> = T extends { variables: object } ? T['variables'] : never

export async function kaerwellAdminFetch<T>({
  cache = 'force-cache',
  headers,
  query,
  variables,
}: {
  cache?: RequestCache
  headers?: HeadersInit
  query: string
  tags?: string[]
  variables?: ExtractVariables<T>
}): Promise<{ status: number; body: T } | never> {
  try {
    const result = await fetch(`${AppSyncConfig.graphqlEndpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': AppSyncConfig.apiKey!,
      },
      body: JSON.stringify({
        ...(query && { query }),
        ...(variables && { variables }),
      }),
      cache,
    })

    const body = await result.json()

    if (body.errors) {
      throw body.errors
    }

    return {
      status: result.status,
      body,
    }
  } catch (e) {
    console.log(e)
    throw {
      error: e,
      query,
    }
  }
}
