import { Box, IconButton, Stack } from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Minus, Plus } from '../icons'
import { integer } from 'aws-sdk/clients/backup'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

export interface OrderQuantityProps {
  moq: number
  iq: number
  onChange: (v: number | null) => void
  defaultQuantity: number | null
}

const OrderQuantity: React.FC<OrderQuantityProps> = ({ defaultQuantity, onChange, moq, iq }) => {
  const [quantity, setQuantity] = useState<integer>(moq || 1)
  const [error, setError] = useState<string[] | undefined>()

  useEffect(() => {
    if (defaultQuantity) {
      setQuantity(defaultQuantity)
      isValidQuantity(defaultQuantity)
    }
  }, [defaultQuantity])

  const isValidQuantity = (n: number) => {
    if (n < moq) {
      setError([`Minimum quantity is ${moq}`])
      onChange(null)
      return false
    }
    if (n % iq) {
      setError([
        `Box size is ${iq}.`,
        `Valid quantities are ${moq * Math.round(n / iq)} or ${moq * (1 + Math.round(n / iq))}.`,
      ])
      return false
    }
    setError(undefined)
    onChange(n)
    return true
  }

  const increaseQuantity = (n = 1) => {
    if (quantity < moq) {
      if (n === 1) {
        setQuantity(moq)
        onChange(moq)
      } else {
        setQuantity(0)
        onChange(0)
      }
    } else {
      if ((quantity - moq) % iq === 0) {
        const val = quantity + n * iq
        setQuantity(val)
        onChange(val)
      } else if (n === -1) {
        const val = moq + Math.floor((quantity - moq) / iq) * iq
        setQuantity(val)
        onChange(val)
      } else {
        const val = moq + Math.floor((quantity - moq) / iq) * iq + n * iq
        setQuantity(val)
        onChange(val)
      }
    }
    setError(undefined)
  }

  const handleQuantity = (e: ChangeEvent<HTMLInputElement>) => {
    const val = Number(e.target.value)
    // if (Number.isInteger(val) && val >= 0) {
    setQuantity(val)
    if (isValidQuantity(val)) {
      onChange(val)
    } else {
      onChange(null)
    }
  }

  return (
    <Stack alignItems='center'>
      <Typography color='#676767' fontSize='0.75rem'>
        QTY
      </Typography>
      <Stack direction={'row'} sx={{ float: 'right' }}>
        <IconButton onClick={() => increaseQuantity(-1)} sx={{ padding: '5px' }}>
          <Minus width={18} height={18} color='#3D3D3D' />
        </IconButton>
        <TextField
          inputProps={{
            style: {
              width: '60px',
              padding: 3,
              textAlignLast: 'center',
            },
          }}
          sx={{
            height: '28px',
          }}
          type='text'
          value={quantity}
          error={!!error}
          onChange={handleQuantity}
        />
        <IconButton onClick={() => increaseQuantity(1)} sx={{ padding: '5px' }}>
          <Plus width={18} height={18} color='#3D3D3D' />
        </IconButton>
        <Box sx={{ margin: 'auto' }}>
          {(error || []).map((items) => {
            return (
              <Typography variant={'body2'} color={'error'}>
                {items}
              </Typography>
            )
          })}
        </Box>
      </Stack>
    </Stack>
  )
}

export default OrderQuantity
