import { gql } from '@apollo/client'

export const GET_SHOPIFY_STORE_CONFIGURATION = gql`
  query getShopifyStoreConfiguration($where: ShopifyStoreConfigurationWhereUniqueInput!) {
    getShopifyStoreConfiguration(where: $where) {
      id
      name
      domain
      brandedDomain
      organizationId
      configurationLastCheckedAt
      configurationErrors
      adminApiToken
      clientId
      clientSecret
      fromEmail
      customerApprovers
      assetsDomain
      assetsKeys
      createdAt
      updatedAt
    }
  }
`
