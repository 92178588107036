import * as XLSX from 'xlsx'
// import { gql } from 'graphql-request'
// import { BRAND, ORGANIZATION, PATH_DOWNLOADED_FILE } from '../config/config.mjs'
// import fetchRetry from 'fetch-retry'

export const getDataFromExcelFile = async (filename: any) => {
  const data = await new Response(filename).arrayBuffer()
  const workbook = XLSX.read(data, { type: 'binary', raw: true })
  const sheet = workbook.Sheets[workbook.SheetNames[0]]
  const cells = Object.keys(sheet)
  cells.forEach((cell) => {
    // If cell contains a hyperlink, overwrite the raw value with the hyperlink
    if (sheet[cell].l && sheet[cell].l.Target) {
      sheet[cell].v = sheet[cell].l.Target
    }
  })
  return XLSX.utils.sheet_to_json(sheet)
}

export const getDimensions = (jsonObjLabelSize: string) => {
  const dim = jsonObjLabelSize.split('x')
  return dim
}

export const getLabelSize = (jsonObjLabelSize: string) => {
  if (!jsonObjLabelSize) return undefined
  return getDimensions(jsonObjLabelSize)
}

// const fetchWithRetry = fetchRetry(global.fetch, {
//   retries: 3,
//   retryDelay: function (attempt, error, response) {
//     return Math.pow(2, attempt) * 1000
//   },
//   retryOn: [419, 503, 504, 509],
// })
//
// export const apiClient = (endpoint, token) => async (query, variables) => {
//   try {
//     const result = await fetchWithRetry(endpoint, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: token,
//       },
//       body: JSON.stringify({
//         ...(query && { query }),
//         ...(variables && { variables }),
//       }),
//     })
//
//     const body = await result.json()
//
//     if (body.errors) {
//       throw body.errors[0]
//     }
//
//     return body?.data
//   } catch (e) {
//     throw {
//       error: e,
//       query,
//     }
//   }
// }
//
// export const getSkuList = (endpoint, token) => async (brandName) => {
//   const client = apiClient(endpoint, token)
//   try {
//     return await client(SKUS_LIST, {
//       where: { brand: { name: { equals: brandName } }, archivedAt: null },
//       take: 1000,
//       orderBy: [{ sku: 'ASC' }],
//     })
//   } catch (err) {
//     console.log(err)
//   }
// }
//
// export const lookupOrganizationId = async (client) => {
//   try {
//     const orgs = await client(LIST_ORGANIZATIONS, {
//       where: {
//         name: { equals: ORGANIZATION },
//       },
//     })
//     if (orgs?.listOrganizations?.length !== 1) throw 'Brand not found'
//     return orgs.listOrganizations[0].id
//   } catch (err) {
//     console.log(err)
//     throw err
//   }
// }
//
// export const lookupBrandId = async (client) => {
//   try {
//     const brands = await client(LIST_BRANDS, {
//       where: {
//         name: { equals: BRAND },
//         organization: {
//           name: { equals: ORGANIZATION },
//         },
//       },
//     })
//     if (brands?.listBrands?.length !== 1) throw 'Brand not found'
//     return brands.listBrands[0].id
//   } catch (err) {
//     console.log(err)
//     throw err
//   }
// }
//
// export const lookupTemplates = (client) => async (skuName) => {
//   const result = await client(LIST_TEMPLATES, {
//     where: {
//       sku: {
//         sku: { equals: `${skuName}` },
//         brand: {
//           name: { equals: BRAND },
//         },
//       },
//     },
//   })
//   return result.listDesignerTemplates
// }
//
// export const LIST_ORGANIZATIONS = gql`
//   query listOrganizations($where: OrganizationWhereInput!) {
//     listOrganizations(where: $where) {
//       id
//       name
//     }
//   }
// `
//
// export const LIST_BRANDS = gql`
//   query listBrands($where: BrandWhereInput!) {
//     listBrands(where: $where) {
//       id
//       name
//       organizationId
//     }
//   }
// `
//
// export const GET_SKU = gql`
//   query getSku($where: SkuWhereUniqueInput!) {
//     getSku(where: $where) {
//       id
//       sku
//       skuWhiteLabel
//       skuFact {
//         privateLabelName
//         size
//         productName
//         dimensions
//       }
//     }
//   }
// `
//
// const SKUS_LIST = gql`
//   query listSkus($where: SkuWhereInput, $take: Int, $orderBy: [SkuOrderByInput]) {
//     listSkus(where: $where, take: $take, orderBy: $orderBy) {
//       id
//       sku
//     }
//   }
// `
//
// export const CREATE_SKU = gql`
//   mutation CreateSku($data: SkuCreateInput!) {
//     createSku(data: $data) {
//       id
//       sku
//     }
//   }
// `
//
// export const UPDATE_SKU = gql`
//   mutation updateSku($where: SkuWhereUniqueInput!, $data: SkuUpdateInput) {
//     updateSku(where: $where, data: $data) {
//       id
//     }
//   }
// `
//
// export const CREATE_TEMPLATE = gql`
//   mutation CreateDesignerTemplate($data: DesignerTemplateCreateInput!) {
//     createDesignerTemplate(data: $data) {
//       id
//     }
//   }
// `
//
// export const LIST_TEMPLATES = gql`
//   query listDesignerTemplates($where: DesignerTemplateWhereInput, $skip: Int, $take: Int) {
//     listDesignerTemplates(where: $where, skip: $skip, take: $take) {
//       skuId
//       sku {
//         sku
//       }
//       templateId
//       displayName
//       archivedAt
//     }
//   }
// `
// export const GET_TEMPLATE = gql`
//   query GetDesignerTemplate($where: DesignerTemplateExtendedWhereUniqueInput!) {
//     getDesignerTemplate(where: $where) {
//       id
//     }
//   }
// `
//
// export const UPDATE_TEMPLATE = gql`
//   mutation UpdateDesignerTemplate(
//     $where: DesignerTemplateExtendedWhereUniqueInput!
//     $data: DesignerTemplateUpdateInput!
//   ) {
//     updateDesignerTemplate(where: $where, data: $data) {
//       id
//     }
//   }
// `
//
// export const SKU_LISTINGS_LIST = gql`
//   query ($where: SkuListingWhereInput, $orderBy: [SkuListingOrderByInput], $offset: Int, $limit: Int) {
//     listSkuListings(where: $where, orderBy: $orderBy, skip: $offset, take: $limit) {
//       id
//       skuId
//       sku {
//         id
//       }
//     }
//   }
// `
//
// export const SKU_LISTINGS_LIST_DETAILS = gql`
//   query ($where: SkuListingWhereInput, $orderBy: [SkuListingOrderByInput], $offset: Int, $limit: Int) {
//     listSkuListings(where: $where, orderBy: $orderBy, skip: $offset, take: $limit) {
//       id
//       skuId
//       sku {
//         id
//       }
//       title
//       description
//       images
//       imagePresignedUrls {
//         key
//         url
//       }
//       wholesaleAvailable
//       wholesalePricing
//       wholesaleMinQuantity
//       wholesaleBoxQuantity
//       wholesaleCustomerGroups {
//         name
//       }
//       retailAvailable
//       retailPricing
//       patientAvailable
//       patientPricing
//       whiteLabelAvailable
//       whiteLabelPricing
//       whiteLabelMinQuantity
//       whiteLabelBoxQuantity
//       whiteLabelCustomerGroups {
//         name
//       }
//     }
//   }
// `
//
// export const CREATE_SKU_LISTING = gql`
//   mutation CreateSkuListing($data: SkuListingCreateInput!) {
//     createSkuListing(data: $data) {
//       id
//     }
//   }
// `
//
// export const UPDATE_SKU_LISTING = gql`
//   mutation UpdateSkuListing($where: SkuListingWhereUniqueInput!, $data: SkuListingUpdateInput!) {
//     updateSkuListing(where: $where, data: $data) {
//       id
//     }
//   }
// `
//
// export const CREATE_SUPPLEMENT_FACT = gql`
//   mutation createSupplementFact($data: SupplementFactCreateInput!) {
//     createSupplementFact(data: $data) {
//       id
//       majorVersion
//     }
//   }
// `
//
// export const UPDATE_SUPPLEMENT_FACT = gql`
//   mutation updateSupplementFact($data: SupplementFactUpdateInput!, $where: SupplementFactWhereUniqueInput!) {
//     updateSupplementFact(data: $data, where: $where) {
//       id
//     }
//   }
// `
//
// export const GET_PRESIGNED_URL_FOR_PUBLIC_ASSET = gql`
//   query getPresignedUrlForPublicAsset(
//     $type: String
//     $storeId: String
//     $orgId: String
//     $filename: String
//     $method: String
//     $contentType: String
//   ) {
//     getPresignedUrlForPublicAsset(
//       type: $type
//       storeId: $storeId
//       orgId: $orgId
//       filename: $filename
//       method: $method
//       contentType: $contentType
//     ) {
//       bucket
//       key
//       presignedUrl
//     }
//   }
// `
//
// export const LIST_SUPPLEMENT_FACTS = gql`
//   query listSupplementFacts($where: SupplementFactWhereInput, $skip: Int, $take: Int) {
//     listSupplementFacts(
//       where: $where
//       orderBy: [{ majorVersion: DESC, minorVersion: DESC }]
//       skip: $skip
//       take: $take
//     ) {
//       id
//       sku {
//         sku
//         skuWhiteLabel
//         id
//         brandId
//       }
//       createdAt
//       majorVersion
//       minorVersion
//       revisionNumber
//       status
//       signedBy {
//         email
//       }
//       signedReason
//       majorVersionUuid
//       otherIngredients
//       revisionNumber
//       suggestedUse
//       allergenStatement
//       caution
//       supplementLabelImage
//     }
//   }
// `
//
// export const LIST_DEFAULT_CUSTOMER_GROUPS = gql`
//   query listCustomerGroups($where: CustomerGroupWhereInput) {
//     listCustomerGroups(where: $where) {
//       id
//       name
//       autoAssignNewUsers
//     }
//   }
// `
